import React from 'react';
import platform from '@/assets/img/platform5th.png';
import S from '../../../../../styles/reactImg.module.css';
import ReactArBr from '@/pages/security/pca-2/adjustment/components/ReactArBr.jsx';

const ReactImg = ({ inputParams, dimensionParams, CG_CD, AV_BV_Y_Ar_Br, alert }) => {
  return (
    <div className={S.containerImg} style={{ filter: alert ? 'grayscale(100%)' : '' }}>
      <div className={S.img}>
        <img src={platform} width="360px" alt="pca-2" />
      </div>
      {!alert && (
        <>
          <div id="indigo">
            {inputParams.totalWidth >= 0 && (
              <div className={`${S.all} ${S.indigo} ${S.totalW}`}>{inputParams.totalWidth}</div>
            )}
            {inputParams.totalDepth >= 0 && (
              <div className={`${S.all} ${S.indigo} ${S.totalH}`}>{inputParams.totalDepth}</div>
            )}
          </div>

          <div id="orange">
            {/*top*/}
            {dimensionParams[1] >= 0 && <div className={`${S.all} ${S.orange} ${S.width}`}>{dimensionParams[1]}</div>}
            {/*right*/}
            {dimensionParams[0] >= 0 && <div className={`${S.all} ${S.orange} ${S.height}`}>{dimensionParams[0]}</div>}
          </div>

          <div id="CG_CD">
            {CG_CD[0] >= 0 && CG_CD[0] <= 35 ? (
              <div className={`${S.all} ${S.red} ${S.cg}`}>{CG_CD[0]}</div>
            ) : (
              <div className={`${S.all} ${S.red} ${S.cg}`}>X</div>
            )}
            {CG_CD[1] >= 0 && CG_CD[1] <= 35 ? (
              <div className={`${S.all} ${S.blue} ${S.cd}`}>{CG_CD[1]}</div>
            ) : (
              <div className={`${S.all} ${S.blue} ${S.cd}`}>X</div>
            )}
          </div>
          <div id="AV_BV_Y_Ar_Br_AR_BR">
            {AV_BV_Y_Ar_Br[0] >= 0 && <div className={`${S.all} ${S.red} ${S.av}`}>{AV_BV_Y_Ar_Br[0]}</div>}
            {AV_BV_Y_Ar_Br[1] >= 10 && <div className={`${S.all} ${S.blue} ${S.bv}`}>{AV_BV_Y_Ar_Br[1]}</div>}
            {/*if Bv<0*/}
            {AV_BV_Y_Ar_Br[1] < 10 && AV_BV_Y_Ar_Br[0] >= 0 && (
              <div className={`${S.all} ${S.blue} ${S.bv}`}>{AV_BV_Y_Ar_Br[1]}</div>
            )}
            {AV_BV_Y_Ar_Br[2] >= 0 && (
              <div className={`${S.all} ${S.green} ${S.y}`} style={{ left: AV_BV_Y_Ar_Br[2] === 0 && '-169px' }}>
                {AV_BV_Y_Ar_Br[2]}
              </div>
            )}
            {/*Here 2 blocks Ar Br*/}
            <ReactArBr AV_BV_Y_Ar_Br={AV_BV_Y_Ar_Br} />
          </div>
          <div id="indigo">
            {inputParams.doG >= 0 && (
              <div className={`${S.all} ${S.indigo} ${S.dog}`} style={{ left: inputParams.doG === 0 && '-101px' }}>
                {inputParams.doG}
              </div>
            )}
            {inputParams.lO >= 0 && (
              <div className={`${S.all} ${S.indigo} ${S.lo}`} style={{ left: inputParams.lO === 0 && '-1px' }}>
                {inputParams.lO}
              </div>
            )}
            {inputParams.doD >= 0 && (
              <div className={`${S.all} ${S.indigo} ${S.dod}`} style={{ left: inputParams.doD === 0 && '109px' }}>
                {inputParams.doD}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ReactImg;

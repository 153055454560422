import React from 'react';
import Px from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstPX/Px.jsx';
import PxPxMax from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstPX/PxPxMax.jsx';
import PxPxMin from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstPX/PxPxMin.jsx';
import PxPxPxMax from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstPX/PxPxPxMax.jsx';
import PxPxPxMin from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstPX/PxPxPxMin.jsx';
import PxPxPxRhMax from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstPX/PxPxPxRhMax.jsx';
import PxPxPxRhMin from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstPX/PxPxPxRhMin.jsx';
import PxPxRhMax from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstPX/PxPxRhMax.jsx';
import PxPxRhMin from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstPX/PxPxRhMin.jsx';
import PxPxRhRhMax from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstPX/PxPxRhRhMax.jsx';
import PxPxRhRhMin from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstPX/PxPxRhRhMin.jsx';
import PxRh from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstPX/PxRh.jsx';
import PxRhRh from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstPX/PxRhRh.jsx';

import ReactImgRS from './ReactImgRS.jsx';

import Sh from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstMeter/Sh.jsx';
import ShRh from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstMeter/ShRh.jsx';
import ShRhRh from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstMeter/ShRhRh.jsx';
import ShPx from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstMeter/ShPx.jsx';
import ShPxPxMax from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstMeter/ShPxPxMax.jsx';
import ShPxPxMin from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstMeter/ShPxPxMin.jsx';
import ShPxPxRhMax from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstMeter/ShPxPxRhMax.jsx';
import ShPxPxRhMin from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstMeter/ShPxPxRhMin.jsx';
import ShPxPxRhRhMax from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstMeter/ShPxPxRhRhMax.jsx';
import ShPxPxRhRhMin from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstMeter/ShPxPxRhRhMin.jsx';
import ShPxRh from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstMeter/ShPxRh.jsx';
import ShPxRhRh from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstMeter/ShPxRhRh.jsx';

import SHPxPxMax from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstMeterAndHalf/SHPxPxMax.jsx';
import SHPx from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstMeterAndHalf/SHPx.jsx';
import SH from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstMeterAndHalf/SH.jsx';
import SHPxPxMin from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstMeterAndHalf/SHPxPxMin.jsx';
import SHPxPxRhMax from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstMeterAndHalf/SHPxPxRhMax.jsx';
import SHPxPxRhMin from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstMeterAndHalf/SHPxPxRhMin.jsx';
import SHPxPxRhRhMax from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstMeterAndHalf/SHPxPxRhRhMax.jsx';
import SHPxPxRhRhMin from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstMeterAndHalf/SHPxPxRhRhMin.jsx';
import SHPxRh from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstMeterAndHalf/SHPxRh.jsx';
import SHPxRhRh from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstMeterAndHalf/SHPxRhRh.jsx';
import SHRh from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstMeterAndHalf/SHRh.jsx';
import SHRhRh from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/FirstMeterAndHalf/SHRhRh.jsx';

function ReactImagesBallast({ allHeightsPanel, B = 0, D, T, L, typeBox, selectedSecondH }) {
  return (
    <>
      {allHeightsPanel.length > 0 && (
        <>
          <Px allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} />
          <PxPxMax allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} B={B} fixTop={selectedSecondH} />
          <PxPxMin allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} B={B} fixTop={selectedSecondH} />
          <PxPxPxMax allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} B={B} fixTop={selectedSecondH} />
          <PxPxPxMin allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} B={B} fixTop={selectedSecondH} />
          <PxPxPxRhMax allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} B={B} fixTop={selectedSecondH} />
          <PxPxPxRhMin allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} B={B} fixTop={selectedSecondH} />
          <PxPxRhMax allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} B={B} fixTop={selectedSecondH} />
          <PxPxRhMin allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} B={B} fixTop={selectedSecondH} />
          <PxPxRhRhMax allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} B={B} fixTop={selectedSecondH} />
          <PxPxRhRhMin allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} B={B} fixTop={selectedSecondH} />
          <PxRh allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} />
          <PxRhRh allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} />

          <Sh allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} />
          <ShPx allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} />
          <ShPxPxMax allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} B={B} fixTop={selectedSecondH} />
          <ShPxPxMin allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} B={B} fixTop={selectedSecondH} />
          <ShPxPxRhMax allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} B={B} fixTop={selectedSecondH} />
          <ShPxPxRhMin allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} B={B} fixTop={selectedSecondH} />
          <ShPxPxRhRhMax allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} B={B} fixTop={selectedSecondH} />
          <ShPxPxRhRhMin allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} B={B} fixTop={selectedSecondH} />
          <ShPxRh allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} />
          <ShPxRhRh allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} />
          <ShRh allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} />
          <ShRhRh allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} />

          <SH allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} />
          <SHPx allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} />
          <SHPxPxMax allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} B={B} fixTop={selectedSecondH} />
          <SHPxPxMin allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} B={B} fixTop={selectedSecondH} />
          <SHPxPxRhMax allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} B={B} fixTop={selectedSecondH} />
          <SHPxPxRhMin allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} B={B} fixTop={selectedSecondH} />
          <SHPxPxRhRhMax allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} B={B} fixTop={selectedSecondH} />
          <SHPxPxRhRhMin allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} B={B} fixTop={selectedSecondH} />
          <SHPxRh allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} />
          <SHPxRhRh allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} />
          <SHRh allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} />
          <SHRhRh allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} />

          <ReactImgRS allHeightsPanel={allHeightsPanel} T={T} D={D} L={L} typeBox={typeBox} />
        </>
      )}
    </>
  );
}

export default ReactImagesBallast;

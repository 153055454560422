import React, { useEffect, useState } from 'react';
import { makeStyles, MenuItem, Select } from '@material-ui/core';
import S from '../../pages/formwork/boxes/styles/ConfiguratorSumming.module.css';
import customConfiguratorStyle from '../../styles/CustomConfiguratorStyle.js';
import style from '../../pages/formwork/boxes/styles/optionStyle.module.css';
import {
  updateHeightCategory,
  updateRecommendStab,
  calcPossibleD1,
  calcPossibleD2,
  calcPossibleDFinally,
  calcBallastCategory,
  calcHeightBallast,
  updateRecommendDiagonalBrace,
} from '@/components/SubSectionComponents/CleanFunctions.jsx';

function SelectionBlockDH({
  updateH,
  updateD = false,
  totalHeight,
  flag_3m_2_8m_FirstH_DoubleTop,
  speedWind,
  ballastHeight,
  updateLengthDS = () => {},
  updateLengthTheLowerDiagonalBrace,
  flag,
  fV,
  widthFormPanels,
  updateSelectedSecondH,
}) {
  const styleCustom = makeStyles(customConfiguratorStyle)();

  const [variableD, setVariableD] = useState([]);
  const [variableH, setVariableH] = useState([]);
  const [selectedH, setSelectedH] = useState(0);
  const [selectedD, setSelectedD] = useState([]);

  useEffect(() => {
    updateH(selectedH);
    if (variableH.length > 1) {
      updateSelectedSecondH((selectedH + 0.12).toFixed(2) === variableH[1].toFixed(2));
    }
  }, [selectedH]);

  useEffect(() => {
    if (updateD) {
      updateD(selectedD);
    }
  }, [selectedD]);

  //update Length The Lower Diagonal Brace (not to reduce!)
  useEffect(() => {
    if (flag === 'anchor') {
      let calculation = Math.pow((selectedD - 0.28) ** 2 + (0.5 - 0.28) ** 2, 0.5);
      updateLengthTheLowerDiagonalBrace(calculation);
    }
    if (flag === 'ballast') {
      let calculation = Math.pow((selectedD - 0.26 - 0.2) ** 2 + (1.27 - 0.2 - 0.17) ** 2, 0.5);
      updateLengthTheLowerDiagonalBrace(calculation);
    }
  }, [selectedD]);

  useEffect(() => {
    updateLengthDS(Math.pow((selectedD - 1.21) ** 2 + (selectedH - ballastHeight) ** 2, 0.5));
  }, [selectedD, selectedH, ballastHeight]);

  //update height category
  useEffect(() => {
    const [newHeightCategory, theoreticalH] = updateHeightCategory(totalHeight, flag_3m_2_8m_FirstH_DoubleTop);

    //update all possible heights
    setVariableH((prev) => [...prev, 1.9]);
    if (totalHeight) {
      if (newHeightCategory === 2) {
        setVariableH([1.5]);
      } else if (newHeightCategory === 3.5) {
        let flagParam = flag_3m_2_8m_FirstH_DoubleTop[0] ? 3 : 2.8;
        setVariableH([flagParam]);
      } else if (newHeightCategory === 4.5) {
        if (theoreticalH === 4 || (theoreticalH > 4.3 && theoreticalH <= 4.5)) {
          if (flag_3m_2_8m_FirstH_DoubleTop[2] === 1000) {
            setVariableH([4]);
          } else {
            if (flag_3m_2_8m_FirstH_DoubleTop[2] === 1500) {
              setVariableH([4.5]);
            } else {
              setVariableH([3.5]);
            }
          }
        } else {
          if (theoreticalH <= 4.3 && flag_3m_2_8m_FirstH_DoubleTop[2] === 1000) {
            setVariableH([3.8]);
          } else {
            if (flag_3m_2_8m_FirstH_DoubleTop[2] === 1500) {
              setVariableH([4.3]);
            } else {
              setVariableH([3.3]);
            }
          }
        }
      } else if (newHeightCategory === 5) {
        let flagParam = flag_3m_2_8m_FirstH_DoubleTop[0] ? 4.5 : 4.3;
        setVariableH([flagParam]);
      } else if (newHeightCategory === 6.5) {
        let flagParam = flag_3m_2_8m_FirstH_DoubleTop[0] ? [3, 6] : [2.8, 5.6];
        setVariableH(flagParam);
      } else if (newHeightCategory === 7.5) {
        if (flag_3m_2_8m_FirstH_DoubleTop[2] === 1000 && flag_3m_2_8m_FirstH_DoubleTop[0]) {
          setVariableH([4, 7]);
        } else if (flag_3m_2_8m_FirstH_DoubleTop[2] === 1500 && flag_3m_2_8m_FirstH_DoubleTop[0]) {
          setVariableH([4.5, 7.5]);
        } else if (flag_3m_2_8m_FirstH_DoubleTop[2] === 1000 && flag_3m_2_8m_FirstH_DoubleTop[1]) {
          setVariableH([3.8, 6.6]);
        } else {
          setVariableH([4.3, 7.1]);
        }
      } else if (newHeightCategory === 8) {
        const flagParam = flag_3m_2_8m_FirstH_DoubleTop[0] ? [4.5, 7.5] : [4.3, 7.1];
        setVariableH(flagParam);
      } else if (newHeightCategory === 9.5) {
        const flagParam = flag_3m_2_8m_FirstH_DoubleTop[0] ? [6, 9] : [5.6, 8.4];
        setVariableH(flagParam);
      }
    }
  }, [totalHeight]);

  //update all possible Positions du lest D
  useEffect(() => {
    setVariableD([]);

    const distanceD = [1.9, 2.5, 3, 3.5, 4, 4.5, 5];
    let weightOfMini = [];
    let ballastWeight = [];
    let ballastCategory = [];
    let varBallastHeight = [];
    let liftSafetyFactor = [];
    let distanceDTypeStab = [];
    let lengthLowerBracon = [];
    let poacher = [];
    let result = [];
    let stabLength = [];
    let possibleD1 = [];
    let possibleD2 = [];

    for (let i = 0; i < 7; i++) {
      weightOfMini[i] = Math.round((fV * (totalHeight / 2)) / (distanceD[i] - 0.17));
      //Ballast Weight
      ballastWeight[i] = Number((weightOfMini[i] / 1000).toFixed(1));
      ballastCategory[i] = calcBallastCategory(ballastWeight[i]);
      //Height
      varBallastHeight[i] = calcHeightBallast(ballastCategory[i]);

      stabLength[i] =
        varBallastHeight[i] === ''
          ? ''
          : Number(Math.pow((distanceD[i] - 1.21) ** 2 + (selectedH - varBallastHeight[i]) ** 2, 0.5).toFixed(2));

      liftSafetyFactor[i] = Number(((widthFormPanels * totalHeight * 180) / weightOfMini[i]).toFixed(2));
      distanceDTypeStab[i] = updateRecommendStab(stabLength[i], totalHeight, speedWind, varBallastHeight[i]);

      lengthLowerBracon[i] = ((distanceD[i] - 0.26 - 0.2) ** 2 + (1.27 - 0.2 - 0.17 ** 2) ** 2) ** 0.5;

      poacher[i] = updateRecommendDiagonalBrace(lengthLowerBracon[i], totalHeight);

      possibleD1[i] = calcPossibleD1(totalHeight, distanceDTypeStab[i], distanceD[i], speedWind, poacher[i]);
      possibleD2[i] = calcPossibleD2(totalHeight, distanceDTypeStab[i], distanceD[i], speedWind, poacher[i]);
      result[i] = calcPossibleDFinally(totalHeight, speedWind, distanceDTypeStab[i], possibleD1[i], possibleD2[i]);
    }
    result = result.filter((el) => el > 0);

    if (selectedH === 0) {
      setVariableD(distanceD);
    } else {
      setVariableD(result);
    }
  }, [totalHeight, fV, widthFormPanels, selectedH, ballastHeight]);

  return (
    <div className={`${S.boxForm} ${S.w350}`}>
      <div className={S.boxForm_Title}>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <div>Choix de position D & hauteur d'accrochage</div>
      </div>
      {variableH !== 'La hauteur indiquée est trop élevée' ? (
        <div>
          <div className={S.blockDHContainer}>
            <div className={S.blockDHSelect}>
              <div>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <h3>Hauteur d'accrochage</h3>
              </div>

              <div>
                <Select
                  className={`${style.counterInput} ${S.ffSerif}`}
                  classes={{ outlined: styleCustom.selectOutlined }}
                  onChange={(e) => {
                    setSelectedH(e.target.value - 0.12);
                  }}
                  value={(Number(selectedH) + 0.12).toFixed(2)}
                >
                  {variableH.map((param, index) => (
                    <MenuItem
                      className={`${styleCustom.selectList} ${S.ffSerif}`}
                      value={Number(param).toFixed(2)}
                      key={index}
                      about={index === 1 ? 'second' : 'first'}
                    >
                      {Number(param).toFixed(2)} m
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            {updateD !== false && (
              <div className={S.blockDHSelect}>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <div>{flag === 'anchor' ? <h3>Distance D de l'ancrage</h3> : <h3>Position du Lest (D)</h3>}</div>
                <div>
                  <Select
                    className={`${style.counterInput} ${S.ffSerif}`}
                    onChange={(e) => setSelectedD(e.target.value)}
                    value={selectedD}
                  >
                    {variableD.map((param, index) => (
                      <MenuItem className={`${styleCustom.selectList} ${S.ffSerif}`} value={param} key={index}>
                        {param} m
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div style={{ margin: '20px' }}>{variableH}</div>
      )}
    </div>
  );
}

export default SelectionBlockDH;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ReactPlayer from 'react-player';
import usePage from '@/hooks/usePage.js';
import Request from '@/helpers/request.js';
import Constants from '@/constants.js';
import mainStyles from '@/assets/js/mainStyles.js';

const useStyles = makeStyles(mainStyles);

const AdditionalImplementation = () => {
  const classes = useStyles();
  const { accessoryId } = useParams();
  const [accessory, setAccessory] = useState({});
  const { setTitle } = usePage();

  useEffect(async () => {
    const fetchAccessories = async () => {
      const response = await Request.get(`/accessories/${accessoryId}`);
      setAccessory(response);
    };
    setTitle('Mise en œuvre');
    await fetchAccessories();
  }, []);
  const accessoryData = accessory.usage
    ? accessory.usage
    : {
        text: '',
        video: '',
        images: [],
      };
  const images = accessoryData.images !== undefined ? accessoryData.images : [];
  return (
    <Container maxWidth="md">
      <Box className={classes.descRoot}>
        <div className={classes.secTitle}>{accessory.title}</div>
        <div className={classes.description}>{accessoryData.text}</div>
        {accessoryData?.video && (
          <Box justifyContent={'center'} className={classes.videoBox}>
            <ReactPlayer url={accessoryData?.video} width="100%" height="100%" controls />
          </Box>
        )}

        <Box>
          {images.map((item, index) => (
            <Card key={index} className={classes.imgCard}>
              <CardActionArea>
                <CardMedia
                  className={classes.imgItem}
                  component="img"
                  alt={item.image.name}
                  image={`${Constants.BASE_URL}/${item.image.path}`}
                />
                <CardContent>
                  <Typography className={classes.subTitle} variant="body2" color="textSecondary" component="p">
                    {item.title}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default AdditionalImplementation;

import axios from 'axios';
import Constants from '../../src/constants.js';
import Response from './response.js';

class Request {
  static async request(url, method = 'get', data) {
    try {
      const response = await axios.request({
        url: `${Constants.API_URL}${url}`,
        method,
        data,
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
        },
      });

      return response.data;
    } catch (error) {
      const { status } = error.response;
      if (status === Response.HTTP_BAD_REQUEST || status === Response.HTTP_UNPROCESSABLE_ENTITY) {
        throw error.response.data;
      }
      throw error;
    }
  }

  static async get(url) {
    return Request.request(url, 'get');
  }

  static async post(url, data) {
    return Request.request(url, 'post', data);
  }

  static async put(url, data) {
    return Request.request(url, 'put', data);
  }

  static async patch(url, data) {
    return Request.request(url, 'patch', data);
  }
}

export default Request;

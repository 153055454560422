import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography,
  Zoom,
} from '@material-ui/core';
import { ArrowForwardIos, Clear, OpenInNewOutlined } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { getData } from '@/mocks/search.js';
import { BASE_URL } from '@/constants.js';

const useStyles = makeStyles(() => ({
  content: {
    textAlign: 'center',
  },
  searchField: {
    width: '40%',
  },
  list: {
    marginTop: 30,
  },
  searchTitle: {
    padding: '10px 0',
  },
  copyIcon: {
    marginLeft: 10,
    cursor: 'pointer',
    float: 'right',
  },
}));

const SearchDialog = ({ open, setOpen, query = null }) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [matches, setMatches] = useState([]);
  const [tooltip, setTooltip] = useState(false);

  const close = () => {
    setOpen(false);
    setMatches([]);
    setSearch('');
  };

  const clear = () => {
    setSearch('');
    setMatches([]);
  };

  const searchMatches = useCallback(async (value) => {
    setSearch(value);

    if (value.length === 0) {
      setMatches([]);

      return;
    }

    if (value.length > 2) {
      try {
        const response = await getData(value);

        if (response) {
          setMatches(response);
        }
      } catch (err) {
        console.error(err);
      }
    }
  }, []);

  const forwardSearch = () => {
    navigator.clipboard.writeText(`${BASE_URL}/#/?search=${search}`);
    setTooltip(true);
  };

  useEffect(() => {
    if (query) {
      setSearch(query);
      searchMatches(query);
    }
  }, [query, searchMatches]);

  return (
    <Dialog fullWidth maxWidth={'xl'} open={open} onClose={() => close()}>
      <DialogTitle className={classes.content}>
        Vous cherchez:
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => setTooltip(false)}
          open={tooltip}
          TransitionComponent={Zoom}
          enterDelay={500}
          leaveDelay={2000}
          title="copié"
        >
          <OpenInNewOutlined
            className={classes.copyIcon}
            onClick={() => forwardSearch()}
            htmlColor="#748D9A"
            fontSize="medium"
          />
        </Tooltip>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Input
          id="search"
          className={classes.searchField}
          variant="standard"
          size={'medium'}
          value={search}
          onChange={({ target }) => searchMatches(target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => clear()} edge="end">
                <Clear />
              </IconButton>
            </InputAdornment>
          }
        />
        {matches.length > 0 && (
          <Typography component="div" className={classes.searchTitle}>
            <Typography component="h3" style={{ paddingRight: 10, fontWeight: 800 }}>
              Les éléments contenants:
            </Typography>{' '}
            {search}{' '}
          </Typography>
        )}
        <List className={classes.list} disablePadding>
          {matches.length > 0 &&
            matches.map((item, index) => (
              <ListItem onClick={() => close()} component={Link} key={index} button to={item.href}>
                <ListItemIcon>
                  <ArrowForwardIos />
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItem>
            ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close()}>Annuler</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SearchDialog;

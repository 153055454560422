export const conditions = {
  doG: {
    skip: true,
  },
  lO: {
    skip: true,
  },
  doD: {
    skip: true,
  },
  totalWidth: {
    min: 160,
    max: 260,
    title: 'LARGEUR',
    cm: 1,
    skip: false,
  },
  totalDepth: {
    min: 160,
    max: 324,
    title: 'PROFONDEUR',
    cm: 1,
    skip: false,
  },
  totalHeight: {
    min: 250,
    max: 480,
    title: 'HAUTEUR',
    cm: 1,
    skip: false,
  },
};

export const defaultParams = {
  doG: '',
  lO: '',
  doD: '',
  totalWidth: '',
  totalDepth: '',
  totalHeight: '',
};

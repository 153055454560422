import React, { useEffect, useRef, useState } from 'react';
import { windZone, windPower } from '@/pages/formwork/boxes/API_Data/fetchParametresBoxes.jsx';
import style from '../../pages/formwork/boxes/styles/optionStyle.module.css';
import S from '../../pages/formwork/boxes/styles/ConfiguratorSumming.module.css';

const RegionWind = ({ updateSpeed, typeBox }) => {
  const [number, setNumber] = useState('№');
  const [department, setDepartment] = useState('Département');
  const [windSpeed, setWindSpeed] = useState('');
  const [normalSpeed, setNormalSpeed] = useState(typeBox === 'BoxRS');
  const [hideSelectionBox, setHideSelectionBox] = useState(false);
  const numberReg = useRef();
  const nameReg = useRef();
  const checkNormal = useRef();

  useEffect(() => {
    setHideSelectionBox(typeBox === 'BoxRS' ? true : normalSpeed);
  }, [typeBox, normalSpeed]);

  useEffect(() => {
    if (typeBox === 'BoxRS') {
      updateSpeed(85);
      setWindSpeed(85);
    }
    if (typeBox !== 'BoxRS') {
      updateSpeed('');
      setWindSpeed('');
      setNormalSpeed(false);
    }
  }, [typeBox]);

  function updateFindData(findReg) {
    if (findReg.length) {
      setTimeout(() => {
        setNumber(findReg[0].regionNumber);
        setDepartment(findReg[0].region);
      });
      windPower.forEach((elem) => {
        if (elem.zone === findReg[0].zone) {
          setWindSpeed(elem.speed);
          updateSpeed(elem.speed);
        }
      });
    }
    numberReg.current.value = findReg[0]?.regionNumber;
    nameReg.current.value = findReg[0]?.region;
  }

  useEffect(() => {
    checkNormal.current.checked = normalSpeed;

    if (normalSpeed) {
      updateSpeed(85);
      setWindSpeed(85);
      numberReg.current.value = '';
      nameReg.current.value = '';
    } else {
      updateSpeed('');
      setWindSpeed('');
      setNormalSpeed(false);
    }
  }, [normalSpeed]);

  useEffect(() => {
    let findReg = '';
    findReg = windZone.filter((region) => region.regionNumber === number);
    updateFindData(findReg);
  }, [number]);

  useEffect(() => {
    let findReg = '';
    findReg = windZone.filter((region) => region.region === department);
    updateFindData(findReg);
  }, [department]);

  return (
    <div className={S.boxForm}>
      <div className={S.boxForm_Title}>
        <div className={S.boxForm_Title_text}>Choix du département </div>
      </div>

      <div>
        {!hideSelectionBox && (
          <div className={S.regWind}>
            <div className={S.regWindContainer}>
              <div>
                <h3>Département</h3>
              </div>
              <div>
                <select
                  id="numberDepartment"
                  ref={numberReg}
                  className={`${style.select} ${S.w50}`}
                  onChange={(event) => {
                    setNumber(event.target.value);
                  }}
                  disabled={hideSelectionBox}
                >
                  <option key={'-1'} value={'-1'} />
                  {windZone.map((element, index) => (
                    <option key={index} value={element.regionNumber}>
                      {element.regionNumber}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className={`department ${S.regWindDepartment}`}>
              <div>
                <h3>Nom de département</h3>
              </div>
              <div>
                <select
                  id="nameDepartment"
                  ref={nameReg}
                  className={style.select}
                  onChange={(event) => {
                    setDepartment(event.target.value);
                  }}
                  disabled={hideSelectionBox}
                >
                  <option key={'-1'} value={'-1'} />
                  {windZone.map((element, index) => (
                    <option key={index} value={element.region}>
                      {element.region}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}

        <div
          className={S.regCheckContainer}
          style={{ marginLeft: hideSelectionBox ? '18px' : '' }}
          onClick={() => (typeBox === 'BoxRS' ? '' : setNormalSpeed((prev) => !prev))}
        >
          <input
            ref={checkNormal}
            checked={typeBox === 'BoxRS' ? true : normalSpeed}
            type="checkbox"
            className={S.regCheckInput}
          />
          <div>Norme</div>
        </div>

        {windSpeed > 0 && (
          <div className={S.regWindResult}>
            <div>
              <h4>Vitesse de vent: </h4>
            </div>
            <div className={S.ffSerif}>
              <div>{windSpeed} Km/h</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegionWind;

import React, { useEffect, useState } from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { AppBar, Container, Toolbar, Link, Typography, Button, Box, makeStyles } from '@material-ui/core';
import HeaderIcon from '@/components/HeaderIcon.jsx';
import SearchDialog from '@/components/SearchDialog.jsx';
import logo from '../assets/img/horizontal-logo.png';
import usePage from '../hooks/usePage.js';

const useStyles = makeStyles(() => ({
  header: {
    background: '#DBE5EB',
    padding: '20px 0 10px',
    color: '#4D5B71',
    boxShadow: 'none',
  },
  headerNav: {
    padding: 0,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  headerLogo: {
    color: '#4D5B71',
    fontWeight: 900,
    textAlign: 'center',
  },
  homeIcon: {
    fontSize: 38,
    marginBottom: '40px',
  },
  headerTitle: {
    color: '#4B4440',
    fontWeight: 600,
    fontSize: '1.4rem',
    fontFamily: 'Saira',
    letterSpacing: '0.2rem',
    marginTop: '20px',
    textTransform: 'uppercase',
  },
  rightButtons: {
    position: 'relative',
    height: 'auto',
  },
  backBtn: {
    display: 'block',
    minWidth: 32,
    height: 32,
    borderRadius: '50%',
    padding: 0,
    margin: '0 auto',
    color: '#4D5B71',
    borderColor: '#4D5B71',
    borderWidth: 2,
    '&:hover': {
      background: '#4D5B71',
      color: '#f1f2f4',
    },
  },
  logoImg: {
    maxWidth: 200,
  },
  logoText: {
    marginLeft: 5,
    fontSize: 30,
    fontWeight: 700,
    fontFamily: 'sans-serif',
  },
  headerContainer: {
    padding: '0 35px',
  },
}));

const Header = () => {
  const { title } = usePage();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  // Search forward
  const searchParam = location.search.slice(1, location.search.length);
  const query = new URLSearchParams(searchParam);
  const queryParam = query.get('search');

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (queryParam) {
      setOpen(true);
    }
  }, [location, queryParam]);

  // if (queryParam) {
  //   setOpen(true);
  // }

  return (
    <AppBar className={classes.header} position="static">
      <Container className={classes.headerContainer} maxWidth="md">
        <Toolbar className={classes.headerNav}>
          <Box>
            {/*Earth*/}
            <Link href="https://www.sateco.fr" color="inherit">
              <HeaderIcon top={'-52px'} left={'-31px'} className={classes.homeIcon} />
            </Link>

            {location.pathname !== '/' && (
              //back icon
              <Button className={classes.backBtn} onClick={() => history.goBack()} variant="outlined">
                <ChevronLeftIcon style={{ fontSize: 28 }} />
              </Button>
            )}
          </Box>
          {/*mail Logo*/}
          <Box>
            <Link component={RouterLink} to="/" color="inherit">
              <div className={classes.headerLogo}>
                <img className={classes.logoImg} src={logo} alt="sateco" />
                {/*{location.pathname !== '/' && <span className={classes.logoText}>tuto</span>}*/}
              </div>
            </Link>

            <Typography className={classes.headerTitle} align="center" component="div">
              {title}
            </Typography>
          </Box>

          <Box className={classes.rightButtons}>
            {/*Search*/}
            <Link href="#" color="inherit" onClick={() => setOpen(true)}>
              <HeaderIcon top={'-34px'} left={'-57px'} className={classes.homeIcon} />
            </Link>

            {/*Mail*/}
            <Link href="mailto:info@sateco.fr" color="inherit">
              <HeaderIcon top={'-52px'} left={'-57px'} />
            </Link>
          </Box>
        </Toolbar>
      </Container>
      <SearchDialog open={open} setOpen={setOpen} query={queryParam} />
    </AppBar>
  );
};

export default Header;

import React, { useEffect, useState } from 'react';
import S from '@/styles/adjustment.module.css';

function Kit({ depth, height, visible }) {
  const [kitH, setKitH] = useState(0);
  const [kitD, setKitD] = useState(0);

  useEffect(() => {
    setKitH(height > 380 ? 1 : 0);
    setKitD(depth > 254 ? 1 : 0);
  }, [depth, height]);

  if (!visible) return <></>;

  return (
    <div className={S.boxContainerMini}>
      <div className={S.boxHeader}>KIT GRANDE</div>
      <div className={S.kitContainer}>
        <div>
          <div> HAUTEUR</div>
          <div>{kitH}</div>
        </div>
        <div>
          <div> PROFONDEUR</div>
          <div>{kitD}</div>
        </div>
      </div>
    </div>
  );
}

export default Kit;

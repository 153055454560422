import React, { useEffect, useState } from 'react';
import { checkElement } from '@/components/SubSectionComponents/CleanFunctions.jsx';
import img from '@/assets/img/BallastImgs/firstPx/LEST_PX+PX+PX_Mini.svg';
import S from '@/styles/reactImgsBallast.module.css';
import InfoT from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/InfoT.jsx';
import InfoD from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/InfoD.jsx';
import InfoL from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/InfoL.jsx';
import ConfigStabilizeInfoButton from '@/pages/formwork/boxes/ConfiguratorStabilizier/ConfigStabilizeInfoButton.jsx';
import InfoB from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/InfoB.jsx';

function PxPxPxMax({ allHeightsPanel, T, D, L, B, fixTop }) {
  const [find, setFind] = useState(false);

  useEffect(() => {
    if (allHeightsPanel.length === 3 && checkElement.allPanels(allHeightsPanel) && !fixTop) {
      setFind(true);
    } else setFind(false);
  }, [allHeightsPanel, fixTop]);

  if (!find) {
    return null;
  }

  return (
    <div className={S.container}>
      <img className={S.containerImg} src={img} alt={'3m'} style={{ top: 0 }} />
      <InfoT top={310} left={195} T={T} />
      <InfoD top={465} left={119} D={D} />
      <InfoL top={413} left={173} L={L} />
      <InfoB top={362} left={120} B={B} />
      <ConfigStabilizeInfoButton top={-20} />
    </div>
  );
}

export default PxPxPxMax;

const halfBlue = '#4D5B71';
const locGray = '#4B4440';
const skyBlue = '#A5BECD';
const saira = 'Saira, sans-serif';

const mainStyles = {
  mainBtn: {
    background: halfBlue,
    color: '#fff',
    margin: '30px auto',
    maxWidth: '325px',
    width: '100%',
    textTransform: 'none',
    padding: 7,
    display: 'block',
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'normal',
    '&.disabled': {
      color: '#fff',
    },
    fontFamily: saira,
  },
  outlinedBtn: {
    color: halfBlue,
    fontFamily: saira,
    margin: '5px auto',
    maxWidth: '325px',
    width: '100%',
    textTransform: 'none',
    padding: '10px 15px',
    fontSize: 16,
    fontWeight: 'normal',
    border: '2px solid #A5BECD',
    borderRadius: 6,
    '& span': {
      color: locGray,
    },
    '&.disabled': {
      opacity: 0.3,
      border: '2px solid #A5BECD',
      color: halfBlue,
    },
    '&:hover': {
      backgroundColor: skyBlue,
      borderColor: '#5898ad',
    },
  },
  doubleBtn: {
    '& span': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  textBox: {
    color: locGray,
    fontFamily: saira,
    maxWidth: 330,
    fontSize: 22,
    whiteSpace: 'nowrap',
    margin: '60px auto 50px',
    // display: 'flex',
    '@media (max-width: 425px)': {
      margin: '25px auto 25px',
      fontSize: 20,
    },
    '@media (max-width: 375px)': {
      margin: '25px auto 25px',
      fontSize: 18,
    },
    '@media (max-width: 320px)': {
      margin: '25px auto 25px',
      fontSize: 16,
    },
  },
  categoryBtn: {
    fontSize: '20px',
    margin: '5px auto',
    maxWidth: '325px',
    width: '100%',
    textTransform: 'none',
    borderRadius: '6px',
    padding: 7,
    border: '2px solid #ccc',
    textAlign: 'center',
  },
  containerTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  containerInner: {
    maxWidth: 350,
    margin: '0 auto',
    textAlign: 'center',
  },
  selectOutlined: {
    padding: '13px 30px',
  },
  selectOutlinedW70: {
    padding: '13px 30px',
    width: 70,
    fontFamily: saira,
  },
  selectOutlinedPR: {
    paddingRight: 50,
  },
  formControl: {
    margin: 10,
    marginLeft: 0,
    minWidth: 100,
  },
  selectLabel: {
    position: 'static',
    color: '#3a3a3a',
    fontSize: '20px',
    transform: 'translate(0px, -6px) scale(0.75)',
    whiteSpace: 'nowrap',
    fontFamily: saira,
  },
  cardsBox: {
    marginTop: 5,
    marginBottom: 5,
    fontFamily: saira,
  },

  cardItem: {
    textAlign: 'center',
    position: 'relative',
  },
  hotLine: {
    margin: '40px auto',
    maxWidth: '400px',
    width: '100%',
  },
  warning: {
    margin: '40px auto 0',
    width: '200px',
  },

  imgItem: {
    width: '100%',
    height: 'auto',
    minHeight: '300px',
    margin: '0 auto',
    backgroundSize: 'contain',
    backgroundPosition: 'top',
    '@media (min-width: 350px)': {
      minHeight: '400px',
    },
    '@media (min-width: 425px)': {
      minHeight: '500px',
    },
    '@media (min-width: 500px)': {
      minHeight: '600px',
    },
  },
  imgItemAdditionalDesc: {
    width: '100%',
    height: 'auto',
    margin: '0 auto',
  },

  imgCard: {
    marginBottom: 15,
    width: '100%',
  },
  cardImg: {
    height: 140,
    margin: '35px auto 0',
  },
  cardsContainer: {
    flexGrow: 1,
    marginTop: 10,
    justifyContent: 'center',
  },
  paper: {
    height: 140,
    width: 100,
  },
  badge: {
    position: 'absolute',
    top: 10,
    left: 10,
    background: skyBlue,
    fontFamily: saira,
    color: '#000000',
    minWidth: 75,
    padding: 5,
    borderRadius: 5,
    textAlign: 'center',
    fontWeight: 700,
    display: 'inline-block',
  },
  active: {
    border: '2px solid #e43a33',
  },

  disabled: {
    pointerEvents: 'none',
    filter: 'grayscale(100%)',
  },
  cardsModule: {
    marginTop: 5,
    marginBottom: 5,
    height: 200,
  },
  selectBody: {
    '& fieldset': {
      border: '2px solid #333333',
      borderRadius: 10,
    },
  },
  selectList: {
    padding: '5px 15px',
    display: 'block',
    textAlign: 'center',
    fontFamily: saira,
  },
  root: {
    margin: '0 auto',
    fontFamily: saira,
  },
  centerSelects: {
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 425px)': {
      display: 'grid',
    },
  },
  videoInner: {
    textAlign: 'center',
    marginTop: 40,
    marginBottom: 20,
    '& iframe': {
      maxWidth: 540,
      maxHeight: 300,
      width: '100% !important',
      '@media (min-width: 350px)': {
        height: 290,
      },
      '@media (min-width: 425px)': {
        height: 320,
      },
      '@media (min-width: 500px)': {
        height: 420,
      },
      '@media (min-width: 768px)': {
        height: 620,
      },
    },
  },
  content: {
    display: 'flex',
    fontFamily: saira,
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    position: 'relative',
    minHeight: 'calc(100vh - 550px)',
    '@media (min-width: 768px)': {
      minHeight: 'calc(100vh - 598px)',
    },
  },
  contentMW: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 550px)',
    '@media (min-width: 768px)': {
      minHeight: 'calc(100vh - 598px)',
    },
  },
  boxInfo: {
    textAlign: 'left',
    maxWidth: 325,
    width: '100%',
  },
  videoBox: {
    margin: 'auto',
    height: 360,
    // '@media (min-width: 768px)': {
    //   height: 350,
    //   width: 550,
    // },
    // '@media (min-width: 992px)': {
    //   height: 430,
    // },
  },
  img: {
    width: '100%',
  },
  boxImg: {
    height: 150,
    backgroundSize: 'contain',
    '@media (min-width: 768px)': {
      height: 300,
    },
    '@media (min-width: 992px)': {
      height: 420,
    },
  },
  boxCard: {
    position: 'relative',
    maxWidth: 230,
    margin: '35px auto',
    '@media (min-width: 768px)': {
      maxWidth: 500,
    },
  },
  box: {
    position: 'relative',
  },
  imgHead: {
    display: 'flex',
    alignItems: 'center',
  },
  popupImg: {
    height: '100vh',
  },
  modalWrapper: {
    position: 'relative',
    overflowY: 'auto',
  },
  closeModal: {
    position: 'absolute',
    top: 25,
    right: 25,
    width: 15,
    height: 15,
    color: '#bf2e21',
    border: '2px solid #bf2e21',
    borderRadius: '50%',
    background: '#fff',
  },
  zoomIcon: {
    position: 'absolute',
    left: 5,
    top: 5,
  },
  point: {
    position: 'absolute',
    minWidth: 25,
    height: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
    borderRadius: '50%',
    color: '#fff',
    fontWeight: 'bolder',
    border: '2px solid',
    cursor: 'pointer',
    '@media (min-width: 360px)': {
      minWidth: 29,
      height: 29,
    },
    '@media (min-width: 500px)': {
      minWidth: 39,
      height: 39,
    },
    '@media (min-width: 700px)': {
      minWidth: 45,
      height: 45,
    },
    '@media (min-width: 900px)': {
      minWidth: 55,
      height: 55,
    },
  },
  videoIco: {
    width: '9px',
    height: '9px',
    '@media (min-width: 360px)': {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
    },
  },
  btnsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  descRoot: {
    margin: '0 auto',
    maxWidth: 640,
    fontFamily: saira,
  },
  flexBoxColumn: {
    display: 'flex',
    flexDirection: 'column',
  },

  root2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'calc(100vh - 230px)',
    textAlign: 'center',
    position: 'relative',
    '@media (min-width: 768px)': {
      minHeight: 'calc(100vh - 238px)',
    },
  },

  cardItem2: {
    textAlign: 'center',
    position: 'relative',
    width: '50%',
    marginTop: 10,
  },
  cardImg2: {
    marginTop: '60px',
    minHeight: '250px',
    height: '150%',
    backgroundSize: '70%',
    '@media (max-width: 770px)': {
      marginTop: '40px',
      backgroundSize: '80%',
    },
    '@media (max-width: 600px)': {
      marginTop: '15px',
    },
    '@media (max-width: 425px)': {
      marginTop: '20px',
      minHeight: '226px',
    },
  },
  cardImg2Big: {
    marginTop: '60px',
    minHeight: '300px',
    height: '150%',
    backgroundSize: '55%',
    '@media (min-width: 900px)': {
      marginBottom: '15px',
    },
    '@media (max-width: 770px)': {
      marginTop: '30px',
      backgroundSize: '60%',
    },
    '@media (max-width: 600px)': {
      marginTop: '15px',
    },
    '@media (max-width: 425px)': {
      minHeight: '226px',
    },
  },

  badge2: {
    position: 'absolute',
    top: 10,
    left: 10,
    background: skyBlue,
    fontFamily: saira,
    color: '#000000',
    minWidth: 75,
    padding: 5,
    borderRadius: 5,
    textAlign: 'center',
    fontWeight: 500,
    display: 'inline-block',
    '@media (max-width: 425px)': {
      fontSize: '0.8em',
    },
  },
  alert: {
    color: 'red',
    fontWeight: 500,
    fontSize: '1rem',
    fontFamily: saira,
    letterSpacing: '0.2rem',
    marginTop: '20px',
    maxWidth: '388px',
    minWidth: '285px',
    margin: '20px auto',
    textAlign: 'center',
    '@media (max-width: 425px)': {
      fontSize: '0.75rem',
    },
    '@media (max-width: 350px)': {
      fontSize: '0.6rem',
    },
  },
  mTop25: {
    marginTop: '25px',
  },
  description: {
    fontFamily: saira,
    color: locGray,
    whiteSpace: 'pre',
    textAlign: 'center',
  },
  subTitle: {
    fontFamily: saira,
    color: locGray,
  },
  secTitle: {
    fontFamily: saira,
    color: locGray,
    fontSize: 24,
    display: 'flex',
    justifyContent: 'center',
    whiteSpace: 'pre-wrap',
    // '@media (max-width: 425px)': {
    //   fontSize: 22,
    // },
    // '@media (max-width: 375px)': {
    //   fontSize: 20,
    // },
    // '@media (max-width: 320px)': {
    //   fontSize: 18,
    // },
  },
  name: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: saira,
    fontSize: 23,
    color: locGray,
  },
  siteLink: {
    color: 'inherit',
    textAlign: 'center',
    display: 'block',
    marginTop: 10,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  iframeContainer: {
    width: 100,
    height: 100,
  },
};

export default mainStyles;

import React, { useState } from 'react';
import { PageContext } from './PageContext.js';

const PageContextProvider = (props) => {
  const [title, setTitle] = useState('');

  return (
    <PageContext.Provider
      value={{
        title,
        setTitle,
      }}
    >
      {props.children}
    </PageContext.Provider>
  );
};
export default PageContextProvider;

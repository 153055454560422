import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Button, makeStyles } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import usePage from '@/hooks/usePage.js';
import mainStyles from '@/assets/js/mainStyles.js';
import Request from '@/helpers/request.js';

const useStyles = makeStyles(mainStyles);

const SelectSubcategory = () => {
  const classes = useStyles();
  const { boxId, measureId } = useParams();
  const history = useHistory();
  const [box, setBox] = useState({});
  const [measure, setMeasure] = useState({});

  const { setTitle } = usePage();

  useEffect(async () => {
    const fetchBoxes = async () => {
      const [responseBox, responseMeasure] = await Promise.all([
        Request.get(`/boxes/${boxId}`),
        Request.get(`/measures/${measureId}`),
      ]);
      setBox(responseBox);
      setMeasure(responseMeasure);
    };
    setTitle('BOX');
    await fetchBoxes();
  }, []);

  return (
    <Container maxWidth="md">
      <Box className={classes.containerInner}>
        <Box className={classes.containerTitle}>
          <Typography className={classes.name}>
            <span>{box.name}</span>
          </Typography>
          <Typography className={classes.subTitle}>{measure.caption}</Typography>
        </Box>
        <Typography className={classes.textBox}>Sélectionnez une rubrique :</Typography>
        <Box align="center" className={classes.flexBoxColumn}>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push(`/formwork/description-box/${boxId}/measures/${measureId}`)}
            variant="outlined"
            size="large"
          >
            Descriptif
          </Button>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push(`/formwork/implementation/${boxId}/measures/${measureId}`)}
            variant="outlined"
            size="large"
          >
            Mise en œuvre
          </Button>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push('/formwork/configurators')}
            variant="outlined"
          >
            Configurateurs
          </Button>
          {/*<Button*/}
          {/*  className={classes.outlinedBtn}*/}
          {/*  onClick={() => history.push('/formwork/configurators/slinging')}*/}
          {/*  variant="outlined"*/}
          {/*  size="large"*/}
          {/*>*/}
          {/*  Consignes d’élingage*/}
          {/*</Button>*/}
          {/*<Button*/}
          {/*  className={classes.outlinedBtn}*/}
          {/*  onClick={() => history.push('/formwork/configurators/handling')}*/}
          {/*  variant="outlined"*/}
          {/*  size="large"*/}
          {/*>*/}
          {/*  Relevage à plat*/}
          {/*</Button>*/}
        </Box>
      </Box>
    </Container>
  );
};

export default SelectSubcategory;

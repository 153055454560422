import React, { useEffect, useState } from 'react';
import { Container, Box, makeStyles, Typography, List, Button, ListItem, ListItemText } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import usePage from '@/hooks/usePage.js';
import mainStyles from '@/assets/js/mainStyles.js';
import Request from '@/helpers/request.js';

const RenderList = (props) => {
  // eslint-disable-next-line react/prop-types
  const { docTechnical, classes } = props;
  return (
    <ListItem
      // eslint-disable-next-line react/prop-types
      key={docTechnical.id}
      // eslint-disable-next-line react/prop-types
      className={classes.categoryBtn}
      button
      // eslint-disable-next-line react/prop-types
      onClick={() => window.open(docTechnical.link)}
    >
      {/* eslint-disable-next-line react/prop-types */}
      <ListItemText primary={docTechnical.title} />
    </ListItem>
  );
};
const useStyles = makeStyles(mainStyles);

const DocTechnical = () => {
  const classes = useStyles();
  const history = useHistory();
  const [docTechnical, setDocTechnical] = useState([]);
  const { setTitle } = usePage();
  useEffect(async () => {
    const fetchDocTechnical = async () => {
      const response = await Request.get('/pca2-doc-technical?sort=title');
      setDocTechnical(response.items);
    };
    setTitle('PCA 2/Doc technique');
    await fetchDocTechnical();
  }, []);

  return (
    <Container maxWidth="md">
      <Box className={classes.content}>
        <Box>
          <Typography>Composants :</Typography>
          <List>
            {docTechnical.map((item) => (
              <RenderList classes={classes} key={item.id} docTechnical={item} />
            ))}
          </List>
        </Box>
        <Button
          className={classes.mainBtn}
          onClick={() => window.open('https://www.sateco.fr/fr/faq.html')}
          variant="outlined"
          size="large"
        >
          FAQ
        </Button>
      </Box>
    </Container>
  );
};

export default DocTechnical;

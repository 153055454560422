import React, { useEffect, useState } from 'react';
import { Badge, Box, Card, CardMedia, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { dragSort } from '@/components/SubSectionComponents/CleanFunctions.jsx';
import usePage from '../../../../hooks/usePage.js';
import mainStyles from '../../../../assets/js/mainStyles.js';
import Request from '../../../../helpers/request.js';
import Constants from '../../../../constants.js';

const useStyles = makeStyles(mainStyles);

const Modules = () => {
  const classes = useStyles();
  const history = useHistory();
  const { setTitle } = usePage();

  const [modules, setModules] = useState([]);

  const renderModules = () =>
    modules.sort(dragSort).map((module) => {
      let fetchImg = `${Constants?.BASE_URL}/${module?.image?.path}`;
      return (
        <Grid item xs={12} md={12} className={classes.cardItem} key={module.id}>
          <Badge className={classes.badge}>{module.title}</Badge>
          <Card className={classes.cardsModule} onClick={() => history.push(`modules/${module.id}`)}>
            <CardMedia className={`${classes.cardImg}`} image={fetchImg || false} />
          </Card>
        </Grid>
      );
    });

  useEffect(async () => {
    const fetchModule = async () => {
      const response = await Request.get('/p3d2-modules');
      setModules(response.items);
    };
    setTitle('Modules');
    await fetchModule();
  }, []);

  return (
    <Container maxWidth="md">
      <Box className={classes.containerInner}>
        <Box className={classes.cardsModule}>
          <Typography className={classes.subTitle} style={{ fontSize: 20 }}>
            Sélectionnez le module
          </Typography>
          <Grid className={classes.cardsContainer} container spacing={1}>
            {renderModules()}
          </Grid>
        </Box>
        {/*<BoxVideo_FAQ pathUrl={modules?.usage?.assembly?.faq} style={classes} />*/}
      </Box>
    </Container>
  );
};

export default Modules;

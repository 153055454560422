import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, makeStyles } from '@material-ui/core';
import ReactPlayer from 'react-player';
import usePage from '@/hooks/usePage.js';
import Request from '@/helpers/request.js';
import mainStyles from '@/assets/js/mainStyles.js';
import { BoxVideo_FAQ } from '@/pages/security/pce-2/modules/Description.jsx';

const useStyles = makeStyles(mainStyles);

const Folding = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [module, setModule] = useState(null);
  const { setTitle } = usePage();

  useEffect(async () => {
    const fetchModule = async () => {
      const response = await Request.get(`/pca2-modules`);
      setModule(response.items[0]);
      setTitle('REPLIAGE');
    };
    await fetchModule();
  }, []);

  return (
    <Container maxWidth="md">
      {/*<Box className={classes.boxInfo}>*/}
      {/*  <Typography style={{ fontSize: 23, }}>{module?.usage?.titleFold}</Typography>*/}
      {/*</Box>*/}
      <div className={classes.secTitle}>
        <span>{module?.usage?.titleFold} </span>
      </div>
      {/*<Box justifyContent={'center'} className={classes.videoBox}>*/}
      {/*  <ReactPlayer url={module.usage ? module.usage.logistics : null} width="100%" height="100%" controls />*/}
      {/*</Box>*/}
      <Box justifyContent={'center'} className={classes.videoBox}>
        <ReactPlayer url={module?.usage?.fold} width="100%" height="100%" controls />
      </Box>
      <Box className={classes.descRoot}>
        <BoxVideo_FAQ pathUrl={module?.usage?.assembly?.faq} style={classes} />
      </Box>
    </Container>
  );
};

export default Folding;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ReactPlayer from 'react-player';
import usePage from '@/hooks/usePage.js';
import Request from '@/helpers/request.js';
import Constants from '@/constants.js';
import mainStyles from '@/assets/js/mainStyles.js';

const useStyles = makeStyles(mainStyles);

const ConfiguratorImplementation = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [configurator, setConfigurator] = useState({});
  const { setTitle } = usePage();

  useEffect(async () => {
    const fetchData = async () => {
      const response = await Request.get(`/pce2-configurators/${id}`);
      setConfigurator(response);
    };
    setTitle('Mise en œuvre');
    await fetchData();
  }, []);

  const data = configurator.usage
    ? configurator.usage
    : {
        text: '',
        video: '',
        images: [],
      };
  const images = data.images !== undefined ? data.images : [];
  return (
    <Container maxWidth="md">
      <Box className={classes.descRoot}>
        <div className={classes.secTitle}>
          <span>{configurator.title}</span>
        </div>
        <Typography className={classes.description}>{data.text}</Typography>
        <Box justifyContent={'center'} className={classes.videoBox}>
          <ReactPlayer url={data.video} width="100%" height="100%" controls />
        </Box>
        <Box>
          {images.map((item, index) => (
            <Card key={index} className={classes.imgCard}>
              <CardActionArea>
                <CardMedia
                  className={classes.imgItem}
                  component="img"
                  alt={item.image.name}
                  image={`${Constants.BASE_URL}/${item.image.path}`}
                />
                <CardContent>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {item.title}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default ConfiguratorImplementation;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, makeStyles } from '@material-ui/core';
import ReactPlayer from 'react-player';
import usePage from '@/hooks/usePage.js';
import Request from '@/helpers/request.js';
import mainStyles from '@/assets/js/mainStyles.js';
import { BoxVideo_FAQ } from '@/pages/security/pce-2/modules/Description.jsx';

const useStyles = makeStyles(mainStyles);

const Implementation = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [component, setComponent] = useState(null);
  const { setTitle } = usePage();

  useEffect(async () => {
    const fetchComponent = async () => {
      const response = await Request.get(`/pca2-components/${id}`);
      setComponent(response);
    };
    setTitle('Mise en œuvre');
    await fetchComponent();
  }, []);

  return (
    <Container maxWidth="md">
      <Box className={classes.descRoot}>
        <div className={classes.secTitle}>
          <span>{component?.title} </span>
        </div>
        <div className={classes.description}>{component?.usage ? component?.usage?.text : ''}</div>
        {component?.usage?.video && (
          <Box justifyContent={'center'} className={classes.videoBox}>
            <ReactPlayer url={component.usage.video} width="100%" height="100%" controls />
          </Box>
        )}

        <BoxVideo_FAQ pathUrl={component?.usage?.faq} style={classes} />
      </Box>
    </Container>
  );
};

export default Implementation;

import React, { useEffect } from 'react';
import { Box, Card, Container, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import usePage from '@/hooks/usePage.js';
import mainStyles from '@/assets/js/mainStyles.js';

const useStyles = makeStyles(mainStyles);

const Stairs = () => {
  const classes = useStyles();
  const { setTitle } = usePage();
  const { type } = useParams();

  const types = {
    helical: {
      name: 'ESCALIER HELICOIDAL',
    },
    right: {
      name: 'ESCALIER DROIT',
    },
    balance: {
      name: 'ESCALIER BALANCE A FUT',
    },
    landing: {
      name: 'ESCALIER A DEMI PALIER',
    },
    'vacuum-balance': {
      name: 'ESCALIER BALANCE A VIDE CENTRAL',
    },
  };

  useEffect(() => {
    setTitle(types[type].name);
  }, []);

  return (
    <Container maxWidth="md">
      <Box>
        <Box className={classes.descRoot}>
          <Card className={classes.imgCard}>
            {/*<CardMedia className={classes.imgItem} component="img" image={} />*/}
          </Card>
        </Box>
      </Box>
    </Container>
  );
};

export default Stairs;

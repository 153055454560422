// eslint-disable-next-line no-undef
const { ENV_FILE } = process.env;
// eslint-disable-next-line no-undef
export const { BASE_URL } = process.env;

const Constants = {
  ENV_FILE,
  BASE_URL,
  API_URL: `${BASE_URL}/api/frontend`,
};

export default Constants;

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Container, makeStyles, Button } from '@material-ui/core';
import mainStyles from '@/assets/js/mainStyles.js';
import usePage from '@/hooks/usePage.js';
import Request from '@/helpers/request.js';

const useStyles = makeStyles(mainStyles);

const CustomProducts = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [customProduct, setCustomProduct] = useState({});
  const { setTitle } = usePage();

  useEffect(async () => {
    const fetch = async () => {
      const response = await Request.get(`/custom-products/${id}`);
      setCustomProduct(response);
    };
    setTitle('Produits sur mesure');
    await fetch();
  }, []);

  return (
    <Container maxWidth="md">
      <Box className={classes.content}>
        <div
          className={classes.subTitle}
          style={{ fontSize: 26, display: 'flex', justifyContent: 'center', whiteSpace: 'nowrap' }}
        >
          <span>{customProduct?.title} </span>
        </div>
        <Button
          className={classes.outlinedBtn}
          onClick={() => history.push(`/custom-products/${customProduct.id}/description`)}
        >
          Descriptif
        </Button>
        <Button
          className={classes.outlinedBtn}
          onClick={() => history.push(`/custom-products/${customProduct.id}/implementation`)}
        >
          Mise en œuvre
        </Button>
        {/*<Button*/}
        {/*  className={classes.outlinedBtn}*/}
        {/*  onClick={() => history.push(`/custom-products/${customProduct.id}/documents`)}*/}
        {/*>*/}
        {/*  Document Commercial*/}
        {/*</Button>*/}
      </Box>
    </Container>
  );
};

export default CustomProducts;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardMedia,
  Container,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import usePage from '../../../../hooks/usePage.js';
import Constants from '../../../../constants.js';
import mainStyles from '@/assets/js/mainStyles.js';
import { slingingFetchData } from '@/components/SubSectionComponents/CleanFunctions.jsx';
import warningImg from '@/assets/img/warning.jpg';
import hotlinkImg from '@/assets/img/hotline.jpg';

export const LiftingProhibited = (classes, text = 'Élingage avec portique interdit') => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img className={`${classes.warning}`} src={warningImg} />
      <div className={classes.subTitle} style={{ color: 'red', marginTop: '50px' }}>
        {text}
      </div>
      <img className={`${classes.hotLine}`} src={hotlinkImg} />
    </div>
  );
};

export const slingingTitleText = (
  <>
    <div>Retirez impérativement le lest</div>
    <div>avant d’effectuer le relevage</div>
    <div>Vérifiez l’angle d’élingage : 60° max</div>
  </>
);

const useStyles = makeStyles(mainStyles);

const ConfiguratorSlinging = () => {
  const classes = useStyles();

  const { setTitle } = usePage();
  setTitle('Consignes d’élingage BOX / BOX R par manilles');

  const [configurators, setConfigurators] = useState([]);
  const [constructorHeights, setConfiguratorHeights] = useState([]);
  const [constructorWidths, setConfiguratorWidths] = useState([]);
  const [selectedWidth, setSelectedWidth] = useState('');
  const [selectedHeight, setSelectedHeight] = useState('');
  const [loader, setLoader] = useState(true);

  const RenderConfigurators = () => {
    setLoader(true);
    const array = configurators.map((constructor) => {
      if (constructor.height === selectedHeight && constructor.width === selectedWidth) {
        return (
          <>
            {constructor?.image?.path ? (
              <Card className={classes.imgCard} key={constructor.id}>
                <CardMedia className={classes.imgItem} image={`${Constants.BASE_URL}/${constructor.image.path} `} />
              </Card>
            ) : (
              LiftingProhibited(classes)
            )}
          </>
        );
      }
    });
    setLoader(false);
    return array;
  };
  useEffect(async () => {
    const [items, sizesH, sizesW] = await slingingFetchData('/slinging');
    setConfigurators(items);
    setConfiguratorHeights(sizesH);
    setConfiguratorWidths(sizesW);
  }, []);

  return (
    <Container maxWidth="md">
      <div className={classes.alert}>{slingingTitleText}</div>
      <Box className={classes.root}>
        <Box className={classes.centerSelects}>
          <FormControl variant="outlined" className={classes.formControl} style={{ marginRight: 15 }}>
            <InputLabel className={classes.selectLabel}>Hauteur (en m)</InputLabel>
            <Select
              className={classes.selectBody}
              classes={{ outlined: classes.selectOutlined }}
              name="height"
              onChange={({ target: { value } }) => setSelectedHeight(value)}
              value={selectedHeight}
            >
              {constructorHeights.map((height) => (
                <MenuItem className={classes.selectList} value={height} key={height}>
                  {height}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel className={classes.selectLabel}>Largeur (en m)</InputLabel>
            <Select
              className={classes.selectBody}
              classes={{ outlined: classes.selectOutlined }}
              name="width"
              onChange={({ target: { value } }) => setSelectedWidth(value)}
              value={selectedWidth}
            >
              {constructorWidths.map((width) => (
                <MenuItem className={classes.selectList} value={width} key={width}>
                  {width}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box className={classes.cardsBox}>
          <Grid className={classes.cardsContainer} container spacing={1}>
            {loader ? (
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            ) : (
              ''
            )}
            <RenderConfigurators />
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default ConfiguratorSlinging;

import React, { useEffect, useState } from 'react';
import { Container, Box, Button, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import usePage from '@/hooks/usePage.js';
import Request from '@/helpers/request.js';
import mainStyles from '@/assets/js/mainStyles.js';
import { dragSort } from '@/components/SubSectionComponents/CleanFunctions.jsx';

const useStyles = makeStyles(mainStyles);

const ComponentsList = () => {
  const classes = useStyles();
  const history = useHistory();
  const [components, setComponents] = useState([]);
  const { setTitle } = usePage();

  useEffect(async () => {
    const fetch = async () => {
      const response = await Request.get('/p3d2-components?sort=title');
      const data = response.items;
      setComponents(data);
    };
    setTitle('Composants');
    await fetch();
  }, []);

  return (
    <Container maxWidth="md">
      <Box className={classes.content}>
        {components.sort(dragSort).map((component) => (
          <Button
            key={component.id}
            className={classes.outlinedBtn}
            onClick={() => history.push(`/security/p3d2/components/${component.id}`)}
          >
            {component.title}
          </Button>
        ))}
        <Button
          className={classes.mainBtn}
          onClick={() => window.open('https://www.sateco.fr/fr/faq.html')}
          variant="outlined"
          size="large"
        >
          FAQ
        </Button>
      </Box>
    </Container>
  );
};

export default ComponentsList;

export const Box_Parameters = [
  { height: 3000, width: 2400, mass: 1145 },
  { height: 3000, width: 1200, mass: 655 },
  { height: 3000, width: 900, mass: 495 },
  { height: 3000, width: 600, mass: 360 },
  { height: 3000, width: 300, mass: 192 },
  { height: 3000, width: 1140, mass: 1018 },
  { height: 3000, width: 90, mass: 181 },
  { height: 3000, width: 100, mass: 185.9 },
  { height: 3000, width: 120, mass: 213 },
  { height: 3000, width: 140, mass: 221 },

  { height: 2800, width: 2400, mass: 1090 },
  { height: 2800, width: 1200, mass: 629 },
  { height: 2800, width: 900, mass: 474 },
  { height: 2800, width: 600, mass: 345 },
  { height: 2800, width: 300, mass: 191 },
  { height: 2800, width: 1140, mass: 960 },
  { height: 2800, width: 90, mass: 178.3 },
  { height: 2800, width: 100, mass: 181.1 },
  { height: 2800, width: 120, mass: 210.5 },
  { height: 2800, width: 140, mass: 218.5 },

  { height: 1500, width: 2400, mass: 783 },
  { height: 1500, width: 1200, mass: 470 },
  { height: 1500, width: 900, mass: 360 },
  { height: 1500, width: 600, mass: 250 },
  { height: 1500, width: 300, mass: 135 },
  { height: 1500, width: 1140, mass: 582 },
  { height: 1500, width: 90, mass: 83.4 },
  { height: 1500, width: 100, mass: 85.3 },
  { height: 1500, width: 120, mass: 88.4 },
  { height: 1500, width: 140, mass: 93.2 },

  { height: 1000, width: 2400, mass: 622 },
  { height: 1000, width: 1200, mass: 400 },
  { height: 1000, width: 900, mass: 316 },
  { height: 1000, width: 600, mass: 242 },
  { height: 1000, width: 300, mass: 102 },
  { height: 1000, width: 1140, mass: 464 },
  { height: 1000, width: 90, mass: 62.4 },
  { height: 1000, width: 100, mass: 64.1 },
  { height: 1000, width: 120, mass: 67 },
  { height: 1000, width: 140, mass: 69.4 },

  { height: 500, width: 2400, mass: 111 },
  { height: 500, width: 1200, mass: 58 },
  { height: 500, width: 900, mass: 47 },
  { height: 500, width: 600, mass: 38 },
  { height: 500, width: 300, mass: 22 },
  { height: 500, width: 1140, mass: 101 },
  { height: 500, width: 90, mass: 16.3 },
  { height: 500, width: 100, mass: 17.7 },
  { height: 500, width: 120, mass: 17.1 },
  { height: 500, width: 140, mass: 20.3 },
];
export const Box_R_Parameters = [
  { height: 3000, width: 2400, mass: 1300 },
  { height: 3000, width: 1200, mass: 820 },
  { height: 3000, width: 900, mass: 495 },
  { height: 3000, width: 600, mass: 360 },
  { height: 3000, width: 300, mass: 192 },
  { height: 3000, width: 1140, mass: 1018 },
  { height: 3000, width: 90, mass: 181 },
  { height: 3000, width: 100, mass: 185.9 },
  { height: 3000, width: 120, mass: 213 },
  { height: 3000, width: 140, mass: 221 },

  { height: 2800, width: 2400, mass: 1275 },
  { height: 2800, width: 1200, mass: 795 },
  { height: 2800, width: 900, mass: 474 },
  { height: 2800, width: 600, mass: 345 },
  { height: 2800, width: 300, mass: 191 },
  { height: 2800, width: 1140, mass: 960 },
  { height: 2800, width: 90, mass: 178.3 },
  { height: 2800, width: 100, mass: 181.1 },
  { height: 2800, width: 120, mass: 210.5 },
  { height: 2800, width: 140, mass: 218.5 },

  { height: 1500, width: 2400, mass: 783 },
  { height: 1500, width: 1200, mass: 470 },
  { height: 1500, width: 900, mass: 360 },
  { height: 1500, width: 600, mass: 250 },
  { height: 1500, width: 300, mass: 135 },
  { height: 1500, width: 1140, mass: 582 },
  { height: 1500, width: 90, mass: 83.4 },
  { height: 1500, width: 100, mass: 85.3 },
  { height: 1500, width: 120, mass: 88.4 },
  { height: 1500, width: 140, mass: 93.2 },

  { height: 1000, width: 2400, mass: 622 },
  { height: 1000, width: 1200, mass: 400 },
  { height: 1000, width: 900, mass: 316 },
  { height: 1000, width: 600, mass: 242 },
  { height: 1000, width: 300, mass: 102 },
  { height: 1000, width: 1140, mass: 464 },
  { height: 1000, width: 90, mass: 62.4 },
  { height: 1000, width: 100, mass: 64.1 },
  { height: 1000, width: 120, mass: 67 },
  { height: 1000, width: 140, mass: 69.4 },

  { height: 500, width: 2400, mass: 121 },
  { height: 500, width: 1200, mass: 65 },
  { height: 500, width: 900, mass: 55 },
  { height: 500, width: 600, mass: 45 },
  { height: 500, width: 300, mass: 28 },
  { height: 500, width: 1140, mass: 101 },
  { height: 500, width: 90, mass: 16.3 },
  { height: 500, width: 100, mass: 17.7 },
  { height: 500, width: 120, mass: 17.1 },
  { height: 500, width: 140, mass: 20.3 },
];
export const Box_RS_Parameters = [
  { height: 3000, width: 2400, mass: 2080 },
  { height: 3000, width: 1200, mass: 1220 },
  { height: 2800, width: 2400, mass: 2055 },
  { height: 2800, width: 1200, mass: 1195 },
  { height: 500, width: 2400, mass: 111 },
  { height: 500, width: 1200, mass: 58 },
];

export const massStabilizers = [
  { stab: 'T1', count: 0, mass: 30 },
  { stab: 'T2', count: 0, mass: 38 },
  { stab: 'T3', count: 0, mass: 44.3 },
  { stab: 'T4', count: 0, mass: 63.5 },
  { stab: 'T5', count: 0, mass: 67.2 },
  { stab: 'T6', count: 0, mass: 74.3 },
  { stab: 'T7', count: 0, mass: 112.3 },
];
export const massPortico = { count: 0, mass: 195 };

export const windZone = [
  { regionNumber: '1', region: 'Ain', zone: 2 },
  { regionNumber: '2', region: 'Aisne', zone: 2 },
  { regionNumber: '3', region: 'Allier', zone: 2 },
  { regionNumber: '4', region: 'Alpes de Hautes-Provence', zone: 2 },
  { regionNumber: '5', region: 'Hautes-Alpes', zone: 2 },
  { regionNumber: '6', region: 'Alpes-Maritimes', zone: 2 },
  { regionNumber: '7', region: 'Ardèche', zone: 2 },
  { regionNumber: '8', region: 'Ardennes', zone: 2 },
  { regionNumber: '9', region: 'Ariège', zone: 2 },
  { regionNumber: '10', region: 'Aube', zone: 2 },
  { regionNumber: '11', region: 'Aude', zone: 3 },
  { regionNumber: '12', region: 'Aveyron', zone: 2 },
  { regionNumber: '13', region: 'Bouches-du-Rhône', zone: 3 },
  { regionNumber: '14', region: 'Calvados', zone: 2 },
  { regionNumber: '15', region: 'Cantal', zone: 2 },
  { regionNumber: '16', region: 'Charente', zone: 1 },
  { regionNumber: '17', region: 'Charente-Maritime', zone: 3 },
  { regionNumber: '18', region: 'Cher', zone: 2 },
  { regionNumber: '19', region: 'Corrèze', zone: 1 },
  { regionNumber: '2A', region: 'Corse-du-Sud', zone: 4 },
  { regionNumber: '2B', region: 'Haute-Corse', zone: 4 },
  { regionNumber: '21', region: "Côte-d'Or", zone: 2 },
  { regionNumber: '22', region: "Côtes d'Armor", zone: 3 },
  { regionNumber: '23', region: 'Creuse', zone: 1 },
  { regionNumber: '24', region: 'Dordogne', zone: 1 },
  { regionNumber: '25', region: 'Doubs', zone: 2 },
  { regionNumber: '26', region: 'Drôme', zone: 2 },
  { regionNumber: '27', region: 'Eure', zone: 2 },
  { regionNumber: '28', region: 'Eure-et-Loir', zone: 2 },
  { regionNumber: '29', region: 'Finistère', zone: 3 },
  { regionNumber: '30', region: 'Gard', zone: 3 },
  { regionNumber: '31', region: 'Haute-Garonne', zone: 2 },
  { regionNumber: '32', region: 'Gers', zone: 1 },
  { regionNumber: '33', region: 'Gironde', zone: 1 },
  { regionNumber: '34', region: 'Hérault', zone: 3 },
  { regionNumber: '35', region: 'Ille-et-Vilaine', zone: 2 },
  { regionNumber: '36', region: 'Indre', zone: 2 },
  { regionNumber: '37', region: 'Indre-et-Loire', zone: 2 },
  { regionNumber: '38', region: 'Isère', zone: 2 },
  { regionNumber: '39', region: 'Jura', zone: 1 },
  { regionNumber: '40', region: 'Landes', zone: 2 },
  { regionNumber: '41', region: 'Loir-et-Cher', zone: 2 },
  { regionNumber: '42', region: 'Loire', zone: 2 },
  { regionNumber: '43', region: 'Haute-Loire', zone: 2 },
  { regionNumber: '44', region: 'Loire-Atlantique', zone: 3 },
  { regionNumber: '45', region: 'Loiret', zone: 2 },
  { regionNumber: '46', region: 'Lot', zone: 1 },
  { regionNumber: '47', region: 'Lot-et-Garonne', zone: 1 },
  { regionNumber: '48', region: 'Lozère', zone: 2 },
  { regionNumber: '49', region: 'Maine-et-Loire', zone: 2 },
  { regionNumber: '50', region: 'Manche', zone: 2 },
  { regionNumber: '51', region: 'Marne', zone: 2 },
  { regionNumber: '52', region: 'Haute-Marne', zone: 2 },
  { regionNumber: '53', region: 'Mayenne', zone: 2 },
  { regionNumber: '54', region: 'Meurthe-et-Moselle', zone: 2 },
  { regionNumber: '55', region: 'Meuse', zone: 2 },
  { regionNumber: '56', region: 'Morbihan', zone: 3 },
  { regionNumber: '57', region: 'Moselle', zone: 2 },
  { regionNumber: '58', region: 'Nièvre', zone: 2 },
  { regionNumber: '59', region: 'Nord', zone: 2 },
  { regionNumber: '60', region: 'Oise', zone: 2 },
  { regionNumber: '61', region: 'Orne', zone: 2 },
  { regionNumber: '62', region: 'Pas-de-Calais', zone: 3 },
  { regionNumber: '63', region: 'Puy-de-Dôme', zone: 2 },
  { regionNumber: '64', region: 'Pyrénées-Atlantiques', zone: 2 },
  { regionNumber: '65', region: 'Hautes-Pyrénées', zone: 1 },
  { regionNumber: '66', region: 'Pyrénées-Orientales', zone: 3 },
  { regionNumber: '67', region: 'Bas-Rhin', zone: 2 },
  { regionNumber: '68', region: 'Haut-Rhin', zone: 2 },
  { regionNumber: '69', region: 'Rhône', zone: 2 },
  { regionNumber: '70', region: 'Haute-Saône', zone: 2 },
  { regionNumber: '71', region: 'Saône-et-Loire', zone: 2 },
  { regionNumber: '72', region: 'Sarthe', zone: 2 },
  { regionNumber: '73', region: 'Savoie', zone: 2 },
  { regionNumber: '74', region: 'Haute-Savoie', zone: 2 },
  { regionNumber: '75', region: 'Paris', zone: 2 },
  { regionNumber: '76', region: 'Seine-Maritime', zone: 3 },
  { regionNumber: '77', region: 'Seine-et-Marne', zone: 2 },
  { regionNumber: '78', region: 'Yvelines', zone: 2 },
  { regionNumber: '79', region: 'Deux-Sèvres', zone: 2 },
  { regionNumber: '80', region: 'Somme', zone: 2 },
  { regionNumber: '81', region: 'Tarn', zone: 2 },
  { regionNumber: '82', region: 'Tarn-et-Garonne', zone: 2 },
  { regionNumber: '83', region: 'Var', zone: 2 },
  { regionNumber: '84', region: 'Vaucluse', zone: 2 },
  { regionNumber: '85', region: 'Vendée', zone: 3 },
  { regionNumber: '86', region: 'Vienne', zone: 2 },
  { regionNumber: '87', region: 'Haute-Vienne', zone: 2 },
  { regionNumber: '88', region: 'Vosges', zone: 2 },
  { regionNumber: '89', region: 'Yonne', zone: 2 },
  { regionNumber: '90', region: 'Territoire-de-Belfort', zone: 2 },
  { regionNumber: '91', region: 'Essonne', zone: 2 },
  { regionNumber: '92', region: 'Hauts-de-Seine', zone: 2 },
  { regionNumber: '93', region: 'Seine-Saint-Denis', zone: 2 },
  { regionNumber: '94', region: 'Val-de-Marne', zone: 2 },
  { regionNumber: '95', region: "Val d'Oise", zone: 2 },
];

export const windPower = [
  { zone: 1, speed: 103 },
  { zone: 2, speed: 113 },
  { zone: 3, speed: 126 },
  { zone: 4, speed: 138 },
];

import { isArray } from 'rxjs/internal-compatibility';
import { Box_Parameters, Box_RS_Parameters } from '@/pages/formwork/boxes/API_Data/fetchParametresBoxes.jsx';
import Request from '@/helpers/request.js';

export function fullFiled(arrayAllParams, typeBox) {
  let arrFull = [0, 0, 0, 0];
  if (arrayAllParams[0].length) {
    if (arrayAllParams[0][0][0] > 0) {
      arrFull[0] = 1;
    }
    if (typeBox === 'Box/BoxR' && arrayAllParams[0][0][1] > 0) {
      arrFull[1] = 1;
    } else if (arrayAllParams[0][0][1] >= 0) {
      arrFull[1] = 1;
    }
  }
  if (arrayAllParams[1].length) {
    if (arrayAllParams[1][0][0] > 0) {
      arrFull[2] = 1;
    }
    if (arrayAllParams[1][0][1] > 0) {
      arrFull[3] = 1;
    }
  }
  return arrFull.every((elem) => elem > 0);
}

export function fullFiledRS(arrayAllParams) {
  const a = isArray(arrayAllParams[0]) && isArray(arrayAllParams[0][1]) && arrayAllParams[0][1][0] > 0;
  const b = isArray(arrayAllParams[1]) && isArray(arrayAllParams[1][0]) && arrayAllParams[1][0][0] > 0;
  return a && b;
}

const findStab = (length) => {
  if (length < 2.495 || length > 8.995) {
    return 'Hors catalogue';
  } else {
    if (length >= 6.695 && length < 8.995) {
      return 'T7';
    } else if (length >= 5.495 && length < 6.695) {
      return 'T6';
    } else if (length >= 4.495 && length < 5.695) {
      return 'T5';
    } else if (length >= 3.795 && length < 4.995) {
      return 'T4';
    } else if (length >= 2.495 && length < 3.695) {
      return 'T3';
    }
  }
};

export const updateRecommendStab = (length, totalHeight, speedWind, ballastHeight) =>
  ballastHeight === '' ? '' : selectRecommendStab(length, totalHeight, speedWind);

export function selectRecommendStab(length, totalHeight, speedWind) {
  let localRecommendStab;

  if (length < 1.98 || length > 8.995) {
    localRecommendStab = 'Hors catalogue';
  } else {
    if (totalHeight < 5.6) {
      if (length < 3.5 && (totalHeight <= 3.5 || speedWind === 85)) {
        localRecommendStab = 'T1';
      } else {
        if (length < 1.98 || length > 8.995) {
          localRecommendStab = 'Hors catalogue';
        } else {
          if (length >= 1.98 && length < 3.5) {
            localRecommendStab = 'T1';
          } else if (length >= 2.63 && length < 8.05) {
            localRecommendStab = 'T2';
          }
        }
      }
    } else {
      if (length < 2.495 || length > 8.995) {
        localRecommendStab = 'Hors catalogue';
      } else {
        localRecommendStab = findStab(length);
      }
    }
  }

  return localRecommendStab;
}

//only BLL logic
export function calcBallastCategory(weight) {
  if (weight < 1.5) {
    return 1.5;
  } else {
    if (weight > 1.5 && weight < 2) {
      return 2;
    } else {
      if (weight > 2 && weight < 3) {
        return 3;
      } else return '';
    }
  }
}

export function calcHeightBallast(weight) {
  if (weight === '') {
    return '';
  } else return weight === 2 || weight === 3 ? 0.97 : 0.77;
}

export function calcPossibleD1(totalHeight, typeStab, distD, speedWind, poacher) {
  if (typeStab === '') {
    return '';
  } else {
    if (totalHeight < 5.6 && typeStab === 'T1' && (totalHeight < 3.5 || speedWind === 85)) return distD;
    return typeStab === 'Hors catalogue' || poacher === 'Hors catalogue' ? '' : distD;
  }
}

export function calcPossibleD2(totalHeight, typeStab, distD, speedWind, poacher) {
  if (typeStab === 'Hors catalogue' || poacher === 'Hors catalogue') return '';
  return totalHeight < 5.6 && typeStab === 'T1' && (totalHeight <= 3.5 || speedWind === 85) ? distD : '';
}

export function calcPossibleDFinally(totalHeight, speedWind, typeStab, d1, d2) {
  return totalHeight < 5.6 && typeStab === 'T1' ? d2 : d1;
}

export function updateRecommendDiagonalBrace(length, totalHeight) {
  let localRecommendBrace = null;
  if (totalHeight < 5.6) {
    localRecommendBrace = 'Pas de bracon bas';
  } else {
    if (length < 2.02 || length > 4.92) {
      localRecommendBrace = 'Hors catalogue';
    } else {
      if (length > 2.8 && length < 4.2) {
        localRecommendBrace = 'B2';
      } else if (length >= 2.02 && length < 3.32) {
        localRecommendBrace = 'B1';
      } else if (length > 2.82 && length < 4.12) {
        localRecommendBrace = 'B2';
      } else if (length > 3.62 && length <= 4.92) {
        localRecommendBrace = 'B3';
      }
    }
  }

  return localRecommendBrace;
}

export function updateWeightCategory(weight) {
  let localWeightCategory;
  if (weight <= 1.5) {
    localWeightCategory = 1.5;
  } else {
    if (weight > 1.5 && weight <= 2) {
      localWeightCategory = 2;
    } else {
      if (weight > 2) {
        localWeightCategory = 3;
      } else {
        localWeightCategory = 2;
      }
    }
  }
  return localWeightCategory;
}

export function updateHeightCategory(totalHeight, flag_3m_2_8m_FirstH_DoubleTop) {
  //BLL
  let flag = flag_3m_2_8m_FirstH_DoubleTop;
  let localHCategory;
  let theoreticalH;
  // Hauteur totale du train de banche = totalHeight

  if (flag[3] === false) {
    theoreticalH = totalHeight;
  } else {
    if (totalHeight < 6) {
      if (flag[2] === 500) {
        theoreticalH = totalHeight;
      } else {
        if (flag[2] === 1500 && !flag[0] && flag[3]) {
          theoreticalH = totalHeight - 1;
        } else {
          if (flag[2] < 1500 && flag[0]) {
            theoreticalH = totalHeight - 0.5;
          } else {
            if (flag[3]) {
              theoreticalH = totalHeight - 1;
            } else {
              theoreticalH = totalHeight;
            }
          }
        }
      }
    } else {
      if (totalHeight > 6) {
        if (flag[2] === 500) {
          theoreticalH = totalHeight;
        } else {
          if (flag[2] === 1500 && !flag[0] && flag[3]) {
            theoreticalH = totalHeight - 1;
          } else {
            if (flag[2] < 1500 && flag[0]) {
              theoreticalH = totalHeight - 0.5;
            } else {
              if (flag[3]) {
                theoreticalH = totalHeight - 1;
              } else {
                theoreticalH = totalHeight;
              }
            }
          }
        }
      } else {
        theoreticalH = totalHeight;
      }
    }
  }

  if (theoreticalH <= 2) {
    localHCategory = 2;
  } else if (theoreticalH > 2 && theoreticalH <= 3.5) {
    localHCategory = 3.5;
  } else if (theoreticalH > 3.5 && theoreticalH <= 4.5) {
    localHCategory = 4.5;
  } else if (theoreticalH > 4.5 && theoreticalH <= 5) {
    localHCategory = 5;
  } else if (theoreticalH > 5 && theoreticalH <= 6.5) {
    localHCategory = 6.5;
  } else if (theoreticalH > 6.5 && theoreticalH <= 7.5) {
    localHCategory = 7.5;
  } else if (theoreticalH > 7.5 && theoreticalH <= 8) {
    localHCategory = 8;
  } else if (theoreticalH > 8 && theoreticalH <= 9.5) {
    localHCategory = 9.5;
  } else {
    localHCategory = 'tooMuch';
  }
  return [localHCategory, theoreticalH];
}

export function updateThereforeFStage(rV, selectedD, positionTheHolder, propLength) {
  let aCos = Math.acos((selectedD - positionTheHolder) / propLength);
  let grad = aCos * (180 / 3.14);
  let incLine = 90 - grad;
  return rV / Math.cos((incLine * Math.PI) / 180);
}

export const updateRv = (fV, totalHeight, selectedD) => (fV * (totalHeight / 2)) / selectedD;

export const updateStabLength = (selectedD, positionTheHolder, selectedH, ballastHeight = 0) =>
  Number((((selectedD - positionTheHolder) ** 2 + (selectedH - ballastHeight) ** 2) ** 0.5).toFixed(2));

export function updateTotalPortico(totalHeight, totalWidth) {
  let localCounter;
  if (totalHeight > 5 || totalHeight < 2.8 || totalWidth < 1.2 || totalWidth > 7.2) {
    localCounter = 'Stabilisation par portique impossible';
  } else if (totalWidth <= 1.21 && totalHeight <= 3.3) {
    localCounter = 1;
  } else if (totalWidth <= 1.21 && totalHeight <= 5) {
    localCounter = 0;
  } else if (totalWidth < 2.41 && totalHeight <= 5) {
    localCounter = 1;
  } else if (totalWidth < 4.81 && totalHeight <= 5) {
    localCounter = 2;
  } else if (totalWidth <= 7.21 && totalHeight <= 5) {
    localCounter = 3;
  }
  return localCounter;
}

export function computationDistribution(data, buildAllBoxes, summingStabilizers, summingPortico) {
  const mass = calculatingMasses(data, buildAllBoxes);
  return [summingStabilizers, mass, mass + summingStabilizers, mass * 2 + summingPortico];
}

function calculatingMasses(data, buildAllBoxes) {
  let mass = 0;
  data.forEach((parameter) => {
    buildAllBoxes.forEach((box) => {
      if (box[0] === parameter.height && box[1] === parameter.width) {
        mass += parameter.mass;
      }
    });
  });
  return mass;
}

export function distributeData(arrayAllParams, params) {
  let arrayAllH = [];
  let arrayAllW = [];
  let arrayAllS = [];
  for (let index = 0; index < params; index++) {
    arrayAllParams[index].forEach((allBoxParams) => {
      if (index === 0) {
        for (let i = 0; i < allBoxParams[1]; i++) {
          arrayAllH.push(allBoxParams[0]);
        }
      } else if (index === 1) {
        for (let i = 0; i < allBoxParams[1]; i++) {
          arrayAllW.push(allBoxParams[0]);
        }
      } else if (index === 2) {
        for (let i = 0; i < allBoxParams[1]; i++) {
          arrayAllS.push(allBoxParams[0]);
        }
      }
    });
  }
  if (params === 3) {
    return [arrayAllH, arrayAllW, arrayAllS];
  }
  if (params === 2) {
    return [arrayAllH, arrayAllW];
  }
}

export function initializationConstruct(typeBox) {
  const sizesHeightArr = [];
  const sizesWidthArr = [];
  let params = typeBox === 'Box/BoxR' ? Box_Parameters : Box_RS_Parameters;
  params.forEach((constructor) => {
    if (!sizesHeightArr.includes(constructor.height)) {
      sizesHeightArr.push(constructor.height);
    }
    if (!sizesWidthArr.includes(constructor.width)) {
      sizesWidthArr.push(constructor.width);
    }
  });
  return [sizesHeightArr.sort((a, b) => b - a), sizesWidthArr.sort((a, b) => b - a)];
}

export function initializationConstructStabilize(typeBox) {
  const sizesHeightArr = [];
  const sizesWidthArr = [];
  const filterStab = [90, 100, 120, 140];
  let params = typeBox === 'Box/BoxR' ? Box_Parameters : Box_RS_Parameters;
  params.forEach((constructor) => {
    if (!sizesHeightArr.includes(constructor.height)) {
      sizesHeightArr.push(constructor.height);
    }
    if (!sizesWidthArr.includes(constructor.width) && !filterStab.includes(constructor.width)) {
      sizesWidthArr.push(constructor.width);
    }
  });
  return [sizesHeightArr.sort((a, b) => b - a), sizesWidthArr.sort((a, b) => b - a)];
}

export function buildingAllBoxes(arrayAllHeight, arrayAllWidth) {
  let maxH = 0;
  let maxW = 0;
  let locPossible;

  let buildAllBoxes = [];
  arrayAllHeight.forEach((height, indexH) => {
    maxH += height;
    arrayAllWidth.forEach((width) => {
      if (indexH === 0) {
        maxW += width;
      }
      let box = [height, width];
      buildAllBoxes.push(box);
    });
  });

  locPossible = maxH <= 9500 ? '' : 'Pour toute hauteur supérieur à 9,5m, consulter notre Hotline !';

  return [maxH, maxW, buildAllBoxes, locPossible];
}

export function roundTop(number, top) {
  if (number % top !== 0) {
    let remainder = number % top;
    return number - remainder + top;
  } else return +number;
}

export function roundDown(number, down) {
  if (number % down !== 0) {
    let remainder = number % down;
    return number - remainder;
  } else return number;
}

export const findDoubleTop = (arr) => arr[0] === 500 && arr[1] === 500;

export function filterPanels(arr) {
  let firstPanel;
  let lastPanel;
  let double05 = findDoubleTop(arr);

  arr.forEach((panel) => {
    if (panel === 1000 || panel === 1500) {
      firstPanel = panel;
    }
    if (panel === 500) {
      lastPanel = panel;
    }
  });

  let filteredArr = arr.filter((el) => el !== 1000 && el !== 500 && el !== 1500);

  if (lastPanel) {
    filteredArr.push(lastPanel);
    if (double05) {
      filteredArr.push(lastPanel);
    }
  }

  if (firstPanel) {
    filteredArr.unshift(firstPanel);
  }
  return filteredArr;
}

export function toPanel(arr) {
  return arr.map((heightPanel) => {
    return heightPanel === 2800 || heightPanel === 3000 ? 'Panel' : heightPanel;
  });
}

export class checkElement {
  static selectedTopConnection(connectH) {
    connectH = connectH.toFixed(2);
    return (
      connectH === (6 - 0.12).toFixed(2) ||
      connectH === (7.1 - 0.12).toFixed(2) ||
      connectH === (7.5 - 0.12).toFixed(2) ||
      connectH === (9 - 0.12).toFixed(2)
    );
  }
  static allPanels(array) {
    return array.every((height) => height === 'Panel');
  }

  static firstMeter(array) {
    return array[0] === 1000;
  }

  static firstMeterAndHalf(array) {
    return array[0] === 1500;
  }

  static lastHalf(array) {
    return array[array.length - 1] === 500;
  }
}

const reN = /[^0-9.]/g;

export function alphaNum(a, b) {
  const aN = Number(a.replace(reN, ''));
  const bN = Number(b.replace(reN, ''));

  return isNaN(aN) || isNaN(bN) ? 0 : bN - aN;
}

export async function slingingFetchData(url) {
  const response = await Request.get(url);
  const sizesHeightArr = [];
  const sizesWidthArr = [];
  response.items.forEach((constructor) => {
    if (!sizesHeightArr.includes(constructor.height)) {
      sizesHeightArr.push(constructor.height);
    }
    if (!sizesWidthArr.includes(constructor.width)) {
      sizesWidthArr.push(constructor.width);
    }
  });
  return [response.items, sizesHeightArr.sort(alphaNum), sizesWidthArr.sort(alphaNum)];
}

export const dragSort = (a, b) => (a.index > b.index ? 1 : -1);

export const styleWidthD = (D) => {
  if (D === 1.9) {
    return '52px';
  } else if (D === 4.5) {
    return '55px';
  } else if (D === 2.5 || D === 3.5) {
    return '54px';
  } else if (D === 3 || D === 4 || D === 5) {
    return '44px';
  } else return '0px';
};

import React, { useEffect, useState } from 'react';
import { checkElement } from '@/components/SubSectionComponents/CleanFunctions.jsx';
import img from '@/assets/img/BallastImgs/firstMeterAndHalf/LEST_SH+RH+RH.svg';
import S from '@/styles/reactImgsBallast.module.css';
import InfoT from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/InfoT.jsx';
import InfoD from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/InfoD.jsx';
import InfoL from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/InfoL.jsx';
import ConfigStabilizeInfoButton from '@/pages/formwork/boxes/ConfiguratorStabilizier/ConfigStabilizeInfoButton.jsx';

function SHRhRh({ allHeightsPanel, T, D, L }) {
  const [find, setFind] = useState(false);

  useEffect(() => {
    if (
      allHeightsPanel.length === 3 &&
      checkElement.firstMeterAndHalf([allHeightsPanel[0]]) &&
      checkElement.lastHalf([allHeightsPanel[1]]) &&
      checkElement.lastHalf([allHeightsPanel[2]])
    ) {
      setFind(true);
    } else setFind(false);
  }, [allHeightsPanel]);

  if (!find) {
    return null;
  }

  return (
    <div className={S.container}>
      <img className={S.containerImg} src={img} alt={'3m'} style={{ top: -261 }} />
      <InfoT top={100} left={145} T={T} />
      <InfoD top={201} left={109} D={D} />
      <InfoL top={153} left={250} L={L} />
      <ConfigStabilizeInfoButton top={-331} />
    </div>
  );
}

export default SHRhRh;

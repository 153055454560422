import React, { useEffect, useState } from 'react';
import { Container, Box, Typography, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import usePage from '@/hooks/usePage.js';
import Request from '@/helpers/request.js';
import mainStyles from '@/assets/js/mainStyles.js';

const useStyles = makeStyles(mainStyles);

const BoxDescription = () => {
  const classes = useStyles();
  const { boxId, measureId } = useParams();
  const [box, setBox] = useState({});
  const [measure, setMeasure] = useState({});
  const { setTitle } = usePage();

  useEffect(async () => {
    const fetchBoxes = async () => {
      const [responseBox, responseMeasure] = await Promise.all([
        Request.get(`/boxes/${boxId}`),
        Request.get(`/measures/${measureId}`),
      ]);
      setBox(responseBox);
      setMeasure(responseMeasure);
    };
    setTitle('Descriptif');
    await fetchBoxes();
  }, []);

  return (
    <Container maxWidth="md">
      <Box className={classes.containerInner}>
        <Box className={classes.containerTitle}>
          <Typography className={classes.title} style={{ fontSize: 23, display: 'flex', justifyContent: 'center' }}>
            <span>{box.name}</span>
          </Typography>
          <Typography className={classes.description}>{measure.description}</Typography>
        </Box>
      </Box>
      <Box>
        <div className={classes.videoInner} dangerouslySetInnerHTML={{ __html: `${measure.iframe}` }} />
      </Box>
    </Container>
  );
};

export default BoxDescription;

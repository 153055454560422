import React, { useEffect, useState } from 'react';
import { checkElement } from '@/components/SubSectionComponents/CleanFunctions.jsx';
import img from '@/assets/img/AncrageImgs/firstPx/PX+PX+RH_Mini.svg';
import S from '@/styles/reactImgsBallast.module.css';
import InfoT from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/InfoT.jsx';
import InfoD from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/InfoD.jsx';
import ConfigStabilizeInfoButton from '@/pages/formwork/boxes/ConfiguratorStabilizier/ConfigStabilizeInfoButton.jsx';
import InfoB from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/InfoB.jsx';

function PxPxRhMin({ allHeightsPanel, T, D, B, fixTop }) {
  const [find, setFind] = useState(false);

  useEffect(() => {
    if (
      allHeightsPanel.length === 3 &&
      checkElement.allPanels([allHeightsPanel[0], allHeightsPanel[1]]) &&
      checkElement.lastHalf(allHeightsPanel) &&
      !fixTop
    ) {
      setFind(true);
    } else setFind(false);
  }, [allHeightsPanel, fixTop]);

  if (!find) {
    return null;
  }

  return (
    <div className={S.container} style={{ marginTop: 50 }}>
      <img className={S.containerImg} src={img} alt={'3m'} style={{ top: -140, left: -20 }} />
      <InfoT top={253} left={186} T={T} />
      <InfoD top={383} left={125} D={D} />
      <InfoB top={323} left={157} B={B} />
      <ConfigStabilizeInfoButton top={-168} />
    </div>
  );
}

export default PxPxRhMin;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Card, CardMedia, Container, makeStyles } from '@material-ui/core';
import ReactPlayer from 'react-player';
import usePage from '@/hooks/usePage.js';
import Request from '@/helpers/request.js';
import Constants from '@/constants.js';
import mainStyles from '@/assets/js/mainStyles.js';

const useStyles = makeStyles(mainStyles);

const CustomProductsImplementation = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [customProduct, setCustomProduct] = useState({});
  const { setTitle } = usePage();

  useEffect(async () => {
    const fetch = async () => {
      const response = await Request.get(`/custom-products/${id}`);
      setCustomProduct(response);
    };
    setTitle('Produits sur mesure');
    await fetch();
  }, []);
  const customProductData = customProduct.usage
    ? customProduct.usage
    : {
        text: '',
        video: '',
        image: null,
      };

  return (
    <Container maxWidth="md">
      <Box className={classes.descRoot}>
        <div
          className={classes.subTitle}
          style={{ fontSize: 26, display: 'flex', justifyContent: 'center', whiteSpace: 'nowrap' }}
        >
          <span>{customProduct?.title} </span>
        </div>
        <div className={classes.subTitle} style={{ whiteSpace: 'pre-wrap', marginTop: 20, marginBottom: 20 }}>
          {customProductData.text}
        </div>
        {customProductData.image && (
          <Card className={classes.imgCard}>
            <CardMedia
              className={classes.imgItem}
              component="img"
              image={`${Constants.BASE_URL}/${customProductData.image.path} `}
            />
          </Card>
        )}
        {customProductData?.video && (
          <Box justifyContent={'center'} className={classes.videoBox}>
            <ReactPlayer url={customProductData?.video} width="100%" height="100%" controls />
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default CustomProductsImplementation;

import React from 'react';
import { FormControl, InputLabel, makeStyles } from '@material-ui/core';
import MySelector from '../../components/SubSectionComponents/MySelector.jsx';
import customConfiguratorStyle from '../../styles/CustomConfiguratorStyle.js';

function MyFormControl({
  selected,
  setSelected,
  constructor,
  arraySelectParameters,
  indexSection,
  flagBoxRS = [0, 0, 0],
  flagBoxH4,
  flagBoxW4,
}) {
  const customStyles = makeStyles(customConfiguratorStyle)();

  return (
    <FormControl variant="outlined" className={customStyles.formControl}>
      <InputLabel className={customStyles.selectLabel} />
      <MySelector
        selected={selected}
        setSelected={setSelected}
        constructor={constructor}
        arraySelectParameters={arraySelectParameters}
        indexSection={indexSection}
        flagBoxRS={flagBoxRS}
        flagBoxH4={flagBoxH4}
        flagBoxW4={flagBoxW4}
      />
    </FormControl>
  );
}

export default MyFormControl;

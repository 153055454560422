import React, { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import {
  Box_Parameters,
  Box_R_Parameters,
  massStabilizers,
  Box_RS_Parameters,
  massPortico,
} from './API_Data/fetchParametresBoxes.jsx';
import usePage from '@/hooks/usePage.js';
import BoxComponent from '@/components/SubSectionComponents/BoxComponent.jsx';
import TypeBox from '@/components/SubSectionComponents/TypeBox.jsx';
import TypeStab from '@/components/SubSectionComponents/TypeStab.jsx';
import S from './styles/ConfiguratorSumming.module.css';
import {
  fullFiled,
  initializationConstruct,
  computationDistribution,
  distributeData,
  buildingAllBoxes,
} from '@/components/SubSectionComponents/CleanFunctions.jsx';

export const configAlert = (text) => {
  return <div className={S.possible}>{text}</div>;
};

export const completedFields = () => {
  return configAlert('Veuillez remplir tous les champs.');
};

export const alertTooSmall = () => {
  return configAlert('Hors catalogue');
};

export const alertTooShort = () => {
  return configAlert('Impossible de stabiliser');
};

export const showFullWidthHeight = (totalWidth, totalHeight) => {
  return (
    <div className={S.boxForm_Middle}>
      <div className={S.showMiddleParams}>
        <div className={S.m5}>La hauteur totale {totalHeight} m</div>
      </div>
      <div className={S.m5}>La longueur totale {totalWidth.toFixed(2)} m</div>
    </div>
  );
};

const ConfiguratorSumming = () => {
  const { setTitle } = usePage();
  const [constructorStabilizers, setConstructorStabilizers] = useState([]);
  const [constructorHeights, setConstructorHeights] = useState([]);
  const [constructorWidths, setConstructorWidths] = useState([]);
  const [arrayAllParams, setArrayAllParams] = useState([[], [], [], []]);
  const [arrayAllMass, setArrayAllMass] = useState([0, 0, 0, 0]);
  const [arrayAllMassR, setArrayAllMassR] = useState([0, 0, 0, 0]);
  const [arrayAllMassRS, setArrayAllMassRS] = useState([0, 0, 0, 0]);
  const [totalHeight, setTotalHeight] = useState(0);
  const [totalWidth, setTotalWidth] = useState(0);
  const [typeBox, setTypeBox] = useState('');
  const [typeStab, setTypeStab] = useState('');
  const [possible, setPossible] = useState('');
  const [oneSmallWidth, setOneSmallWidth] = useState(false);
  const [manyBigWidth, setManyBigWidth] = useState(false);
  const [prohibited_1_2or2_4, setProhibited_1_2or2_4] = useState(0);
  const [totalWeight, setTotalWeight] = useState([0, 0, 0]);

  useEffect(() => {
    const addMass = totalHeight >= 6500;
    //update weight
    let WeightBox = 'stabilizers' === typeStab ? arrayAllMass[2] : arrayAllMass[3];
    let WeightBoxR = 'stabilizers' === typeStab ? arrayAllMassR[2] : arrayAllMassR[3];
    let WeightBoxRS = arrayAllMassRS[2];
    setTotalWeight([addMass ? WeightBox + 52 : WeightBox, addMass ? WeightBoxR + 52 : WeightBoxR, WeightBoxRS]);
  }, [arrayAllMass, arrayAllMassR, arrayAllMassRS, typeStab, totalHeight]);

  useEffect(() => {
    let [height, width] = initializationConstruct(typeBox);
    setConstructorHeights(height);
    setConstructorWidths(width);

    const arrStab = [];
    massStabilizers.forEach((constructor) => {
      arrStab.push(constructor.stab);
    });
    setConstructorStabilizers(arrStab);
    let autoH = typeBox === 'BoxRS' ? [0, 1] : [];
    setArrayAllParams([autoH, [], [], []]);
    setArrayAllMass([0, 0, 0, 0]);
    setArrayAllMassR([0, 0, 0, 0]);
    setArrayAllMassRS([0, 0, 0, 0]);
    setTitle('Calcul de masse à lever');
  }, [typeBox]);

  useEffect(() => {
    let mass = [arrayAllMass[0], arrayAllMass[1], arrayAllMass[1], arrayAllMass[1] * 2];
    let massR = [arrayAllMassR[0], arrayAllMassR[1], arrayAllMassR[1], arrayAllMassR[1] * 2];
    let massRS = [arrayAllMassRS[0], arrayAllMassRS[1], arrayAllMassRS[1], arrayAllMassRS[1]];
    setArrayAllMass(mass);
    setArrayAllMassR(massR);
    setArrayAllMassRS(massRS);
  }, [typeStab]);

  function sortChildData() {
    //Convert back from 3.00 to 3,000
    arrayAllParams.map((array, iArr) => {
      if (iArr < 2) {
        array.map((element) => {
          if (element[0] === 'Exter 0.09') {
            element[0] = 90;
          } else if (element[0] === 'Exter 0.1') {
            element[0] = 100;
          } else if (element[0] === 'Exter 0.12') {
            element[0] = 120;
          } else if (element[0] === 'Exter 0.14') {
            element[0] = 140;
          } else if (element[0] === 'Inter 1.14') {
            element[0] = 1140;
          } else if (element[0] === '1.2< L < 2.4' || element[0] === '1.2< L < 1.8' || element[0] === 'L = 1.2 maxi') {
            element[0] = 1200;
          } else if (
            element[0] === '2.4< L < 4.20' ||
            element[0] === '2.4< L < 3.30' ||
            element[0] === 'L = 2.4 maxi'
          ) {
            element[0] = 2400;
          } else if (element[0] <= 100) element[0] = Number(element[0]) * 1000;
        });
      }
    });

    //distribute data
    if (arrayAllParams) {
      let [arrayAllHeight, arrayAllWidth, arrayAllStab] = distributeData(arrayAllParams, 3);

      //find 1 construction of width 1.2m or 0.9m or 0.6m or 0.3m
      if (
        arrayAllWidth.length === 1 &&
        (arrayAllWidth[0] === 1200 || arrayAllWidth[0] === 900 || arrayAllWidth[0] === 600 || arrayAllWidth[0] === 300)
      ) {
        setOneSmallWidth(true);
      } else setOneSmallWidth(false);

      //find 1 or more  constructions of width 2.4m
      setManyBigWidth(arrayAllWidth.every((width) => width === 2400));

      //find configurations which are prohibited
      if (arrayAllWidth.length === 1) {
        if (arrayAllWidth[0] === 1200) {
          setProhibited_1_2or2_4(12);
        } else if (arrayAllWidth[0] === 2400) {
          setProhibited_1_2or2_4(24);
        }
      }

      //Form all possible boxes with each height and width
      let [maxH, maxW, buildAllBoxes, locPossible] = buildingAllBoxes(arrayAllHeight, arrayAllWidth);
      setTotalHeight(maxH);
      setTotalWidth(maxW);
      setPossible(locPossible);

      //get the masses of all the boxes
      let summingStabilizers = 0;
      let summingPortico = 0;

      if (Array.isArray(arrayAllParams[3][0])) {
        summingPortico = arrayAllParams[3][0][1] * massPortico.mass;
      }

      massStabilizers.forEach((param) => {
        arrayAllStab.forEach((stab) => {
          if (param.stab === stab) {
            summingStabilizers += param.mass;
          }
        });
      });

      setArrayAllMass(computationDistribution(Box_Parameters, buildAllBoxes, summingStabilizers, summingPortico));
      setArrayAllMassR(computationDistribution(Box_R_Parameters, buildAllBoxes, summingStabilizers, summingPortico));
      setArrayAllMassRS(computationDistribution(Box_RS_Parameters, buildAllBoxes, summingStabilizers, summingPortico));
    }
  }

  function updateArrayParams(childData) {
    setArrayAllParams(childData);
    sortChildData();
  }

  function updateTypeBox(type) {
    if (type === 'BoxRS') {
      setTypeStab('portico');
    } else setTypeStab('');
    setTotalWeight([0, 0, 0]);
    setTypeBox(type);
  }

  function updateTypeStab(type) {
    setTypeStab(type);
  }

  function numberWithSpaces(x) {
    return Math.ceil(x)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  let text = {
    height: 'Indiquez la hauteur du train de banches',
    width: 'Indiquez la longueur du train de banches',
  };

  return (
    <Container className={S.container}>
      <TypeBox setTypeBox={updateTypeBox} />
      {typeBox === 'Box/BoxR' ? (
        <>
          {/*Select Height*/}
          <BoxComponent
            indexBox={0}
            constructor={constructorHeights}
            arrayAllParams={arrayAllParams}
            updateArrayParams={updateArrayParams}
            name={text.height}
            typeBox={typeBox}
          />
          {/*Select Width*/}
          <BoxComponent
            indexBox={1}
            constructor={constructorWidths}
            arrayAllParams={arrayAllParams}
            updateArrayParams={updateArrayParams}
            name={text.width}
            typeBox={typeBox}
          />
          {totalWidth > 0 && totalHeight > 0 && (
            <>
              {showFullWidthHeight(totalWidth / 1000, totalHeight / 1000)}
              {/*{totalWeight[1] >= 8000 && prohibitedHandling()}*/}

              {possible !== '' && (
                <>
                  <div className={S.possible}>{possible}</div>
                  {configAlert(
                    'Ceci est une calculette cela ne dispense pas de respecter les consignes de la documentation technique et de la hotline',
                  )}
                </>
              )}
              {/*Stabilizer type*/}
              {possible === '' && fullFiled(arrayAllParams, typeBox) && (
                <TypeStab
                  totalH={totalHeight}
                  setTypeStab={updateTypeStab}
                  prohibited={prohibited_1_2or2_4}
                  forUpdate={totalWidth}
                />
              )}
            </>
          )}

          {/*Stabilization output*/}
          {typeStab === 'stabilizers' && possible === '' ? (
            <BoxComponent
              indexBox={2}
              constructor={constructorStabilizers}
              arrayAllParams={arrayAllParams}
              updateArrayParams={updateArrayParams}
              name="Сhoisissez le type et la quantité"
              typeStab={typeStab}
            />
          ) : (
            typeStab === 'portico' &&
            possible === '' && (
              <BoxComponent
                indexBox={3}
                constructor={constructorStabilizers}
                arrayAllParams={arrayAllParams}
                updateArrayParams={updateArrayParams}
                name="Сhoisissez la quantité "
                typeStab={typeStab}
              />
            )
          )}

          {/*Deduction of box masses*/}
          {totalWeight[0] &&
          totalWeight[1] &&
          possible === '' &&
          fullFiled(arrayAllParams, typeBox) &&
          typeStab !== '' ? (
            <>
              <div className={S.boxForm_Final}>
                <div className={S.boxForm_Title}>
                  <div>Masse à lever</div>
                </div>
                <div className={`${S.boxForm_Body} ${S.mt25}`}>
                  <div className={S.boxForm_Final_elem}>
                    <div>BOX :</div>
                    <div>{numberWithSpaces(totalWeight[0])} Kg</div>
                  </div>
                  <div className={S.line} />
                  <div className={S.boxForm_Final_elem}>
                    <div>BOX R:</div>
                    <div>{numberWithSpaces(totalWeight[1])} Kg</div>
                  </div>
                </div>

                {typeStab === 'stabilizers' && oneSmallWidth && (
                  <div className={S.result3th}>
                    <div>Levage par manille sur 1 point CMU max 3.75 t</div>
                    <div> Levage par bras interdit</div>
                  </div>
                )}
                {typeStab === 'stabilizers' && manyBigWidth && (
                  <div className={S.result3th} style={{ margin: '10px 0' }}>
                    <div>Levage par manille en 2 points CMU max 7.5 t</div>
                    <div>Levage par bras CMU max : 6 t</div>
                  </div>
                )}
                {typeStab === 'portico' && (
                  <div className={S.result3th}>
                    <div>Levage maxi 4 t / portique</div>
                  </div>
                )}
                {(totalWeight[0] >= 7500 || totalWeight[1] >= 6000) &&
                  configAlert('Revoir la composition du train de banche pour en autoriser la manutention')}
              </div>
              {configAlert(
                'Ceci est une calculette cela ne dispense pas de respecter les consignes de la documentation technique et de la hotline',
              )}
            </>
          ) : (
            possible === '' && completedFields(S)
          )}
        </>
      ) : (
        typeBox === 'BoxRS' && (
          <>
            <BoxComponent
              indexBox={0}
              constructor={constructorHeights}
              arrayAllParams={arrayAllParams}
              updateArrayParams={updateArrayParams}
              name={text.height}
              typeBox={typeBox}
            />
            <BoxComponent
              indexBox={1}
              constructor={constructorWidths}
              arrayAllParams={arrayAllParams}
              updateArrayParams={updateArrayParams}
              name={text.width}
              typeBox={typeBox}
            />
            {totalWidth > 0 && totalHeight > 0 && showFullWidthHeight(totalWidth / 1000, totalHeight / 1000)}
            {/*{totalWeight[2] >= 8000 && prohibitedHandling()}*/}
            {possible !== '' && (
              <>
                <div className={S.possible}>{possible}</div>
                {configAlert(
                  'Ceci est une calculette cela ne dispense pas de respecter les consignes de la documentation technique et de la hotline',
                )}
              </>
            )}
            {/*Deduction of box masses*/}
            {totalWeight[2] && possible === '' && fullFiled(arrayAllParams) ? (
              <>
                <div className={S.boxForm_Final}>
                  <div className={S.boxForm_Title}>
                    <div>Masse à lever</div>
                  </div>
                  <div className={`${S.boxForm_Body} ${S.mt25}`}>
                    <div className={S.boxForm_Final_elem}>
                      <div>BOX RS :</div>
                      <div>{numberWithSpaces(totalWeight[2])} Kg</div>
                    </div>
                    <div className={S.line} />
                  </div>
                  {totalWeight[2] >= 6000 &&
                    configAlert('Revoir la composition du train de banche pour en autoriser la manutention')}
                </div>
                {configAlert(
                  'Ceci est une calculette cela ne dispense pas de respecter les consignes de la documentation technique et de la hotline',
                )}
              </>
            ) : (
              possible === '' && completedFields(S)
            )}
          </>
        )
      )}
    </Container>
  );
};

export default ConfiguratorSumming;

const customConfiguratorStyle = {
  formControl: {
    margin: 10,
    marginLeft: 0,
    minWidth: 100,
    maxWidth: 250,
  },
  selectLabel: {
    position: 'static',
    color: '#3a3a3a',
    fontSize: '20px',
    transform: 'translate(0px, -6px) scale(0.75)',
  },
  selectBody: {
    '& fieldset': {
      border: '2px solid #333333',
      borderRadius: 10,
    },
  },
  selectList: {
    padding: '5px 15px',
    display: 'block',
    textAlign: 'center',
  },
  selectOutlined: {
    padding: '13px 20px',
  },
};
export default customConfiguratorStyle;

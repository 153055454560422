import React, { useRef, useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Card,
  CardMedia,
  Container,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { alphaNum } from '@/components/SubSectionComponents/CleanFunctions.jsx';
import usePage from '../../../hooks/usePage.js';
import mainStyles from '../../../assets/js/mainStyles.js';
import Request from '../../../helpers/request.js';
import Constants from '../../../constants.js';

const useStyles = makeStyles(mainStyles);

const ChoiceBox = () => {
  const classes = useStyles();
  const history = useHistory();

  const { setTitle } = usePage();

  const [boxes, setBoxes] = useState([]);
  const [boxHeights, setBoxHeights] = useState([]);
  const [boxWidths, setBoxWidths] = useState([]);

  const selectedWidth = useRef(0);
  const selectedHeight = useRef(0);
  const measures = useRef([]);
  const [selectedBoxIds, setSelectedBoxIds] = useState([]);

  const getBoxMeasureId = (boxId) => {
    for (const measure of measures.current) {
      if (
        measure.boxId === boxId &&
        measure.width === selectedWidth.current &&
        measure.height === selectedHeight.current
      ) {
        return measure.id;
      }
    }

    return null;
  };

  const selectBoxIds = () => {
    const ids = [];

    for (const measure of measures.current) {
      if (
        measure.width === selectedWidth.current &&
        measure.height === selectedHeight.current &&
        !ids.includes(measure.boxId)
      ) {
        ids.push(measure.boxId);
      }
    }

    setSelectedBoxIds(ids);
  };

  const selectWidth = ({ target: { value } }) => {
    selectedWidth.current = value;
    selectBoxIds();
  };

  const selectHeight = ({ target: { value } }) => {
    selectedHeight.current = value;
    selectBoxIds();
  };

  const choiceBox = (active, boxId, measureId) => {
    active && history.push(`/formwork/select-subcategory/${boxId}/measures/${measureId}`);
  };

  const renderBox = () =>
    boxes.map((box) => {
      const active = selectedBoxIds.includes(box.id);
      return (
        <Grid
          item
          xs={12}
          md={12}
          className={classes.cardItem}
          key={box.id}
          onClick={() => {
            choiceBox(active, box.id, getBoxMeasureId(box.id));
          }}
        >
          <Badge className={classes.badge}>{box.name}</Badge>
          <Card className={classes.cardsBox}>
            <CardMedia
              className={`${classes.cardImg}
               ${!active ? classes.disabled : ''}`}
              image={`${Constants.BASE_URL}/${box.image.path} `}
            />
          </Card>
        </Grid>
      );
    });
  useEffect(async () => {
    const fetchBoxes = async () => {
      const [boxesResponse, measuresResponse] = await Promise.all([
        Request.get('/boxes'),
        Request.get('/measures?start=0&end=100'),
      ]);
      const boxesArr = boxesResponse.items;
      const measureArr = measuresResponse.items;

      setBoxes(boxesArr);
      const sizesHeightArr = [];
      const sizesWidthArr = [];
      measureArr.forEach((measure) => {
        if (!sizesHeightArr.includes(measure.height)) {
          sizesHeightArr.push(measure.height);
        }
        if (!sizesWidthArr.includes(measure.width)) {
          sizesWidthArr.push(measure.width);
        }

        measures.current.push({
          id: measure.id,
          boxId: measure.boxId,
          width: measure.width,
          height: measure.height,
        });
      });

      setBoxHeights(sizesHeightArr.sort(alphaNum));
      setBoxWidths(sizesWidthArr.sort(alphaNum));
    };
    setTitle('Box');
    await fetchBoxes();
  }, []);

  return (
    <Container maxWidth="md">
      <Box className={classes.containerInner}>
        <Typography gutterBottom className={classes.subTitle} style={{ fontSize: 22 }}>
          Choisissez vos dimensions :
        </Typography>
        <Box>
          <FormControl variant="outlined" className={classes.formControl} style={{ marginRight: 15 }}>
            <InputLabel className={classes.selectLabel}>Hauteur (en m)</InputLabel>
            <Select
              className={classes.selectBody}
              classes={{ outlined: classes.selectOutlinedW70 }}
              name="height"
              onChange={selectHeight}
              value={selectedHeight.current || ''}
            >
              {boxHeights.map((height) => (
                <MenuItem className={classes.selectList} value={height} key={height}>
                  {height}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControl} style={{ marginRight: 0 }}>
            <InputLabel className={classes.selectLabel}>Largeur (en m)</InputLabel>
            <Select
              className={classes.selectBody}
              classes={{ outlined: classes.selectOutlinedW70 }}
              name="width"
              onChange={selectWidth}
              value={selectedWidth.current || ''}
            >
              {boxWidths.map((width) => (
                <MenuItem className={classes.selectList} value={width} key={width}>
                  {width}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box className={classes.cardsBox}>
          <div>Sélectionnez le type de BOX</div>
          <Grid className={classes.cardsContainer} container spacing={1}>
            {renderBox()}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default ChoiceBox;

import React, { useEffect, useState } from 'react';
import { Container, Box, makeStyles, Button, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import usePage from '@/hooks/usePage.js';
import mainStyles from '@/assets/js/mainStyles.js';
import Request from '@/helpers/request.js';
import { dragSort } from '@/components/SubSectionComponents/CleanFunctions.jsx';

const useStyles = makeStyles(mainStyles);

const RenderList = (props) => {
  const { configurator, classes, history } = props;
  return (
    <Button
      key={configurator.id}
      className={classes.outlinedBtn}
      onClick={() => history.push(`configurators/${configurator.id}`)}
    >
      {configurator.title}
    </Button>
  );
};

const Configurators = () => {
  const classes = useStyles();
  const history = useHistory();
  const [configurators, setConfigurators] = useState([]);
  const { setTitle } = usePage();
  useEffect(async () => {
    const fetchData = async () => {
      const response = await Request.get('/pce2-configurators');
      setConfigurators(response.items);
    };
    setTitle('Configurateurs');
    await fetchData();
  }, []);
  return (
    <Container maxWidth="md">
      <Box className={classes.content}>
        <Typography className={classes.textBox} style={{ marginBottom: 0 }}>
          PCE 2
        </Typography>
        <div className={classes.secTitle}>
          <Typography className={classes.textBox}>Sélectionnez une rubrique :</Typography>
        </div>
        {configurators.sort(dragSort).map((configurator) => (
          <RenderList classes={classes} key={configurator.id} configurator={configurator} history={history} />
        ))}
      </Box>
    </Container>
  );
};

export default Configurators;

import React from 'react';
import { Button, Link, makeStyles } from '@material-ui/core';
import mainStyles from '@/assets/js/mainStyles.js';

const useStyles = makeStyles(mainStyles);

const ConfigStabilizeInfoButton = ({ top }) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.mainBtn}
      style={{
        zoom: 0.8,
        minWidth: 'fit-content',
        top: top,
      }}
    >
      <Link
        href="https://satecotuto.fr/#/formwork/accessories/stability"
        className={classes.siteLink}
        style={{ margin: '0' }}
      >
        Voir accessoires de stabilité
      </Link>
    </Button>
  );
};

export default ConfigStabilizeInfoButton;

import React, { useEffect, useState } from 'react';
import { MenuItem, Select } from '@material-ui/core';
import S from '@/styles/adjustment.module.css';
import style from '@/pages/formwork/boxes/styles/optionStyle.module.css';

function ExtG_ExtD({ extG_extD, updateExtG_extD }) {
  const varG = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];
  const [G, setG] = useState(extG_extD[0]);
  const [D, setD] = useState(extG_extD[1]);

  useEffect(() => {
    setG(extG_extD[0]);
    setD(extG_extD[1]);
  }, [extG_extD]);

  return (
    <div className={`${S.boxContainer} ${S.boxCalculate}`}>
      <div className={S.boxHeader}>Sélectionner les dimensions</div>
      <div className={S.dimensionContainer}>
        <div className={S.dimensionSubContainer}>
          <div className={S.red}>Ext G.</div>
          <div style={{ margin: '-3px 4px 0px 10px' }}>
            <Select className={`${style.selectG}`} onChange={(e) => updateExtG_extD([e.target.value, D])} value={G}>
              {varG.map((param, index) => (
                <MenuItem className={`${style.selectList}`} value={param} key={index}>
                  {param} cm
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div className={S.dimensionSubContainer}>
          <div className={S.black}>Lf</div>
          <div>130 cm</div>
        </div>
        <div className={S.dimensionSubContainer}>
          <div className={S.blue}>Ext D.</div>
          <div>{D} cm</div>
        </div>
      </div>
    </div>
  );
}

export default ExtG_ExtD;

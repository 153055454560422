import React from 'react';
import { Link } from '@material-ui/core';
import ReactImg1 from './PorticoImgComponents/ReactImg1.jsx';
import ReactImg2 from './PorticoImgComponents/ReactImg2.jsx';

function ReactImagesPortico({ allHeightsPanel }) {
  return (
    <>
      {allHeightsPanel.length > 0 && (
        <>
          <Link href="https://satecotuto.fr/#/formwork/accessories/stability">
            <ReactImg1 allHeightsPanel={allHeightsPanel} />
            <ReactImg2 allHeightsPanel={allHeightsPanel} />
          </Link>
        </>
      )}
    </>
  );
}

export default ReactImagesPortico;

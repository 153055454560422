import React, { useEffect, useState } from 'react';
import S from '@/styles/reactImg.module.css';
import arrowLeftRed from '@/assets/img/platform5arrows/arrowLeftRed.png';
import arrowLeftBlue from '@/assets/img/platform5arrows/arrowLeftBlue.png';
import arrowRightRed from '@/assets/img/platform5arrows/arrowRightRed.png';
import arrowRightBlue from '@/assets/img/platform5arrows/arrowRightBlue.png';

const ReactArBr = ({ AV_BV_Y_Ar_Br }) => {
  const [widthRedBlock, setWidthRedBlock] = useState('50%');
  const [widthBlueBlock, setWidthBlueBlock] = useState('50%');

  useEffect(() => {
    const widthAr = AV_BV_Y_Ar_Br[3];
    const widthBr = AV_BV_Y_Ar_Br[4];
    let cof = (widthAr / (widthBr + widthAr)) * 100;
    if (cof <= 30) {
      cof = 30;
    } else if (cof >= 70) {
      cof = 70;
    } else if (cof > 50 && cof < 55) {
      cof = 55;
    } else if (cof < 50 && cof > 45) {
      cof = 45;
    }
    setWidthRedBlock(cof + '%');
    setWidthBlueBlock(100 - cof + '%');
  }, [AV_BV_Y_Ar_Br]);
  return (
    <div
      id="Carkas"
      style={{
        width: 253,
        height: 37,
        zIndex: 5,
        position: 'absolute',
        top: 36,
        left: -130.4,
        display: 'flex',
      }}
    >
      <div id="leftRedBlock" style={{ width: `${widthRedBlock}` }}>
        <div
          id="Data"
          style={{
            height: 11,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            borderBottom: '0.5px solid #FF061E',
          }}
        >
          <div className={`${S.all} ${S.red}`} style={{ marginRight: 15 }}>
            Ar =
          </div>
          <div className={`${S.all} ${S.red}`} style={{ marginLeft: 15 }}>
            {AV_BV_Y_Ar_Br[3]}
          </div>
        </div>

        <div
          id="borders"
          style={{ minHeight: 29, borderLeft: '0.6px solid #FF061E', borderRight: '0.6px solid #FF061E' }}
        >
          <div
            id="Arrows"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              height: 2,
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <div id="leftArrow" style={{ height: 19.2 }}>
              <img src={arrowLeftRed} alt="leftArrow" style={{ height: 2.2, width: 6.5 }} />
            </div>
            <div id="rightArrow" style={{ height: 19.2 }}>
              <img src={arrowRightRed} alt="rightArrow" style={{ height: 2.2, width: 6.5 }} />
            </div>
          </div>
        </div>
        <div style={{ borderRight: '0.5px solid black', minHeight: 120 }}></div>
      </div>

      <div id="rightBlueBlock" style={{ width: `${widthBlueBlock}` }}>
        <div
          id="Data"
          style={{
            height: 11,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            borderBottom: '0.5px solid #2591FF',
          }}
        >
          <div className={`${S.all} ${S.blue}`} style={{ marginRight: 15 }}>
            Br =
          </div>
          <div className={`${S.all} ${S.blue}`} style={{ marginLeft: 15 }}>
            {AV_BV_Y_Ar_Br[4]}
          </div>
        </div>

        <div
          id="borders"
          style={{ minHeight: 29, borderLeft: '0.6px solid #2591FF', borderRight: '0.6px solid #2591FF' }}
        >
          <div
            id="Arrows"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              height: 2,
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <div id="leftArrow" style={{ height: 19.2 }}>
              <img src={arrowLeftBlue} alt="leftArrow" style={{ height: 2.2, width: 6.5 }} />
            </div>
            <div id="rightArrow" style={{ height: 19.2 }}>
              <img src={arrowRightBlue} alt="rightArrow" style={{ height: 2.2, width: 6.5 }} />
            </div>
          </div>
        </div>
        <div style={{ borderLeft: '0.5px solid black', minHeight: 120 }}></div>
      </div>
    </div>
  );
};

export default ReactArBr;

import React, { useEffect, useState } from 'react';
import { checkElement } from '@/components/SubSectionComponents/CleanFunctions.jsx';
import S from '../../../../../styles/reactImgsBallast.module.css';
import imgRS from '@/assets/img/boxRSImgs/panoRS.jpg';
import imgRS05 from '@/assets/img/boxRSImgs/pano05RS.jpg';
import imgRS0505 from '@/assets/img/boxRSImgs/pano0505RS.jpg';

function ReactImgRS({ allHeightsPanel, typeBox = 'Box/BoxR' }) {
  const [find, setFind] = useState(false);
  const [find2, setFind2] = useState([0, 0, 0]);

  useEffect(() => {
    if (allHeightsPanel.length === 1 && checkElement.allPanels(allHeightsPanel)) {
      setFind(true);
    } else if (
      allHeightsPanel.length === 2 &&
      checkElement.allPanels([allHeightsPanel[0]]) &&
      checkElement.lastHalf(allHeightsPanel)
    ) {
      setFind(true);
    } else setFind(false);

    if (allHeightsPanel.length === 1 && checkElement.allPanels(allHeightsPanel)) {
      setFind2([1, 0, 0]);
    } else if (
      allHeightsPanel.length === 2 &&
      checkElement.allPanels([allHeightsPanel[0]]) &&
      checkElement.lastHalf(allHeightsPanel)
    ) {
      setFind2([0, 1, 0]);
    } else if (
      allHeightsPanel.length === 3 &&
      checkElement.allPanels([allHeightsPanel[0]]) &&
      checkElement.lastHalf([allHeightsPanel[1]]) &&
      checkElement.lastHalf([allHeightsPanel[2]])
    ) {
      setFind2([0, 0, 1]);
    } else {
      setFind2([0, 0, 0]);
    }
  }, [allHeightsPanel]);

  if (!find && find2.every((el) => el === 0)) {
    return null;
  }

  return (
    <>
      {typeBox === 'Box/BoxR' ? (
        <></>
      ) : (
        <div className={S.container}>
          <div className={`${S.imgRS}`}>
            {find2[0] > 0 && <img src={imgRS} width="170px" alt={'img'} />}
            {find2[1] > 0 && <img src={imgRS05} width="170px" alt={'img'} />}
            {find2[2] > 0 && <img src={imgRS0505} width="170px" style={{ top: '-7px' }} alt={'img'} />}
          </div>
        </div>
      )}
    </>
  );
}

export default ReactImgRS;

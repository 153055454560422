import React, { useState } from 'react';
import boxActive from '../../assets/img/BoxActive.png';
import boxInActive from '../../assets/img/BoxInActive.png';
import S from '../../pages/formwork/boxes/styles/addPicture.module.css';
import boxRSActive from '../../assets/img/BoxRSActive.png';
import boxRSInActive from '../../assets/img/BoxRSInActive.png';

const scroll400 = () => {
  setTimeout(() => {
    window.scrollTo(0, 320);
  }, 200);
};

const TypeBox = ({ setTypeBox }) => {
  const [activeFirst, setActiveFirst] = useState(false);
  const [activeSecond, setActiveSecond] = useState(false);

  return (
    <div className={S.typeBoxContainer}>
      <div className={S.typeTitle}>TYPE DE BANCHE</div>
      <div
        onClick={() => {
          setActiveFirst(true);
          setActiveSecond(false);
          setTypeBox('Box/BoxR');
          scroll400();
        }}
      >
        <img
          src={activeFirst ? boxActive : boxInActive}
          className={`${S.imgStyle} `}
          style={{
            border: activeFirst ? '2px solid #758D9A' : '',
            boxShadow: activeFirst ? '0px 0px 10px #535477' : '',
          }}
        />
        {/*{!activeFirst && <img src={imgInActive} className={S.inActive} />}*/}
      </div>
      <div
        onClick={() => {
          setActiveSecond(true);
          setActiveFirst(false);
          setTypeBox('BoxRS');
          scroll400();
        }}
      >
        <img
          src={activeSecond ? boxRSActive : boxRSInActive}
          className={`${S.imgStyle} `}
          style={{
            border: activeSecond ? '2px solid #758D9A' : '',
            boxShadow: activeSecond ? '0px 0px 10px #535477' : '',
          }}
        />
        {/*{!activeSecond && <img src={imgInActive} className={S.inActive} />}*/}
      </div>
    </div>
  );
};

export default TypeBox;

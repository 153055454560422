import React, { useEffect, useState } from 'react';
import { checkElement } from '@/components/SubSectionComponents/CleanFunctions.jsx';
import img from '@/assets/img/AncrageImgs/firstPx/PX.svg';
import S from '@/styles/reactImgsBallast.module.css';
import InfoT from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/InfoT.jsx';
import InfoD from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/InfoD.jsx';
import ConfigStabilizeInfoButton from '@/pages/formwork/boxes/ConfiguratorStabilizier/ConfigStabilizeInfoButton.jsx';

function Px({ allHeightsPanel, T, D }) {
  const [find, setFind] = useState(false);

  useEffect(() => {
    if (allHeightsPanel.length === 1 && checkElement.allPanels(allHeightsPanel)) {
      setFind(true);
    } else setFind(false);
  }, [allHeightsPanel]);

  if (!find) {
    return null;
  }

  return (
    <div className={S.container}>
      <img className={S.containerImg} src={img} alt={'3m'} style={{ top: -261, left: 15 }} />
      <InfoT top={114} left={173} T={T} />
      <InfoD top={257} left={127} D={D} />
      <ConfigStabilizeInfoButton top={-331} />
    </div>
  );
}

export default Px;

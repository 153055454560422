import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { printPlugin } from '@react-pdf-viewer/print';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import Request from '@/helpers/request.js';
import usePage from '@/hooks/usePage.js';
import Constants from '@/constants.js';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';

const useStyles = makeStyles((theme) => ({
  videoInner: {
    textAlign: 'center',
    marginTop: 40,
    marginBottom: 20,
    '& iframe': {
      maxWidth: 540,
      maxHeight: 300,
      width: '100% !important',
      '@media (min-width: 350px)': {
        height: 200,
      },
      '@media (min-width: 425px)': {
        height: 250,
      },
      '@media (min-width: 500px)': {
        height: 350,
      },
      '@media (min-width: 768px)': {
        height: 550,
      },
    },
  },
  root: {
    margin: '0 auto',
    maxWidth: 640,
    fontFamily: 'Saira, sans-serif',
  },
  imgItem: {
    width: '100%',
    height: 'auto',
    margin: '0 auto',
  },
  imgCard: {
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'center',
  },
  description: {
    fontFamily: 'Saira, sans-serif',
    color: '#4B4440',
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
  },
  subTitle: {
    fontFamily: 'Saira, sans-serif',
    color: '#4B4440',
  },
  secTitle: {
    fontFamily: 'Saira, sans-serif',
    color: '#4B4440',
    fontSize: 24,
    display: 'flex',
    justifyContent: 'center',
    whiteSpace: 'pre-wrap',
    // '@media (max-width: 425px)': {
    //   fontSize: 22,
    // },
    // '@media (max-width: 375px)': {
    //   fontSize: 20,
    // },
    // '@media (max-width: 320px)': {
    //   fontSize: 18,
    // },
  },
}));

const AdditionalDescription = () => {
  const classes = useStyles();
  const { accessoryId } = useParams();
  const [accessory, setAccessory] = useState({});
  const { setTitle } = usePage();
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const printPluginInstance = printPlugin();
  const { Print } = printPluginInstance;

  useEffect(async () => {
    const fetchAccessories = async () => {
      const response = await Request.get(`/accessories/${accessoryId}`);
      setAccessory(response);
    };
    setTitle('DESCRIPTIF');
    await fetchAccessories();
  }, []);

  const description = accessory.description !== undefined ? accessory.description : {};
  const image = description.image !== undefined ? description.image : null;
  const images = description.images !== undefined ? description.images : [];
  const documents = accessory?.documents
    ? accessory?.documents
    : {
        file: null,
        image: null,
      };

  return (
    <Container maxWidth="md">
      <Box>
        <Box className={classes.root}>
          <div className={classes.secTitle}>
            <span>{accessory.title}</span>
          </div>
          <div className={classes.description}>{description.text}</div>
          <div className={classes.videoInner} dangerouslySetInnerHTML={{ __html: `${description.iframe ?? ''}` }} />
          <Card className={classes.imgCard}>
            {image && (
              <CardMedia className={classes.imgItem} component="img" image={`${Constants.BASE_URL}/${image.path} `} />
            )}
          </Card>
          {images.map((item, index) => (
            <Card key={index} className={classes.imgCard}>
              <CardActionArea>
                {item.image && (
                  <CardMedia
                    className={classes.imgItem}
                    component="img"
                    alt={item.image.name}
                    image={`${Constants.BASE_URL}/${item.image.path}`}
                  />
                )}
                <CardContent>
                  <Typography className={classes.subTitle} variant="body2" color="textSecondary" component="p">
                    {item.title}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
          {accessory?.description?.video && (
            <Box className={classes.videoBox} style={{ height: '500px' }}>
              <ReactPlayer url={accessory?.description?.video} width="100%" height="100%" controls />
            </Box>
          )}
          {documents.file && (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
              <div
                className="rpv-core__viewer"
                style={{
                  border: '1px solid rgba(0, 0, 0, 0.3)',
                  display: 'flex',
                  flexDirection: 'column',
                  height: '700px',
                }}
              >
                <div
                  style={{
                    alignItems: 'center',
                    backgroundColor: '#eeeeee',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '4px',
                  }}
                >
                  <ZoomOutButton />
                  <ZoomPopover />
                  <ZoomInButton />
                  <Print />
                </div>
                <div
                  style={{
                    flex: 1,
                    overflow: 'hidden',
                  }}
                >
                  <Viewer
                    defaultScale={SpecialZoomLevel.PageWidth}
                    plugins={[zoomPluginInstance, printPluginInstance]}
                    fileUrl={`${Constants.BASE_URL}/${documents.file.path} `}
                  />
                </div>
              </div>
            </Worker>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default AdditionalDescription;

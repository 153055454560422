import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Container, makeStyles, Typography } from '@material-ui/core';
import mainStyles from '@/assets/js/mainStyles.js';
import usePage from '@/hooks/usePage.js';
import Request from '@/helpers/request.js';

const useStyles = makeStyles(mainStyles);

const Module = () => {
  const classes = useStyles();
  const history = useHistory();
  const [module, setModule] = useState(null);
  const { setTitle } = usePage();

  useEffect(async () => {
    const fetchModule = async () => {
      const response = await Request.get(`/pca2-modules`);
      setModule(response.items[0]);
    };
    setTitle('PCA 2');
    await fetchModule();
  }, []);

  return (
    <Container maxWidth="md">
      <Box className={classes.contentMW}>
        <Typography className={classes.textBox}>Sélectionnez une rubrique :</Typography>
        <Box align="center" className={classes.flexBoxColumn}>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push(`module/description`)}
            variant="outlined"
            size="large"
          >
            Descriptif
          </Button>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push(`module/implementation`)}
            variant="outlined"
            size="large"
          >
            Mise en œuvre
          </Button>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push('/security/pca-2/configurators')}
            variant="outlined"
            size="large"
          >
            Configurateurs
          </Button>
          {/*<BoxVideo_FAQ pathUrl={module?.usage?.assembly?.faq} style={classes} />*/}
        </Box>
      </Box>
    </Container>
  );
};

export default Module;

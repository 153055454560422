import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardMedia,
  Container,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import usePage from '../../../../hooks/usePage.js';
import Constants from '../../../../constants.js';
import mainStyles from '@/assets/js/mainStyles.js';
import { slingingFetchData } from '@/components/SubSectionComponents/CleanFunctions.jsx';
import { slingingTitleText, LiftingProhibited } from '@/pages/formwork/boxes/Slinging/ConfiguratorSlinging.jsx';

const useStyles = makeStyles(mainStyles);

const ConfiguratorSlinging = () => {
  const classes = useStyles();

  const { setTitle } = usePage();
  setTitle('Consignes d’élingage BOX R par bras');

  const [configurators, setConfigurators] = useState([]);
  const [constructorHeights, setConfiguratorHeights] = useState([]);
  const [constructorWidths, setConfiguratorWidths] = useState([]);
  const [selectedWidth, setSelectedWidth] = useState('');
  const [selectedHeight, setSelectedHeight] = useState('');
  const [loader, setLoader] = useState(true);

  const RenderConfigurators = () => {
    setLoader(true);
    const array = configurators.map((constructor) => {
      if (constructor.height === selectedHeight && constructor.width === selectedWidth) {
        return (
          <>
            {constructor?.image?.path ? (
              <Grid item xs={12} md={12} className={classes.cardItem} key={constructor.id}>
                <Card className={classes.imgCard}>
                  <CardMedia
                    className={`${classes.imgItem}`}
                    image={`${Constants.BASE_URL}/${constructor.image.path} `}
                  />
                </Card>
              </Grid>
            ) : (
              LiftingProhibited(classes, 'Élingage par bras interdit')
            )}
          </>
        );
      }
    });
    setLoader(false);
    return array;
  };
  useEffect(async () => {
    const [items, sizesH, sizesW] = await slingingFetchData('/slinging-box-r');
    setConfigurators(items);
    setConfiguratorHeights(sizesH);
    setConfiguratorWidths(sizesW);
  }, []);

  return (
    <Container maxWidth="md">
      <div className={classes.alert}>{slingingTitleText}</div>
      <Box className={classes.root}>
        <Box className={classes.centerSelects}>
          <FormControl variant="outlined" className={classes.formControl} style={{ marginRight: 15 }}>
            <InputLabel className={classes.selectLabel}>Hauteur (en m)</InputLabel>
            <Select
              className={classes.selectBody}
              classes={{ outlined: classes.selectOutlined }}
              name="height"
              onChange={({ target: { value } }) => setSelectedHeight(value)}
              value={selectedHeight}
            >
              {constructorHeights.map((height) => (
                <MenuItem className={classes.selectList} value={height} key={height}>
                  {height}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel className={classes.selectLabel}>Largeur (en m)</InputLabel>
            <Select
              className={classes.selectBody}
              classes={{ outlined: classes.selectOutlined }}
              name="width"
              onChange={({ target: { value } }) => setSelectedWidth(value)}
              value={selectedWidth}
            >
              {constructorWidths.map((width) => (
                <MenuItem className={classes.selectList} value={width} key={width}>
                  {width}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box className={classes.cardsBox}>
          <Grid className={classes.cardsContainer} container spacing={1}>
            {loader && (
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            )}
            <RenderConfigurators />
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default ConfiguratorSlinging;

import React, { useEffect } from 'react';
import { Container, Typography, Box, Button, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import usePage from '../../hooks/usePage.js';
import mainStyles from '../../assets/js/mainStyles.js';

const useStyles = makeStyles(mainStyles);

const Configurators = () => {
  const classes = useStyles();
  const history = useHistory();

  const { setTitle } = usePage();

  useEffect(() => {
    setTitle('Configurateurs');
  }, []);

  return (
    <Container>
      <Box className={classes.contentMW}>
        <Typography className={classes.textBox} style={{ marginBottom: 0 }}>
          BOX / BOX R / BOX RS
        </Typography>
        <Typography className={classes.textBox}>Sélectionnez une rubrique :</Typography>
        <Box align="center" className={classes.flexBoxColumn}>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push('/formwork/configurators/slinging')}
            variant="outlined"
            size="large"
          >
            Consignes d’élingage
          </Button>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push('/formwork/configurators/handling-skills')}
            variant="outlined"
            size="large"
          >
            Relevage à plat
          </Button>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push('/formwork/configurators/summing')}
            variant="outlined"
            size="large"
          >
            Calcul de masse à lever
          </Button>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push('/formwork/configurators/stabilisation')}
            variant="outlined"
            size="large"
          >
            Stabilité
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Configurators;

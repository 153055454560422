import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { ErrorOutline, CheckCircle } from '@material-ui/icons';
import usePage from '../../../../hooks/usePage.js';
import mainStyles from '@/assets/js/mainStyles.js';
import { slingingFetchData, alphaNum } from '@/components/SubSectionComponents/CleanFunctions.jsx';
import hotlinkImg from '@/assets/img/hotline.jpg';
import warningImg from '@/assets/img/warning.jpg';

const useStyles = makeStyles(mainStyles);

const Handling = () => {
  const classes = useStyles();
  const iframe =
    '<div ' +
    'class="sketchfab-embed-wrapper">' +
    ' <iframe title="Bol De Relevage 27300100" frameborder="0" ' +
    'allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" ' +
    'allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share' +
    ' width="100%" height="400" src="https://sketchfab.com/models/f12090746f9b4e73a020edb6ec923149/embed?autostart=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_settings=0&ui_vr=0&ui_fullscreen=1">' +
    ' </iframe> </div>';

  const { setTitle } = usePage();

  const [configurators, setConfigurators] = useState([]);
  const [constructorHeights, setConfiguratorHeights] = useState([]);
  const [constructorWidths, setConfiguratorWidths] = useState([]);
  const [selectedWidth, setSelectedWidth] = useState('');
  const [selectedHeight, setSelectedHeight] = useState('');
  const statuses = {
    ok: <CheckCircle htmlColor={'#0fbb25'} fontSize="inherit" />,
    drp: <ErrorOutline htmlColor={'#ff7b3a'} fontSize="inherit" />,
    ci: <img className={`${classes.warning}`} src={warningImg} />,
  };
  const statusName = {
    ok: 'Relevage par élingue possible. Après un relevage, toujours contrôler le serrage des assemblages.',
    drp: 'Relevage avec dispositif de relevage à plat.  Après un relevage, toujours contrôler le serrage des assemblages.',
    ci: 'Relevage par élingue interdit',
  };

  const renderConfigurators = () =>
    configurators?.map((constructor) => {
      if (constructor.height === selectedHeight && constructor.width === selectedWidth) {
        return (
          <Grid item xs={12} md={12} className={classes.cardItem} key={constructor.id}>
            <Typography className={classes.subTitle} style={{ fontSize: 110 }}>
              {statuses[constructor.status]}
            </Typography>
            <Typography className={classes.subTitle} style={{ color: constructor.status === 'ci' ? 'red' : '' }}>
              {statusName[constructor.status]}
            </Typography>
            {constructor.status === 'drp' && (
              <div className={classes.videoInner} dangerouslySetInnerHTML={{ __html: iframe }} />
            )}
            {/*  Hot line*/}
            {constructor.status === 'ci' && <img className={`${classes.hotLine}`} src={hotlinkImg} />}
          </Grid>
        );
      }
    });

  useEffect(async () => {
    const [items, sizesH, sizesW] = await slingingFetchData('/handling-box');
    setConfigurators(items);
    setConfiguratorHeights(sizesH);
    setConfiguratorWidths(sizesW);
    setTitle('Relevage à plat BOX R par bras');
  }, []);

  useEffect(() => {
    const sizesWidthArr = [];
    configurators.forEach((constructor) => {
      if (!sizesWidthArr.includes(constructor.width) && constructor.height === selectedHeight) {
        sizesWidthArr.push(constructor.width);
      }
    });
    setConfiguratorWidths(sizesWidthArr.sort(alphaNum));
  }, [selectedHeight]);

  useEffect(() => {
    const sizesHeightArr = [];
    configurators.forEach((constructor) => {
      if (!sizesHeightArr.includes(constructor.height) && constructor.width === selectedWidth) {
        sizesHeightArr.push(constructor.height);
      }
    });
    setConfiguratorHeights(sizesHeightArr.sort(alphaNum));
  }, [selectedWidth]);

  return (
    <Container maxWidth="md">
      <Box className={classes.root}>
        <Box className={classes.centerSelects}>
          <FormControl variant="outlined" className={classes.formControl} style={{ marginRight: 15 }}>
            <InputLabel className={classes.selectLabel}>Hauteur (en m)</InputLabel>
            <Select
              className={classes.selectBody}
              classes={{ outlined: classes.selectOutlined }}
              name="height"
              onChange={({ target: { value } }) => setSelectedHeight(value)}
              value={selectedHeight}
            >
              {constructorHeights.map((height) => (
                <MenuItem className={classes.selectList} value={height} key={height}>
                  {height}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel className={classes.selectLabel}>Largeur (en m)</InputLabel>
            <Select
              className={classes.selectBody}
              classes={{ outlined: classes.selectOutlined }}
              name="width"
              onChange={({ target: { value } }) => setSelectedWidth(value)}
              value={selectedWidth}
            >
              {constructorWidths.map((width) => (
                <MenuItem className={classes.selectList} value={width} key={width}>
                  {width}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box className={classes.cardsBox}>
          <Grid className={classes.cardsContainer} container spacing={1}>
            {renderConfigurators()}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Handling;

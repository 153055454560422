import React, { useEffect } from 'react';
import { Container, Typography, Box, makeStyles, Button, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import usePage from '@/hooks/usePage.js';
import mainStyles from '@/assets/js/mainStyles.js';

const customStyle = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 'calc(100vh - 230px)',
    '@media (min-width: 768px)': {
      minHeight: 'calc(100vh - 238px)',
    },
  },
  infoWrapper: {
    background: '#edf1f4',
    padding: '15px 20px',
    fontSize: 20,
    fontWeight: 700,
    fontFamily: 'sans-serif',
    color: '#85949d',
  },
  infoLink: {
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  siteLink: {
    color: 'inherit',
    textAlign: 'center',
    display: 'block',
    marginTop: 10,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  infoText: {
    fontWeight: 700,
    textTransform: 'uppercase',
    fontStyle: 'italic',
    marginTop: 10,
  },
  infoBox: {
    marginTop: 5,
    background: '#fff',
    padding: '0 5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid #d7decd',
  },
  linkHotline: {
    color: '#78b634',
  },
  infoBadge: {
    background: 'linear-gradient(#b2cc79, #479639);',
    color: '#fff',
    width: 100,
    fontSize: 12,
    padding: '5px 5px 5px 10px',
    position: 'relative',
    marginLeft: 5,
  },
};
const useStyles = makeStyles(mainStyles);
const useStylesCustom = makeStyles(customStyle);

const Faq = () => {
  const classes = useStyles();
  const classesCustom = useStylesCustom();
  // const history = useHistory();

  const { setTitle } = usePage();

  useEffect(() => {
    setTitle('FAQ');
  }, []);

  return (
    <Container maxWidth="md">
      <Box className={classesCustom.root}>
        <Typography className={classes.subTitle}>Si vous avez des questions, contactez-nous</Typography>
        <Box>
          <Box className={classesCustom.infoWrapper}>
            <span>Tel :</span>
            <Link href="tel:+330549504169" className={classesCustom.infoLink}>
              {' '}
              +33 (0)5 49 50 41 69
            </Link>
            <Typography className={classesCustom.infoText}>Hotline</Typography>
            <Box className={classesCustom.infoBox}>
              <Link href="tel:0809400380" className={classesCustom.linkHotline}>
                0 809 400 380
              </Link>
              <span className={classesCustom.infoBadge}>Service gratuit &prix d&apos;appel</span>
            </Box>
            <Link href="https://www.sateco.fr/fr/faq.html" className={classesCustom.siteLink}>
              sateco.fr
            </Link>
          </Box>
          <Button component={RouterLink} className={classes.mainBtn} to="/">
            FAQ
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Faq;

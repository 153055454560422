import React, { useEffect, useState } from 'react';
import S from '../styles/ConfiguratorSumming.module.css';
import SelectionBlockDH from '@/components/SubSectionComponents/SelectionBlockDH.jsx';
import {
  selectRecommendStab,
  updateRecommendDiagonalBrace,
  updateWeightCategory,
  updateThereforeFStage,
  updateRv,
  updateStabLength,
} from '@/components/SubSectionComponents/CleanFunctions.jsx';
import ReactImagesAnchor from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/ReactImagesAnchor.jsx';
import { completedFields } from '@/pages/formwork/boxes/ConfiguratorSumming.jsx';

const StabilisationByAnchor = (props) => {
  const {
    totalHeight,
    fV,
    positionTheHolder,
    flag_3m_2_8m_FirstH_DoubleTop,
    speedWind,
    numberBallastRequired,
    allHeightsPanel,
    widthFormPanels,
  } = props;

  const [rV, setRV] = useState(0);
  const [stabLength, setStabLength] = useState(0);
  const [thereforeFStage, setThereforeFStage] = useState(0);
  const [lengthTheLowerDiagonalBrace, setLengthTheLowerDiagonalBrace] = useState(0);
  const [stabilizer, setStabilizer] = useState('');
  const [diagonalBrace, setDiagonalBrace] = useState('');
  const [ballastWeight, setBallastWeight] = useState(0);
  const [weightCategory, setWeightCategory] = useState(0);
  const [selectedH, setSelectedH] = useState([]);
  const [selectedSecondH, setSelectedSecondH] = useState('');
  const [selectedD, setSelectedD] = useState([]);
  const [ballastHeight, setBallastHeight] = useState(0);

  const updateSelectedSecondH = (boolean) => {
    setSelectedSecondH(boolean);
  };

  function updateH(data) {
    setSelectedH(data);
  }

  function updateD(data) {
    setSelectedD(data);
  }

  useEffect(() => {
    if (weightCategory === 2 || weightCategory === 3) {
      setBallastHeight(0.97);
    } else {
      setBallastHeight(0.77);
    }
  }, [weightCategory]);

  useEffect(() => {
    if (totalHeight === 0) {
      setRV(0);
      setStabLength(0);
      setThereforeFStage(0);
      setLengthTheLowerDiagonalBrace(0);
      setBallastWeight(0);
      setBallastHeight(0);
      setWeightCategory(0);
      setStabilizer('');
      setDiagonalBrace('');
    }
  }, [totalHeight]);

  function updateLengthTheLowerDiagonalBrace(data) {
    setLengthTheLowerDiagonalBrace(data);
  }

  //category weight du lest
  useEffect(() => {
    setWeightCategory(updateWeightCategory(ballastWeight));
  }, [ballastWeight]);

  //update poids de lest
  useEffect(() => {
    let weight = (fV * (totalHeight / 2)) / selectedD / 1000;
    setBallastWeight(weight);
  }, [fV, totalHeight, selectedD]);

  //category weight category
  useEffect(() => {
    setWeightCategory(updateWeightCategory(ballastWeight));
  }, [ballastWeight]);

  useEffect(() => {
    //update recommend stab
    setStabilizer(selectRecommendStab(stabLength, totalHeight, speedWind));

    //update recommend diagonalBrace
    setDiagonalBrace(updateRecommendDiagonalBrace(lengthTheLowerDiagonalBrace, totalHeight));
  }, [stabLength, totalHeight, speedWind, lengthTheLowerDiagonalBrace]);

  //update lengthTheLowerDiagonalBrace
  useEffect(() => {
    let diagonalBracePnx = 0.28;
    let diagonalBraceStabSol = 0.5;
    let hauteurDiagonalBraceSolSurPnx = 0.28;

    let calculate = Math.pow(
      Math.pow(selectedD - diagonalBracePnx, 2) + Math.pow(diagonalBraceStabSol - hauteurDiagonalBraceSolSurPnx, 2),
      0.5,
    );
    setLengthTheLowerDiagonalBrace(calculate);
  }, [selectedD]);

  //update thereforeFStage
  useEffect(() => {
    setThereforeFStage(updateThereforeFStage(rV, selectedD, positionTheHolder, stabLength));
  }, [selectedD, positionTheHolder, stabLength, rV]);

  //update rV
  useEffect(() => {
    setRV(updateRv(fV, totalHeight, selectedD));
  }, [totalHeight, fV, selectedD]);

  //update stabLength
  useEffect(() => {
    setStabLength(updateStabLength(selectedD, positionTheHolder, selectedH));
  }, [selectedD, positionTheHolder, selectedH]);

  return (
    <div className={S.containerAnchor}>
      <div>
        <SelectionBlockDH
          updateH={updateH}
          updateD={updateD}
          totalHeight={totalHeight}
          flag_3m_2_8m_FirstH_DoubleTop={flag_3m_2_8m_FirstH_DoubleTop}
          speedWind={speedWind}
          ballastHeight={ballastHeight}
          weightCategory={weightCategory}
          updateLengthTheLowerDiagonalBrace={updateLengthTheLowerDiagonalBrace}
          flag={'anchor'}
          fV={fV}
          widthFormPanels={widthFormPanels}
          updateSelectedSecondH={updateSelectedSecondH}
        />
      </div>

      {selectedH > 0 && selectedD > 0 ? (
        <>
          <div className={S.boxForm_Final}>
            <div className={S.boxForm_Title}>
              <div>Сalcul Final</div>
            </div>

            <div className={`${S.boxForm_Body} ${S.mt25}`}>
              <div className={S.boxForm_Final_elem}>
                <div>Type et quantité de stabilisateurs (T) :</div>
                <div className={S.disFlex}>
                  {stabilizer !== 'Hors catalogue' &&
                  stabilizer !== 'La hauteur indiquée est trop petite' &&
                  stabilizer !== 'La hauteur indiquée est trop élevée' ? (
                    <div className={S.ml5}>
                      {stabilizer} x {numberBallastRequired}
                    </div>
                  ) : (
                    <div className={S.textR}>{stabilizer}</div>
                  )}
                </div>
              </div>
              <div className={S.line} />
              <div className={S.boxForm_Final_elem}>
                <div>Type de Bracon bas (B) :</div>
                {diagonalBrace !== 'Hors catalogue' && diagonalBrace !== 'Pas de bracon bas' ? (
                  <div className={S.ml5}>
                    {diagonalBrace} x {numberBallastRequired}
                  </div>
                ) : (
                  <div className={S.textR}>{diagonalBrace}</div>
                )}
              </div>
            </div>
          </div>
          <ReactImagesAnchor
            allHeightsPanel={allHeightsPanel}
            T={stabilizer}
            D={selectedD}
            B={diagonalBrace}
            selectedSecondH={selectedSecondH}
          />
        </>
      ) : (
        completedFields(S)
      )}
    </div>
  );
};

export default StabilisationByAnchor;

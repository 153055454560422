import React, { useEffect, useState } from 'react';
import { isArray } from 'rxjs/internal-compatibility';
import MyFormControl from './MyFormControl.jsx';
import S from '../../pages/formwork/boxes/styles/ConfiguratorSumming.module.css';
import deleteIcon from '../../assets/img/delIcon.png';
import DropList from './DropList.jsx';

const SubBoxComponent = ({
  typeStab = 'stabilizers',
  typeBox = 'Box/BoxR',
  arrayAllParams = [],
  flagBoxW4 = false,
  flagBoxRS = [0, 0, 0],
  ...props
}) => {
  const { arraySelectParameters, indexBox, indexSection, updateInputData, deleteForm, flagBoxH4, flagStabilisation } =
    props;
  let autoH = typeBox === 'BoxRS' && indexBox === 0 && indexSection === 1 ? 1 : 0;

  const [counterSection, setCounterSection] = useState(autoH);
  const [selectedParameter, setSelectedParameter] = useState(0);

  useEffect(() => {
    if (isArray(arrayAllParams[1])) {
      if (isArray(arrayAllParams[1][0])) {
        if (arrayAllParams[1][0][0] === 0 && flagBoxW4) {
          setSelectedParameter(0);
        }
      }
    }
    if (flagBoxW4 && arraySelectParameters[0] === 30) {
      setSelectedParameter('30');
    }
    if (flagBoxW4 && arraySelectParameters[0] === 60) {
      setSelectedParameter('60');
    }
  }, [arrayAllParams]);

  function updateCounter(value) {
    setCounterSection(value);
  }

  useEffect(() => {
    setCounterSection(autoH);
    setSelectedParameter('');
  }, [typeBox]);

  useEffect(() => {
    if (typeBox === 'Box/BoxR') {
      setCounterSection('');
      setSelectedParameter('');
    }
  }, [typeStab]);

  useEffect(() => {
    let newArray = arraySelectParameters;
    let localSelector = selectedParameter;
    let rs12 = ["PX de 1,20 + rallonge jusqu'à 2,40", "PX de 1,20 + rallonge jusqu'à 1,80", 'L = 1,2 m max'];
    let rs24 = ["PX de 2,40 + rallonge jusqu'à 4,20", "PX de 2,40 + rallonge jusqu'à 3,30", 'L = 2,40 m max'];

    if (rs12.find((el) => el === selectedParameter) !== undefined) {
      localSelector = 1200;
    } else if (rs24.find((el) => el === selectedParameter) !== undefined) {
      localSelector = 2400;
    } else if (
      selectedParameter === "Angle + rallonge jusqu'à 2 X 0,60" ||
      selectedParameter === 'Angle + rallonge 0,30' ||
      selectedParameter === 'Angle'
    ) {
      localSelector = 777;
    } else if (selectedParameter === '30' && flagBoxW4) {
      localSelector = 30;
    } else if (selectedParameter === '60' && flagBoxW4) {
      localSelector = 60;
    }

    if (localSelector) {
      newArray[indexSection] = [localSelector, counterSection];
    } else {
      newArray[indexSection] = [selectedParameter, counterSection];
    }

    updateInputData(newArray);
  }, [selectedParameter, counterSection]);

  return (
    <div className={S.subBoxContainer}>
      <div>
        {(typeStab === 'stabilizers' || typeBox === 'BoxRS') && (
          <MyFormControl
            selected={selectedParameter}
            setSelected={setSelectedParameter}
            constructor={props.constructor}
            indexBox={indexBox}
            arraySelectParameters={arraySelectParameters}
            indexSection={indexSection}
            flagBoxRS={flagBoxRS}
            flagBoxH4={flagBoxH4}
            flagBoxW4={flagBoxW4}
          />
        )}
      </div>
      {(typeBox === 'Box/BoxR' || !flagBoxRS[3]) && (
        <>
          <div className={S.count}>Quantité</div>
          <DropList
            updateSelect={updateCounter}
            typeBox={typeBox}
            indexBox={indexBox}
            typeStab={typeStab}
            selectParams={arraySelectParameters}
            indexSection={indexSection}
            flagBoxH4={flagBoxH4}
            flagStabilisation={flagStabilisation}
          />
        </>
      )}

      {indexSection > 0 && !flagBoxH4 && !flagBoxW4 && (
        <img
          src={deleteIcon}
          onClick={() => {
            deleteForm(indexSection);
          }}
          className={S.delButton}
        />
      )}
    </div>
  );
};

export default SubBoxComponent;

import React, { useEffect, useState } from 'react';
import { checkElement } from '@/components/SubSectionComponents/CleanFunctions.jsx';
import img from '@/assets/img/BallastImgs/firstMeter/LEST_SH+PX+RH.svg';
import S from '@/styles/reactImgsBallast.module.css';
import InfoT from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/InfoT.jsx';
import InfoD from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/InfoD.jsx';
import InfoL from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/InfoL.jsx';
import ConfigStabilizeInfoButton from '@/pages/formwork/boxes/ConfiguratorStabilizier/ConfigStabilizeInfoButton.jsx';

function ShPxRh({ allHeightsPanel, T, D, L }) {
  const [find, setFind] = useState(false);

  useEffect(() => {
    if (
      allHeightsPanel.length === 3 &&
      checkElement.firstMeter([allHeightsPanel[0]]) &&
      checkElement.allPanels([allHeightsPanel[1]]) &&
      checkElement.lastHalf([allHeightsPanel[2]])
    ) {
      setFind(true);
    } else setFind(false);
  }, [allHeightsPanel]);

  if (!find) {
    return null;
  }

  return (
    <div className={S.container}>
      <img className={S.containerImg} src={img} alt={'3m'} style={{ top: -201, left: 17 }} />
      <InfoT top={160} left={145} T={T} />
      <InfoD top={261} left={109} D={D} />
      <InfoL top={213} left={250} L={L} />
      <ConfigStabilizeInfoButton top={-271} />
    </div>
  );
}

export default ShPxRh;

import React, { useEffect, useState } from 'react';
import S from '@/styles/adjustment.module.css';
import { roundDown, roundTop } from '@/components/SubSectionComponents/CleanFunctions.jsx';

function Dimensions({ inputParams, updateParams }) {
  const [depth, setDepth] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setDepth(inputParams.totalDepth - 6.5);
    setWidth(roundTop(inputParams.totalWidth - 10, 5));
    setHeight(roundDown(inputParams.totalHeight, 10));
  }, [inputParams]);

  useEffect(() => {
    updateParams([depth, width, height]);
  }, [depth, width, height]);

  return (
    <div className={`${S.boxContainer} ${S.boxCalculate}`}>
      <div className={S.boxHeader}>Dimensions du PCA2</div>
      <div className={S.dimensionContainer}>
        <div className={S.dimensionSubContainer}>
          <div className={S.orange}>Profondeur</div>
          <div>{depth} cm</div>
        </div>
        <div className={S.dimensionSubContainer}>
          <div className={S.orange}>Largeur</div>
          <div>{width} cm</div>
        </div>
        <div className={S.dimensionSubContainer}>
          <div className={S.orange}>Hauteur</div>
          <div>{height} cm</div>
        </div>
      </div>
    </div>
  );
}

export default Dimensions;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, makeStyles } from '@material-ui/core';
import ReactPlayer from 'react-player';
import usePage from '@/hooks/usePage.js';
import Request from '@/helpers/request.js';
import mainStyles from '@/assets/js/mainStyles.js';

const useStyles = makeStyles(mainStyles);

const Setting = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [module, setModule] = useState(null);
  const { setTitle } = usePage();

  useEffect(async () => {
    const fetchModule = async () => {
      const response = await Request.get(`/p3d2-modules/${id}`);
      setModule(response);
      setTitle('Reglage');
    };
    await fetchModule();
  }, []);

  return (
    <Container maxWidth="md">
      <div className={classes.secTitle}>
        <span>{module?.usage?.setting?.title}</span>
      </div>
      <Box className={classes.descRoot}>
        {module?.usage?.setting && (
          <Box justifyContent={'center'} className={classes.videoBox}>
            <ReactPlayer url={module?.usage.setting.video} width="100%" height="100%" controls />
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default Setting;

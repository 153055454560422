import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardMedia, Container, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { printPlugin } from '@react-pdf-viewer/print';
import Request from '@/helpers/request.js';
import usePage from '@/hooks/usePage.js';
import Constants from '@/constants.js';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';

const useStyles = makeStyles((theme) => ({
  videoInner: {
    textAlign: 'center',
    marginTop: 40,
    marginBottom: 20,
    '& iframe': {
      maxWidth: 540,
      maxHeight: 300,
      width: '100% !important',
      '@media (min-width: 350px)': {
        height: 200,
      },
      '@media (min-width: 425px)': {
        height: 250,
      },
      '@media (min-width: 500px)': {
        height: 350,
      },
      '@media (min-width: 768px)': {
        height: 550,
      },
    },
  },
  root: {
    margin: '0 auto',
    maxWidth: 640,
    fontFamily: 'Saira, sans-serif',
    '@media (min-width: 350px)': {
      maxWidth: 290,
    },
    '@media (min-width: 425px)': {
      maxWidth: 320,
    },
    '@media (min-width: 500px)': {
      maxWidth: 420,
    },
    '@media (min-width: 768px)': {
      maxWidth: 620,
    },
  },
  imgItem: {
    width: '100%',
    height: 'auto',
    margin: '0 auto',
  },
  imgCard: {
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'center',
  },
  mainBtn: {
    background: '#4D5B71',
    color: '#fff',
    margin: '30px auto',
    maxWidth: '325px',
    width: '100%',
    textTransform: 'none',
    padding: 7,
    display: 'block',
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'normal',
    '&.disabled': {
      color: '#fff',
    },
    fontFamily: 'Saira, sans-serif',
  },
  description: {
    fontFamily: 'Saira, sans-serif',
    color: '#4B4440',
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
  },
  subTitle: {
    fontFamily: 'Saira, sans-serif',
    color: '#4B4440',
  },
  secTitle: {
    fontFamily: 'Saira, sans-serif',
    color: '#4B4440',
    fontSize: 24,
    display: 'flex',
    justifyContent: 'center',
    whiteSpace: 'pre-wrap',
    // '@media (max-width: 425px)': {
    //   fontSize: 22,
    // },
    // '@media (max-width: 375px)': {
    //   fontSize: 20,
    // },
    // '@media (max-width: 320px)': {
    //   fontSize: 18,
    // },
  },
}));

const InnovationDescription = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [component, setComponent] = useState({});
  const { setTitle } = usePage();
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const printPluginInstance = printPlugin();
  const { Print } = printPluginInstance;

  useEffect(async () => {
    const fetch = async () => {
      const response = await Request.get(`/p3d2-components/${ id }`);
      setComponent(response);
    };
    setTitle('DESCRIPTIF');
    await fetch();
  }, []);

  const description = component.description !== undefined ? component.description : {};
  const images = description.images !== undefined ? description.images : null;
  const documents = component.documents
    ? component.documents
    : {
      file: null,
      image: null,
    };

  return (
    <Container maxWidth="md">
      <Box>
        <Box className={ classes.root }>
          <div className={ classes.secTitle }>
            <span>{ component?.title } </span>
          </div>
          <div className={ classes.description }>{ description.text }</div>
          <div className={ classes.videoInner }
               dangerouslySetInnerHTML={ { __html: `${ description.iframe ?? '' }` } }/>
          { documents.image && (
            <Card className={ classes.imgCard }>
              <CardMedia
                className={ classes.imgItem }
                component="img"
                image={ `${ Constants.BASE_URL }/${ documents.image.path } ` }
              />
            </Card>
          ) }
          { documents.file && (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
              <div
                className="rpv-core__viewer"
                style={ {
                  border: '1px solid rgba(0, 0, 0, 0.3)',
                  display: 'flex',
                  flexDirection: 'column',
                  height: '700px',
                } }
              >
                <div
                  style={ {
                    alignItems: 'center',
                    backgroundColor: '#eeeeee',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '4px',
                  } }
                >
                  <ZoomOutButton/>
                  <ZoomPopover/>
                  <ZoomInButton/>
                  <Print/>
                </div>
                <div
                  style={ {
                    flex: 1,
                    overflow: 'hidden',
                  } }
                >
                  <Viewer
                    defaultScale={ SpecialZoomLevel.PageWidth }
                    plugins={ [zoomPluginInstance, printPluginInstance] }
                    fileUrl={ `${ Constants.BASE_URL }/${ documents.file.path } ` }
                  />
                </div>
              </div>
            </Worker>
          ) }
          { images && images.map(({ image }) => (
            <Card key={image.path} className={ classes.imgCard }>
              <CardMedia className={ classes.imgItem } component="img"
                         image={ `${ Constants.BASE_URL }/${ image.path } ` }/>
            </Card>
          )) }
          { component?.description?.video && (
            <Box className={ classes.videoBox } style={ { height: '500px' } }>
              <ReactPlayer url={ component?.description?.video } width="100%" height="100%" controls/>
            </Box>
          ) }
          <Button
            className={ classes.mainBtn }
            onClick={ () => window.open('https://www.sateco.fr/fr/faq.html') }
            variant="outlined"
            size="large"
          >
            FAQ
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default InnovationDescription;

import React, { useEffect, useState } from 'react';
import { Container, Box, makeStyles, Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import usePage from '@/hooks/usePage.js';
import mainStyles from '@/assets/js/mainStyles.js';
import Request from '@/helpers/request.js';
import { dragSort } from '@/components/SubSectionComponents/CleanFunctions.jsx';

const RenderList = (props) => {
  const { component, classes, history } = props;
  return (
    <Button
      key={component.id}
      className={classes.outlinedBtn}
      button
      onClick={() => history.push(`components/${component.id}`)}
    >
      {component.title}
    </Button>
  );
};
const useStyles = makeStyles(mainStyles);

const Components = () => {
  const classes = useStyles();
  const history = useHistory();
  const [components, setComponents] = useState([]);
  const { setTitle } = usePage();

  useEffect(async () => {
    const fetchComponents = async () => {
      const response = await Request.get('/pca2-components?sort=title');
      setComponents(response.items);
    };
    setTitle('PCA 2');
    await fetchComponents();
  }, []);

  return (
    <Container maxWidth="md">
      <Box className={classes.content}>
        <Typography className={classes.subTitle} style={{ fontSize: 22 }}>
          Composants :
        </Typography>
        {components.sort(dragSort).map((component) => (
          <RenderList classes={classes} key={component.id} component={component} history={history} />
        ))}
        {/*<BoxVideo_FAQ pathUrl={components?.usage?.faq} style={classes} />*/}
      </Box>
    </Container>
  );
};

export default Components;

import React, { useEffect } from 'react';
import { Container, Typography, Box, Button, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import usePage from '@/hooks/usePage.js';
import mainStyles from '@/assets/js/mainStyles.js';

const useStyles = makeStyles(mainStyles);

const Configurators = () => {
  const classes = useStyles();
  const history = useHistory();

  const { setTitle } = usePage();

  useEffect(() => {
    setTitle("CONSIGNES D'ELINGAGE");
  }, []);

  return (
    <Container maxWidth="md">
      <Box className={classes.contentMW}>
        <Typography className={classes.textBox}>Sélectionnez une rubrique :</Typography>
        <Box align="center" className={classes.flexBoxColumn}>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push('/formwork/configurators/slinging/box-box-r')}
            variant="outlined"
            size="large"
          >
            Consignes d’élingage BOX / BOX R par manilles
          </Button>
          <Button
            className={`${classes.outlinedBtn} ${classes.doubleBtn}`}
            onClick={() => history.push('/formwork/configurators/slinging/box-rs')}
          >
            <div>Consignes d’élingage BOX RS</div>
            <div>par bras</div>
          </Button>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push('/formwork/configurators/slinging/box-r')}
            variant="outlined"
            size="large"
          >
            Consignes d’élingage BOX R par bras
          </Button>
          <Button
            className={`${classes.outlinedBtn} ${classes.doubleBtn}`}
            onClick={() => history.push('/formwork/configurators/slinging/box')}
          >
            <div>Consignes d’élingage BOX</div>
            <div>avec portique</div>
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Configurators;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, makeStyles } from '@material-ui/core';
import ReactPlayer from 'react-player';
import usePage from '@/hooks/usePage.js';
import Request from '@/helpers/request.js';
import mainStyles from '@/assets/js/mainStyles.js';
import { BoxVideo_FAQ } from '@/pages/security/pce-2/modules/Description.jsx';

const useStyles = makeStyles(mainStyles);

const Logistics = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [module, setModule] = useState(null);
  const { setTitle } = usePage();

  useEffect(() => {
    const fetchModule = async () => {
      const response = await Request.get(`/pce2-modules/${id}`);
      setModule(response);
      setTitle('LOGISTIQUE');
    };
    fetchModule();
  }, []);

  return (
    <Container maxWidth="md">
      <div className={classes.secTitle}>
        <span>{module?.usage?.titleLog}</span>
      </div>
      <Box className={classes.descRoot}>
        {module?.usage?.logistics && (
          <Box justifyContent={'center'} className={classes.videoBox}>
            <ReactPlayer url={module.usage.logistics} width="100%" height="100%" controls />
          </Box>
        )}
        <BoxVideo_FAQ pathUrl={module?.usage?.assembly?.faq} style={classes} />
      </Box>
    </Container>
  );
};

export default Logistics;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Card, CardMedia, Container, makeStyles } from '@material-ui/core';
import ReactPlayer from 'react-player';
import usePage from '@/hooks/usePage.js';
import Request from '@/helpers/request.js';
import Constants from '@/constants.js';
import mainStyles from '@/assets/js/mainStyles.js';

const useStyles = makeStyles(mainStyles);

const InnovationImplementation = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [innovation, setInnovation] = useState({});
  const { setTitle } = usePage();

  useEffect(async () => {
    const fetch = async () => {
      const response = await Request.get(`/innovations/${id}`);
      setInnovation(response);
    };
    setTitle('Mise en œuvre');
    await fetch();
  }, []);
  const innovationData = innovation.usage
    ? innovation.usage
    : {
        text: '',
        video: '',
        image: null,
      };

  return (
    <Container maxWidth="md">
      <Box className={classes.descRoot}>
        <div className={classes.secTitle}>
          <span>{innovation.title} </span>
        </div>
        <div className={classes.description}>{innovationData.text}</div>
        {innovationData.image && (
          <Card className={classes.imgCard}>
            <CardMedia
              className={classes.imgItem}
              component="img"
              image={`${Constants.BASE_URL}/${innovationData.image.path} `}
            />
          </Card>
        )}
        {innovationData?.video && (
          <Box justifyContent={'center'} className={classes.videoBox}>
            <ReactPlayer url={innovationData?.video} width="100%" height="100%" controls />
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default InnovationImplementation;

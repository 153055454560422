import React, { useEffect, useState } from 'react';
import { Container, Box, Typography, Button, makeStyles } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import usePage from '@/hooks/usePage.js';
import mainStyles from '@/assets/js/mainStyles.js';
import Request from '@/helpers/request.js';

const useStyles = makeStyles(mainStyles);

const Implementation = () => {
  const classes = useStyles();
  const history = useHistory();
  const { boxId, measureId } = useParams();
  const [box, setBox] = useState({});
  const [measure, setMeasure] = useState({});
  const { setTitle } = usePage();

  const { touchedUsage = [] } = measure;

  useEffect(async () => {
    const fetchBoxes = async () => {
      const [responseBox, responseMeasure] = await Promise.all([
        Request.get(`/boxes/${boxId}`),
        Request.get(`/measures/${measureId}`),
      ]);
      setBox(responseBox);
      setMeasure(responseMeasure);
    };
    setTitle('Mise en œuvre');
    await fetchBoxes();
  }, []);

  return (
    <Container maxWidth="md">
      <Box className={classes.containerInner}>
        <Box className={classes.containerTitle}>
          <Typography className={classes.name}>
            <span>{box.name}</span>
          </Typography>
          <Typography className={classes.subTitle}>{measure.caption}</Typography>
        </Box>
        <Typography className={classes.textBox} component="div" align="center">
          Sélectionnez une rubrique :
        </Typography>
        <Box className={classes.flexBoxColumn}>
          <Button
            className={`${classes.outlinedBtn} ${!touchedUsage.includes('logistic') ? 'disabled' : ''}`}
            onClick={() => history.push(`/formwork/implementation/${boxId}/measures/${measureId}/logistic`)}
            variant="outlined"
            disabled={!touchedUsage.includes('logistic')}
          >
            Logistique
          </Button>
          <Button
            className={`${classes.outlinedBtn} ${!touchedUsage.includes('unfold') ? 'disabled' : ''}`}
            onClick={() => history.push(`/formwork/implementation/${boxId}/measures/${measureId}/unfold`)}
            variant="outlined"
            disabled={!touchedUsage.includes('unfold')}
          >
            Dépliage et relevage
          </Button>
          <Button
            className={`${classes.outlinedBtn} ${!touchedUsage.includes('folding') ? 'disabled' : ''}`}
            onClick={() => history.push(`/formwork/implementation/${boxId}/measures/${measureId}/folding`)}
            variant="outlined"
            disabled={!touchedUsage.includes('folding')}
          >
            Repliage
          </Button>
          {(box.name === 'BOX R' || box.name === 'BOX') && (
            <Button
              className={`${classes.outlinedBtn} ${!touchedUsage.includes('relevageGrandeHauteur') ? 'disabled' : ''}`}
              onClick={() =>
                history.push(`/formwork/implementation/${boxId}/measures/${measureId}/relevageGrandeHauteur`)
              }
              variant="outlined"
              disabled={!touchedUsage.includes('relevageGrandeHauteur')}
            >
              Relevage grande hauteur
            </Button>
          )}
          <Button
            className={`${classes.outlinedBtn} ${!touchedUsage.includes('juxtaposition') ? 'disabled' : ''}`}
            onClick={() => history.push(`/formwork/implementation/${boxId}/measures/${measureId}/juxtaposition`)}
            variant="outlined"
            disabled={!touchedUsage.includes('juxtaposition')}
          >
            Juxtaposition
          </Button>
          <Button
            className={`${classes.outlinedBtn} ${!touchedUsage.includes('superposition') ? 'disabled' : ''}`}
            onClick={() => history.push(`/formwork/implementation/${boxId}/measures/${measureId}/superposition`)}
            variant="outlined"
            disabled={!touchedUsage.includes('superposition')}
          >
            Superposition
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Implementation;

import React from 'react';
import { makeStyles, MenuItem, Select } from '@material-ui/core';
import customConfiguratorStyle from '../../styles/CustomConfiguratorStyle.js';
import '../../pages/formwork/boxes/styles/optionStyle.module.css';
import S from '@/pages/formwork/boxes/styles/ConfiguratorSumming.module.css';
// eslint-disable-next-line import/order

//cannot select the selected values
function recursiveFilter(filterConstructor, arraySelectParameters, indexSection) {
  if (indexSection === 0) {
    return filterConstructor;
  }
  filterConstructor = filterConstructor.filter((el) => {
    return arraySelectParameters[indexSection - 1][0] !== el;
  });
  return recursiveFilter(filterConstructor, arraySelectParameters, indexSection - 1);
}

const MySelector = ({
  constructor,
  selected,
  setSelected,
  arraySelectParameters,
  indexSection,
  flagBoxRS = [0, 0, 0],
  flagBoxH4,
  flagBoxW4,
}) => {
  const styleCustom = makeStyles(customConfiguratorStyle)();
  const optionRS1 = [
    "PX de 1,20 + rallonge jusqu'à 2,40",
    "PX de 2,40 + rallonge jusqu'à 4,20",
    "Angle + rallonge jusqu'à 2 X 0,60",
  ];
  const optionRS2 = [
    "PX de 1,20 + rallonge jusqu'à 1,80",
    "PX de 2,40 + rallonge jusqu'à 3,30",
    'Angle + rallonge 0,30',
  ];
  const optionRS3 = ['L = 1,2 m max', 'L = 2,40 m max', 'Angle'];
  let showMeters = constructor.includes('T1');

  function transform(constructor) {
    if (flagBoxRS[0] > 0) {
      return optionRS1;
    } else if (flagBoxRS[1] > 0) {
      return optionRS2;
    } else if (flagBoxRS[2] > 0) {
      return optionRS3;
    } else if (flagBoxRS[3]) {
      return optionRS1;
    }
    function mySort(constructor) {
      let filterConstructor = constructor;

      if (filterConstructor.includes(500)) {
        filterConstructor = [500, ...filterConstructor.filter((el) => el !== 500)];
      }
      if (flagBoxH4) {
        return constructor;
      }
      return recursiveFilter(filterConstructor, arraySelectParameters, indexSection);
    }

    return mySort(constructor).map((parameter) => {
      if (parameter === 3000) {
        return '3.00';
      } else if (parameter === 2800) {
        return '2.80';
      } else if (parameter === 1500) {
        return '1.50';
      } else if (parameter === 1000) {
        return '1.00';
      } else if (parameter === 500) {
        return '0.50';
      } else if (parameter === 2400) {
        return '2.40';
      } else if (parameter === 1200) {
        return '1.20';
      } else if (parameter === 900) {
        return '0.90';
      } else if (parameter === 600) {
        return '0.60';
      } else if (parameter === 300) {
        return '0.30';
      } else if (parameter === 1140) {
        return 'Inter 1.14';
      } else if (parameter === 90) {
        return 'Exter 0.09';
      } else if (parameter === 100) {
        return 'Exter 0.1';
      } else if (parameter === 120) {
        return 'Exter 0.12';
      } else if (parameter === 140) {
        return 'Exter 0.14';
      } else if (parameter === 777) {
        return 'Angle';
      } else if (parameter === 30) {
        return '30';
      } else if (parameter === 60) {
        return '60';
      } else return parameter;
    });
  }

  let auto30 = constructor[0] === 30 && 30;
  let autoValue = constructor[0] === 60 ? 60 : auto30;

  if (flagBoxW4 && (constructor[0] === 30 || constructor[0] === 60)) {
    setSelected(autoValue);
  }

  return (
    <Select
      className={`${styleCustom.selectBody} ${S.mySelector} ${S.ffSerif}`}
      classes={{ outlined: styleCustom.selectOutlined }}
      onChange={(e) => {
        setSelected(e.target.value);
      }}
      value={flagBoxW4 && (constructor[0] === 30 || constructor[0] === 60) ? autoValue : selected}
      // disable={flagBoxRS[3] && (flagBoxRS[0] || flagBoxRS[1] || flagBoxRS[2])}
    >
      {flagBoxW4 && constructor[0] === 30 ? (
        <MenuItem className={`${styleCustom.selectList} ${S.ffSerif}`} value={30}>
          30
        </MenuItem>
      ) : flagBoxW4 && constructor[0] === 60 ? (
        <MenuItem className={`${styleCustom.selectList} ${S.ffSerif}`} value={60}>
          60
        </MenuItem>
      ) : (
        transform(constructor).map((param, index) => (
          <MenuItem className={`${styleCustom.selectList} ${S.ffSerif}`} value={param} key={index}>
            {param}{' '}
            {showMeters || param === 'Angle' || param === 'L = 1,2 m max' || param === 'L = 2,40 m max' ? (
              <></>
            ) : (
              <>m</>
            )}
          </MenuItem>
        ))
      )}
    </Select>
  );
};

export default MySelector;

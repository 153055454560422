import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, Container, makeStyles, Typography } from '@material-ui/core';
import mainStyles from '@/assets/js/mainStyles.js';
import usePage from '@/hooks/usePage.js';
import Request from '@/helpers/request.js';
import { BoxVideo_FAQ } from '@/pages/security/pce-2/modules/Description.jsx';

const useStyles = makeStyles(mainStyles);

const Implementation = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [module, setModule] = useState({});
  const { setTitle } = usePage();

  useEffect(async () => {
    const fetchModule = async () => {
      const response = await Request.get(`/pca2-modules`);
      setModule(response.items[0]);
    };
    setTitle('Mise en œuvre');
    await fetchModule();
  }, []);
  return (
    <Container maxWidth="md">
      <Box className={classes.contentMW}>
        <Typography className={classes.textBox}>Sélectionnez une rubrique :</Typography>
        <Box align="center" className={classes.flexBoxColumn}>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push(`implementation/logistics`)}
            variant="outlined"
            size="large"
          >
            Logistique
          </Button>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push(`implementation/unfolding`)}
            variant="outlined"
            size="large"
          >
            Dépliage et réglage
          </Button>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push(`implementation/folding`)}
            variant="outlined"
            size="large"
          >
            Repliage
          </Button>
          <BoxVideo_FAQ pathUrl={module?.usage?.assembly?.faq} style={classes} />
        </Box>
      </Box>
    </Container>
  );
};

export default Implementation;

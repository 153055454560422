import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import Request from '@/helpers/request.js';
import usePage from '@/hooks/usePage.js';
import Constants from '@/constants.js';
import mainStyles from '@/assets/js/mainStyles.js';

const useStyles = makeStyles(mainStyles);

const ConfiguratorDescription = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [configurator, setConfigurator] = useState({});
  const { setTitle } = usePage();

  useEffect(async () => {
    const fetchData = async () => {
      const response = await Request.get(`/pce2-configurators/${id}`);
      setConfigurator(response);
      setTitle(response.title);
    };
    await fetchData();
  }, []);

  const description = configurator.description !== undefined ? configurator.description : {};
  const image = description.image !== undefined ? description.image : null;
  const images = description.images !== undefined ? description.images : [];
  return (
    <Container maxWidth="md">
      <Box>
        <Box className={classes.descRoot}>
          <Typography className={classes.description}>{description?.text}</Typography>
          {configurator?.usage?.video && (
            <Box justifyContent={'center'} className={classes.videoBox}>
              <ReactPlayer url={configurator?.usage?.video} width="100%" height="100%" controls />
            </Box>
          )}
          <Card className={classes.imgCard}>
            {image && (
              <CardMedia className={classes.imgItem} component="img" image={`${Constants.BASE_URL}/${image.path} `} />
            )}
          </Card>
          {images.map((item, index) => (
            <Card key={index} className={classes.imgCard}>
              <CardActionArea>
                {item.image && (
                  <CardMedia
                    className={classes.imgItem}
                    component="img"
                    alt={item.image.name}
                    image={`${Constants.BASE_URL}/${item.image.path}`}
                  />
                )}
                <CardContent>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {item.title}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default ConfiguratorDescription;

import React, { useEffect, useState } from 'react';
import { Container, Box, makeStyles, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import usePage from '@/hooks/usePage.js';
import mainStyles from '@/assets/js/mainStyles.js';
import Request from '@/helpers/request.js';
import { dragSort } from '@/components/SubSectionComponents/CleanFunctions.jsx';

const useStyles = makeStyles(mainStyles);

const RenderList = (props) => {
  const { configurator, classes, history } = props;
  return (
    <Button
      key={configurator.id}
      className={classes.outlinedBtn}
      button
      onClick={() => history.push(`configurators/${configurator.id}`)}
    >
      {configurator.title}
    </Button>
  );
};

const Configurators = () => {
  const classes = useStyles();
  const history = useHistory();
  const [configurators, setConfigurators] = useState([]);
  const { setTitle } = usePage();

  useEffect(async () => {
    const fetchData = async () => {
      const response = await Request.get('/pca2-configurators');
      setConfigurators(response.items);
    };
    setTitle('Configurateurs');
    await fetchData();
  }, []);

  return (
    <Container maxWidth="md">
      <Box className={classes.content} style={{ marginTop: '15%', minHeight: '0px' }}>
        <Button className={classes.outlinedBtn} button onClick={() => history.push('/security/pca-2/adjustment')}>
          Réglage & Découpe CP platelage
        </Button>
        {configurators.sort(dragSort).map((configurator) => (
          <RenderList classes={classes} key={configurator.id} configurator={configurator} history={history} />
        ))}
      </Box>
    </Container>
  );
};

export default Configurators;

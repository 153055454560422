import React from 'react';
import icon from '../assets/img/header/homeSearch.svg';

const HeaderIcon = ({ top, left, ...props }) => {
  //-52 -31 -earth
  //-34 -31 -home
  //-34 -57 -search
  //-52 -57 -mail
  return (
    <div style={{ position: 'relative', width: '34px', height: '34px', overflow: 'hidden' }} {...props}>
      <img
        src={icon}
        alt="Header SVG"
        style={{ position: 'absolute', zoom: '4', top: top, left: left, width: '100px' }}
      />
    </div>
  );
};
export default HeaderIcon;

import React, { useEffect, useState } from 'react';
import { checkElement } from '@/components/SubSectionComponents/CleanFunctions.jsx';
import img from '@/assets/img/AncrageImgs/firstMeterAndHalf/SH+PX+PX_Maxi.svg';
import S from '@/styles/reactImgsBallast.module.css';
import InfoT from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/InfoT.jsx';
import InfoD from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/InfoD.jsx';
import ConfigStabilizeInfoButton from '@/pages/formwork/boxes/ConfiguratorStabilizier/ConfigStabilizeInfoButton.jsx';
import InfoB from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/InfoB.jsx';

function SHPxPxMax({ allHeightsPanel, T, D, B, fixTop }) {
  const [find, setFind] = useState(false);

  useEffect(() => {
    if (
      allHeightsPanel.length === 3 &&
      checkElement.firstMeterAndHalf([allHeightsPanel[0]]) &&
      checkElement.allPanels([allHeightsPanel[1]]) &&
      checkElement.allPanels([allHeightsPanel[2]]) &&
      fixTop
    ) {
      setFind(true);
    } else setFind(false);
  }, [allHeightsPanel, fixTop]);

  if (!find) {
    return null;
  }

  return (
    <div className={S.container}>
      <img className={S.containerImg} src={img} alt={'3m'} style={{ top: -61, left: -20 }} />
      <InfoT top={203} left={176} T={T} />
      <InfoD top={462} left={125} D={D} />
      <InfoB top={400} left={157} B={B} />
      <ConfigStabilizeInfoButton top={-55} />
    </div>
  );
}

export default SHPxPxMax;

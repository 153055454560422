import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, Container, makeStyles, Typography } from '@material-ui/core';
import mainStyles from '@/assets/js/mainStyles.js';
import usePage from '@/hooks/usePage.js';
import Request from '@/helpers/request.js';
import { BoxVideo_FAQ } from '@/pages/security/pce-2/modules/Description.jsx';

const useStyles = makeStyles(mainStyles);

const Module = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { setTitle } = usePage();
  const [module, setModule] = useState({});

  useEffect(async () => {
    const fetchModule = async () => {
      const response = await Request.get(`/pce2-modules/${id}`);
      setModule(response);
      setTitle(`PCE 2: ${response.title}`);
    };
    await fetchModule();
  }, []);
  return (
    <Container maxWidth="md">
      <Box className={classes.contentMW}>
        <div className={classes.secTitle}>
          <Typography className={classes.textBox}>Sélectionnez une rubrique :</Typography>
        </div>
        <Box align="center" className={classes.flexBoxColumn}>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push(`${id}/description`)}
            variant="outlined"
            size="large"
          >
            Descriptif
          </Button>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push(`${id}/implementation`)}
            variant="outlined"
            size="large"
          >
            Mise en œuvre
          </Button>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push('/security/pce-2/configurators')}
            variant="outlined"
            size="large"
          >
            Configurateurs
          </Button>
          <BoxVideo_FAQ pathUrl={module?.usage?.assembly?.faq} style={classes} />
        </Box>
      </Box>
    </Container>
  );
};

export default Module;

import React, { useEffect, useState } from 'react';
import { checkElement } from '@/components/SubSectionComponents/CleanFunctions.jsx';
import img from '@/assets/img/BallastImgs/firstPx/LEST_PX+PX+RH+RH_Maxi.svg';
import S from '@/styles/reactImgsBallast.module.css';
import InfoT from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/InfoT.jsx';
import InfoD from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/InfoD.jsx';
import InfoL from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/InfoL.jsx';
import ConfigStabilizeInfoButton from '@/pages/formwork/boxes/ConfiguratorStabilizier/ConfigStabilizeInfoButton.jsx';
import InfoB from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/InfoB.jsx';

function PxPxRhRhMax({ allHeightsPanel, T, D, L, B, fixTop }) {
  const [find, setFind] = useState(false);

  useEffect(() => {
    if (
      allHeightsPanel.length === 4 &&
      checkElement.allPanels([allHeightsPanel[0], allHeightsPanel[1]]) &&
      checkElement.lastHalf([allHeightsPanel[2]]) &&
      checkElement.lastHalf([allHeightsPanel[3]]) &&
      fixTop
    ) {
      setFind(true);
    } else setFind(false);
  }, [allHeightsPanel, fixTop]);

  if (!find) {
    return null;
  }

  return (
    <div className={S.container}>
      <img className={S.containerImg} src={img} alt={'3m'} style={{ top: -140 }} />
      <InfoT top={170} left={162} T={T} />
      <InfoD top={325} left={98} D={D} />
      <InfoL top={272} left={231} L={L} />
      <InfoB top={235} left={120} B={B} />
      <ConfigStabilizeInfoButton top={-180} />
    </div>
  );
}

export default PxPxRhRhMax;

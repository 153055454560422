import React, { useEffect, useRef, useState } from 'react';
import S from '@/styles/inputBox.module.css';
import style from '../../../../../styles/adjustment.module.css';
import '../../../../../styles/removeArrows.css';

const logicUpdateCurrInput = (currInput) => {
  if (currInput < 4) {
    return ++currInput;
  } else return 0;
};

// eslint-disable-next-line react/prop-types
const InputLine = ({ blockText, value, onChange, readOnly, localRef }) => {
  return (
    <div className={S.inputLine} style={{ backgroundColor: readOnly ? 'lightgrey' : '' }}>
      {blockText}
      <div>
        <input
          ref={localRef}
          className={S.input}
          type="number"
          value={value > 0 && value}
          onClick={(event) => {
            if (!readOnly) {
              event.preventDefault();
              event.target.value = '';
              onChange(0);
            }
          }}
          onChange={(e) => onChange(+e.target.value)}
          readOnly={readOnly}
          style={{ outline: readOnly ? 'none' : '' }}
        />{' '}
        cm
      </div>
    </div>
  );
};
InputLine.defaultProps = {
  onChange: () => {},
  readOnly: false,
};

const modText = (text) => {
  return <b style={{ color: '#8058AB' }}>{text}</b>;
};

// eslint-disable-next-line react/prop-types
function InputBox({ updateParams }) {
  const [doG, setDoG] = useState('');
  const [lO, setLO] = useState('');
  const [doD, setDoD] = useState('');
  const [totalWidth, setWidth] = useState('');
  const [totalDepth, setDepth] = useState('');
  const [totalHeight, setHeight] = useState('');
  let currentInput = 0;
  const input0 = useRef();
  const input1 = useRef();
  const input2 = useRef();
  const refTotalWidth = useRef();
  const input3 = useRef();
  const input4 = useRef();

  useEffect(() => {
    if ((totalWidth > 260 || totalWidth < 160) && doG > 0 && lO > 0 && doD > 0) {
      refTotalWidth.current.style.background = '#D93829';
      refTotalWidth.current.style.color = 'white';
    } else {
      refTotalWidth.current.style.background = '';
      refTotalWidth.current.style.color = 'black';
    }
  }, [totalWidth]);

  useEffect(() => {
    if ((totalDepth > 324 || totalDepth < 160) && totalDepth > 0) {
      input3.current.style.background = '#D93829';
      input3.current.style.color = 'white';
    } else {
      input3.current.style.background = '';
      input3.current.style.color = 'black';
    }
  }, [totalDepth]);

  useEffect(() => {
    if ((totalHeight > 480 || totalHeight < 250) && totalHeight > 0) {
      input4.current.style.background = '#D93829';
      input4.current.style.color = 'white';
    } else {
      input4.current.style.background = '';
      input4.current.style.color = 'black';
    }
  }, [totalHeight]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        currentInput = logicUpdateCurrInput(currentInput);
        const arrRefs = [input0, input1, input2, input3, input4];
        arrRefs[currentInput].current.focus();
      }
    };
    input0.current.focus();
    document.addEventListener('keypress', handleKeyPress);

    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  useEffect(() => {
    setWidth(doG + lO + doD);
  }, [doG, lO, doD]);

  useEffect(() => {
    let params = {
      doG: doG,
      lO: lO,
      doD: doD,
      totalWidth: totalWidth,
      totalDepth: totalDepth,
      totalHeight: totalHeight,
    };
    updateParams(params);
  }, [doG, lO, doD, totalWidth, totalDepth, totalHeight]);

  return (
    <div>
      <div className={style.boxContainer}>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <div className={style.boxHeader}>Données d'entrée</div>
        <InputLine
          blockText={<div>Décalage ouverture à Gauche ({modText('DoG')})</div>}
          value={doG}
          onChange={setDoG}
          localRef={input0}
        />
        <InputLine
          blockText={<div>Largeur Ouverture ({modText('Lo')})</div>}
          value={lO}
          onChange={setLO}
          localRef={input1}
        />
        <InputLine
          blockText={<div>Décalage ouverture à Droite ({modText('DoD')})</div>}
          value={doD}
          onChange={setDoD}
          localRef={input2}
        />
        <InputLine
          blockText={
            <div className={S.inputTotal}>
              <div>LARGEUR CAGE BETON</div>
              <div>
                <>(160-260) :</>
              </div>{' '}
            </div>
          }
          value={totalWidth}
          readOnly={true}
          localRef={refTotalWidth}
        />
        <InputLine
          blockText={
            <div className={S.inputTotal}>
              <div>PROFONDEUR CAGE BETON</div>
              <div>
                <>(160-324) :</>
              </div>{' '}
            </div>
          }
          value={totalDepth}
          onChange={setDepth}
          localRef={input3}
        />
        <InputLine
          blockText={
            <div className={S.inputTotal}>
              <div>HAUTEUR ETAGE</div>
              <div>
                <>(250-480) :</>
              </div>{' '}
            </div>
          }
          value={totalHeight}
          onChange={setHeight}
          localRef={input4}
        />
      </div>
    </div>
  );
}

export default InputBox;

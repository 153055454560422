import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Container, Button, makeStyles } from '@material-ui/core';
import mainStyles from '@/assets/js/mainStyles.js';
import usePage from '@/hooks/usePage.js';
import Request from '@/helpers/request.js';
import { BoxVideo_FAQ } from '@/pages/security/pce-2/modules/Description.jsx';

const useStyles = makeStyles(mainStyles);

const Component = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [component, setComponent] = useState({});
  const { setTitle } = usePage();

  useEffect(async () => {
    const fetchComponent = async () => {
      const response = await Request.get(`/pca2-components/${id}`);
      setComponent(response);
    };
    setTitle('COMPOSANTS');
    await fetchComponent();
  }, []);

  return (
    <Container maxWidth="md">
      <Box className={classes.content}>
        <div className={classes.secTitle}>
          <span>{component.title} </span>
        </div>
        <Button className={classes.outlinedBtn} onClick={() => history.push(`${id}/description`)}>
          Descriptif
        </Button>
        <Button className={classes.outlinedBtn} onClick={() => history.push(`${id}/implementation`)}>
          Mise en œuvre
        </Button>
        <BoxVideo_FAQ pathUrl={component?.usage?.faq} style={classes} />
      </Box>
    </Container>
  );
};

export default Component;

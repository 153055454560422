import React from 'react';
import S from '@/styles/reactImgsBallast.module.css';

const InfoD = ({ top, left, D }) => {
  return (
    <>
      {D > 0 ? (
        <div className={S.value} style={{ top: top, left: left, width: 'auto' }}>
          D = {D}m
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default InfoD;

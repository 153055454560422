import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Container, Button, makeStyles, Typography } from '@material-ui/core';
import mainStyles from '@/assets/js/mainStyles.js';
import usePage from '@/hooks/usePage.js';
import Request from '@/helpers/request.js';

const useStyles = makeStyles(mainStyles);

const AccessoriesStability = () => {
  const classes = useStyles();
  const history = useHistory();
  const { accessoryId, category } = useParams();
  const [accessory, setAccessory] = useState({});
  const { setTitle } = usePage();

  useEffect(async () => {
    const fetchAccessories = async () => {
      const response = await Request.get(`/accessories/${accessoryId}`);
      setAccessory(response);
    };
    setTitle('Accessoires');
    await fetchAccessories();
  }, []);
  return (
    <Container maxWidth="md">
      <Box className={classes.content}>
        <Typography className={classes.secTitle} style={{ marginBottom: '10px' }} align="center">
          {accessory?.title}
        </Typography>
        <Button
          className={classes.outlinedBtn}
          onClick={() =>
            history.push(`/formwork/accessories/${category}/choice-${category}/${accessory.id}/description`)
          }
        >
          Descriptif
        </Button>
        <Button
          className={classes.outlinedBtn}
          onClick={() =>
            history.push(`/formwork/accessories/${category}/choice-${category}/${accessory.id}/implementation`)
          }
        >
          Mise en œuvre
        </Button>
      </Box>
    </Container>
  );
};

export default AccessoriesStability;

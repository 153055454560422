import React, { useEffect, useState } from 'react';
import S from '@/styles/adjustment.module.css';
import { roundTop } from '@/components/SubSectionComponents/CleanFunctions.jsx';

function LowerIndent({ inputParams, width, updateCG_CD, updateExtG_extD, visible }) {
  const [CG, setCG] = useState(0);
  const [CD, setCD] = useState(0);

  useEffect(() => {
    const R36 = inputParams?.totalWidth / 2 - inputParams?.doG;
    const S36 = inputParams?.totalWidth / 2 - inputParams?.doD;

    const R37 = R36 - 12;
    const S37 = S36 - 12;

    const R38 = R37 > 50 && R37 < 65;
    const S38 = S37 > 50 && S37 < 65;

    const I36 = (width - 130) / 2 > 60 ? 'A vérifier !' : roundTop((width - 130) / 2, 5);
    const K36 = width - I36 - 130 > 60 ? 'A vérifier !' : roundTop(width - I36 - 130, 5);

    const R39 = (() => {
      if (R37 <= 50) {
        return R37 + (I36 - K36) / 2;
      } else if (R38) {
        return 50;
      } else if (R37 >= 65) {
        return 65;
      } else return 0;
    })();
    const S39 = (() => {
      if (S37 <= 50) {
        return S37 - (I36 - K36) / 2;
      } else if (S38) {
        return 50;
      } else if (S37 >= 65) {
        return 65;
      } else return 0;
    })();

    const R40 = (() => {
      if (R39 < 0) {
        return 0;
      } else if (R39 < 30) {
        return roundTop(65 - R39 - 35, 5);
      } else return 0;
    })();
    const S40 = (() => {
      if (S39 < 0) {
        return 0;
      } else if (S39 < 30) {
        return roundTop(65 - S39 - 35, 5);
      } else return 0;
    })();

    const R41 = R37 - S40 + (I36 - K36) / 2 > 50 && R37 - S40 + (I36 - K36) / 2 < 65;
    const S41 = S37 - R40 + (I36 - K36) / 2 > 50 && S37 - R40 - (I36 - K36) / 2 < 65;

    const R42 = (() => {
      if (R37 - S40 <= 50) {
        return R37 - S40 + (I36 - K36) / 2;
      } else if (R41) {
        return 50;
      } else if (R37 - S40 >= 65) {
        return 65;
      } else return 0;
    })();
    const S42 = (() => {
      if (S37 - R40 <= 50) {
        return S37 - R40 - (I36 - K36) / 2;
      } else if (S41) {
        return 50;
      } else if (S37 - R40 >= 65) {
        return 65;
      } else return 0;
    })();

    const extG = R40 <= 0 ? I36 - S40 : I36 + R40;
    const extD = S40 <= 0 ? K36 - R40 : K36 + S40;
    updateExtG_extD([extG, extD]);

    const getCG = 65 - (I36 - K36) / 2 - R42 - R40 < 0 ? 0 : roundTop(65 - R42 - R40, 5);
    const getCD = 65 + (I36 - K36) / 2 - S42 - S40 < 0 ? 0 : roundTop(65 - S42 - S40, 5);
    setCG(getCG);
    setCD(getCD);
  }, [inputParams?.doG, inputParams?.doD, width]);

  useEffect(() => {
    updateCG_CD([CG, CD]);
  }, [CG, CD]);

  if (!visible) return <></>;

  return (
    <div className={S.boxContainerMini}>
      <div className={S.boxHeader}>Appui bas réglable</div>
      <div className={S.lowerIntendContainer}>
        <div>
          <div style={{ color: 'rgb(255 6 30 / 87%)' }}>CG</div>
          {CG <= 35 ? <div>{CG} cm</div> : <div style={{ color: 'rgb(255 6 30 / 87%)' }}>X</div>}
        </div>
        <div>
          <div style={{ color: '#2591FF' }}>CD</div>
          {CD <= 35 ? <div>{CD} cm</div> : <div style={{ color: '#2591FF' }}>X</div>}
        </div>
      </div>
    </div>
  );
}

export default LowerIndent;

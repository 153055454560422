import React, { useEffect, useState } from 'react';
import S from '../styles/ConfiguratorSumming.module.css';
import SelectionBlockDH from '@/components/SubSectionComponents/SelectionBlockDH.jsx';
import {
  selectRecommendStab,
  updateRecommendDiagonalBrace,
  updateWeightCategory,
  updateThereforeFStage,
  updateRv,
  updateStabLength,
} from '@/components/SubSectionComponents/CleanFunctions.jsx';
import ReactImagesBallast from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/ReactImagesBallast.jsx';
import { completedFields } from '@/pages/formwork/boxes/ConfiguratorSumming.jsx';
import ReactImg2 from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/ReactImgRS.jsx';

const StabilisationByBallast = (props) => {
  const {
    totalHeight,
    fV,
    positionTheHolder,
    flag_3m_2_8m_FirstH_DoubleTop,
    speedWind,
    numberBallastRequired,
    allHeightsPanel,
    typeBox,
    massLest = 0,
    widthFormPanels,
  } = props;

  const [rV, setRV] = useState(0);
  const [propLength, setPropLength] = useState(0);
  const [thereforeFStage, setThereforeFStage] = useState(0);
  const [lengthTheLowerDiagonalBrace, setLengthTheLowerDiagonalBrace] = useState(0);
  const [stabilizer, setStabilizer] = useState('');
  const [diagonalBrace, setDiagonalBrace] = useState('');
  const [ballastWeight, setBallastWeight] = useState(0);
  const [weightCategory, setWeightCategory] = useState(0);
  const [ballastHeight, setBallastHeight] = useState(0);
  const [stabilizerLength, setStabilizerLength] = useState(0);
  const [selectedH, setSelectedH] = useState('');
  const [selectedSecondH, setSelectedSecondH] = useState('');
  const [selectedD, setSelectedD] = useState('');

  const updateSelectedSecondH = (boolean) => {
    setSelectedSecondH(boolean);
  };

  function updateH(data) {
    setSelectedH(data);
  }

  function updateD(data) {
    setSelectedD(data);
  }

  useEffect(() => {
    if (totalHeight === 0) {
      setRV(0);
      setPropLength(0);
      setThereforeFStage(0);
      setLengthTheLowerDiagonalBrace(0);
      setBallastWeight(0);
      setWeightCategory(0);
      setBallastHeight(0);
      setStabilizerLength(0);
      setStabilizer('');
      setDiagonalBrace('');
    }
  }, [totalHeight]);

  function updateLengthDS(data) {
    setStabilizerLength(data);
  }

  function updateLengthTheLowerDiagonalBrace(data) {
    setLengthTheLowerDiagonalBrace(data);
  }

  useEffect(() => {
    if (weightCategory === 2 || weightCategory === 3) {
      setBallastHeight(0.97);
    } else {
      setBallastHeight(0.77);
    }
  }, [weightCategory]);

  //update weight de lest
  useEffect(() => {
    let weight = (fV * (totalHeight / 2)) / selectedD / 1000;
    setBallastWeight(weight);
  }, [fV, totalHeight, selectedD]);

  //category weight du lest
  useEffect(() => {
    setWeightCategory(updateWeightCategory(ballastWeight));
  }, [ballastWeight]);

  useEffect(() => {
    //update recommend stab
    setStabilizer(selectRecommendStab(stabilizerLength, totalHeight, speedWind, ballastHeight));

    //update recommend diagonal Brace
    setDiagonalBrace(updateRecommendDiagonalBrace(lengthTheLowerDiagonalBrace, totalHeight));
  }, [ballastHeight, lengthTheLowerDiagonalBrace, speedWind, stabilizerLength, totalHeight]);

  //update thereforeFStage
  useEffect(() => {
    setThereforeFStage(updateThereforeFStage(rV, selectedD, positionTheHolder, propLength));
  }, [selectedD, positionTheHolder, propLength, rV]);

  //update rV
  useEffect(() => {
    setRV(updateRv(fV, totalHeight, selectedD));
  }, [totalHeight, fV, selectedD]);

  //update propLength
  useEffect(() => {
    setPropLength(updateStabLength(selectedD, positionTheHolder, selectedH, ballastHeight));
  }, [selectedD, positionTheHolder, selectedH, ballastHeight]);

  return (
    <>
      <div className={S.containerAnchor}>
        {typeBox !== 'BoxRS' && (
          <div>
            <SelectionBlockDH
              updateH={updateH}
              updateD={updateD}
              totalHeight={totalHeight}
              flag_3m_2_8m_FirstH_DoubleTop={flag_3m_2_8m_FirstH_DoubleTop}
              speedWind={speedWind}
              ballastHeight={ballastHeight}
              updateLengthDS={updateLengthDS}
              updateLengthTheLowerDiagonalBrace={updateLengthTheLowerDiagonalBrace}
              flag={'ballast'}
              fV={fV}
              widthFormPanels={widthFormPanels}
              updateSelectedSecondH={updateSelectedSecondH}
            />
          </div>
        )}
        {typeBox !== 'BoxRS' ? (
          <>
            {selectedH > 0 && selectedD > 0 ? (
              <>
                <div className={S.boxForm_Final}>
                  <div className={S.boxForm_Title}>
                    <div>Сalcul Final</div>
                  </div>

                  <div className={`${S.boxForm_Body} ${S.mt25}`}>
                    <div className={S.boxForm_Final_elem}>
                      <div>Type et quantité de stabilisateurs (T) :</div>
                      <div className={S.disFlex}>
                        {stabilizer !== 'Hors catalogue' &&
                        stabilizer !== 'La hauteur indiquée est trop petite' &&
                        stabilizer !== 'La hauteur indiquée est trop élevée' ? (
                          <div className={S.ml5}>
                            {stabilizer} x {numberBallastRequired}
                          </div>
                        ) : (
                          <div className={S.textR}>{stabilizer}</div>
                        )}
                      </div>
                    </div>
                    <div className={S.line} />
                    <div className={S.boxForm_Final_elem}>
                      <div>Type de Bracon bas (B) :</div>
                      {diagonalBrace !== 'Hors catalogue' && diagonalBrace !== 'Pas de bracon bas' ? (
                        <div className={S.ml5}>
                          {diagonalBrace} x {numberBallastRequired}
                        </div>
                      ) : (
                        <div className={S.textR}>{diagonalBrace}</div>
                      )}
                    </div>
                    <div className={S.line} />
                    {diagonalBrace !== 'Hors catalogue' &&
                      diagonalBrace !== 'Pas de bracon bas' &&
                      weightCategory !== 0 && (
                        <div className={S.boxForm_Final_elem}>
                          <div>Poids du Lest (L) : </div>
                          <div>{weightCategory} T</div>
                        </div>
                      )}
                    {(weightCategory === 2 || weightCategory === 3) && (
                      <div className={S.boxForm_Final_elem_alert}>
                        <div>Pour diminuer le poids du lest augmentez la position du lest</div>
                      </div>
                    )}
                  </div>
                </div>
                <ReactImagesBallast
                  allHeightsPanel={allHeightsPanel}
                  T={stabilizer}
                  D={selectedD}
                  L={weightCategory}
                  B={diagonalBrace}
                  typeBox={typeBox}
                  selectedSecondH={selectedSecondH}
                />
              </>
            ) : (
              completedFields(S)
            )}
          </>
        ) : (
          <>
            {/*For BOX RS*/}
            <div className={S.boxForm_Final}>
              <div className={S.boxForm_Title}>
                <div>Сalcul Final</div>
              </div>
              <div className={`${S.boxForm_Body} ${S.mt25}`}>
                <div className={S.boxForm_Final_elem}>
                  <div>Lest :</div>
                  <div className={S.textR}>{massLest} kg</div>
                </div>
                <div className={S.line} />
              </div>
            </div>
            <ReactImg2
              allHeightsPanel={allHeightsPanel}
              T={stabilizer}
              D={selectedD}
              L={weightCategory}
              typeBox={'BoxRS'}
            />
          </>
        )}
      </div>
    </>
  );
};

export default StabilisationByBallast;

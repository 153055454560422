import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, Container, makeStyles, Typography } from '@material-ui/core';
import mainStyles from '@/assets/js/mainStyles.js';
import usePage from '@/hooks/usePage.js';
import Request from '@/helpers/request.js';

const useStyles = makeStyles(mainStyles);

const Implementation = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { setTitle } = usePage();
  const [module, setModule] = useState({});

  useEffect(async () => {
    const fetchModule = async () => {
      const response = await Request.get(`/pce2-modules/${id}`);
      setModule(response);
    };
    setTitle('Mise en œuvre');

    await fetchModule();
  }, []);
  return (
    <Container maxWidth="md">
      <Box className={classes.contentMW}>
        <div className={classes.secTitle}>
          <span>{module && module.title} </span>
        </div>
        <div className={classes.secTitle}>
          <Typography className={classes.textBox}>Sélectionnez une rubrique :</Typography>
        </div>
        <Box align="center" className={classes.flexBoxColumn}>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push(`implementation/logistics`)}
            variant="outlined"
            size="large"
          >
            Logistique
          </Button>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push(`implementation/assembly`)}
            variant="outlined"
            size="large"
          >
            Assemblage
          </Button>
          <Button
            className={classes.mainBtn}
            onClick={() => window.open('https://www.sateco.fr/fr/faq.html')}
            variant="outlined"
            size="large"
          >
            FAQ
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Implementation;

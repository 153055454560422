import React, { useEffect, useState } from 'react';
import style from '../../pages/formwork/boxes/styles/optionStyle.module.css';

const TypeStab = ({ setTypeStab, totalH, prohibited, forUpdate }) => {
  const [firstButtActive, setFirstButtActive] = useState(false);
  const [limit, setLimit] = useState(4000);
  const [secButtActive, setSecButtActive] = useState(false);
  const [textButton, setTextButton] = useState(true);

  useEffect(() => {
    if (prohibited === 12) {
      setLimit(4000);
    }
    if (prohibited === 24) {
      setLimit(5000);
    }
  }, [prohibited, totalH, forUpdate]);

  useEffect(() => {
    setTextButton(totalH < limit ? 'Par portique' : 'Par portique interdit');
  }, [totalH, limit, forUpdate]);

  //when portico disabled, auto select stab
  useEffect(() => {
    if (totalH >= limit) {
      setFirstButtActive(true);
      setSecButtActive(false);
      setTypeStab('stabilizers');
    }
  }, [textButton]);

  return (
    <div className={style.typeContainer}>
      <div className={`${style.typeContainer__Title}`} style={{ minWidth: '320px' }}>
        TYPE DE STABILISATION
      </div>
      <div className={style.typeContainer__Body}>
        <div
          onClick={() => {
            setFirstButtActive(true);
            setSecButtActive(false);
            setTypeStab('stabilizers');
          }}
          className={`${style.optionStab} ${firstButtActive ? style.active : ''}`}
        >
          Par stabilisateur
        </div>
        <div
          onClick={() => {
            if (totalH < limit) {
              setFirstButtActive(false);
              setSecButtActive(true);
              setTypeStab('portico');
            }
          }}
          className={`${style.optionStab} ${secButtActive ? style.active : ''}`}
          style={{
            backgroundColor: textButton === 'Par portique interdit' ? '#D93829' : '',
            color: textButton === 'Par portique interdit' ? 'white' : '',
          }}
        >
          {textButton}
        </div>
      </div>
    </div>
  );
};

export default TypeStab;

import React from 'react';
import S from '@/styles/reactImgsBallast.module.css';

const InfoT = ({ top, left, T }) => {
  return (
    <div className={S.value} style={{ top: top, left: left }}>
      {Number(T?.toString()[1]) > 0 ? T : 'T'}
    </div>
  );
};

export default InfoT;

import React from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import useStyles from '@/hooks/useStyles.js';
import Header from '@/components/Header.jsx';
import Home from '@/pages/Home.jsx';
import FormworkSelectCategory from '@/pages/formwork/SelectCategory.jsx';
import SecuritySelectCategory from '@/pages/security/SelectCategory.jsx';
import ChoiceBox from '@/pages/formwork/boxes/ChoiceBox.jsx';
import Accessories from '@/pages/formwork/accessories/Accessories.jsx';
import Handling from '@/pages/formwork/boxes/Handling/Handling.jsx';
import ConfiguratorHandling from '@/pages/formwork/boxes/Handling/ConfiguratorHandling.jsx';
import ConfiguratorHandlingBox from '@/pages/formwork/boxes/Handling/ConfiguratorHandlingBox.jsx';
import ConfiguratorHandlingBoxR from '@/pages/formwork/boxes/Handling/ConfiguratorHandlingBoxR.jsx';
import ConfiguratorHandlingBoxRs from '@/pages/formwork/boxes/Handling/ConfiguratorHandlingBoxRs.jsx';
import SelectSubcategory from '@/pages/formwork/boxes/SelectSubcategory.jsx';
import BoxConfigurators from '@/pages/formwork/Configurators.jsx';

import Pce2SelectSubcategory from '@/pages/security/pce-2/SelectSubcategory.jsx';
import Pce2ModulesList from '@/pages/security/pce-2/modules/Modules.jsx';
import Pce2Module from '@/pages/security/pce-2/modules/Module.jsx';
import Pce2ModuleDescription from '@/pages/security/pce-2/modules/Description.jsx';
import Pce2ModuleImplementation from '@/pages/security/pce-2/modules/Implementation/Implementation.jsx';
import Pce2ModuleImplementationLogistics from '@/pages/security/pce-2/modules/Implementation/Logistics.jsx';
import Pce2ModuleImplementationAssembly from '@/pages/security/pce-2/modules/Implementation/Assembly.jsx';
import Pce2Configurators from '@/pages/security/pce-2/configurators/Configurators.jsx';
// import Pce2Configurator from '@/pages/security/pce-2/configurators/Configurator.jsx';
import Pce2ConfiguratorDescription from '@/pages/security/pce-2/configurators/ConfiguratorDescription.jsx';
import Pce2ConfiguratorImplementation from '@/pages/security/pce-2/configurators/ConfiguratorImplementation.jsx';
import Pce2Stairs from '@/pages/security/pce-2/configurators/Stairs.jsx';
import Pce2ComponentsList from '@/pages/security/pce-2/components/Components.jsx';
import Pce2Component from '@/pages/security/pce-2/components/Component.jsx';
import Pce2DocTechnical from '@/pages/security/pce-2/doc-technical/DocTechnical.jsx';
import Pce2ComponentDescription from '@/pages/security/pce-2/components/Description.jsx';
import Pce2ComponentImplementation from '@/pages/security/pce-2/components/Implementation.jsx';

import P3D2SelectSubcategory from '@/pages/security/p3d2/SelectSubcategory.jsx';
import P3D2ModulesList from '@/pages/security/p3d2/modules/Modules.jsx';
import P3D2Module from '@/pages/security/p3d2/modules/Module.jsx';
import P3D2ModuleDescription from '@/pages/security/p3d2/modules/Description.jsx';
import P3D2ModuleImplementation from '@/pages/security/p3d2/modules/Implementation/Implementation.jsx';
import P3D2ModuleImplementationLogistics from '@/pages/security/p3d2/modules/Implementation/Logistics.jsx';
import P3D2ModuleImplementationUnfolding from '@/pages/security/p3d2/modules/Implementation/Unfolding.jsx';
import P3D2ModuleImplementationFolding from '@/pages/security/p3d2/modules/Implementation/Folding.jsx';
import P3D2ModuleImplementationSetting from '@/pages/security/p3d2/modules/Implementation/Setting.jsx';
import P3D2ModuleImplementationMaintenance from '@/pages/security/p3d2/modules/Implementation/Maintenance.jsx';
import P3D2Components from '@/pages/security/p3d2/components/ComponentsList.jsx';
import P3D2Component from '@/pages/security/p3d2/components/Component.jsx';
import P3D2ComponentDescription from '@/pages/security/p3d2/components/Description.jsx';
import P3D2ComponentImplementation from '@/pages/security/p3d2/components/Implementation.jsx';
import P3D2Accessories from '@/pages/security/p3d2/accessories/AccessoriesList.jsx';
import P3D2Accessor from '@/pages/security/p3d2/accessories/Accessor.jsx';
import P3D2AccessorDescription from '@/pages/security/p3d2/accessories/Description.jsx';
import P3D2AccessorImplementation from '@/pages/security/p3d2/accessories/Implementation.jsx';
import P3D2DocTechnical from '@/pages/security/p3d2/doc-technical/DocTechnical.jsx';

import P3D2Configurators from '@/pages/security/p3d2/configurators/Configurators.jsx';
import P3D2Configurator from '@/pages/security/p3d2/configurators/Configurator.jsx';
import P3D2ConfiguratorDescription from '@/pages/security/p3d2/configurators/Description.jsx';
import P3D2ConfiguratorImplementation from '@/pages/security/p3d2/configurators/Implementation.jsx';

import Pca2SelectSubcategory from '@/pages/security/pca-2/SelectSubcategory.jsx';
import Pca2Module from '@/pages/security/pca-2/modules/Module.jsx';
import Pca2ModuleDescription from '@/pages/security/pca-2/modules/Description.jsx';
import Pca2ModuleImplementation from '@/pages/security/pca-2/modules/Implementation/Implementation.jsx';
import Pca2ModuleImplementationLogistics from '@/pages/security/pca-2/modules/Implementation/Logistics.jsx';
import Pca2ModuleImplementationFolding from '@/pages/security/pca-2/modules/Implementation/Folding.jsx';
import Pca2ModuleImplementationUnfolding from '@/pages/security/pca-2/modules/Implementation/Unfolding.jsx';
import Pca2Configurators from '@/pages/security/pca-2/configurators/Configurators.jsx';
// import Pca2Configurator from '@/pages/security/pca-2/configurators/Configurator.jsx';
import Pca2ConfiguratorDescription from '@/pages/security/pca-2/configurators/ConfiguratorDescription.jsx';
import Pca2ConfiguratorImplementation from '@/pages/security/pca-2/configurators/ConfiguratorImplementation.jsx';
import Pca2Stairs from '@/pages/security/pca-2/configurators/Stairs.jsx';
import Pca2ComponentsList from '@/pages/security/pca-2/components/Components.jsx';
import Pca2Component from '@/pages/security/pca-2/components/Component.jsx';
import Pca2DocTechnical from '@/pages/security/pca-2/doc-technical/DocTechnical.jsx';
import Pca2Adjustment from '@/pages/security/pca-2/adjustment/Adjustment.jsx';

import Pca2ComponentDescription from '@/pages/security/pca-2/components/Description.jsx';
import Pca2ComponentImplementation from '@/pages/security/pca-2/components/Implementation.jsx';

import Faq from '@/pages/Faq.jsx';
import BoxDescription from '@/pages/formwork/boxes/BoxDescription.jsx';
import Implementation from '@/pages/formwork/boxes/Implementation.jsx';
import ImplementationCategory from '@/pages/formwork/boxes/ImplementationCategory.jsx';
import Juxtaposition from '@/pages/formwork/boxes/Juxtaposition.jsx';
import Superposition from '@/pages/formwork/boxes/Superposition.jsx';
import AccessoriesStability from '@/pages/formwork/accessories/AccessoriesStability.jsx';
import StabilityDescription from '@/pages/formwork/accessories/StabilityDescription.jsx';
import StabilityImplementation from '@/pages/formwork/accessories/StabilityImplementation.jsx';
import AccessoriesAdditional from '@/pages/formwork/accessories/AccessoriesAdditional.jsx';
import AdditionalDescription from '@/pages/formwork/accessories/AdditionalDescription.jsx';
import AdditionalImplementation from '@/pages/formwork/accessories/AdditionalImplementation.jsx';
import Slinging from '@/pages/formwork/boxes/Slinging/Slinging.jsx';
import ConfiguratorSlinging from '@/pages/formwork/boxes/Slinging/ConfiguratorSlinging.jsx';
import ConfiguratorSlingingBox from '@/pages/formwork/boxes/Slinging/ConfiguratorSlingingBox.jsx';
import ConfiguratorSlingingBoxR from '@/pages/formwork/boxes/Slinging/ConfiguratorSlingingBoxR.jsx';
import ConfiguratorSlingingBoxRs from '@/pages/formwork/boxes/Slinging/ConfiguratorSlingingBoxRs.jsx';
import ConfiguratorSumming from '@/pages/formwork/boxes/ConfiguratorSumming.jsx';
import ConfiguratorFourth from '@/pages/formwork/boxes/ConfiguratorStabilizier/ConfiguratorFourth.jsx';

import InnovationsList from '@/pages/innovations/InnovationsList.jsx';
import Innovation from '@/pages/innovations/Innovation.jsx';
import InnovationDescription from '@/pages/innovations/InnovationDescription.jsx';
import InnovationImplementation from '@/pages/innovations/InnovationImplementation.jsx';
import InnovationDocuments from '@/pages/innovations/InnovationDocuments.jsx';

import CustomProductsList from '@/pages/customProducts/CustomProductsList.jsx';
import CustomProducts from '@/pages/customProducts/CustomProducts.jsx';
import CustomProductsDescription from '@/pages/customProducts/CustomProductsDescription.jsx';
import CustomProductsImplementation from '@/pages/customProducts/CustomProductsImplementation.jsx';
import CustomProductsDocuments from '@/pages/customProducts/CustomProductsDocuments.jsx';

import PageContextProvider from './context/PageContextProvider.jsx';

const App = () => {
  useStyles();

  return (
    <>
      <PageContextProvider>
        <Router>
          <Header />
          <main>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/faq">
                <Faq />
              </Route>
              {/* Formwork routes */}
              <Route path="/formwork/select-category">
                <FormworkSelectCategory />
              </Route>
              <Route path="/formwork/choice-box">
                <ChoiceBox />
              </Route>
              <Route exact path="/formwork/accessories/:category">
                <Accessories />
              </Route>
              <Route exact path="/formwork/accessories/:category/choice-stability/:accessoryId">
                <AccessoriesStability />
              </Route>
              <Route exact path="/formwork/accessories/:category/choice-additional/:accessoryId">
                <AccessoriesAdditional />
              </Route>
              <Route exact path="/formwork/accessories/:category/choice-stability/:accessoryId/description">
                <StabilityDescription />
              </Route>
              <Route exact path="/formwork/accessories/:category/choice-stability/:accessoryId/implementation">
                <StabilityImplementation />
              </Route>
              <Route exact path="/formwork/accessories/:category/choice-additional/:accessoryId/description">
                <AdditionalDescription />
              </Route>
              <Route exact path="/formwork/accessories/:category/choice-additional/:accessoryId/implementation">
                <AdditionalImplementation />
              </Route>
              <Route exact path="/formwork/configurators">
                <BoxConfigurators />
              </Route>
              <Route exact path="/formwork/configurators/slinging">
                <Slinging />
              </Route>
              <Route exact path="/formwork/configurators/slinging/box-box-r">
                <ConfiguratorSlinging />
              </Route>
              <Route exact path="/formwork/configurators/slinging/box">
                <ConfiguratorSlingingBox />
              </Route>
              <Route exact path="/formwork/configurators/slinging/box-r">
                <ConfiguratorSlingingBoxR />
              </Route>
              <Route exact path="/formwork/configurators/slinging/box-rs">
                <ConfiguratorSlingingBoxRs />
              </Route>
              <Route exact path="/formwork/configurators/handling-skills">
                <Handling />
              </Route>
              <Route exact path="/formwork/configurators/handling-skills/handling">
                <ConfiguratorHandling />
              </Route>
              <Route exact path="/formwork/configurators/handling-skills/handling-box">
                <ConfiguratorHandlingBox />
              </Route>
              <Route exact path="/formwork/configurators/handling-skills/handling-box-r">
                <ConfiguratorHandlingBoxR />
              </Route>
              <Route exact path="/formwork/configurators/handling-skills/handling-box-rs">
                <ConfiguratorHandlingBoxRs />
              </Route>
              <Route exact path="/formwork/configurators/summing">
                <ConfiguratorSumming />
              </Route>
              <Route exact path="/formwork/configurators/stabilisation">
                <ConfiguratorFourth />
              </Route>
              <Route exact path="/formwork/select-subcategory/:boxId/measures/:measureId">
                <SelectSubcategory />
              </Route>
              <Route exact path="/formwork/description-box/:boxId/measures/:measureId">
                <BoxDescription />
              </Route>
              <Route exact path="/formwork/implementation/:boxId/measures/:measureId/juxtaposition">
                <Juxtaposition />
              </Route>
              <Route exact path="/formwork/implementation/:boxId/measures/:measureId/superposition">
                <Superposition />
              </Route>
              <Route exact path="/formwork/implementation/:boxId/measures/:measureId/:category">
                <ImplementationCategory />
              </Route>
              <Route exact path="/formwork/implementation/:boxId/measures/:measureId">
                <Implementation />
              </Route>
              {/* End formwork routes */}

              {/* Security routes */}
              <Route exact path="/security/select-category">
                <SecuritySelectCategory />
              </Route>

              {/* PCE 2 */}
              <Route exact path="/security/pce-2">
                <Pce2SelectSubcategory />
              </Route>
              <Route exact path="/security/pce-2/components">
                <Pce2ComponentsList />
              </Route>
              <Route exact path="/security/pce-2/components/:id">
                <Pce2Component />
              </Route>
              <Route exact path="/security/pce-2/components/:id/implementation">
                <Pce2ComponentImplementation />
              </Route>
              <Route exact path="/security/pce-2/components/:id/description">
                <Pce2ComponentDescription />
              </Route>
              <Route exact path="/security/pce-2/configurators">
                <Pce2Configurators />
              </Route>
              <Route exact path="/security/pce-2/configurators/:id">
                <Pce2ConfiguratorDescription />
              </Route>
              <Route exact path="/security/pce-2/configurators/:id/implementation">
                <Pce2ConfiguratorImplementation />
              </Route>
              <Route exact path="/security/pce-2/configurator/stairs/:type">
                <Pce2Stairs />
              </Route>
              <Route exact path="/security/pce-2/modules">
                <Pce2ModulesList />
              </Route>
              <Route exact path="/security/pce-2/modules/:id">
                <Pce2Module />
              </Route>
              <Route exact path="/security/pce-2/modules/:id/description">
                <Pce2ModuleDescription />
              </Route>
              <Route exact path="/security/pce-2/modules/:id/implementation">
                <Pce2ModuleImplementation />
              </Route>
              <Route exact path="/security/pce-2/modules/:id/implementation/logistics">
                <Pce2ModuleImplementationLogistics />
              </Route>
              <Route exact path="/security/pce-2/modules/:id/implementation/assembly">
                <Pce2ModuleImplementationAssembly />
              </Route>
              <Route exact path="/security/pce-2/doc-technical">
                <Pce2DocTechnical />
              </Route>
              {/* End PCE 2 */}

              {/* P3D 2 */}
              <Route exact path="/security/p3d2">
                <P3D2SelectSubcategory />
              </Route>
              <Route exact path="/security/p3d2/modules">
                <P3D2ModulesList />
              </Route>
              <Route exact path="/security/p3d2/modules/:id">
                <P3D2Module />
              </Route>
              <Route exact path="/security/p3d2/modules/:id/description">
                <P3D2ModuleDescription />
              </Route>
              <Route exact path="/security/p3d2/modules/:id/implementation">
                <P3D2ModuleImplementation />
              </Route>
              <Route exact path="/security/p3d2/modules/:id/implementation/logistics">
                <P3D2ModuleImplementationLogistics />
              </Route>
              <Route exact path="/security/p3d2/modules/:id/implementation/unfolding">
                <P3D2ModuleImplementationUnfolding />
              </Route>
              <Route exact path="/security/p3d2/modules/:id/implementation/folding">
                <P3D2ModuleImplementationFolding />
              </Route>
              <Route exact path="/security/p3d2/modules/:id/implementation/setting">
                <P3D2ModuleImplementationSetting />
              </Route>
              <Route exact path="/security/p3d2/modules/:id/implementation/maintenance">
                <P3D2ModuleImplementationMaintenance />
              </Route>
              <Route exact path="/security/p3d2/doc-technical">
                <P3D2DocTechnical />
              </Route>
              <Route exact path="/security/p3d2/components">
                <P3D2Components />
              </Route>
              <Route exact path="/security/p3d2/components/:id">
                <P3D2Component />
              </Route>
              <Route exact path="/security/p3d2/components/:id/description">
                <P3D2ComponentDescription />
              </Route>
              <Route exact path="/security/p3d2/components/:id/implementation">
                <P3D2ComponentImplementation />
              </Route>
              <Route exact path="/security/p3d2/accessories">
                <P3D2Accessories />
              </Route>
              <Route exact path="/security/p3d2/accessories/:id">
                <P3D2Accessor />
              </Route>
              <Route exact path="/security/p3d2/accessories/:id/description">
                <P3D2AccessorDescription />
              </Route>
              <Route exact path="/security/p3d2/accessories/:id/implementation">
                <P3D2AccessorImplementation />
              </Route>
              <Route exact path="/security/p3d2/configurators">
                <P3D2Configurators />
              </Route>
              <Route exact path="/security/p3d2/configurators/:id">
                <P3D2Configurator />
              </Route>
              <Route exact path="/security/p3d2/configurators/:id/description">
                <P3D2ConfiguratorDescription />
              </Route>
              <Route exact path="/security/p3d2/configurators/:id/implementation">
                <P3D2ConfiguratorImplementation />
              </Route>
              {/* End P3D 2 */}

              {/* PCA 2 */}
              <Route exact path="/security/pca-2">
                <Pca2SelectSubcategory />
              </Route>
              <Route exact path="/security/pca-2/components">
                <Pca2ComponentsList />
              </Route>
              <Route exact path="/security/pca-2/components/:id">
                <Pca2Component />
              </Route>
              <Route exact path="/security/pca-2/components/:id/implementation">
                <Pca2ComponentImplementation />
              </Route>
              <Route exact path="/security/pca-2/components/:id/description">
                <Pca2ComponentDescription />
              </Route>
              <Route exact path="/security/pca-2/configurators">
                <Pca2Configurators />
              </Route>
              <Route exact path="/security/pca-2/configurators/:id">
                <Pca2ConfiguratorDescription />
              </Route>
              <Route exact path="/security/pca-2/configurators/:id/implementation">
                <Pca2ConfiguratorImplementation />
              </Route>
              <Route exact path="/security/pca-2/configurator/stairs/:type">
                <Pca2Stairs />
              </Route>
              <Route exact path="/security/pca-2/module">
                <Pca2Module />
              </Route>
              <Route exact path="/security/pca-2/module/description">
                <Pca2ModuleDescription />
              </Route>
              <Route exact path="/security/pca-2/module/implementation">
                <Pca2ModuleImplementation />
              </Route>
              <Route exact path="/security/pca-2/module/implementation/logistics">
                <Pca2ModuleImplementationLogistics />
              </Route>
              <Route exact path="/security/pca-2/module/implementation/unfolding">
                <Pca2ModuleImplementationUnfolding />
              </Route>
              <Route exact path="/security/pca-2/module/implementation/folding">
                <Pca2ModuleImplementationFolding />
              </Route>
              <Route exact path="/security/pca-2/doc-technical">
                <Pca2DocTechnical />
              </Route>
              <Route exact path="/security/pca-2/adjustment">
                <Pca2Adjustment />
              </Route>
              {/* End PCA 2 */}

              {/* innovations */}
              <Route exact path="/innovations">
                <InnovationsList />
              </Route>
              <Route exact path="/innovations/:id">
                <Innovation />
              </Route>
              <Route exact path="/innovations/:id/description">
                <InnovationDescription />
              </Route>
              <Route exact path="/innovations/:id/implementation">
                <InnovationImplementation />
              </Route>
              <Route exact path="/innovations/:id/documents">
                <InnovationDocuments />
              </Route>
              {/* end innovations*/}

              {/* custom products */}
              <Route exact path="/custom-products">
                <CustomProductsList />
              </Route>
              <Route exact path="/custom-products/:id">
                <CustomProducts />
              </Route>
              <Route exact path="/custom-products/:id/description">
                <CustomProductsDescription />
              </Route>
              <Route exact path="/custom-products/:id/implementation">
                <CustomProductsImplementation />
              </Route>
              <Route exact path="/custom-products/:id/documents">
                <CustomProductsDocuments />
              </Route>
              {/* end custom products */}

              {/* End security routes */}
            </Switch>
          </main>
        </Router>
      </PageContextProvider>
    </>
  );
};

export default App;

import React, { useEffect, useState } from 'react';
// import { isArray } from 'rxjs/internal-compatibility';
import S from '../../pages/formwork/boxes/styles/ConfiguratorSumming.module.css';
import SubBoxComponent from './SubBoxComponent.jsx';
import addIcon from '../../assets/img/addIcon.png';

const BoxComponent = ({
  typeBox = 'Box/BoxR',
  typeStab = 'stabilizers',
  flagBoxRS = [0, 0, 0, false],
  flagBoxH4 = false,
  flagBoxW4 = false,
  flagStabilisation = false,
  ...props
}) => {
  const { arrayAllParams, indexBox, updateArrayParams, name } = props;
  const [addForm, setAddForm] = useState([0]);
  const [arraySelectParameters, setArraySelectParameters] = useState([]);
  const [isThisBoxR, setIsThisBoxR] = useState(true);
  const [buttonAdd30, setButtonAdd30] = useState([false, false]);
  const [buttonAdd60, setButtonAdd60] = useState([false, false]);
  const logicRS_Adding = () => !flagBoxRS[3];

  // useEffect(() => {
  //   if (flagBoxW4 && isArray(arrayAllParams[1])) {
  //     if (isArray(arrayAllParams[1][0]) && arrayAllParams[1][0][0] === 777 && !arrayAllParams[1][1]) {
  //       setButtonAdd30([true, false]);
  //     }
  //     if (isArray(arrayAllParams[1][1]) && arrayAllParams[1][1][0] === 30) {
  //       setButtonAdd60([true, false]);
  //     }
  //   }
  // }, [arrayAllParams, flagBoxRS, flagBoxW4]);

  useEffect(() => {
    setAddForm([0]);
    setArraySelectParameters([]);
    setIsThisBoxR(typeBox === 'Box/BoxR' && typeStab === 'stabilizers');
  }, [typeBox]);

  // zeroing when the stabilizer changes
  useEffect(() => {
    setAddForm([0]);
    setArraySelectParameters([]);
  }, [typeStab]);

  //check fields occupancy
  function blankedFields() {
    let timeParams = [false, false];
    if (arrayAllParams) {
      if (arrayAllParams[indexBox]?.length) {
        if (arrayAllParams[indexBox][addForm.length - 1]?.length) {
          if (arrayAllParams[indexBox][addForm.length - 1][0]) {
            timeParams[0] = true;
          }
          if (arrayAllParams[indexBox][addForm.length - 1][1]) {
            timeParams[1] = true;
          }
        }
      }
    }
    return timeParams[0] && timeParams[1];
  }

  function updateInputData(childData) {
    let data = childData;
    setArraySelectParameters(data);
    let newParams = arrayAllParams;

    newParams[indexBox] = data;

    updateArrayParams(newParams);
  }

  function updateInputData4H(childData) {
    setArraySelectParameters(childData);

    let newParams = arrayAllParams;
    newParams[indexBox] = childData;

    if (flagBoxH4 && typeBox === 'BoxRS') {
      newParams[1][0] = [0, ''];
    }

    updateArrayParams(newParams);
  }

  function deleteForm(index) {
    let filterParams = arraySelectParameters;
    filterParams.splice(index, 1);
    setArraySelectParameters(filterParams);

    let newParams = arrayAllParams;
    newParams[indexBox][index] = filterParams;
    updateArrayParams(newParams);

    let clearForm = [...addForm];
    clearForm.splice(index, 1);
    setAddForm(clearForm);
  }

  //for the universality
  function separator(first) {
    if (first) {
      return [props.constructor[0], props.constructor[1]];
    }
    return [props.constructor[2]];
  }

  const logicShowConstructor = (i) =>
    indexBox === 0 && i === 0 && !isThisBoxR
      ? separator(true)
      : indexBox === 0 && i === 1 && !isThisBoxR
      ? separator(false)
      : props.constructor;
  // height constructor Stabilize
  if (flagBoxH4) {
    return (
      <div className={S.boxForm}>
        <div className={S.boxForm_Title}>
          <div className={S.boxForm_Title_text}>{name}</div>
        </div>
        <div className={S.boxForm_Body}>
          <div style={{ marginTop: '20px' }}>REHAUSSE</div>
          <SubBoxComponent
            constructor={['0.50']}
            arraySelectParameters={arraySelectParameters}
            updateInputData={updateInputData4H}
            indexBox={0}
            indexSection={0}
            typeStab={typeStab}
            typeBox={typeBox}
            deleteForm={deleteForm}
            flagBoxH4={flagBoxH4}
          />
          <div>PANNEAUX</div>
          <SubBoxComponent
            constructor={['3.00', '2.80']}
            arraySelectParameters={arraySelectParameters}
            updateInputData={updateInputData4H}
            indexBox={0}
            indexSection={1}
            typeStab={typeStab}
            typeBox={typeBox}
            deleteForm={deleteForm}
            flagBoxH4={flagBoxH4}
          />
          {typeBox !== 'BoxRS' && (
            <>
              <div>SOUS HAUSSE</div>
              <SubBoxComponent
                constructor={['1.50', '1.00']}
                arraySelectParameters={arraySelectParameters}
                updateInputData={updateInputData4H}
                indexBox={0}
                indexSection={2}
                typeStab={typeStab}
                typeBox={typeBox}
                deleteForm={deleteForm}
                flagBoxH4={flagBoxH4}
              />
            </>
          )}
        </div>
      </div>
    );
  }
  // width constructor Stabilize BoxRS
  if (flagBoxW4) {
    return (
      <div className={S.boxForm}>
        <div className={S.boxForm_Title}>
          <div className={S.boxForm_Title_text}>{name}</div>
        </div>
        <div className={S.boxForm_Body}>
          <SubBoxComponent
            constructor={[...logicShowConstructor(0), 777]}
            arraySelectParameters={arraySelectParameters}
            arrayAllParams={arrayAllParams}
            updateInputData={updateInputData}
            indexBox={indexBox}
            indexSection={0}
            typeStab={typeStab}
            typeBox={typeBox}
            flagBoxW4={flagBoxW4}
            deleteForm={deleteForm}
            flagBoxRS={flagBoxRS}
            flagStabilisation={flagStabilisation}
          />
        </div>
        {buttonAdd30[0] && (flagBoxRS[0] === 1 || flagBoxRS[1] === 1) && (
          <div
            className={S.addingButton}
            onClick={(e) => {
              setButtonAdd30([false, true]);
            }}
          >
            <div>
              <img alt="addIcon" src={addIcon} />
            </div>
          </div>
        )}
        {buttonAdd30[1] && (
          <>
            <SubBoxComponent
              constructor={[30]}
              arraySelectParameters={[[777, 0], 30]}
              arrayAllParams={arrayAllParams}
              updateInputData={updateInputData}
              indexBox={indexBox}
              indexSection={1}
              typeStab={typeStab}
              typeBox={typeBox}
              flagBoxW4={flagBoxW4}
              deleteForm={() => {}}
              flagBoxRS={flagBoxRS}
              flagStabilisation={flagStabilisation}
            />
            {buttonAdd60[0] && flagBoxRS[0] === 1 && buttonAdd30[1] && !buttonAdd60[1] && (
              <div
                className={S.addingButton}
                onClick={(e) => {
                  e.stopPropagation();
                  setButtonAdd60([false, true]);
                }}
              >
                <div>
                  <img alt="addIcon" src={addIcon} />
                </div>
              </div>
            )}
            {buttonAdd60[1] && (
              <SubBoxComponent
                constructor={[60]}
                arraySelectParameters={[[777, 0], 30, 60]}
                arrayAllParams={arrayAllParams}
                updateInputData={updateInputData}
                indexBox={indexBox}
                indexSection={2}
                typeStab={typeStab}
                typeBox={typeBox}
                flagBoxW4={flagBoxW4}
                deleteForm={() => {}}
                flagBoxRS={flagBoxRS}
                flagStabilisation={flagStabilisation}
              />
            )}
          </>
        )}
      </div>
    );
  }

  return (
    <div className={S.boxForm}>
      <div className={S.boxForm_Title}>
        <div className={S.boxForm_Title_text}>{name}</div>
      </div>
      <div className={S.boxForm_Body}>
        {addForm.map((form, i) => (
          <SubBoxComponent
            key={form}
            constructor={logicShowConstructor(i)}
            arraySelectParameters={arraySelectParameters}
            updateInputData={updateInputData}
            indexBox={indexBox}
            indexSection={i}
            typeStab={typeStab}
            typeBox={typeBox}
            deleteForm={deleteForm}
            flagBoxRS={typeBox === 'BoxRS' && indexBox === 1 && flagBoxRS}
            flagStabilisation={flagStabilisation}
          />
        ))}
        {/*Limit for BoxRS*/}
        {typeStab === 'stabilizers' &&
        (indexBox !== 0 || (indexBox === 0 && addForm.length < 2)) &&
        logicRS_Adding() ? (
          <div
            className={S.addingButton}
            onClick={(event) => {
              if (addForm.length > 1 && indexBox === 0) {
                event.stopPropagation();
              } else if (blankedFields() && addForm.length < props.constructor?.length) {
                setAddForm([...addForm, addForm[addForm.length - 1] + 1]);
              }
            }}
          >
            <div>
              <img alt="addIcon" src={addIcon} />
            </div>
          </div>
        ) : (
          isThisBoxR &&
          typeStab === 'stabilizers' && (
            <div
              className={S.addingButton}
              onClick={() => {
                if (blankedFields() && addForm.length < props.constructor?.length) {
                  setAddForm([...addForm, addForm[addForm.length - 1] + 1]);
                }
              }}
            >
              <div>
                <img alt="addIcon" src={addIcon} />
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default BoxComponent;

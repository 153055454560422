import React, { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import { isArray } from 'rxjs/internal-compatibility';
import usePage from '@/hooks/usePage.js';
import BoxComponent from '@/components/SubSectionComponents/BoxComponent.jsx';

import TypeBox from '@/components/SubSectionComponents/TypeBox.jsx';
import RegionWind from '@/components/SubSectionComponents/RegionWind.jsx';
import TypeStabilisationBy from '@/components/SubSectionComponents/TypeStabilisationBy.jsx';
import StabilisationByPortico from './StabilisationByPortico.jsx';
import StabilisationByAnchor from './StabilisationByAnchor.jsx';
import S from '../styles/ConfiguratorSumming.module.css';
import StabilisationByBallast from './StabilisationByBallast.jsx';
import {
  initializationConstruct,
  toPanel,
  filterPanels,
  fullFiledRS,
  distributeData,
  findDoubleTop,
} from '@/components/SubSectionComponents/CleanFunctions.jsx';
import { showFullWidthHeight, completedFields, alertTooSmall, alertTooShort } from '../ConfiguratorSumming.jsx';
// import ReactImg2 from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/BallastImgComponents/ReactImgRS';

const positionTheHolder = 1.21;
const limitWidth = 1.14;
const limitHeight = 1;

const ConfiguratorFourth = () => {
  const { setTitle } = usePage();
  const [constructorHeights, setConstructorHeights] = useState([]);
  const [constructorWidths, setConstructorWidths] = useState([]);
  const [arrayAllParams, setArrayAllParams] = useState([[], [], [], []]);
  const [flag_3m_2_8m_FirstH_DoubleTop, setFlag_3m_2_8m_FirstH_DoubleTop] = useState([false, false, 0, false]);
  const [typeBox, setTypeBox] = useState('');
  const [speedWind, setSpeedWind] = useState(0);
  const [totalHeight, setTotalHeight] = useState(0);
  const [totalWidth, setTotalWidth] = useState(0);
  const [possible, setPossible] = useState('');
  const [typeStabPar, setTypeStabPar] = useState('anchor');

  const [widthFormPanels, setWidthFormPanels] = useState(0);
  const [areaFormPanels, setAreaFormPanels] = useState(0);
  const [either, setEither] = useState(0);
  const [fV, setFV] = useState(0);
  const [numberBallastRequired, setNumberBallastRequired] = useState(0);
  const [allHeightsPanel, setAllHeightsPanel] = useState([]);
  const [flagBoxRS, setFlagBoxRS] = useState([0, 0, 0, true]);
  const [massLest, setMassLest] = useState(0);
  const [selectedAngle, setSelectedAngle] = useState(false);

  //number of stabilizers
  useEffect(() => {
    if (totalHeight >= 8.4) {
      setNumberBallastRequired(Math.ceil((totalWidth - 0.6) / 1.2));
    } else {
      if (totalWidth < 3.9) {
        setNumberBallastRequired(2);
      } else if (totalWidth < 5.7) {
        setNumberBallastRequired(3);
      } else if (totalWidth < 7.5) {
        setNumberBallastRequired(4);
      } else {
        setNumberBallastRequired(5);
      }
    }
    //specify whether stabilization is possible
    const updateValue = totalHeight < 8.4 ? 1.95 : 1.2;
    let width = totalHeight >= 8.9 ? 1.5 : updateValue;
    setWidthFormPanels(width);

    if (totalHeight <= 9) {
      if (totalWidth <= 9.6) {
        setPossible('');
      } else {
        setPossible('Au delà de 9.6m consulter la hotline!');
      }
    } else {
      setPossible('Pour toute hauteur supérieur à 9,0m, consulter notre Hotline !');
    }
    if (width * totalHeight !== areaFormPanels) {
      setAreaFormPanels(width * totalHeight);
    }
  }, [areaFormPanels, totalHeight, totalWidth, widthFormPanels]);

  useEffect(() => {
    setEither(Math.pow(speedWind / 3.6, 2) * (1.75 / 16.3));
  }, [speedWind]);

  useEffect(() => {
    setFV(either * areaFormPanels);
  }, [either, areaFormPanels]);

  useEffect(() => {
    let [height, width] = initializationConstruct(typeBox);
    setConstructorHeights(height);
    setConstructorWidths(width);
    setTypeStabPar('anchor');
    setFlag_3m_2_8m_FirstH_DoubleTop([false, false, 0, false]);
    setTotalHeight(0);
    setTotalWidth(0);
    setWidthFormPanels(0);
    setAreaFormPanels(0);
    setEither(0);
    setFV(0);
    setNumberBallastRequired(0);
    setArrayAllParams([[], [], [], []]);
    setTitle('Stabilité');
  }, [typeBox]);

  function updateSpeed(speed) {
    setSpeedWind(speed);
  }

  const sortChildData = () => {
    //Convert back from 3:00 to 3000
    arrayAllParams.map((array, iArr) => {
      if (iArr < 2) {
        array.map((element) => {
          if (element[0] === 'Exter 0.09') {
            element[0] = 90;
          } else if (element[0] === 'Exter 0.1') {
            element[0] = 100;
          } else if (element[0] === 'Exter 0.12') {
            element[0] = 120;
          } else if (element[0] === 'Exter 0.14') {
            element[0] = 140;
          } else if (element[0] === 'Inter 1.14') {
            element[0] = 1140;
          } else if (element[0] === 30) {
            element[0] = 30;
          } else if (element[0] === 60) {
            element[0] = 60;
          } else if (element[0] < 100) {
            element[0] = Number(element[0]) * 1000;
          }
        });
      }
    });
    //distribute data
    if (arrayAllParams) {
      let [arrayAllHeight, arrayAllWidth] = distributeData(arrayAllParams, 2);

      //update flagBOX_RS
      if (arrayAllHeight.length > 0) {
        let defaultValueFlag = [0, 0, 0, true];
        if (typeBox === 'BoxRS') {
          let sumH = [...arrayAllHeight]?.reduce((sum, curr) => sum + curr);
          if (sumH === 3000 || sumH === 2800) {
            defaultValueFlag = [1, 0, 0, true];
          } else if (sumH === 3500 || sumH === 3300) {
            defaultValueFlag = [0, 1, 0, true];
          } else if (sumH === 4000 || sumH === 3800) {
            defaultValueFlag = [0, 0, 1, true];
          }
        }
        setFlagBoxRS(defaultValueFlag);
      }
      let haveDoubleTop = findDoubleTop(arrayAllHeight);
      arrayAllHeight = filterPanels(arrayAllHeight);
      setAllHeightsPanel(toPanel(arrayAllHeight));
      // Looking for height 3m and 2.8m
      if (arrayAllHeight.length && arrayAllWidth.length) {
        let find3m = false;
        let find2_8m = false;
        let firstH = arrayAllHeight[0];

        arrayAllHeight.forEach((panel) => {
          if (panel === 3000) {
            find3m = true;
          }
          if (panel === 2800) {
            find2_8m = true;
          }
        });

        setFlag_3m_2_8m_FirstH_DoubleTop([find3m, find2_8m, firstH, haveDoubleTop]);
        let totalH = arrayAllHeight?.reduce((sum, h) => sum + h / 1000, 0);
        setTotalHeight(totalH > 0 ? Number(totalH.toFixed(1)) : 0);
        setTotalWidth(arrayAllWidth?.reduce((sum, w) => sum + Number(w) / 1000, 0));
      }
    }
  };

  function updateArrayParams(childData) {
    if (typeBox === 'BoxRS') {
      const check = isArray(arrayAllParams[1]) && isArray(arrayAllParams[1][0]) && arrayAllParams[1][0][0] === 777;
      setSelectedAngle(check);
    }
    if (childData?.length > 0 && childData[1]?.length > 0 && childData[1][0]?.length > 0) {
      if (typeBox === 'BoxRS' && childData[1][0][1] === 0 && childData[1][0][0] !== 0) {
        const localMass = childData[1][0][0] === 1200 ? 400 : 780;
        setMassLest(childData[1][0][0] === 777 ? 130 : localMass);
      }
    }
    setArrayAllParams(childData);
    sortChildData();
  }

  function updateTypeBox(type) {
    setTypeBox(type);
  }

  function updateTypeStabPar(type) {
    setTypeStabPar(type);
  }

  function TypeStabilisationByShow() {
    if (typeStabPar === 'anchor') {
      return (
        <StabilisationByAnchor
          totalHeight={totalHeight}
          fV={fV}
          positionTheHolder={positionTheHolder}
          flag_3m_2_8m_FirstH_DoubleTop={flag_3m_2_8m_FirstH_DoubleTop}
          speedWind={speedWind}
          numberBallastRequired={numberBallastRequired}
          allHeightsPanel={allHeightsPanel}
          widthFormPanels={widthFormPanels}
        />
      );
    } else if (typeStabPar === 'ballast') {
      return (
        <StabilisationByBallast
          totalHeight={totalHeight}
          fV={fV}
          positionTheHolder={positionTheHolder}
          flag_3m_2_8m_FirstH_DoubleTop={flag_3m_2_8m_FirstH_DoubleTop}
          speedWind={speedWind}
          numberBallastRequired={numberBallastRequired}
          allHeightsPanel={allHeightsPanel}
          widthFormPanels={widthFormPanels}
        />
      );
    } else if (typeStabPar === 'portico') {
      return (
        <StabilisationByPortico
          totalHeight={totalHeight}
          totalWidth={totalWidth}
          allHeightsPanel={allHeightsPanel}
          doubleTopSection={flag_3m_2_8m_FirstH_DoubleTop[3]}
        />
      );
    }
  }

  const text = {
    height: 'Indiquez la hauteur du train de banches',
    width: 'Indiquez la longueur du train de banches',
  };

  return (
    //Container needs inLine Style!
    <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <TypeBox setTypeBox={updateTypeBox} />
      {typeBox !== '' && (
        <>
          <RegionWind updateSpeed={updateSpeed} typeBox={typeBox} />

          <BoxComponent
            indexBox={0}
            constructor={constructorHeights}
            arrayAllParams={arrayAllParams}
            updateArrayParams={updateArrayParams}
            name={text.height}
            typeBox={typeBox}
            flagBoxH4={true}
          />

          <BoxComponent
            indexBox={1}
            constructor={constructorWidths}
            arrayAllParams={arrayAllParams}
            updateArrayParams={updateArrayParams}
            name={text.width}
            typeBox={typeBox}
            flagBoxRS={typeBox === 'BoxRS' ? flagBoxRS : [0, 0, 0, false]}
            flagStabilisation={true}
            flagBoxW4={typeBox === 'BoxRS'}
          />

          {totalWidth > 0 && totalHeight > 0 && speedWind > 0 && showFullWidthHeight(totalWidth, totalHeight)}
          {possible !== '' && speedWind > 0 && (
            <div className={S.possible}>
              <div>{possible}</div>
            </div>
          )}

          {totalHeight < limitHeight && totalHeight > 0 && alertTooSmall(S)}
          {totalWidth < limitWidth && totalWidth > 0 && alertTooShort(S)}

          {fullFiledRS(arrayAllParams) && possible === '' && speedWind > 0 && typeBox === 'BoxRS' ? (
            <>
              {selectedAngle ? (
                <>
                  <div className={S.boxForm_Final}>
                    <div className={S.boxForm_Title}>
                      <div>Сalcul Final</div>
                    </div>
                    <div className={`${S.boxForm_Body} ${S.mt25}`}>
                      <div className={S.boxForm_Final_elem}>
                        <div>Lest :</div>
                        <div className={S.textR}>130 kg</div>
                      </div>
                      <div className={S.line} />
                    </div>
                  </div>
                  {/*/!*0.5 0.5*!/*/}
                  {/*{flagBoxRS[0] === 1 && <ReactImg2 allHeightsPanel={allHeightsPanel} typeBox={typeBox} />}*/}
                  {/*/!*0.5*!/*/}
                  {/*{flagBoxRS[1] === 1 && <ReactImg2 allHeightsPanel={allHeightsPanel} typeBox={typeBox} />}*/}
                  {/*/!*0*!/*/}
                  {/*{flagBoxRS[2] === 1 && <ReactImg2 allHeightsPanel={allHeightsPanel} typeBox={typeBox} />}*/}
                </>
              ) : (
                <StabilisationByBallast
                  totalHeight={totalHeight}
                  fV={fV}
                  positionTheHolder={positionTheHolder}
                  flag_3m_2_8m_FirstH_DoubleTop={flag_3m_2_8m_FirstH_DoubleTop}
                  speedWind={speedWind}
                  numberBallastRequired={numberBallastRequired}
                  allHeightsPanel={allHeightsPanel}
                  typeBox={typeBox}
                  massLest={massLest}
                />
              )}
            </>
          ) : (
            possible === '' && typeBox === 'BoxRS' && totalHeight >= limitHeight && completedFields(S)
          )}
          {possible === '' &&
          speedWind > 0 &&
          typeBox !== 'BoxRS' &&
          totalWidth >= limitWidth &&
          totalHeight >= limitHeight ? (
            <>
              <TypeStabilisationBy
                setTypeStab={updateTypeStabPar}
                totalHeight={totalHeight}
                totalWidth={totalWidth}
                doubleTopSection={flag_3m_2_8m_FirstH_DoubleTop[3]}
              />
              {TypeStabilisationByShow()}
            </>
          ) : (
            possible === '' &&
            typeBox !== 'BoxRS' &&
            totalHeight >= limitHeight &&
            totalWidth >= limitWidth &&
            completedFields(S)
          )}
        </>
      )}
    </Container>
  );
};

export default ConfiguratorFourth;

import React, { useEffect, useState } from 'react';
import S from '../styles/ConfiguratorSumming.module.css';
import { updateTotalPortico } from '@/components/SubSectionComponents/CleanFunctions.jsx';
import ReactImagesPortico from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/ReactImagesPortico.jsx';

const StabilisationByPortico = ({ totalHeight, totalWidth, allHeightsPanel, doubleTopSection }) => {
  const [totalPortico, setTotalPortico] = useState(0);

  useEffect(() => {
    setTotalPortico(updateTotalPortico(totalHeight, totalWidth));
  }, [totalHeight, totalWidth]);

  const showLogic = () => {
    return (
      totalPortico === 'Stabilisation par portique impossible' ||
      totalPortico === 0 ||
      totalHeight < 1.2 ||
      doubleTopSection
    );
  };

  return (
    <>
      <div
        className={`${S.boxForm_Final} ${S.containerPortico}`}
        style={{ backgroundColor: showLogic() ? '#D4311F' : '' }}
      >
        <div className={S.boxForm_Title}>
          <div>Сalcul final</div>
        </div>
        <div className={`${S.boxForm_Body} ${S.mt25}`} style={{ backgroundColor: showLogic() ? '#D4311F' : '' }}>
          {showLogic() ? (
            <>
              <div className={S.showFinalParams}>Stabilisation par portique impossible</div>
              <div className={S.line} />
            </>
          ) : (
            <div className={`${S.boxForm_Final_elem} ${S.porticoContainer}`}>
              <div className={S.m5}>Nombre de portique :</div>
              <div className={S.line} />
              <div className={S.m5}>{totalPortico}</div>
            </div>
          )}
        </div>
      </div>
      {totalPortico !== 'Stabilisation par portique impossible' && (
        <ReactImagesPortico allHeightsPanel={allHeightsPanel} />
      )}
    </>
  );
};

export default StabilisationByPortico;

import React, { useEffect } from 'react';
import { Container, Typography, Box, Button, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import usePage from '@/hooks/usePage.js';
import mainStyles from '@/assets/js/mainStyles.js';

const useStyles = makeStyles(mainStyles);

const SelectCategory = () => {
  const classes = useStyles();
  const history = useHistory();
  const { setTitle } = usePage();

  useEffect(() => {
    setTitle('Coffrage');
  }, []);

  return (
    <Container maxWidth="md">
      <Box className={classes.content}>
        <Typography className={classes.textBox} style={{ fontSize: 19 }} component="div" align="center">
          Vous cherchez des informations sur :
        </Typography>
        <Box className={classes.btnsContainer} align="center">
          <Button className={classes.outlinedBtn} onClick={() => history.push('choice-box')} variant="outlined">
            BOX
          </Button>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push('/formwork/accessories/additional')}
            variant="outlined"
          >
            Accessoires complémentaires
          </Button>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push('/formwork/accessories/stability')}
            variant="outlined"
          >
            Accessoires stabilité
          </Button>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push('/formwork/configurators')}
            variant="outlined"
          >
            Configurateurs
          </Button>
          <Button
            className={classes.outlinedBtn}
            onClick={() => window.open('https://www.sateco.fr/fr/satecotheque.html')}
            variant="outlined"
            size="large"
          >
            Docs techniques
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default SelectCategory;

import React, { useEffect, useState } from 'react';
import { Box, Card, CardMedia, Container, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { printPlugin } from '@react-pdf-viewer/print';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import Request from '@/helpers/request.js';
import usePage from '@/hooks/usePage.js';
import Constants from '@/constants.js';
import mainStyles from '@/assets/js/mainStyles.js';
import { BoxVideo_FAQ } from '@/pages/security/pce-2/modules/Description.jsx';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';

const useStyles = makeStyles(mainStyles);

const Description = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [component, setComponent] = useState(null);
  const { setTitle } = usePage();
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const printPluginInstance = printPlugin();
  const { Print } = printPluginInstance;

  useEffect(async () => {
    const fetchComponent = async () => {
      const response = await Request.get(`/pce2-components/${id}`);
      setComponent(response);
    };
    setTitle('DESCRIPTIF');
    await fetchComponent();
  }, []);

  const documents = component?.documents
    ? component?.documents
    : {
        file: null,
        image: null,
      };

  return (
    <Container maxWidth="md">
      <Box>
        <Box className={classes.descRoot}>
          <div className={classes.secTitle}>
            <span>{component?.title}</span>
          </div>
          <div className={classes.description}>{component?.description?.text ?? ''}</div>
          <div
            className={classes.videoInner}
            dangerouslySetInnerHTML={{ __html: `${component?.description?.iframe ?? ''}` }}
          />
          <Card className={classes.imgCard}>
            {component?.description?.image && (
              <CardMedia
                className={classes.imgItem}
                component="img"
                image={`${Constants.BASE_URL}/${component.description.image.path} `}
              />
            )}
          </Card>
        </Box>
        {component?.description?.video && (
          <Box className={classes.videoBox} style={{ height: '500px' }}>
            <ReactPlayer url={component?.description?.video} width="100%" height="100%" controls />
          </Box>
        )}
        {documents?.file && (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
            <div
              className="rpv-core__viewer"
              style={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                display: 'flex',
                flexDirection: 'column',
                height: '700px',
              }}
            >
              <div
                style={{
                  alignItems: 'center',
                  backgroundColor: '#eeeeee',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '4px',
                }}
              >
                <ZoomOutButton />
                <ZoomPopover />
                <ZoomInButton />
                <Print />
              </div>
              <div
                style={{
                  flex: 1,
                  overflow: 'hidden',
                }}
              >
                <Viewer
                  defaultScale={SpecialZoomLevel.PageWidth}
                  plugins={[zoomPluginInstance, printPluginInstance]}
                  fileUrl={`${Constants.BASE_URL}/${documents.file.path} `}
                />
              </div>
            </div>
          </Worker>
        )}
        <BoxVideo_FAQ pathUrl={component?.usage?.faq} style={classes} />
      </Box>
    </Container>
  );
};

export default Description;

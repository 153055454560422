import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Button, makeStyles } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import usePage from '@/hooks/usePage.js';
import mainStyles from '@/assets/js/mainStyles.js';
import Request from '@/helpers/request.js';
import CategoriesName from '@/helpers/CategoriesName.js';

const useStyles = makeStyles(mainStyles);

const ImplementationCategory = () => {
  const classes = useStyles();
  const history = useHistory();
  const { boxId, measureId, category: categoryName } = useParams();
  const [box, setBox] = useState({});
  const [measure, setMeasure] = useState({});
  const [category, setCategory] = useState({});
  const { setTitle } = usePage();
  useEffect(async () => {
    const fetchData = async () => {
      const [responseBox, responseMeasure] = await Promise.all([
        Request.get(`/boxes/${boxId}`),
        Request.get(`/measures/${measureId}`),
      ]);
      setBox(responseBox);
      setMeasure(responseMeasure);
      setCategory(responseMeasure.usage[categoryName]);
    };
    setTitle(CategoriesName[categoryName]);
    await fetchData();
  }, []);

  return (
    <Container maxWidth="md">
      <Box className={classes.content}>
        <Box>
          <Typography className={classes.name}>
            <span>{box.name}</span>
          </Typography>
          <Typography className={classes.description} gutterBottom>
            {category.description}
          </Typography>
          <Box className={classes.videoBox}>
            {category.links &&
              category.links.map((item, index) => (
                <ReactPlayer key={index} url={item.link} width="100%" height="100%" controls />
              ))}
          </Box>
        </Box>
        <Button className={classes.mainBtn} onClick={() => window.open('https://www.sateco.fr/fr/faq.html')}>
          FAQ
        </Button>
      </Box>
    </Container>
  );
};

export default ImplementationCategory;

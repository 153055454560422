import React, { useEffect, useState } from 'react';
import { Container, Box, Typography, Button, makeStyles } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import usePage from '@/hooks/usePage.js';
import Request from '@/helpers/request.js';
import mainStyles from '@/assets/js/mainStyles.js';
import { dragSort } from '@/components/SubSectionComponents/CleanFunctions.jsx';

const useStyles = makeStyles(mainStyles);

const RenderList = (props) => {
  const { accessory, category, classes, history } = props;
  if (accessory.type && category === 'stability') {
    return (
      <Button
        key={accessory.id}
        className={classes.outlinedBtn}
        onClick={() => history.push(`stability/choice-stability/${accessory.id}`)}
      >
        {accessory.title}
      </Button>
    );
  } else if (!accessory.type && category === 'additional') {
    return (
      <Button
        key={accessory.id}
        className={classes.outlinedBtn}
        onClick={() => history.push(`additional/choice-additional/${accessory.id}`)}
      >
        {accessory.title}
      </Button>
    );
  } else {
    return '';
  }
};

const Accessories = () => {
  const classes = useStyles();
  const history = useHistory();
  const { category } = useParams();
  const [accessories, setAccessories] = useState([]);
  const { setTitle } = usePage();
  useEffect(async () => {
    const fetchAccessories = async () => {
      const response = await Request.get('/accessories?sort=title');
      const accessoriesData = response.items;
      setAccessories(accessoriesData);
    };
    setTitle('COFFRAGE');
    await fetchAccessories();
  }, []);
  return (
    <Container maxWidth="md">
      <Box className={classes.content}>
        {category === 'additional' ? (
          <Typography className={classes.subTitle} style={{ fontSize: 22 }}>
            Accessoires complémentaires
          </Typography>
        ) : (
          <Typography className={classes.subTitle} style={{ fontSize: 22 }}>
            Accessoires de stabilité
          </Typography>
        )}
        {accessories.sort(dragSort).map((accessory) => (
          <RenderList
            classes={classes}
            key={accessory.id}
            accessory={accessory}
            category={category}
            history={history}
          />
        ))}
        <Button
          className={classes.mainBtn}
          onClick={() => window.open('https://www.sateco.fr/fr/faq.html')}
          variant="outlined"
          size="large"
        >
          FAQ
        </Button>
      </Box>
    </Container>
  );
};

export default Accessories;

import React, { useEffect, useState } from 'react';
import { Container, Typography, Card, Box, Button, makeStyles, Dialog, IconButton, CardMedia } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ReactPlayer from 'react-player';
import usePage from '@/hooks/usePage.js';
import mainStyles from '@/assets/js/mainStyles.js';
import Request from '@/helpers/request.js';
import Constants from '@/constants.js';
import ico from '../../../assets/img/play-png.png';

const useStyles = makeStyles(mainStyles);
const categoryName = 'superposition';

const Superposition = () => {
  const classes = useStyles();
  const history = useHistory();
  const { boxId, measureId } = useParams();
  const [box, setBox] = useState({});
  const [category, setCategory] = useState({});
  const [description, setDescription] = useState('');
  const [dialogData, setDialogData] = useState({});
  const { setTitle } = usePage();

  useEffect(async () => {
    const fetchData = async () => {
      const [responseBox, responseMeasure] = await Promise.all([
        Request.get(`/boxes/${boxId}`),
        Request.get(`/measures/${measureId}`),
      ]);
      setBox(responseBox);
      setCategory(responseMeasure.usage[categoryName]);
      setDescription(responseMeasure.usage[categoryName].globalDescription);
    };
    setTitle('Superposition');
    await fetchData();
  }, []);

  const [open, setOpen] = useState(false);
  const handleClickOpen = (data) => {
    setDialogData(data);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setDialogData({});
  };

  function calculateAutoRight(right) {
    const center = [88, 71, 55, 39, 23, 6];
    let findValue = right;
    for (let i = 0; i < 6; i++) {
      if (right > center[i]) {
        if (right - center[i] < 7) {
          findValue = center[i];
          break;
        }
      } else {
        if (center - center[i] < 7) {
          findValue = center[i];
          break;
        }
      }
    }
    return findValue;
  }

  const categoryImages = category.images || [];
  const { points } = category;
  return (
    <Container maxWidth="md">
      <Typography className={classes.name} gutterBottom>
        <span> {box.name}</span>
      </Typography>
      <Typography className={classes.description} style={{ whiteSpace: 'pre-wrap' }}>
        {description}
      </Typography>
      {categoryImages.map((categoryImage, index) => (
        <Box key={index} className={classes.box}>
          {points.map((item, index) => {
            if (item.imageName === categoryImage.name) {
              return (
                <span
                  className={classes.point}
                  key={index}
                  style={{ right: `${calculateAutoRight(item.width)}%`, bottom: `-5%` }}
                  onClick={() => handleClickOpen({ link: item.link, description: item.description })}
                >
                  <CardMedia className={classes.videoIco} image={ico} />
                </span>
              );
            }
          })}
          <img className={classes.img} src={`${Constants.BASE_URL}/${categoryImage.path}`} alt={box.name} />
        </Box>
      ))}
      <Dialog fullWidth open={open} onClick={handleClose}>
        <Card className={classes.modalWrapper}>
          <IconButton className={classes.closeModal} color="inherit" onClick={handleClose}>
            <ChevronLeftIcon style={{ fontSize: 28 }} />
          </IconButton>
          <Box justifyContent={'center'} className={classes.videoBox}>
            <ReactPlayer url={dialogData.link} width="100%" height="100%" controls />
            <Typography style={{ fontSize: 18, paddingTop: 20, paddingBottom: 20 }} gutterBottom>
              {dialogData.description}
            </Typography>
          </Box>
        </Card>
      </Dialog>
      <Box align="center">
        <Button
          className={`${classes.outlinedBtn} ${classes.mTop25}`}
          onClick={() => history.push('/formwork/configurators')}
          variant="outlined"
        >
          Configurateurs
        </Button>
        <Button
          className={classes.mainBtn}
          onClick={() => window.open('https://www.sateco.fr/fr/faq.html')}
          variant="outlined"
        >
          FAQ
        </Button>
      </Box>
    </Container>
  );
};

export default Superposition;

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Container, makeStyles, Button } from '@material-ui/core';
import mainStyles from '@/assets/js/mainStyles.js';
import usePage from '@/hooks/usePage.js';
import Request from '@/helpers/request.js';

const useStyles = makeStyles(mainStyles);

const Innovation = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [innovation, setInnovation] = useState({});
  const { setTitle } = usePage();

  useEffect(async () => {
    const fetch = async () => {
      const response = await Request.get(`/innovations/${id}`);
      setInnovation(response);
    };
    setTitle('Innovations');
    await fetch();
  }, []);

  return (
    <Container maxWidth="md">
      <Box className={classes.content}>
        <div className={classes.secTitle}>
          <span>{innovation.title} </span>
        </div>
        <Button
          className={classes.outlinedBtn}
          onClick={() => history.push(`/innovations/${innovation.id}/description`)}
        >
          Descriptif
        </Button>
        <Button
          className={classes.outlinedBtn}
          onClick={() => history.push(`/innovations/${innovation.id}/implementation`)}
        >
          Mise en œuvre
        </Button>
        {/*<Button*/}
        {/*  className={classes.outlinedBtn}*/}
        {/*  onClick={() => history.push(`/innovations/${innovation.id}/documents`)}*/}
        {/*>*/}
        {/*  Document Commercial*/}
        {/*</Button>*/}
      </Box>
    </Container>
  );
};

export default Innovation;

import React, { useEffect, useState } from 'react';
import { Container, Box, Button, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import usePage from '@/hooks/usePage.js';
import Request from '@/helpers/request.js';
import mainStyles from '@/assets/js/mainStyles.js';
import { dragSort } from '@/components/SubSectionComponents/CleanFunctions.jsx';

const useStyles = makeStyles(mainStyles);

const CustomProductsList = () => {
  const classes = useStyles();
  const history = useHistory();
  const [customProducts, setCustomProducts] = useState([]);
  const { setTitle } = usePage();

  useEffect(async () => {
    const fetch = async () => {
      const response = await Request.get('/custom-products?sort=title');
      const data = response.items;
      setCustomProducts(data);
    };
    setTitle('Produits sur mesure');
    await fetch();
  }, []);

  return (
    <Container maxWidth="md">
      <Box className={classes.content}>
        {customProducts.sort(dragSort).map((innovation) => (
          <Button
            key={innovation.id}
            className={classes.outlinedBtnGrey}
            onClick={() => history.push(`/custom-products/${innovation.id}`)}
          >
            {innovation.title}
          </Button>
        ))}
        <Button
          className={classes.mainBtn}
          onClick={() => window.open('https://www.sateco.fr/fr/faq.html')}
          variant="outlined"
          size="large"
        >
          FAQ
        </Button>
      </Box>
    </Container>
  );
};

export default CustomProductsList;

import React, { useEffect, useState } from 'react';
import S from '@/styles/adjustment.module.css';
import { roundDown, roundTop } from '@/components/SubSectionComponents/CleanFunctions.jsx';

const greenY = () => {
  return <div style={{ color: '#068306', display: 'inline-block' }}>Y</div>;
};
const redTxt = (text) => {
  return <div style={{ color: 'rgb(255 6 30 / 87%)', display: 'inline-block' }}>{text}</div>;
};
const blueTxt = (text) => {
  return <div style={{ color: '#2591FF', display: 'inline-block' }}>{text}</div>;
};
const blackTxt = (text) => {
  return <div style={{ color: 'white', display: 'inline-block' }}>{text}</div>;
};

function Decouple({ depth, width, updateAV_BV_Y_Ar_Br, extG_extD, visible }) {
  const [topLeft, setTopLeft] = useState('');
  const [bottomLeft, setBottomLeft] = useState('');
  const [topRight, setTopRight] = useState('');
  const [bottomRight, setBottomRight] = useState('');

  //initialization
  useEffect(() => {
    let AV = roundTop(extG_extD[0] - 5, 5);
    let BV = roundDown(extG_extD[1] - 5, 5);
    let AR = 70 + roundTop((width - 140) / 2, 5);
    let BR = 70 + roundDown((width - 140) / 2, 5);

    let Y = depth - 126.5;
    setTopLeft(`126.5 x ${AV}  cm`);
    setBottomLeft(`${Y} x ${AR}  cm`);
    setTopRight(`126.5 x ${BV}  cm`);
    setBottomRight(`${Y} x ${BR}  cm`);
    updateAV_BV_Y_Ar_Br([AV, BV, Y, AR, BR]);
  }, [depth, width, extG_extD]);

  if (!visible) return <></>;

  return (
    <div className={`${S.boxContainer} ${S.boxCalculate}`}>
      <div className={S.boxHeader}>Découpe CP sur extension</div>
      <div className={S.decoupleContainer}>
        <div className={S.decoupleElem}>
          <div>
            CP Extension Avant Gauche ({blackTxt('126.5')} x {redTxt('Av')})
          </div>
          <div>{topLeft}</div>
        </div>
        <div className={S.line} />
        <div className={S.decoupleElem}>
          <div>
            CP Extension Arrière Gauche ({greenY()} x {redTxt('Ar')})
          </div>
          <div>{bottomLeft}</div>
        </div>
        <div className={S.line} />
        <div className={S.decoupleElem}>
          <div>
            CP Extension Avant Droite ({blackTxt('126.5')} x {blueTxt('Bv')})
          </div>
          <div>{topRight}</div>
        </div>
        <div className={S.line} />
        <div className={S.decoupleElem}>
          <div>
            CP Extension Arrière Droite ({greenY()} x {blueTxt('Br')})
          </div>
          <div>{bottomRight}</div>
        </div>
      </div>
    </div>
  );
}

export default Decouple;

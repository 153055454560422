import React, { useEffect, useState } from 'react';
import { roundTop } from '@/components/SubSectionComponents/CleanFunctions.jsx';
import { configAlert } from '@/pages/formwork/boxes/ConfiguratorSumming.jsx';

const AdjustmentWarnings = ({ extG_extD, inputParams, updateHaveWarnings, CG_CD }) => {

  useEffect(() => {
    const W =
      CG_CD[0] > 35 ||
      CG_CD[1] > 35 ||
      (extG_extD[0] < 10 && !(extG_extD[1] < 10)) ||
      (extG_extD[1] < 10 && !(extG_extD[0] < 10)) ||
      (extG_extD[0] < 10 && extG_extD[1] < 10) ||
      extG_extD[0] + 130 + extG_extD[1] !== roundTop(inputParams.totalWidth - 10, 5);
    updateHaveWarnings(W);
  }, [extG_extD, inputParams, CG_CD]);

  return (
    <>
      {extG_extD[0] < 10 && !(extG_extD[1] < 10) && (
        <>
          {configAlert('Réglage d’extension Ext G pas possible, consulter Hotline ')}
          {configAlert('Réglage Av pas possible, consulter Hotline')}
        </>
      )}
      {extG_extD[1] < 10 && !(extG_extD[0] < 10) && (
        <>
          {configAlert('Réglage d’extension Ext D pas possible, consulter Hotline ')}
          {configAlert('Réglage Bv pas possible, consulter Hotline')}
        </>
      )}
      {extG_extD[0] < 10 && extG_extD[1] < 10 && (
        <>
          {configAlert('Réglage d’extensions Ext G, Ext D pas possible, consulter Hotline ')}
          {configAlert('Réglage Bv, Av pas possible, consulter Hotline')}
        </>
      )}

      {extG_extD[0] + 130 + extG_extD[1] !== roundTop(inputParams.totalWidth - 10, 5) &&
        configAlert('!!! Problème, contrôler dimensions des extensions ou consulter Sateco Hotline !!!')}
    </>
  );
};

export default AdjustmentWarnings;

import React, { useEffect } from 'react';
import { Container, Typography, Box, Button, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import usePage from '@/hooks/usePage.js';
import mainStyles from '@/assets/js/mainStyles.js';

const useStyles = makeStyles(mainStyles);

const Handling = () => {
  const classes = useStyles();
  const history = useHistory();

  const { setTitle } = usePage();

  useEffect(() => {
    setTitle('RELEVAGE À PLAT');
  }, []);

  return (
    <Container maxWidth="md">
      <Box className={classes.contentMW}>
        <Typography className={classes.textBox}>Sélectionnez une rubrique :</Typography>
        <Box align="center" className={classes.flexBoxColumn}>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push('/formwork/configurators/handling-skills/handling')}
            variant="outlined"
            size="large"
          >
            Relevage à plat BOX
          </Button>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push('/formwork/configurators/handling-skills/handling-box')}
            variant="outlined"
            size="large"
          >
            Relevage à plat BOX R par bras
          </Button>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push('/formwork/configurators/handling-skills/handling-box-r')}
            variant="outlined"
            size="large"
          >
            Relevage à plat BOX R par manilles
          </Button>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push('/formwork/configurators/handling-skills/handling-box-rs')}
            variant="outlined"
            size="large"
          >
            Relevage à plat BOX RS
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Handling;

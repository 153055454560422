import React, { useEffect, useState } from 'react';
import { Box, Button, Container, makeStyles, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Request from '@/helpers/request.js';
import usePage from '@/hooks/usePage.js';
import mainStyles from '@/assets/js/mainStyles.js';

const useStyles = makeStyles(mainStyles);

// eslint-disable-next-line react/prop-types
export const BoxVideo_FAQ = ({ pathUrl = '', style }) => {
  const fixUrl = pathUrl?.toString()?.search('https://') !== -1 ? pathUrl : 'https://' + pathUrl;

  return (
    <Button
      onClick={() => window.open(pathUrl?.toString()?.search('youtu.be' || 'youtube.com') !== -1 ? pathUrl : fixUrl)}
      variant="outlined"
      size="large"
      // eslint-disable-next-line react/prop-types
      className={style.mainBtn}
    >
      FAQ
    </Button>
  );
};

const Description = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [module, setModule] = useState(null);
  const { setTitle } = usePage();

  useEffect(async () => {
    const fetchModule = async () => {
      const response = await Request.get(`/p3d2-modules/${id}`);
      setModule(response);
    };
    setTitle('DESCRIPTIF');
    await fetchModule();
  }, []);

  return (
    <Container maxWidth="md">
      <Box>
        <Box className={classes.descRoot}>
          <div className={classes.secTitle}>
            <span>{module?.title} </span>
          </div>
          <Typography className={classes.description}>{module?.description?.text ?? ''}</Typography>
          <div
            className={classes.videoInner}
            dangerouslySetInnerHTML={{ __html: `${module?.description?.iframe ?? ''}` }}
          />
          {/*<Card className={classes.imgCard}>*/}
          {/*  {module?.image && (*/}
          {/*    <CardMedia*/}
          {/*      className={classes.imgItem}*/}
          {/*      component="img"*/}
          {/*      image={`${Constants.BASE_URL}/${module.image.path} `}*/}
          {/*    />*/}
          {/*  )}*/}
          {/*</Card>*/}
        </Box>
        <BoxVideo_FAQ pathUrl={module?.usage?.assembly?.faq} style={classes} />
      </Box>
    </Container>
  );
};

export default Description;

// separator in matches is ";"
export const data = [
  // Formwork
  {
    text: 'Vous cherchez des informations sur;',
    href: '/formwork/select-category',
    name: 'COFFRAGE',
  },
  {
    text: 'Choisissez vos dimensions; Sélectionnez le type de BOX; Hauteur; Largeur',
    href: '/formwork/choice-box',
    name: 'LA BOX',
  },

  // Configurators
  {
    text: 'Configurateurs; Sélectionnez une rubrique',
    href: '/formwork/configurators',
    name: 'CONFIGURATEURS',
  },
  {
    text: 'Configurateurs; Stabilité; Choix du département; Indiquez la hauteur du train de banches; Indiquez la longueur du train de banches;',
    href: '/formwork/configurators/stabilisation',
    name: 'CONFIGURATEURS STABILITÉ',
  },
  {
    text: 'Configurateurs; Calcul de masse à lever; Indiquez la hauteur du train de banches; Indiquez la longueur du train de banches;',
    href: '/formwork/configurators/summing',
    name: 'CONFIGURATEURS CALCUL DE MASSE À LEVER',
  },
  {
    text: 'RELEVAGE À PLAT;',
    href: '/formwork/configurators/handling-skills',
    name: 'CONFIGURATEURS Relevage à plat',
  },
  {
    text: 'RELEVAGE À PLAT BOX;',
    href: '/formwork/configurators/handling-skills/handling',
    name: 'CONFIGURATEURS Relevage RELEVAGE À PLAT BOX',
  },
  {
    text: 'RELEVAGE À PLAT BOX R PAR BRAS;',
    href: '/formwork/configurators/handling-skills/handling-box',
    name: 'CONFIGURATEURS RELEVAGE À PLAT BOX R PAR BRAS',
  },
  {
    text: 'RELEVAGE À PLAT BOX R PAR MANILLES;',
    href: '/formwork/configurators/handling-skills/handling-box-r',
    name: 'CONFIGURATEURS RELEVAGE À PLAT BOX R PAR MANILLES',
  },
  {
    text: 'RELEVAGE À PLAT BOX RS;',
    href: '/formwork/configurators/handling-skills/handling-box-rs',
    name: 'CONFIGURATEURS RELEVAGE À PLAT BOX RS',
  },
  {
    text: 'Consignes d’élingage;',
    href: '/formwork/configurators/slinging',
    name: 'CONFIGURATEURS CONSIGNES D’ÉLINGAGE',
  },
  {
    text: 'CONSIGNES D’ÉLINGAGE BOX / BOX R PAR MANILLES; Retirez impérativement le lest avant d’effectuer le relevage Vérifiez l’angle d’élingage : 60° max;',
    href: '/formwork/configurators/slinging/box-box-r',
    name: 'CONFIGURATEURS CONSIGNES D’ÉLINGAGE BOX / BOX R PAR MANILLES',
  },
  {
    text: 'CONSIGNES D’ÉLINGAGE BOX RS PAR BRAS; Retirez impérativement le lest avant d’effectuer le relevage Vérifiez l’angle d’élingage : 60° max;',
    href: '/formwork/configurators/slinging/box-rs',
    name: 'CONFIGURATEURS CONSIGNES D’ÉLINGAGE BOX RS PAR BRAS',
  },
  {
    text: 'CONSIGNES D’ÉLINGAGE BOX R PAR BRAS; Retirez impérativement le lest avant d’effectuer le relevage Vérifiez l’angle d’élingage : 60° max;',
    href: '/formwork/configurators/slinging/box-r',
    name: 'CONFIGURATEURS CONSIGNES D’ÉLINGAGE BOX R PAR BRAS',
  },
  {
    text: 'CONSIGNES D’ÉLINGAGE BOX AVEC PORTIQUE; Retirez impérativement le lest avant d’effectuer le relevage Vérifiez l’angle d’élingage : 60° max;',
    href: '/formwork/configurators/slinging/box',
    name: 'CONFIGURATEURS CONSIGNES D’ÉLINGAGE BOX AVEC PORTIQUE',
  },

  // Accessories additional
  {
    text: 'Accessoires complémentaires;',
    href: '/formwork/accessories/additional',
    name: 'COFFRAGE Accessoires complémentaires',
  },

  {
    text: 'Accessoires; About pivotant;',
    href: '/formwork/accessories/additional/choice-additional/29',
    name: 'ACCESSOIRES About pivotant',
  },
  {
    text: "Accessoires; About pivotant; - 3 goupilles d'axe fil de 2.5 - 3 broches 12 x 105",
    href: '/formwork/accessories/additional/choice-additional/29/description',
    name: 'ACCESSOIRES About pivotant DESCRIPTIF',
  },
  {
    text: 'Accessoires; About pivotant; pour fermeture des voiles béton;',
    href: '/formwork/accessories/additional/choice-additional/29/implementation',
    name: 'ACCESSOIRES About pivotant MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Aimant pour alignement sur platine fil à plomb;',
    href: '/formwork/accessories/additional/choice-additional/19',
    name: 'ACCESSOIRES Aimant pour alignement sur platine fil à plomb',
  },
  {
    text: "Accessoires; Aimant pour alignement sur platine fil à plomb; 2 aimants d'alignement 1 cordeau",
    href: '/formwork/accessories/additional/choice-additional/19/description',
    name: 'ACCESSOIRES Aimant pour alignement sur platine fil à plomb DESCRIPTIF',
  },
  {
    text: "Accessoires; Aimant pour alignement sur platine fil à plomb; pour le réglage de l'alignement du train de banches;",
    href: '/formwork/accessories/additional/choice-additional/19/implementation',
    name: 'ACCESSOIRES Aimant pour alignement sur platine fil à plomb MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Barre à mine;',
    href: '/formwork/accessories/additional/choice-additional/43',
    name: 'ACCESSOIRES Barre à mine',
  },
  {
    text: 'Accessoires; Barre à mine; Plusieurs modèles de barres à mine : 1/ la barre à mine standard, 2/ la barre à mine à talon, 3/ La barre à mine LEBORGNE que vous trouverez dans le commerce',
    href: '/formwork/accessories/additional/choice-additional/43/description',
    name: 'ACCESSOIRES Barre à mine DESCRIPTIF',
  },
  {
    text: 'Accessoires; Barre à mine; Plusieurs modèles de barres à mine : 1/ la barre à mine standard, 2/ la barre à mine à talon, 3/ La barre à mine LEBORGNE que vous trouverez dans le commerce',
    href: '/formwork/accessories/additional/choice-additional/43/implementation',
    name: 'ACCESSOIRES Barre à mine MISE EN ŒUVRE',
  },

  {
    text: "Accessoires; Barrette d'about de voile;",
    href: '/formwork/accessories/additional/choice-additional/30',
    name: "ACCESSOIRES Barrette d'about de voile",
  },
  {
    text: "Accessoires; Barrette d'about de voile; pour voile droit et poteau;",
    href: '/formwork/accessories/additional/choice-additional/30/description',
    name: "ACCESSOIRES Barrette d'about de voile DESCRIPTIF",
  },
  {
    text: "Accessoires; Barrette d'about de voile;",
    href: '/formwork/accessories/additional/choice-additional/30/implementation',
    name: "ACCESSOIRES Barrette d'about de voile MISE EN ŒUVRE",
  },

  {
    text: 'Accessoires; Bloc entretoise détensionneur;',
    href: '/formwork/accessories/additional/choice-additional/56',
    name: 'ACCESSOIRES Bloc entretoise détensionneur',
  },
  {
    text: 'Accessoires; Bloc entretoise détensionneur; - Goupille d’axe fil de 2,5 Broche Ø 12 x 105;',
    href: '/formwork/accessories/additional/choice-additional/56/description',
    name: 'ACCESSOIRES Bloc entretoise détensionneur DESCRIPTIF',
  },
  {
    text: 'Accessoires; Bloc entretoise détensionneur; - Goupille d’axe fil de 2,5 - Broche Ø 12 x 105; ',
    href: '/formwork/accessories/additional/choice-additional/56/implementation',
    name: 'ACCESSOIRES Bloc entretoise détensionneur MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Bloc entretoise haut avec maille;',
    href: '/formwork/accessories/additional/choice-additional/47',
    name: 'ACCESSOIRES Bloc entretoise haut avec maille',
  },
  {
    text: 'Accessoires; Bloc entretoise haut avec maille;- Goupille d’axe fil de 2,5 - Goupille V3,2 L 36 - Broche Ø 12 x 105 - Bloc haut moulé - Manille + boulon Ø 22 L 186;',
    href: '/formwork/accessories/additional/choice-additional/47/description',
    name: 'ACCESSOIRES Bloc entretoise haut avec maille DESCRIPTIF',
  },
  {
    text: 'Accessoires; Bloc entretoise détensionneur;- Goupille d’axe fil de 2,5 - Goupille V3,2 L 36 - Broche Ø 12 x 105 - Bloc haut moulé - Manille + boulon Ø 22 L 186; ',
    href: '/formwork/accessories/additional/choice-additional/47/implementation',
    name: 'ACCESSOIRES Bloc entretoise haut avec maille MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Bloc entretoise haut avec maille (PX H 3,00);',
    href: '/formwork/accessories/additional/choice-additional/48',
    name: 'ACCESSOIRES Bloc entretoise haut avec maille (PX H 3,00)',
  },
  {
    text: 'Accessoires; Bloc entretoise haut avec maille (PX H 3,00); - Goupille V3,2 L 36 - Bloc haut  pour PX H 3,00 - Manille de levage - Boulon renforcé Ø 22 x 130 - Boulon renforcé Ø 22 x 160;',
    href: '/formwork/accessories/additional/choice-additional/48/description',
    name: 'ACCESSOIRES Bloc entretoise haut avec maille (PX H 3,00) DESCRIPTIF',
  },
  {
    text: 'Accessoires; Bloc entretoise haut avec maille (PX H 3,00); - Goupille V3,2 L 36 - Bloc haut  pour PX H 3,00 - Manille de levage - Boulon renforcé Ø 22 x 130 - Boulon renforcé Ø 22 x 160;',
    href: '/formwork/accessories/additional/choice-additional/48/implementation',
    name: 'ACCESSOIRES Bloc entretoise haut avec maille (PX H 3,00) MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Bloc intermédiaire universel;',
    href: '/formwork/accessories/additional/choice-additional/57',
    name: 'ACCESSOIRES Bloc intermédiaire universel',
  },
  {
    text: 'Accessoires; Bloc entretoise haut avec maille (PX H 3,00); - Goupille d’axe fil de 2,5 - Broche Ø 12 x 105;',
    href: '/formwork/accessories/additional/choice-additional/57/description',
    name: 'ACCESSOIRES Bloc intermédiaire universel DESCRIPTIF',
  },
  {
    text: 'Accessoires; Bloc entretoise haut avec maille (PX H 3,00); - Goupille d’axe fil de 2,5 - Broche Ø 12 x 105;',
    href: '/formwork/accessories/additional/choice-additional/57/implementation',
    name: 'ACCESSOIRES Bloc intermédiaire universel MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Bloc magnétique;',
    href: '/formwork/accessories/additional/choice-additional/54',
    name: 'ACCESSOIRES Bloc magnétique',
  },
  {
    text: 'Accessoires; Bloc magnétique; 2 options : avec fixation cône et sans fixation cône;',
    href: '/formwork/accessories/additional/choice-additional/54/description',
    name: 'ACCESSOIRES Bloc magnétique DESCRIPTIF',
  },
  {
    text: 'Accessoires; Bloc magnétique; pour la fixation de vos réservations sur la tôle coffrante;',
    href: '/formwork/accessories/additional/choice-additional/54/implementation',
    name: 'ACCESSOIRES Bloc magnétique MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Bouchon intermédiaire pour bloc entretoise;',
    href: '/formwork/accessories/additional/choice-additional/33',
    name: 'ACCESSOIRES Bouchon intermédiaire pour bloc entretoise',
  },
  {
    text: 'Accessoires; Bouchon intermédiaire pour bloc entretoise; - Broche Ø 12 x 105 - Goupille axe fil de 2,5;',
    href: '/formwork/accessories/additional/choice-additional/33/description',
    name: 'ACCESSOIRES Bouchon intermédiaire pour bloc entretoise DESCRIPTIF',
  },
  {
    text: 'Accessoires; Bouchon intermédiaire pour bloc entretoise; - Broche Ø 12 x 105 - Goupille axe fil de 2,5;',
    href: '/formwork/accessories/additional/choice-additional/33/implementation',
    name: 'ACCESSOIRES Bouchon intermédiaire pour bloc entretoise MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Capteur de pression;',
    href: '/formwork/accessories/additional/choice-additional/59',
    name: 'ACCESSOIRES Capteur de pression',
  },
  {
    text: 'Accessoires; Capteur de pression;',
    href: '/formwork/accessories/additional/choice-additional/59/description',
    name: 'ACCESSOIRES Capteur de pression DESCRIPTIF',
  },
  {
    text: 'Accessoires; Capteur de pression;',
    href: '/formwork/accessories/additional/choice-additional/59/implementation',
    name: 'ACCESSOIRES Capteur de pression MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Cellule de charge;',
    href: '/formwork/accessories/additional/choice-additional/40',
    name: 'ACCESSOIRES Cellule de charge',
  },
  {
    text: 'Accessoires; Cellule de charge; pour contrôle de la pression;',
    href: '/formwork/accessories/additional/choice-additional/40/description',
    name: 'ACCESSOIRES Cellule de charge DESCRIPTIF',
  },
  {
    text: 'Accessoires; Cellule de charge; pour contrôle de la pression;',
    href: '/formwork/accessories/additional/choice-additional/40/implementation',
    name: 'ACCESSOIRES Cellule de charge MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Cornière de reprise;',
    href: '/formwork/accessories/additional/choice-additional/34',
    name: 'ACCESSOIRES Cornière de reprise',
  },
  {
    text: 'Accessoires; Cornière de reprise; pour contrôle de la pression; H 0,50 à 2,80 livré avec : 2 rondelles M12 2 boulons HM 12x35 H 3,30 livré avec : 3 rondelles M12 3 boulons HM 12x35;',
    href: '/formwork/accessories/additional/choice-additional/34/description',
    name: 'ACCESSOIRES Cornière de reprise DESCRIPTIF',
  },
  {
    text: 'Accessoires; Cornière de reprise;',
    href: '/formwork/accessories/additional/choice-additional/34/implementation',
    name: 'ACCESSOIRES Cornière de reprise MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Dispositif de relevage (BOLS);',
    href: '/formwork/accessories/additional/choice-additional/41',
    name: 'ACCESSOIRES Dispositif de relevage (BOLS)',
  },
  {
    text: 'Accessoires; Dispositif de relevage (BOLS);',
    href: '/formwork/accessories/additional/choice-additional/41/description',
    name: 'ACCESSOIRES Dispositif de relevage (BOLS) DESCRIPTIF',
  },
  {
    text: 'Accessoires; Dispositif de relevage (BOLS);',
    href: '/formwork/accessories/additional/choice-additional/41/implementation',
    name: 'ACCESSOIRES Dispositif de relevage (BOLS) MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Ferme de reprise 1 face;',
    href: '/formwork/accessories/additional/choice-additional/35',
    name: 'ACCESSOIRES Ferme de reprise 1 face',
  },
  {
    text: 'Accessoires; Ferme de reprise 1 face; 2 rondelles M16 2 écrous HU M16 2 tiges M16 pour ferme;',
    href: '/formwork/accessories/additional/choice-additional/35/description',
    name: 'ACCESSOIRES Ferme de reprise 1 face DESCRIPTIF',
  },
  {
    text: 'Accessoires; Ferme de reprise 1 face;',
    href: '/formwork/accessories/additional/choice-additional/35/implementation',
    name: 'ACCESSOIRES Ferme de reprise 1 face MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Mannequin de porte;',
    href: '/formwork/accessories/additional/choice-additional/50',
    name: 'ACCESSOIRES Mannequin de porte',
  },
  {
    text: 'Accessoires; Mannequin de porte; 4 rondelles M12 4 boulons HM12 x 25 Poids : 105,5 kg;',
    href: '/formwork/accessories/additional/choice-additional/50/description',
    name: 'ACCESSOIRES Mannequin de porte DESCRIPTIF',
  },
  {
    text: 'Accessoires; Mannequin de porte;',
    href: '/formwork/accessories/additional/choice-additional/50/implementation',
    name: 'ACCESSOIRES Mannequin de porte MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Palette de stockage pour accessoires;',
    href: '/formwork/accessories/additional/choice-additional/46',
    name: 'ACCESSOIRES Palette de stockage pour accessoires',
  },
  {
    text: 'Accessoires; Palette de stockage pour accessoires; Dimensions ext.162x121x75 cm PTAC = 1600 kg;',
    href: '/formwork/accessories/additional/choice-additional/46/description',
    name: 'ACCESSOIRES Palette de stockage pour accessoires DESCRIPTIF',
  },
  {
    text: 'Accessoires; Palette de stockage pour accessoires; Dimensions ext.162x121x75 cm PTAC = 1600 kg',
    href: '/formwork/accessories/additional/choice-additional/46/implementation',
    name: 'ACCESSOIRES Palette de stockage pour accessoires MISE EN ŒUVRE',
  },

  {
    text: "Accessoires; Panneau d'appoint 60 à 300;",
    href: '/formwork/accessories/additional/choice-additional/36',
    name: "ACCESSOIRES Panneau d'appoint 60 à 300",
  },
  {
    text: "Accessoires; Panneau d'appoint 60 à 300; H 1,00 et 1,50 livrés avec : 2 goupilles fil 2,5 2 broches Ø 16x52 1 éclisse 2 glissières H 1,50 / 1 glissière H 1,00 2 vis de serrage L 200 H 1,50 / 1 vis de serrage H 1,00 H 2,80 et 3,30 livrés avec : 2 glissières 2 vis de serrage L 2,00",
    href: '/formwork/accessories/additional/choice-additional/36/description',
    name: "ACCESSOIRES Panneau d'appoint 60 à 300 DESCRIPTIF",
  },
  {
    text: "Accessoires; Panneau d'appoint 60 à 300",
    href: '/formwork/accessories/additional/choice-additional/36/implementation',
    name: "ACCESSOIRES Panneau d'appoint 60 à 300 MISE EN ŒUVRE",
  },

  {
    text: 'Accessoires; Passerelle de contournement (Position haute);',
    href: '/formwork/accessories/additional/choice-additional/26',
    name: 'ACCESSOIRES Passerelle de contournement (Position haute)',
  },
  {
    text: 'Accessoires; Passerelle de contournement (Position haute);',
    href: '/formwork/accessories/additional/choice-additional/26/description',
    name: 'ACCESSOIRES Passerelle de contournement (Position haute) DESCRIPTIF',
  },
  {
    text: 'Accessoires; Passerelle de contournement (Position haute)',
    href: '/formwork/accessories/additional/choice-additional/26/implementation',
    name: 'ACCESSOIRES Passerelle de contournement (Position haute) MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Passerelle de contournement (Position basse);',
    href: '/formwork/accessories/additional/choice-additional/20',
    name: 'ACCESSOIRES Passerelle de contournement (Position basse)',
  },
  {
    text: 'Accessoires; Passerelle de contournement (Position basse);',
    href: '/formwork/accessories/additional/choice-additional/20/description',
    name: 'ACCESSOIRES Passerelle de contournement (Position basse) DESCRIPTIF',
  },
  {
    text: 'Accessoires; Passerelle de contournement (Position basse)',
    href: '/formwork/accessories/additional/choice-additional/20/implementation',
    name: 'ACCESSOIRES Passerelle de contournement (Position basse) MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Plat de compensation;',
    href: '/formwork/accessories/additional/choice-additional/37',
    name: 'ACCESSOIRES Plat de compensation',
  },
  {
    text: 'Accessoires; Plat de compensation; - H 0,50 à 2,80 : pas de boulonnerie - H 3,30 et 3,80 : L 30 à 50 livré avec : 3 rondelles M12 3 boulons HM 12x70 L 60 livré avec : 3 pièces assemblages;',
    href: '/formwork/accessories/additional/choice-additional/37/description',
    name: 'ACCESSOIRES Plat de compensation DESCRIPTIF',
  },
  {
    text: 'Accessoires; Plat de compensation;',
    href: '/formwork/accessories/additional/choice-additional/37/implementation',
    name: 'ACCESSOIRES Plat de compensation MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Plat de décoffrage;',
    href: '/formwork/accessories/additional/choice-additional/38',
    name: 'ACCESSOIRES Plat de décoffrage',
  },
  {
    text: 'Accessoires; Plat de décoffrage; Livré avec : Vis TF 15x50 Rondelles M12 Ecrou HU 12',
    href: '/formwork/accessories/additional/choice-additional/38/description',
    name: 'ACCESSOIRES Plat de décoffrage DESCRIPTIF',
  },
  {
    text: 'Accessoires; Plat de décoffrage;',
    href: '/formwork/accessories/additional/choice-additional/38/implementation',
    name: 'ACCESSOIRES Plat de décoffrage MISE EN ŒUVRE',
  },

  {
    text: "Accessoires; Réglage de l'aplomb;",
    href: '/formwork/accessories/additional/choice-additional/18',
    name: "ACCESSOIRES Réglage de l'aplomb",
  },
  {
    text: "Accessoires; Réglage de l'aplomb; Inclinomètre Fil à plomb",
    href: '/formwork/accessories/additional/choice-additional/18/description',
    name: "ACCESSOIRES Réglage de l'aplomb DESCRIPTIF",
  },
  {
    text: "Accessoires; Réglage de l'aplomb;",
    href: '/formwork/accessories/additional/choice-additional/18/implementation',
    name: "ACCESSOIRES Réglage de l'aplomb MISE EN ŒUVRE",
  },

  {
    text: 'Accessoires; Règle de correction 0,50 à 3,30;',
    href: '/formwork/accessories/additional/choice-additional/39',
    name: 'ACCESSOIRES Règle de correction 0,50 à 3,30',
  },
  {
    text: 'Accessoires; Règle de correction 0,50 à 3,30; Tige filet rond Ø 24  L 219 Platine de fixation 90x90x8 Ø 26',
    href: '/formwork/accessories/additional/choice-additional/39/description',
    name: 'ACCESSOIRES Règle de correction 0,50 à 3,30 DESCRIPTIF',
  },
  {
    text: 'Accessoires; Règle de correction 0,50 à 3,30;',
    href: '/formwork/accessories/additional/choice-additional/39/implementation',
    name: 'ACCESSOIRES Règle de correction 0,50 à 3,30 MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Serre-joint pour extrémité de voile;',
    href: '/formwork/accessories/additional/choice-additional/28',
    name: 'ACCESSOIRES Serre-joint pour extrémité de voile',
  },
  {
    text: 'Accessoires; Serre-joint pour extrémité de voile; Montage sur rehausse (voile ep. 35 cm max)',
    href: '/formwork/accessories/additional/choice-additional/28/description',
    name: 'ACCESSOIRES Serre-joint pour extrémité de voile DESCRIPTIF',
  },
  {
    text: 'Accessoires; Serre-joint pour extrémité de voile;',
    href: '/formwork/accessories/additional/choice-additional/28/implementation',
    name: 'ACCESSOIRES Serre-joint pour extrémité de voile MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Stockage des tiges entretoises et des outils;',
    href: '/formwork/accessories/additional/choice-additional/44',
    name: 'ACCESSOIRES Stockage des tiges entretoises et des outils',
  },
  {
    text: 'Accessoires; Stockage des tiges entretoises et des outils;',
    href: '/formwork/accessories/additional/choice-additional/44/description',
    name: 'ACCESSOIRES Stockage des tiges entretoises et des outils DESCRIPTIF',
  },
  {
    text: 'Accessoires; Stockage des tiges entretoises et des outils;',
    href: '/formwork/accessories/additional/choice-additional/44/implementation',
    name: 'ACCESSOIRES Stockage des tiges entretoises et des outils MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Tiges entretoises;',
    href: '/formwork/accessories/additional/choice-additional/45',
    name: 'ACCESSOIRES Tiges entretoises',
  },
  {
    text: 'Accessoires; Tiges entretoises; - Blocs entretoises - Tiges entretoises - Cône magnétique Ø 23;',
    href: '/formwork/accessories/additional/choice-additional/45/description',
    name: 'ACCESSOIRES Tiges entretoises DESCRIPTIF',
  },
  {
    text: 'Accessoires; Tiges entretoises;',
    href: '/formwork/accessories/additional/choice-additional/45/implementation',
    name: 'ACCESSOIRES Tiges entretoises MISE EN ŒUVRE',
  },

  // Accessories stability
  {
    text: 'Accessoires de stabilité;',
    href: '/formwork/accessories/stability',
    name: 'COFFRAGE Accessoires de stabilité',
  },

  {
    text: 'Accessoires; Ancrage au sol des béquilles;',
    href: '/formwork/accessories/stability/choice-stability/14',
    name: 'ACCESSOIRES Ancrage au sol des béquilles',
  },
  {
    text: 'Accessoires; Ancrage au sol des béquilles;',
    href: '/formwork/accessories/stability/choice-stability/14/description',
    name: 'ACCESSOIRES Ancrage au sol des béquilles DESCRIPTIF',
  },
  {
    text: 'Accessoires; Ancrage au sol des béquilles;',
    href: '/formwork/accessories/stability/choice-stability/14/implementation',
    name: 'ACCESSOIRES Ancrage au sol des béquilles MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Ancrage au sol des stabilisateurs;',
    href: '/formwork/accessories/stability/choice-stability/15',
    name: 'ACCESSOIRES Ancrage au sol des stabilisateurs',
  },
  {
    text: 'Accessoires; Ancrage au sol des stabilisateurs; Chape de stab pour ancrage au sol GALVA;',
    href: '/formwork/accessories/stability/choice-stability/15/description',
    name: 'ACCESSOIRES Ancrage au sol des stabilisateurs béquilles DESCRIPTIF',
  },
  {
    text: 'Accessoires; Ancrage au sol des stabilisateurs; vidéo bientôt disponible;',
    href: '/formwork/accessories/stability/choice-stability/15/implementation',
    name: 'ACCESSOIRES Ancrage au sol des stabilisateurs MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Bracons Bas;',
    href: '/formwork/accessories/stability/choice-stability/11',
    name: 'ACCESSOIRES Bracons Bas',
  },
  {
    text: 'Accessoires; Bracons Bas;',
    href: '/formwork/accessories/stability/choice-stability/11/description',
    name: 'ACCESSOIRES Bracons Bas DESCRIPTIF',
  },
  {
    text: 'Accessoires; Bracons Bas;',
    href: '/formwork/accessories/stability/choice-stability/11/implementation',
    name: 'ACCESSOIRES Bracons Bas MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Lest de stabilité standard;',
    href: '/formwork/accessories/stability/choice-stability/12',
    name: 'ACCESSOIRES Lest de stabilité standard',
  },
  {
    text: 'Accessoires; Lest de stabilité standard;',
    href: '/formwork/accessories/stability/choice-stability/12/description',
    name: 'ACCESSOIRES Lest de stabilité standard DESCRIPTIF',
  },
  {
    text: 'Accessoires; Lest de stabilité standard;',
    href: '/formwork/accessories/stability/choice-stability/12/implementation',
    name: 'ACCESSOIRES Lest de stabilité standard MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Lest RS (400 kg);',
    href: '/formwork/accessories/stability/choice-stability/13',
    name: 'ACCESSOIRES Lest RS (400 kg)',
  },
  {
    text: 'Accessoires; Lest RS (400 kg); pour PX l 1,20;',
    href: '/formwork/accessories/stability/choice-stability/13/description',
    name: 'ACCESSOIRES Lest RS (400 kg) DESCRIPTIF',
  },
  {
    text: 'Accessoires; Lest RS (400 kg);',
    href: '/formwork/accessories/stability/choice-stability/13/implementation',
    name: 'ACCESSOIRES Lest RS (400 kg) MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Lest RS (780 kg);',
    href: '/formwork/accessories/stability/choice-stability/27',
    name: 'ACCESSOIRES Lest RS (780 kg)',
  },
  {
    text: 'Accessoires; Lest RS (780 kg); pour PX l 2,40;',
    href: '/formwork/accessories/stability/choice-stability/27/description',
    name: 'ACCESSOIRES Lest RS (780 kg) DESCRIPTIF',
  },
  {
    text: 'Accessoires; Lest RS (780 kg);',
    href: '/formwork/accessories/stability/choice-stability/27/implementation',
    name: 'ACCESSOIRES Lest RS (780 kg) MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Palette de stockage;',
    href: '/formwork/accessories/stability/choice-stability/21',
    name: 'ACCESSOIRES Palette de stockage',
  },
  {
    text: 'Accessoires; Palette de stockage; Dimensions ext. 162x121x75 cm PTAC = 1600 kg;',
    href: '/formwork/accessories/stability/choice-stability/21/description',
    name: 'ACCESSOIRES Palette de stockage DESCRIPTIF',
  },
  {
    text: 'Accessoires; Palette de stockage;',
    href: '/formwork/accessories/stability/choice-stability/21/implementation',
    name: 'ACCESSOIRES Palette de stockage MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Palette de stockage portique;',
    href: '/formwork/accessories/stability/choice-stability/24',
    name: 'ACCESSOIRES Palette de stockage portique',
  },
  {
    text: 'Accessoires; Palette de stockage portique; Dimensions ext. 2253x2233x1747 cm PTAC = 1840 kg',
    href: '/formwork/accessories/stability/choice-stability/24/description',
    name: 'ACCESSOIRES Palette de stockage portique DESCRIPTIF',
  },
  {
    text: 'Accessoires; Palette de stockage portique;',
    href: '/formwork/accessories/stability/choice-stability/24/implementation',
    name: 'ACCESSOIRES Palette de stockage portique MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Portique;',
    href: '/formwork/accessories/stability/choice-stability/23',
    name: 'ACCESSOIRES Portique',
  },
  {
    text: 'Accessoires; Portique;',
    href: '/formwork/accessories/stability/choice-stability/23/description',
    name: 'ACCESSOIRES Portique DESCRIPTIF',
  },
  {
    text: 'Accessoires; Portique;',
    href: '/formwork/accessories/stability/choice-stability/23/implementation',
    name: 'ACCESSOIRES Portique MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Portique hydraulique;',
    href: '/formwork/accessories/stability/choice-stability/58',
    name: 'ACCESSOIRES Portique hydraulique',
  },
  {
    text: 'Accessoires; Portique hydraulique;',
    href: '/formwork/accessories/stability/choice-stability/58/description',
    name: 'ACCESSOIRES Portique hydraulique DESCRIPTIF',
  },
  {
    text: 'Accessoires; Portique hydraulique;',
    href: '/formwork/accessories/stability/choice-stability/58/implementation',
    name: 'ACCESSOIRES Portique hydraulique MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Stabétais;',
    href: '/formwork/accessories/stability/choice-stability/10',
    name: 'ACCESSOIRES Stabétais',
  },
  {
    text: 'Accessoires; Stabétais;',
    href: '/formwork/accessories/stability/choice-stability/10/description',
    name: 'ACCESSOIRES Stabétais DESCRIPTIF',
  },
  {
    text: 'Accessoires; Stabétais;',
    href: '/formwork/accessories/stability/choice-stability/10/implementation',
    name: 'ACCESSOIRES Stabétais MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Stabilisateur T1;',
    href: '/formwork/accessories/stability/choice-stability/4',
    name: 'ACCESSOIRES Stabilisateur T1',
  },
  {
    text: 'Accessoires; Stabilisateur T1;',
    href: '/formwork/accessories/stability/choice-stability/4/description',
    name: 'ACCESSOIRES Stabilisateur T1 DESCRIPTIF',
  },
  {
    text: 'Accessoires; Stabilisateur T1;',
    href: '/formwork/accessories/stability/choice-stability/4/implementation',
    name: 'ACCESSOIRES Stabilisateur T1 MISE EN ŒUVRE',
  },

  {
    text: 'Accessoires; Stabilisateur T2;',
    href: '/formwork/accessories/stability/choice-stability/60',
    name: 'ACCESSOIRES Stabilisateur T2',
  },
  {
    text: 'Accessoires; Stabilisateur T2;',
    href: '/formwork/accessories/stability/choice-stability/60/description',
    name: 'ACCESSOIRES Stabilisateur T2 DESCRIPTIF',
  },
  {
    text: 'Accessoires; Stabilisateur T2;',
    href: '/formwork/accessories/stability/choice-stability/60/implementation',
    name: 'ACCESSOIRES Stabilisateur T2 MISE EN ŒUVRE',
  },

  // BOX
  {
    text: '(3,00 x 2,40) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/91',
    name: 'BOX (3,00 x 2,40)',
  },
  {
    text: '(3,00 x 1,20) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/92',
    name: 'BOX (3,00 x 1,20)',
  },
  {
    text: '(3,00 x 0,90) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/93',
    name: 'BOX (3,00 x 0,90)',
  },
  {
    text: '(3,00 x 0,60) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/94',
    name: 'BOX (3,00 x 0,60)',
  },
  {
    text: '(3,00 x 0,30) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/95',
    name: 'BOX (3,00 x 0,30)',
  },
  {
    text: '(3,00 x 1,14 x 1,14) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/118',
    name: 'BOX (3,00 x 1,14 x 1,14)',
  },
  {
    text: '(2,80 x 2,40) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/80',
    name: 'BOX (2,80 x 2,40)',
  },
  {
    text: '(2,80 x 1,20) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/81',
    name: 'BOX (2,80 x 1,20)',
  },
  {
    text: '(2,80 x 0,90) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/96',
    name: 'BOX (2,80 x 0,90)',
  },
  {
    text: '(2,80 x 0,60) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/97',
    name: 'BOX (2,80 x 0,60)',
  },
  {
    text: '(2,80 x 0,30) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/98',
    name: 'BOX (2,80 x 0,30)',
  },
  {
    text: '(2,80 x 1,14 x 1,14) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/114',
    name: 'BOX (2,80 x 1,14 x 1,14)',
  },
  {
    text: '(1,50 x 2,40) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/99',
    name: 'BOX (1,50 x 2,40)',
  },
  {
    text: '(1,50 x 1,20) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/100',
    name: 'BOX (1,50 x 1,20)',
  },
  {
    text: '(1,50 x 0,90) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/101',
    name: 'BOX (1,50 x 0,90)',
  },
  {
    text: '(1,50 x 0,60) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/102',
    name: 'BOX (1,50 x 0,60)',
  },
  {
    text: '(1,50 x 0,30) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/103',
    name: 'BOX (1,50 x 0,30)',
  },
  {
    text: '(1,50 x 1,14 x 1,14) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/115',
    name: 'BOX (1,50 x 1,14 x 1,14)',
  },
  {
    text: '(1,00 x 2,40) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/104',
    name: 'BOX (1,00 x 2,40)',
  },
  {
    text: '(1,00 x 1,20) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/105',
    name: 'BOX (1,00 x 1,20)',
  },
  {
    text: '(1,00 x 0,90) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/106',
    name: 'BOX (1,00 x 0,90)',
  },
  {
    text: '(1,00 x 0,60) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/107',
    name: 'BOX (1,00 x 0,60)',
  },
  {
    text: '(1,00 x 0,30) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/108',
    name: 'BOX (1,00 x 0,30)',
  },
  {
    text: '(1,00 x 1,14 x 1,14) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/116',
    name: 'BOX (1,00 x 1,14 x 1,14)',
  },
  {
    text: '(0,50 x 2,40) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/109',
    name: 'BOX (0,50 x 2,40)',
  },
  {
    text: '(0,50 x 1,20) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/110',
    name: 'BOX (0,50 x 1,20)',
  },
  {
    text: '(0,50 x 0,90) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/111',
    name: 'BOX (0,50 x 0,90)',
  },
  {
    text: '(0,50 x 0,60) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/112',
    name: 'BOX (0,50 x 0,60)',
  },
  {
    text: '(0,50 x 0,30) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/113',
    name: 'BOX (0,50 x 0,30)',
  },
  {
    text: '(0,50 x 1,14 x 1,14) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/select-subcategory/25/measures/117',
    name: 'BOX (0,50 x 1,14 x 1,14)',
  },

  // BOX R
  {
    text: '(3,00 x 2,40) Banche avec structure caissonnée anti-corrosion. Bras de levage intégrés;',
    href: '/formwork/select-subcategory/26/measures/82',
    name: 'BOX R (3,00 x 2,40)',
  },
  {
    text: '(3,00 x 1,20) Banche avec structure caissonnée anti-corrosion. Bras de levage intégrés;',
    href: '/formwork/select-subcategory/26/measures/83',
    name: 'BOX R (3,00 x 1,20)',
  },
  {
    text: '(2,80 x 2,40) Banche avec structure caissonnée anti-corrosion. Bras de levage intégrés;',
    href: '/formwork/select-subcategory/26/measures/84',
    name: 'BOX R (2,80 x 2,40)',
  },
  {
    text: '(2,80 x 1,20) Banche avec structure caissonnée anti-corrosion. Bras de levage intégrés;',
    href: '/formwork/select-subcategory/26/measures/85',
    name: 'BOX R (2,80 x 1,20)',
  },

  // BOX RS
  {
    text: '(3,00 x 2,40) Banche avec structure caissonnée anti-corrosion. Bras de levage et lest intégrés;',
    href: '/formwork/select-subcategory/27/measures/86',
    name: 'BOX RS (3,00 x 2,40)',
  },
  {
    text: '(3,00 x 1,20) Banche avec structure caissonnée anti-corrosion. Bras de levage et lest intégrés;',
    href: '/formwork/select-subcategory/27/measures/87',
    name: 'BOX RS (3,00 x 1,20)',
  },
  {
    text: '(3,00 x 1,14 x 1,14) Banche avec structure caissonnée anti-corrosion. Bras de levage et lest intégrés;',
    href: '/formwork/select-subcategory/27/measures/119',
    name: 'BOX RS (3,00 x 1,14 x 1,14)',
  },
  {
    text: '(2,80 x 2,40) Banche avec structure caissonnée anti-corrosion. Bras de levage et lest intégrés;',
    href: '/formwork/select-subcategory/27/measures/88',
    name: 'BOX RS (2,80 x 2,40)',
  },
  {
    text: '(2,80 x 1,20) Banche avec structure caissonnée anti-corrosion. Bras de levage et lest intégrés;',
    href: '/formwork/select-subcategory/27/measures/89',
    name: 'BOX RS (2,80 x 1,20)',
  },
  {
    text: '(2,80 x 1,14 x 1,14) Banche avec structure caissonnée anti-corrosion. Bras de levage et lest intégrés;',
    href: '/formwork/select-subcategory/27/measures/90',
    name: 'BOX RS (2,80 x 1,14 x 1,14)',
  },

  // BOX description
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/91',
    name: 'BOX (3,00 x 2,40) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/92',
    name: 'BOX (3,00 x 1,20) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/93',
    name: 'BOX (3,00 x 0,90) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/94',
    name: 'BOX (3,00 x 0,60) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/95',
    name: 'BOX (3,00 x 0,30) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/118',
    name: 'BOX (3,00 x 1,14 x 1,14) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/80',
    name: 'BOX (2,80 x 2,40) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/81',
    name: 'BOX (2,80 x 1,20) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/96',
    name: 'BOX (2,80 x 0,90) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/97',
    name: 'BOX (2,80 x 0,60) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/98',
    name: 'BOX (2,80 x 0,30) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/114',
    name: 'BOX (2,80 x 1,14 x 1,14) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/99',
    name: 'BOX (1,50 x 2,40) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/100',
    name: 'BOX (1,50 x 1,20) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/101',
    name: 'BOX (1,50 x 0,90) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/102',
    name: 'BOX (1,50 x 0,60) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/103',
    name: 'BOX (1,50 x 0,30) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/115',
    name: 'BOX (1,50 x 1,14 x 1,14) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/104',
    name: 'BOX (1,00 x 2,40) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/105',
    name: 'BOX (1,00 x 1,20) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/106',
    name: 'BOX (1,00 x 0,90) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/107',
    name: 'BOX (1,00 x 0,60) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/108',
    name: 'BOX (1,00 x 0,30) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/116',
    name: 'BOX (1,00 x 1,14 x 1,14) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/109',
    name: 'BOX (0,50 x 2,40) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/110',
    name: 'BOX (0,50 x 1,20) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/111',
    name: 'BOX (0,50 x 0,90) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/112',
    name: 'BOX (0,50 x 0,60) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/113',
    name: 'BOX (0,50 x 0,30) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/25/measures/117',
    name: 'BOX (0,50 x 1,14 x 1,14) DESCRIPTIF 3D',
  },

  // BOX R description
  {
    text: 'Découvrez en 3D les composants de votre BOX R.\n Utiliser le bouton "?" si vous avez besoin d\'aide.; ',
    href: '/formwork/description-box/26/measures/82',
    name: 'BOX R (3,00 x 2,40) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX R. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/26/measures/83',
    name: 'BOX R (3,00 x 1,20) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX R. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/26/measures/84',
    name: 'BOX R (2,80 x 2,40) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX R. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/26/measures/85',
    name: 'BOX R (2,80 x 1,20) DESCRIPTIF 3D',
  },

  // BOX RS description
  {
    text: 'Découvrez en 3D les composants de votre BOX RS. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/27/measures/86',
    name: 'BOX RS (3,00 x 2,40) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX RS. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/27/measures/87',
    name: 'BOX RS (3,00 x 1,20) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX RS. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/27/measures/119',
    name: 'BOX RS (3,00 x 1,14 x 1,14) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX RS. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/27/measures/88',
    name: 'BOX RS (2,80 x 2,40) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX RS. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/27/measures/89',
    name: 'BOX RS (2,80 x 1,20) DESCRIPTIF 3D',
  },
  {
    text: 'Découvrez en 3D les composants de votre BOX RS. Utiliser le bouton "?" si vous avez besoin d\'aide; ',
    href: '/formwork/description-box/27/measures/90',
    name: 'BOX RS (2,80 x 1,14 x 1,14) DESCRIPTIF 3D',
  },

  // BOX implementation
  {
    text: '(3,00 x 2,40) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/91',
    name: 'BOX (3,00 x 2,40) MISE EN ŒUVRE',
  },
  {
    text: '(3,00 x 1,20) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/92',
    name: 'BOX (3,00 x 1,20) MISE EN ŒUVRE',
  },
  {
    text: '(3,00 x 0,90) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/93',
    name: 'BOX (3,00 x 0,90) MISE EN ŒUVRE',
  },
  {
    text: '(3,00 x 0,60) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/94',
    name: 'BOX (3,00 x 0,60) MISE EN ŒUVRE',
  },
  {
    text: '(3,00 x 0,30) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/95',
    name: 'BOX (3,00 x 0,30) MISE EN ŒUVRE',
  },
  {
    text: '(3,00 x 1,14 x 1,14) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/118',
    name: 'BOX (3,00 x 1,14 x 1,14) MISE EN ŒUVRE',
  },
  {
    text: '(2,80 x 2,40) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/80',
    name: 'BOX (2,80 x 2,40) MISE EN ŒUVRE',
  },
  {
    text: '(2,80 x 1,20) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/81',
    name: 'BOX (2,80 x 1,20) MISE EN ŒUVRE',
  },
  {
    text: '(2,80 x 0,90) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/96',
    name: 'BOX (2,80 x 0,90) MISE EN ŒUVRE',
  },
  {
    text: '(2,80 x 0,60) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/97',
    name: 'BOX (2,80 x 0,60) MISE EN ŒUVRE',
  },
  {
    text: '(2,80 x 0,30) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/98',
    name: 'BOX (2,80 x 0,30) MISE EN ŒUVRE',
  },
  {
    text: '(2,80 x 1,14 x 1,14) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/114',
    name: 'BOX (2,80 x 1,14 x 1,14) MISE EN ŒUVRE',
  },
  {
    text: '(1,50 x 2,40) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/99',
    name: 'BOX (1,50 x 2,40) MISE EN ŒUVRE',
  },
  {
    text: '(1,50 x 1,20) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/100',
    name: 'BOX (1,50 x 1,20) MISE EN ŒUVRE',
  },
  {
    text: '(1,50 x 0,90) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/101',
    name: 'BOX (1,50 x 0,90) MISE EN ŒUVRE',
  },
  {
    text: '(1,50 x 0,60) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/102',
    name: 'BOX (1,50 x 0,60) MISE EN ŒUVRE',
  },
  {
    text: '(1,50 x 0,30) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/103',
    name: 'BOX (1,50 x 0,30) MISE EN ŒUVRE',
  },
  {
    text: '(1,50 x 1,14 x 1,14) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/115',
    name: 'BOX (1,50 x 1,14 x 1,14) MISE EN ŒUVRE',
  },
  {
    text: '(1,00 x 2,40) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/105',
    name: 'BOX (1,00 x 2,40) MISE EN ŒUVRE',
  },
  {
    text: '(1,00 x 1,20) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/105',
    name: 'BOX (1,00 x 1,20) MISE EN ŒUVRE',
  },
  {
    text: '(1,00 x 0,90) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/106',
    name: 'BOX (1,00 x 0,90) MISE EN ŒUVRE',
  },
  {
    text: '(1,00 x 0,60) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/107',
    name: 'BOX (1,00 x 0,60) MISE EN ŒUVRE',
  },
  {
    text: '(1,00 x 0,30) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/108',
    name: 'BOX (1,00 x 0,30) MISE EN ŒUVRE',
  },
  {
    text: '(1,00 x 1,14 x 1,14) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/116',
    name: 'BOX (1,00 x 1,14 x 1,14) MISE EN ŒUVRE',
  },
  {
    text: '(0,50 x 2,40) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/109',
    name: 'BOX (0,50 x 2,40) MISE EN ŒUVRE',
  },
  {
    text: '(0,50 x 1,20) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/110',
    name: 'BOX (0,50 x 1,20) MISE EN ŒUVRE',
  },
  {
    text: '(0,50 x 0,90) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/111',
    name: 'BOX (0,50 x 0,90) MISE EN ŒUVRE',
  },
  {
    text: '(0,50 x 0,60) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/112',
    name: 'BOX (0,50 x 0,60) MISE EN ŒUVRE',
  },
  {
    text: '(0,50 x 0,30) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/113',
    name: 'BOX (0,50 x 0,30) MISE EN ŒUVRE',
  },
  {
    text: '(0,50 x 1,14 x 1,14) Banche avec structure caissonnée anti-corrosion; ',
    href: '/formwork/implementation/25/measures/117',
    name: 'BOX (0,50 x 1,14 x 1,14) MISE EN ŒUVRE',
  },

  // BOX R implementation
  {
    text: '(3,00 x 2,40) Banche avec structure caissonnée anti-corrosion. Bras de levage intégrés; ',
    href: '/formwork/implementation/26/measures/82',
    name: 'BOX R (3,00 x 2,40) MISE EN ŒUVRE',
  },
  {
    text: '(3,00 x 1,20) Banche avec structure caissonnée anti-corrosion. Bras de levage intégrés; ',
    href: '/formwork/implementation/26/measures/83',
    name: 'BOX R (3,00 x 1,20) MISE EN ŒUVRE',
  },
  {
    text: '(2,80 x 2,40) Banche avec structure caissonnée anti-corrosion. Bras de levage intégrés; ',
    href: '/formwork/implementation/26/measures/84',
    name: 'BOX R (2,80 x 2,40) MISE EN ŒUVRE',
  },
  {
    text: '(2,80 x 1,20) Banche avec structure caissonnée anti-corrosion. Bras de levage intégrés; ',
    href: '/formwork/implementation/26/measures/85',
    name: 'BOX R (2,80 x 1,20) MISE EN ŒUVRE',
  },

  // BOX RS implementation
  {
    text: '(3,00 x 2,40) Banche avec structure caissonnée anti-corrosion. Bras de levage et lest intégrés.',
    href: '/formwork/implementation/27/measures/86',
    name: 'BOX RS (3,00 x 2,40) MISE EN ŒUVRE',
  },
  {
    text: '(3,00 x 1,20) Banche avec structure caissonnée anti-corrosion. Bras de levage et lest intégrés.',
    href: '/formwork/description-box/27/measures/87',
    name: 'BOX RS (3,00 x 1,20) MISE EN ŒUVRE',
  },
  {
    text: '(3,00 x 1,14 x 1,14) Banche avec structure caissonnée anti-corrosion. Bras de levage et lest intégrés.',
    href: '/formwork/description-box/27/measures/119',
    name: 'BOX RS (3,00 x 1,14 x 1,14) MISE EN ŒUVRE',
  },
  {
    text: '(2,80 x 2,40) Banche avec structure caissonnée anti-corrosion. Bras de levage et lest intégrés.',
    href: '/formwork/description-box/27/measures/88',
    name: 'BOX RS (2,80 x 2,40) MISE EN ŒUVRE',
  },
  {
    text: '(2,80 x 1,20) Banche avec structure caissonnée anti-corrosion. Bras de levage et lest intégrés.',
    href: '/formwork/description-box/27/measures/89',
    name: 'BOX RS (2,80 x 1,20) MISE EN ŒUVRE',
  },
  {
    text: '(2,80 x 1,14 x 1,14) Banche avec structure caissonnée anti-corrosion. Bras de levage et lest intégrés.',
    href: '/formwork/description-box/27/measures/90',
    name: 'BOX RS (2,80 x 1,14 x 1,14) MISE EN ŒUVRE',
  },

  // BOX logistic
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/91/logistic',
    name: 'BOX (3,00 x 2,40) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/92/logistic',
    name: 'BOX (3,00 x 1,20) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/93/logistic',
    name: 'BOX (3,00 x 0,90) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/94/logistic',
    name: 'BOX (3,00 x 0,60) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/95/logistic',
    name: 'BOX (3,00 x 0,30) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/118/logistic',
    name: 'BOX (3,00 x 1,14 x 1,14) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/80/logistic',
    name: 'BOX (2,80 x 2,40) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/81/logistic',
    name: 'BOX (2,80 x 1,20) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/96/logistic',
    name: 'BOX (2,80 x 0,90) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/97/logistic',
    name: 'BOX (2,80 x 0,60) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/98/logistic',
    name: 'BOX (2,80 x 0,30) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/114/logistic',
    name: 'BOX (2,80 x 1,14 x 1,14) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/99/logistic',
    name: 'BOX (1,50 x 2,40) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/100/logistic',
    name: 'BOX (1,50 x 1,20) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/101/logistic',
    name: 'BOX (1,50 x 0,90) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/102/logistic',
    name: 'BOX (1,50 x 0,60) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/103/logistic',
    name: 'BOX (1,50 x 0,30) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/115/logistic',
    name: 'BOX (1,50 x 1,14 x 1,14) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/104/logistic',
    name: 'BOX (1,00 x 2,40) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/105/logistic',
    name: 'BOX (1,00 x 1,20) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/106/logistic',
    name: 'BOX (1,00 x 0,90) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/107/logistic',
    name: 'BOX (1,00 x 0,60) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/108/logistic',
    name: 'BOX (1,00 x 0,30) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/116/logistic',
    name: 'BOX (1,00 x 1,14 x 1,14) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/109/logistic',
    name: 'BOX (0,50 x 2,40) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/110/logistic',
    name: 'BOX (0,50 x 1,20) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/111/logistic',
    name: 'BOX (0,50 x 0,90) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/112/logistic',
    name: 'BOX (0,50 x 0,60) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/113/logistic',
    name: 'BOX (0,50 x 0,30) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco; ',
    href: '/formwork/implementation/25/measures/117/logistic',
    name: 'BOX (0,50 x 1,14 x 1,14) MISE EN ŒUVRE LOGISTIQUE',
  },

  // BOX R logistic
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco',
    href: '/formwork/implementation/26/measures/82/logistic',
    name: 'BOX R (3,00 x 2,40) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco',
    href: '/formwork/implementation/26/measures/83/logistic',
    name: 'BOX R (3,00 x 1,20) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco',
    href: '/formwork/implementation/26/measures/84/logistic',
    name: 'BOX R (2,80 x 2,40) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco',
    href: '/formwork/implementation/26/measures/85/logistic',
    name: 'BOX R (2,80 x 1,20) MISE EN ŒUVRE LOGISTIQUE',
  },

  // BOX RS logistic
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco.',
    href: '/formwork/implementation/27/measures/86/logistic',
    name: 'BOX RS (3,00 x 2,40) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco.',
    href: '/formwork/description-box/27/measures/87/logistic',
    name: 'BOX RS (3,00 x 1,20) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco.',
    href: '/formwork/description-box/27/measures/119/logistic',
    name: 'BOX RS (3,00 x 1,14 x 1,14) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco.',
    href: '/formwork/description-box/27/measures/88/logistic',
    name: 'BOX RS (2,80 x 2,40) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco.',
    href: '/formwork/description-box/27/measures/89/logistic',
    name: 'BOX RS (2,80 x 1,20) MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'Découvrez en images les instructions pour le chargement et le déchargement des BOX sateco.',
    href: '/formwork/description-box/27/measures/90/logistic',
    name: 'BOX RS (2,80 x 1,14 x 1,14) MISE EN ŒUVRE LOGISTIQUE',
  },

  // BOX unfold
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (3,00 x 2,40); ',
    href: '/formwork/implementation/25/measures/91/unfold',
    name: 'BOX (3,00 x 2,40) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (3,00 x 1,20); ',
    href: '/formwork/implementation/25/measures/92/unfold',
    name: 'BOX (3,00 x 1,20) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (3,00 x 0,90); ',
    href: '/formwork/implementation/25/measures/93/unfold',
    name: 'BOX (3,00 x 0,90) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (3,00 x 0,60); ',
    href: '/formwork/implementation/25/measures/94/unfold',
    name: 'BOX (3,00 x 0,60) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (3,00 x 0,30); ',
    href: '/formwork/implementation/25/measures/95/unfold',
    name: 'BOX (3,00 x 0,30) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (3,00 x 1,14 x 1,14);',
    href: '/formwork/implementation/25/measures/118/unfold',
    name: 'BOX (3,00 x 1,14 x 1,14) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (2,80 x 2,40);',
    href: '/formwork/implementation/25/measures/80/unfold',
    name: 'BOX (2,80 x 2,40) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (2,80 x 1,20);',
    href: '/formwork/implementation/25/measures/81/unfold',
    name: 'BOX (2,80 x 1,20) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (2,80 x 0,90);',
    href: '/formwork/implementation/25/measures/96/unfold',
    name: 'BOX (2,80 x 0,90) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (2,80 x 0,60);',
    href: '/formwork/implementation/25/measures/97/unfold',
    name: 'BOX (2,80 x 0,60) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (2,80 x 0,30);',
    href: '/formwork/implementation/25/measures/98/unfold',
    name: 'BOX (2,80 x 0,30) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (2,80 x 1,14 x 1,14);',
    href: '/formwork/implementation/25/measures/114/unfold',
    name: 'BOX (2,80 x 1,14 x 1,14) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (1,50 x 2,40);',
    href: '/formwork/implementation/25/measures/99/unfold',
    name: 'BOX (1,50 x 2,40) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (1,50 x 1,20);',
    href: '/formwork/implementation/25/measures/100/unfold',
    name: 'BOX (1,50 x 1,20) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (1,50 x 0,90);',
    href: '/formwork/implementation/25/measures/101/unfold',
    name: 'BOX (1,50 x 0,90) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (1,50 x 0,60);',
    href: '/formwork/implementation/25/measures/102/unfold',
    name: 'BOX (1,50 x 0,60) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (1,50 x 0,30);',
    href: '/formwork/implementation/25/measures/103/unfold',
    name: 'BOX (1,50 x 0,30) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (1,50 x 1,14 x 1,14);',
    href: '/formwork/implementation/25/measures/115/unfold',
    name: 'BOX (1,50 x 1,14 x 1,14) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (1,00 x 2,40);',
    href: '/formwork/implementation/25/measures/104/unfold',
    name: 'BOX (1,00 x 2,40) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (1,00 x 1,20);',
    href: '/formwork/implementation/25/measures/105/unfold',
    name: 'BOX (1,00 x 1,20) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (1,00 x 0,90);',
    href: '/formwork/implementation/25/measures/106/unfold',
    name: 'BOX (1,00 x 0,90) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (1,00 x 0,60);',
    href: '/formwork/implementation/25/measures/107/unfold',
    name: 'BOX (1,00 x 0,60) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (1,00 x 0,30);',
    href: '/formwork/implementation/25/measures/108/unfold',
    name: 'BOX (1,00 x 0,30) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (1,00 x 1,14 x 1,14);',
    href: '/formwork/implementation/25/measures/116/unfold',
    name: 'BOX (1,00 x 1,14 x 1,14) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (0,50 x 2,40);',
    href: '/formwork/implementation/25/measures/109/unfold',
    name: 'BOX (0,50 x 2,40) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (0,50 x 1,20);',
    href: '/formwork/implementation/25/measures/110/unfold',
    name: 'BOX (0,50 x 1,20) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (0,50 x 0,90);',
    href: '/formwork/implementation/25/measures/111/unfold',
    name: 'BOX (0,50 x 0,90) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (0,50 x 0,60);',
    href: '/formwork/implementation/25/measures/112/unfold',
    name: 'BOX (0,50 x 0,60) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (0,50 x 0,30);',
    href: '/formwork/implementation/25/measures/113/unfold',
    name: 'BOX (0,50 x 0,30) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX (0,50 x 1,14 x 1,14);',
    href: '/formwork/implementation/25/measures/117/unfold',
    name: 'BOX (0,50 x 1,14 x 1,14) MISE EN ŒUVRE DÉPLIAGE',
  },

  // BOX R unfold
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX R (3,00 x 2,40)',
    href: '/formwork/implementation/26/measures/82/unfold',
    name: 'BOX R (3,00 x 2,40) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX R (3,00 x 1,20)',
    href: '/formwork/implementation/26/measures/83/unfold',
    name: 'BOX R (3,00 x 1,20) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX R (2,80 x 2,40)',
    href: '/formwork/implementation/26/measures/84/unfold',
    name: 'BOX R (2,80 x 2,40) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX R (2,80 x 1,20)',
    href: '/formwork/implementation/26/measures/85/unfold',
    name: 'BOX R (2,80 x 1,20) MISE EN ŒUVRE DÉPLIAGE',
  },

  // BOX RS unfold
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX RS (3,00 x 2,40)',
    href: '/formwork/implementation/27/measures/86/unfold',
    name: 'BOX RS (3,00 x 2,40) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX RS (3,00 x 1,20)',
    href: '/formwork/description-box/27/measures/87/unfold',
    name: 'BOX RS (3,00 x 1,20) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX RS (3,00 x 1,14 x 1,14)',
    href: '/formwork/description-box/27/measures/119/unfold',
    name: 'BOX RS (3,00 x 1,14 x 1,14) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX RS (2,80 x 2,40)',
    href: '/formwork/description-box/27/measures/88/unfold',
    name: 'BOX RS (2,80 x 2,40) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX RS (2,80 x 1,20)',
    href: '/formwork/description-box/27/measures/89/unfold',
    name: 'BOX RS (2,80 x 1,20) MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'Découvrez en images le dépliage étape par étape de la BOX RS (2,80 x 1,14 x 1,14)',
    href: '/formwork/description-box/27/measures/90/unfold',
    name: 'BOX RS (2,80 x 1,14 x 1,14) MISE EN ŒUVRE DÉPLIAGE',
  },

  // BOX folding
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (3,00 x 2,40); ',
    href: '/formwork/implementation/25/measures/91/folding',
    name: 'BOX (3,00 x 2,40) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (3,00 x 1,20); ',
    href: '/formwork/implementation/25/measures/92/folding',
    name: 'BOX (3,00 x 1,20) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (3,00 x 0,90); ',
    href: '/formwork/implementation/25/measures/93/folding',
    name: 'BOX (3,00 x 0,90) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (3,00 x 0,60); ',
    href: '/formwork/implementation/25/measures/94/folding',
    name: 'BOX (3,00 x 0,60) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (3,00 x 0,30); ',
    href: '/formwork/implementation/25/measures/95/folding',
    name: 'BOX (3,00 x 0,30) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (3,00 x 1,14 x 1,14);',
    href: '/formwork/implementation/25/measures/118/folding',
    name: 'BOX (3,00 x 1,14 x 1,14) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (2,80 x 2,40);',
    href: '/formwork/implementation/25/measures/80/folding',
    name: 'BOX (2,80 x 2,40) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (2,80 x 1,20);',
    href: '/formwork/implementation/25/measures/81/folding',
    name: 'BOX (2,80 x 1,20) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (2,80 x 0,90);',
    href: '/formwork/implementation/25/measures/96/folding',
    name: 'BOX (2,80 x 0,90) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (2,80 x 0,60);',
    href: '/formwork/implementation/25/measures/97/folding',
    name: 'BOX (2,80 x 0,60) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (2,80 x 0,30);',
    href: '/formwork/implementation/25/measures/98/folding',
    name: 'BOX (2,80 x 0,30) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (2,80 x 1,14 x 1,14);',
    href: '/formwork/implementation/25/measures/114/folding',
    name: 'BOX (2,80 x 1,14 x 1,14) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (1,50 x 2,40);',
    href: '/formwork/implementation/25/measures/99/folding',
    name: 'BOX (1,50 x 2,40) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (1,50 x 1,20);',
    href: '/formwork/implementation/25/measures/100/folding',
    name: 'BOX (1,50 x 1,20) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (1,50 x 0,90);',
    href: '/formwork/implementation/25/measures/101/folding',
    name: 'BOX (1,50 x 0,90) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (1,50 x 0,60);',
    href: '/formwork/implementation/25/measures/102/folding',
    name: 'BOX (1,50 x 0,60) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (1,50 x 0,30);',
    href: '/formwork/implementation/25/measures/103/folding',
    name: 'BOX (1,50 x 0,30) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (1,50 x 1,14 x 1,14);',
    href: '/formwork/implementation/25/measures/115/folding',
    name: 'BOX (1,50 x 1,14 x 1,14) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (1,00 x 2,40);',
    href: '/formwork/implementation/25/measures/104/folding',
    name: 'BOX (1,00 x 2,40) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (1,00 x 1,20);',
    href: '/formwork/implementation/25/measures/105/folding',
    name: 'BOX (1,00 x 1,20) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (1,00 x 0,90);',
    href: '/formwork/implementation/25/measures/106/folding',
    name: 'BOX (1,00 x 0,90) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (1,00 x 0,60);',
    href: '/formwork/implementation/25/measures/107/folding',
    name: 'BOX (1,00 x 0,60) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (1,00 x 0,30);',
    href: '/formwork/implementation/25/measures/108/folding',
    name: 'BOX (1,00 x 0,30) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (1,00 x 1,14 x 1,14);',
    href: '/formwork/implementation/25/measures/116/folding',
    name: 'BOX (1,00 x 1,14 x 1,14) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (0,50 x 2,40);',
    href: '/formwork/implementation/25/measures/109/folding',
    name: 'BOX (0,50 x 2,40) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (0,50 x 1,20);',
    href: '/formwork/implementation/25/measures/110/folding',
    name: 'BOX (0,50 x 1,20) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (0,50 x 0,90);',
    href: '/formwork/implementation/25/measures/111/folding',
    name: 'BOX (0,50 x 0,90) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (0,50 x 0,60);',
    href: '/formwork/implementation/25/measures/112/folding',
    name: 'BOX (0,50 x 0,60) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (0,50 x 0,30);',
    href: '/formwork/implementation/25/measures/113/folding',
    name: 'BOX (0,50 x 0,30) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX (0,50 x 1,14 x 1,14);',
    href: '/formwork/implementation/25/measures/117/folding',
    name: 'BOX (0,50 x 1,14 x 1,14) MISE EN ŒUVRE REPLIAGE',
  },

  // BOX R folding
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX R (3,00 x 2,40)',
    href: '/formwork/implementation/26/measures/82/folding',
    name: 'BOX R (3,00 x 2,40) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX R (3,00 x 1,20)',
    href: '/formwork/implementation/26/measures/83/folding',
    name: 'BOX R (3,00 x 1,20) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX R (2,80 x 2,40)',
    href: '/formwork/implementation/26/measures/84/folding',
    name: 'BOX R (2,80 x 2,40) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX R (2,80 x 1,20)',
    href: '/formwork/implementation/26/measures/85/folding',
    name: 'BOX R (2,80 x 1,20) MISE EN ŒUVRE REPLIAGE',
  },

  // BOX RS folding
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX RS (3,00 x 2,40)',
    href: '/formwork/implementation/27/measures/86/folding',
    name: 'BOX RS (3,00 x 2,40) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX RS (3,00 x 1,20)',
    href: '/formwork/description-box/27/measures/87/folding',
    name: 'BOX RS (3,00 x 1,20) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX RS (3,00 x 1,14 x 1,14)',
    href: '/formwork/description-box/27/measures/119/folding',
    name: 'BOX RS (3,00 x 1,14 x 1,14) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX RS (2,80 x 2,40)',
    href: '/formwork/description-box/27/measures/88/folding',
    name: 'BOX RS (2,80 x 2,40) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX RS (2,80 x 1,20)',
    href: '/formwork/description-box/27/measures/89/folding',
    name: 'BOX RS (2,80 x 1,20) MISE EN ŒUVRE REPLIAGE',
  },
  {
    text: 'Découvrez en images le repliage étape par étape de la BOX RS (2,80 x 1,14 x 1,14)',
    href: '/formwork/description-box/27/measures/90/folding',
    name: 'BOX RS (2,80 x 1,14 x 1,14) MISE EN ŒUVRE REPLIAGE',
  },

  // BOX juxtaposition
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/25/measures/91/juxtaposition',
    name: 'BOX (3,00 x 2,40) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/25/measures/92/juxtaposition',
    name: 'BOX (3,00 x 1,20) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/25/measures/93/juxtaposition',
    name: 'BOX (3,00 x 0,90) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/25/measures/94/juxtaposition',
    name: 'BOX (3,00 x 0,60) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/25/measures/95/juxtaposition',
    name: 'BOX (3,00 x 0,30) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/25/measures/80/juxtaposition',
    name: 'BOX (2,80 x 2,40) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/25/measures/81/juxtaposition',
    name: 'BOX (2,80 x 1,20) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/25/measures/96/juxtaposition',
    name: 'BOX (2,80 x 0,90) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/25/measures/97/juxtaposition',
    name: 'BOX (2,80 x 0,60) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/25/measures/98/juxtaposition',
    name: 'BOX (2,80 x 0,30) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/25/measures/99/juxtaposition',
    name: 'BOX (1,50 x 2,40) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/25/measures/100/juxtaposition',
    name: 'BOX (1,50 x 1,20) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/25/measures/101/juxtaposition',
    name: 'BOX (1,50 x 0,90) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/25/measures/102/juxtaposition',
    name: 'BOX (1,50 x 0,60) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/25/measures/103/juxtaposition',
    name: 'BOX (1,50 x 0,30) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/25/measures/104/juxtaposition',
    name: 'BOX (1,00 x 2,40) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/25/measures/105/juxtaposition',
    name: 'BOX (1,00 x 1,20) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/25/measures/106/juxtaposition',
    name: 'BOX (1,00 x 0,90) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/25/measures/107/juxtaposition',
    name: 'BOX (1,00 x 0,60) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/25/measures/108/juxtaposition',
    name: 'BOX (1,00 x 0,30) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/25/measures/109/juxtaposition',
    name: 'BOX (0,50 x 2,40) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/25/measures/110/juxtaposition',
    name: 'BOX (0,50 x 1,20) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/25/measures/111/juxtaposition',
    name: 'BOX (0,50 x 0,90) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/25/measures/112/juxtaposition',
    name: 'BOX (0,50 x 0,60) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/25/measures/113/juxtaposition',
    name: 'BOX (0,50 x 0,30) MISE EN ŒUVRE JUXTAPOSITION',
  },

  // BOX R juxtaposition
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/26/measures/82/juxtaposition',
    name: 'BOX R (3,00 x 2,40) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/26/measures/83/juxtaposition',
    name: 'BOX R (3,00 x 1,20) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/26/measures/84/juxtaposition',
    name: 'BOX R (2,80 x 2,40) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/26/measures/85/juxtaposition',
    name: 'BOX R (2,80 x 1,20) MISE EN ŒUVRE JUXTAPOSITION',
  },

  // BOX RS juxtaposition
  {
    text: 'Assemblage;',
    href: '/formwork/implementation/27/measures/86/juxtaposition',
    name: 'BOX RS (3,00 x 2,40) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/description-box/27/measures/87/juxtaposition',
    name: 'BOX RS (3,00 x 1,20) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/description-box/27/measures/88/juxtaposition',
    name: 'BOX RS (2,80 x 2,40) MISE EN ŒUVRE JUXTAPOSITION',
  },
  {
    text: 'Assemblage;',
    href: '/formwork/description-box/27/measures/89/juxtaposition',
    name: 'BOX RS (2,80 x 1,20) MISE EN ŒUVRE JUXTAPOSITION',
  },

  // BOX superposition
  {
    text: 'Superposition;',
    href: '/formwork/implementation/25/measures/91/superposition',
    name: 'BOX (3,00 x 2,40) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition; ',
    href: '/formwork/implementation/25/measures/92/superposition',
    name: 'BOX (3,00 x 1,20) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition; ',
    href: '/formwork/implementation/25/measures/93/superposition',
    name: 'BOX (3,00 x 0,90) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition; ',
    href: '/formwork/implementation/25/measures/94/superposition',
    name: 'BOX (3,00 x 0,60) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition; ',
    href: '/formwork/implementation/25/measures/95/superposition',
    name: 'BOX (3,00 x 0,30) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/25/measures/118/superposition',
    name: 'BOX (3,00 x 1,14 x 1,14) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/25/measures/80/superposition',
    name: 'BOX (2,80 x 2,40) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/25/measures/81/superposition',
    name: 'BOX (2,80 x 1,20) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/25/measures/96/superposition',
    name: 'BOX (2,80 x 0,90) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/25/measures/97/superposition',
    name: 'BOX (2,80 x 0,60) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/25/measures/98/superposition',
    name: 'BOX (2,80 x 0,30) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/25/measures/114/superposition',
    name: 'BOX (2,80 x 1,14 x 1,14) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/25/measures/99/superposition',
    name: 'BOX (1,50 x 2,40) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/25/measures/100/superposition',
    name: 'BOX (1,50 x 1,20) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/25/measures/101/superposition',
    name: 'BOX (1,50 x 0,90) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/25/measures/102/superposition',
    name: 'BOX (1,50 x 0,60) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/25/measures/103/superposition',
    name: 'BOX (1,50 x 0,30) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/25/measures/115/superposition',
    name: 'BOX (1,50 x 1,14 x 1,14) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/25/measures/104/superposition',
    name: 'BOX (1,00 x 2,40) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/25/measures/105/superposition',
    name: 'BOX (1,00 x 1,20) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/25/measures/106/superposition',
    name: 'BOX (1,00 x 0,90) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/25/measures/107/superposition',
    name: 'BOX (1,00 x 0,60) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/25/measures/108/superposition',
    name: 'BOX (1,00 x 0,30) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/25/measures/116/superposition',
    name: 'BOX (1,00 x 1,14 x 1,14) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/25/measures/109/superposition',
    name: 'BOX (0,50 x 2,40) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/25/measures/110/superposition',
    name: 'BOX (0,50 x 1,20) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/25/measures/111/superposition',
    name: 'BOX (0,50 x 0,90) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/25/measures/112/superposition',
    name: 'BOX (0,50 x 0,60) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/25/measures/113/superposition',
    name: 'BOX (0,50 x 0,30) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/25/measures/117/superposition',
    name: 'BOX (0,50 x 1,14 x 1,14) MISE EN ŒUVRE SUPERPOSITION',
  },

  // BOX R superposition
  {
    text: 'Superposition;',
    href: '/formwork/implementation/26/measures/82/superposition',
    name: 'BOX R (3,00 x 2,40) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/26/measures/83/superposition',
    name: 'BOX R (3,00 x 1,20) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/26/measures/84/superposition',
    name: 'BOX R (2,80 x 2,40) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/implementation/26/measures/85/superposition',
    name: 'BOX R (2,80 x 1,20) MISE EN ŒUVRE SUPERPOSITION',
  },

  // BOX RS superposition
  {
    text: 'Superposition;',
    href: '/formwork/implementation/27/measures/86/superposition',
    name: 'BOX RS (3,00 x 2,40) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/description-box/27/measures/87/superposition',
    name: 'BOX RS (3,00 x 1,20) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/description-box/27/measures/119/superposition',
    name: 'BOX RS (3,00 x 1,14 x 1,14) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/description-box/27/measures/88/superposition',
    name: 'BOX RS (2,80 x 2,40) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/description-box/27/measures/89/superposition',
    name: 'BOX RS (2,80 x 1,20) MISE EN ŒUVRE SUPERPOSITION',
  },
  {
    text: 'Superposition;',
    href: '/formwork/description-box/27/measures/90/superposition',
    name: 'BOX RS (2,80 x 1,14 x 1,14) MISE EN ŒUVRE SUPERPOSITION',
  },

  // Security
  {
    text: 'Vous cherchez des informations sur;',
    href: '/security/select-category',
    name: 'SÉCURITÉ',
  },

  // PCE 2
  {
    text: 'Sélectionnez une rubrique;',
    href: '/security/pce-2',
    name: 'SÉCURITÉ PCE 2',
  },

  // PCE 2 modules
  {
    text: 'Sélectionnez le module;',
    href: '/security/pce-2/modules',
    name: 'SÉCURITÉ PCE 2 MODULES',
  },

  {
    text: 'Modules; [A+A] EN BOUT À BOUT;',
    href: '/security/pce-2/modules/4',
    name: 'SÉCURITÉ PCE 2/MODULES [A+A] EN BOUT À BOUT',
  },
  {
    text: 'Modules; [A+A] EN BOUT À BOUT; Découvrez en 3D les composants de votre PCE 2. Utiliser le bouton "?" si vous avez besoin d\'aide.',
    href: '/security/pce-2/modules/4/description',
    name: 'SÉCURITÉ PCE 2/MODULES [A+A] EN BOUT À BOUT DESCRIPTIF',
  },
  {
    text: 'Modules; [A+A] EN BOUT À BOUT;',
    href: '/security/pce-2/modules/4/implementation',
    name: 'SÉCURITÉ PCE 2/MODULES [A+A] EN BOUT À BOUT MISE EN ŒUVRE',
  },
  {
    text: 'Modules; [A+A] EN BOUT À BOUT; Manutention, transport et stockage du module A;',
    href: '/security/pce-2/modules/4/implementation/logistics',
    name: 'SÉCURITÉ PCE 2/MODULES [A+A] EN BOUT À BOUT MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: "Modules; [A+A] EN BOUT À BOUT; Découvrez en vidéo l'assemblage des 2 modules A en bout à bout;",
    href: '/security/pce-2/modules/4/implementation/assembly',
    name: 'SÉCURITÉ PCE 2/MODULES [A+A] EN BOUT À BOUT MISE EN ŒUVRE ASSEMBLAGE',
  },

  {
    text: 'Modules; [A+B] EN BOUT À BOUT;',
    href: '/security/pce-2/modules/2',
    name: 'SÉCURITÉ PCE 2 MODULES [A+B] EN BOUT À BOUT',
  },
  {
    text: 'Modules; [A+B] EN BOUT À BOUT; Découvrez en 3D les composants de votre PCE 2. Utiliser le bouton "?" si vous avez besoin d\'aide.',
    href: '/security/pce-2/modules/2/description',
    name: 'SÉCURITÉ PCE 2 MODULES [A+B] EN BOUT À BOUT DESCRIPTIF',
  },
  {
    text: 'Modules; [A+B] EN BOUT À BOUT;',
    href: '/security/pce-2/modules/2/implementation',
    name: 'SÉCURITÉ PCE 2 MODULES [A+B] EN BOUT À BOUT MISE EN ŒUVRE',
  },
  {
    text: 'Modules; [A+B] EN BOUT À BOUT; Manutention, transport et stockage du module A',
    href: '/security/pce-2/modules/2/implementation/logistics',
    name: 'SÉCURITÉ PCE 2 MODULES [A+B] EN BOUT À BOUT MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: "Modules; [A+B] EN BOUT À BOUT; Découvrez en vidéo l'assemblage des modules A et B en bout à bout",
    href: '/security/pce-2/modules/2/implementation/assembly',
    name: 'SÉCURITÉ PCE 2 MODULES [A+B] EN BOUT À BOUT MISE EN ŒUVRE ASSEMBLAGE',
  },

  {
    text: 'Modules; [A+B] + [A+B] JUXTAPOSÉS;',
    href: '/security/pce-2/modules/5',
    name: 'SÉCURITÉ PCE 2 MODULES [A+B] + [A+B] JUXTAPOSÉS',
  },
  {
    text: 'Modules; [A+B] + [A+B] JUXTAPOSÉS; Découvrez en 3D les composants de votre PCE 2. Utiliser le bouton "?" si vous avez besoin d\'aide.',
    href: '/security/pce-2/modules/5/description',
    name: 'SÉCURITÉ PCE 2 MODULES [A+B] + [A+B] JUXTAPOSÉS DESCRIPTIF',
  },
  {
    text: 'Modules; [A+B] + [A+B] JUXTAPOSÉS;',
    href: '/security/pce-2/modules/5/implementation',
    name: 'SÉCURITÉ PCE 2 MODULES [A+B] + [A+B] JUXTAPOSÉS MISE EN ŒUVRE',
  },
  {
    text: 'Modules; [A+B] + [A+B] JUXTAPOSÉS; Manutention, transport et stockage du module A',
    href: '/security/pce-2/modules/5/implementation/logistics',
    name: 'SÉCURITÉ PCE 2 MODULES [A+B] + [A+B] JUXTAPOSÉS MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: "Modules; [A+B] + [A+B] JUXTAPOSÉS; Découvrez en vidéo l'assemblage des modules [A+B] + [A+B] Juxtaposés",
    href: '/security/pce-2/modules/5/implementation/assembly',
    name: 'SÉCURITÉ PCE 2 MODULES [A+B] + [A+B] JUXTAPOSÉS MISE EN ŒUVRE ASSEMBLAGE',
  },

  {
    text: 'Modules; [A+A] JUXTAPOSÉS;',
    href: '/security/pce-2/modules/6',
    name: 'SÉCURITÉ PCE 2 MODULES [A+A] JUXTAPOSÉS',
  },
  {
    text: 'Modules; [A+A] JUXTAPOSÉS; Découvrez en 3D les composants de votre PCE 2. Utiliser le bouton "?" si vous avez besoin d\'aide.',
    href: '/security/pce-2/modules/6/description',
    name: 'SÉCURITÉ PCE 2 MODULES [A+A] JUXTAPOSÉS DESCRIPTIF',
  },
  {
    text: 'Modules; [A+A] JUXTAPOSÉS;',
    href: '/security/pce-2/modules/6/implementation',
    name: 'SÉCURITÉ PCE 2 MODULES [A+A] JUXTAPOSÉS MISE EN ŒUVRE',
  },
  {
    text: 'Modules; [A+A] JUXTAPOSÉS; Manutention, transport et stockage du module A',
    href: '/security/pce-2/modules/6/implementation/logistics',
    name: 'SÉCURITÉ PCE 2 MODULES [A+A] JUXTAPOSÉS MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: "Modules; [A+A] JUXTAPOSÉS; Découvrez en vidéo l'assemblage des 2 modules A juxtaposés",
    href: '/security/pce-2/modules/6/implementation/assembly',
    name: 'SÉCURITÉ PCE 2 MODULES [A+A] JUXTAPOSÉS MISE EN ŒUVRE ASSEMBLAGE',
  },

  {
    text: 'Modules; [A+A] + [A+A] JUXTAPOSÉS;',
    href: '/security/pce-2/modules/8',
    name: 'SÉCURITÉ PCE 2 MODULES [A+A] + [A+A] JUXTAPOSÉS',
  },
  {
    text: 'Modules; [A+A] + [A+A] JUXTAPOSÉS; Découvrez en 3D les composants de votre PCE 2. Utiliser le bouton "?" si vous avez besoin d\'aide.',
    href: '/security/pce-2/modules/8/description',
    name: 'SÉCURITÉ PCE 2 MODULES [A+A] + [A+A] JUXTAPOSÉS DESCRIPTIF',
  },
  {
    text: 'Modules; [A+A] + [A+A] JUXTAPOSÉS;',
    href: '/security/pce-2/modules/8/implementation',
    name: 'SÉCURITÉ PCE 2 MODULES [A+A] + [A+A] JUXTAPOSÉS MISE EN ŒUVRE',
  },
  {
    text: 'Modules; [A+A] + [A+A] JUXTAPOSÉS; Manutention, transport et stockage du module A',
    href: '/security/pce-2/modules/8/implementation/logistics',
    name: 'SÉCURITÉ PCE 2 MODULES [A+A] + [A+A] JUXTAPOSÉS MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: "Modules; [A+A] + [A+A] JUXTAPOSÉS; Découvrez en vidéo l'assemblage des modules [A+A] + [A+A] Juxtaposés",
    href: '/security/pce-2/modules/8/implementation/assembly',
    name: 'SÉCURITÉ PCE 2 MODULES [A+A] + [A+A] JUXTAPOSÉS MISE EN ŒUVRE ASSEMBLAGE',
  },

  {
    text: 'Modules; [A+B] JUXTAPOSÉS;',
    href: '/security/pce-2/modules/9',
    name: 'SÉCURITÉ PCE 2 MODULES [A+B] JUXTAPOSÉS',
  },
  {
    text: 'Modules; [A+B] JUXTAPOSÉS; Découvrez en 3D les composants de votre PCE 2. Utiliser le bouton "?" si vous avez besoin d\'aide.',
    href: '/security/pce-2/modules/9/description',
    name: 'SÉCURITÉ PCE 2 MODULES [A+B] JUXTAPOSÉS DESCRIPTIF',
  },
  {
    text: 'Modules; [A+B] JUXTAPOSÉS;',
    href: '/security/pce-2/modules/9/implementation',
    name: 'SÉCURITÉ PCE 2 MODULES [A+B] JUXTAPOSÉS MISE EN ŒUVRE',
  },
  {
    text: 'Modules; [A+B] JUXTAPOSÉS; Manutention, transport et stockage du module A',
    href: '/security/pce-2/modules/9/implementation/logistics',
    name: 'SÉCURITÉ PCE 2 MODULES [A+B] JUXTAPOSÉS MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: "Modules; [A+B] JUXTAPOSÉS; Découvrez en vidéo l'assemblage des modules A et B juxtaposés",
    href: '/security/pce-2/modules/9/implementation/assembly',
    name: 'SÉCURITÉ PCE 2 MODULES [A+B] JUXTAPOSÉS MISE EN ŒUVRE ASSEMBLAGE',
  },

  // PCE 2 components
  {
    text: 'COMPOSANTS; Les composants:',
    href: '/security/pce-2/components',
    name: 'COMPOSANTS PCE 2',
  },

  {
    text: 'COMPOSANTS; Les composants; MODIFICATION DE LA POSITION DU PIED DE STOCKAGE SUR LE MODULE',
    href: '/security/pce-2/components/2',
    name: 'COMPOSANTS PCE 2 MODIFICATION DE LA POSITION DU PIED DE STOCKAGE SUR LE MODULE',
  },
  {
    text: 'COMPOSANTS; Les composants; MODIFICATION DE LA POSITION DU PIED DE STOCKAGE SUR LE MODULE; Découvrez en 3D les composants de votre PCE 2. Utiliser le bouton "?" si vous avez besoin d\'aide.',
    href: '/security/pce-2/components/2/description',
    name: 'COMPOSANTS PCE 2 MODIFICATION DE LA POSITION DU PIED DE STOCKAGE SUR LE MODULE DESCRIPTIF',
  },
  {
    text: 'COMPOSANTS; Les composants; MODIFICATION DE LA POSITION DU PIED DE STOCKAGE SUR LE MODULE; Découvrez en images la modification de la position du pied de stockage sur le module',
    href: '/security/pce-2/components/2/implementation',
    name: 'COMPOSANTS PCE 2 MODIFICATION DE LA POSITION DU PIED DE STOCKAGE SUR LE MODULE MISE EN ŒUVRE',
  },

  {
    text: 'COMPOSANTS; Les composants; RETOURNEMENT DE L’ÉCHELLE D’ACCÈS',
    href: '/security/pce-2/components/1',
    name: 'COMPOSANTS PCE 2 RETOURNEMENT DE L’ÉCHELLE D’ACCÈS',
  },
  {
    text: 'COMPOSANTS; Les composants; RETOURNEMENT DE L’ÉCHELLE D’ACCÈS; Découvrez en 3D les composants de votre PCE 2. Utiliser le bouton "?" si vous avez besoin d\'aide.',
    href: '/security/pce-2/components/1/description',
    name: 'COMPOSANTS PCE 2 RETOURNEMENT DE L’ÉCHELLE D’ACCÈS DESCRIPTIF',
  },
  {
    text: 'COMPOSANTS; Les composants; RETOURNEMENT DE L’ÉCHELLE D’ACCÈS; Découvrez en images le retournement de l’échelle d’accès',
    href: '/security/pce-2/components/1/implementation',
    name: 'COMPOSANTS PCE 2 RETOURNEMENT DE L’ÉCHELLE D’ACCÈS MISE EN ŒUVRE',
  },

  {
    text: 'COMPOSANTS; Les composants; MONTAGE DU PETIT PIED',
    href: '/security/pce-2/components/3',
    name: 'COMPOSANTS PCE 2 MONTAGE DU PETIT PIED',
  },
  {
    text: 'COMPOSANTS; Les composants; MONTAGE DU PETIT PIED; Découvrez en 3D les composants de votre PCE 2. Utiliser le bouton "?" si vous avez besoin d\'aide.',
    href: '/security/pce-2/components/3/description',
    name: 'COMPOSANTS PCE 2 MONTAGE DU PETIT PIED DESCRIPTIF',
  },
  {
    text: 'COMPOSANTS; Les composants; MONTAGE DU PETIT PIED; Découvrez en images du montage du petit pied',
    href: '/security/pce-2/components/3/implementation',
    name: 'COMPOSANTS PCE 2 MONTAGE DU PETIT PIED MISE EN ŒUVRE',
  },

  {
    text: 'COMPOSANTS; Les composants; CONDAMNATION DE LA TRAPPE D’ACCÈS',
    href: '/security/pce-2/components/4',
    name: 'COMPOSANTS PCE 2 CONDAMNATION DE LA TRAPPE D’ACCÈS',
  },
  {
    text: 'COMPOSANTS; Les composants; CONDAMNATION DE LA TRAPPE D’ACCÈS; Découvrez en 3D les composants de votre PCE 2. Utiliser le bouton "?" si vous avez besoin d\'aide.',
    href: '/security/pce-2/components/4/description',
    name: 'COMPOSANTS PCE 2 CONDAMNATION DE LA TRAPPE D’ACCÈS DESCRIPTIF',
  },
  {
    text: 'COMPOSANTS; Les composants; CONDAMNATION DE LA TRAPPE D’ACCÈS; Découvrez en images la condamnation de la trappe d’accès',
    href: '/security/pce-2/components/4/implementation',
    name: 'COMPOSANTS PCE 2 CONDAMNATION DE LA TRAPPE D’ACCÈS MISE EN ŒUVRE',
  },

  {
    text: "COMPOSANTS; Les composants; MONTAGE DE LA TÊTE D'ÉTAI (AVEC PIÈCE DE DÉPORT ET SANS PIÈCE DE DÉPORT)",
    href: '/security/pce-2/components/5',
    name: "COMPOSANTS PCE 2 MONTAGE DE LA TÊTE D'ÉTAI (AVEC PIÈCE DE DÉPORT ET SANS PIÈCE DE DÉPORT)",
  },
  {
    text: 'COMPOSANTS; Les composants; MONTAGE DE LA TÊTE D\'ÉTAI (AVEC PIÈCE DE DÉPORT ET SANS PIÈCE DE DÉPORT); Découvrez en 3D les composants de votre PCE 2. Utiliser le bouton "?" si vous avez besoin d\'aide.',
    href: '/security/pce-2/components/5/description',
    name: "COMPOSANTS PCE 2 MONTAGE DE LA TÊTE D'ÉTAI (AVEC PIÈCE DE DÉPORT ET SANS PIÈCE DE DÉPORT) DESCRIPTIF",
  },
  {
    text: "COMPOSANTS; Les composants; MONTAGE DE LA TÊTE D'ÉTAI (AVEC PIÈCE DE DÉPORT ET SANS PIÈCE DE DÉPORT); Découvrez en images le montage de la tête d'étai (avec pièce de déport et sans pièce de déport)",
    href: '/security/pce-2/components/5/implementation',
    name: "COMPOSANTS PCE 2 MONTAGE DE LA TÊTE D'ÉTAI (AVEC PIÈCE DE DÉPORT ET SANS PIÈCE DE DÉPORT) MISE EN ŒUVRE",
  },

  {
    text: "COMPOSANTS; Les composants; MONTAGE DE LA CORNIÈRE D'APPUI (AVEC COMPENSATION ET SANS COMPENSATION)",
    href: '/security/pce-2/components/6',
    name: "COMPOSANTS PCE 2 MONTAGE DE LA CORNIÈRE D'APPUI (AVEC COMPENSATION ET SANS COMPENSATION)",
  },
  {
    text: 'COMPOSANTS; Les composants; MONTAGE DE LA CORNIÈRE D\'APPUI (AVEC COMPENSATION ET SANS COMPENSATION); Découvrez en 3D les composants de votre PCE 2. Utiliser le bouton "?" si vous avez besoin d\'aide.',
    href: '/security/pce-2/components/6/description',
    name: "COMPOSANTS PCE 2 MONTAGE DE LA CORNIÈRE D'APPUI (AVEC COMPENSATION ET SANS COMPENSATION) DESCRIPTIF",
  },
  {
    text: "COMPOSANTS; Les composants; MONTAGE DE LA CORNIÈRE D'APPUI (AVEC COMPENSATION ET SANS COMPENSATION); Découvrez en images le montage de la cornière d'appui (avec compensation et sans compensation)",
    href: '/security/pce-2/components/6/implementation',
    name: "COMPOSANTS PCE 2 MONTAGE DE LA CORNIÈRE D'APPUI (AVEC COMPENSATION ET SANS COMPENSATION) MISE EN ŒUVRE",
  },

  {
    text: "COMPOSANTS; Les composants; MONTAGE DES TRAVERSES D'ANGLE",
    href: '/security/pce-2/components/7',
    name: "COMPOSANTS PCE 2 MONTAGE DES TRAVERSES D'ANGLE",
  },
  {
    text: 'COMPOSANTS; Les composants; MONTAGE DES TRAVERSES D\'ANGLE; Découvrez en 3D les composants de votre PCE 2. Utiliser le bouton "?" si vous avez besoin d\'aide.',
    href: '/security/pce-2/components/7/description',
    name: "COMPOSANTS PCE 2 MONTAGE DES TRAVERSES D'ANGLE DESCRIPTIF",
  },
  {
    text: "COMPOSANTS; Les composants; MONTAGE DES TRAVERSES D'ANGLE; Découvrez en images le montage des traverses d'angle",
    href: '/security/pce-2/components/7/implementation',
    name: "COMPOSANTS PCE 2 MONTAGE DES TRAVERSES D'ANGLE MISE EN ŒUVRE",
  },

  {
    text: 'COMPOSANTS; Les composants; RÉGLAGES DES EXTENSIONS AV/AR ET DES EXTENSIONS LATÉRALES',
    href: '/security/pce-2/components/8',
    name: 'COMPOSANTS PCE 2 RÉGLAGES DES EXTENSIONS AV/AR ET DES EXTENSIONS LATÉRALES',
  },
  {
    text: 'COMPOSANTS; Les composants; RÉGLAGES DES EXTENSIONS AV/AR ET DES EXTENSIONS LATÉRALES; Découvrez en 3D les composants de votre PCE 2. Utiliser le bouton "?" si vous avez besoin d\'aide.',
    href: '/security/pce-2/components/8/description',
    name: 'COMPOSANTS PCE 2 RÉGLAGES DES EXTENSIONS AV/AR ET DES EXTENSIONS LATÉRALES DESCRIPTIF',
  },
  {
    text: 'COMPOSANTS; Les composants; RÉGLAGES DES EXTENSIONS AV/AR ET DES EXTENSIONS LATÉRALES; Découvrez en images les réglages des extensions AV/AR et des extensions latérales',
    href: '/security/pce-2/components/8/implementation',
    name: 'COMPOSANTS PCE 2 RÉGLAGES DES EXTENSIONS AV/AR ET DES EXTENSIONS LATÉRALES MISE EN ŒUVRE',
  },

  {
    text: 'COMPOSANTS; Les composants; MONTAGE DU CONTREPLAQUÉ SUR LES EXTENSIONS',
    href: '/security/pce-2/components/9',
    name: 'COMPOSANTS PCE 2 MONTAGE DU CONTREPLAQUÉ SUR LES EXTENSIONS',
  },
  {
    text: 'COMPOSANTS; Les composants; MONTAGE DU CONTREPLAQUÉ SUR LES EXTENSIONS; Découvrez en 3D les composants de votre PCE 2. Utiliser le bouton "?" si vous avez besoin d\'aide.',
    href: '/security/pce-2/components/9/description',
    name: 'COMPOSANTS PCE 2 MONTAGE DU CONTREPLAQUÉ SUR LES EXTENSIONS DESCRIPTIF',
  },
  {
    text: 'COMPOSANTS; Les composants; MONTAGE DU CONTREPLAQUÉ SUR LES EXTENSIONS; Découvrez en images du montage du contreplaqué sur les extensions',
    href: '/security/pce-2/components/9/implementation',
    name: 'COMPOSANTS PCE 2 MONTAGE DU CONTREPLAQUÉ SUR LES EXTENSIONS MISE EN ŒUVRE',
  },

  // PCE 2 configurators
  {
    text: 'CONFIGURATEURS;',
    href: '/security/pce-2/configurators',
    name: 'SÉCURITÉ PCE 2 CONFIGURATEURS',
  },
  {
    text: 'CONFIGURATEURS; ESCALIER BALANCE A VIDE CENTRAL;',
    href: '/security/pce-2/configurators/9',
    name: 'SÉCURITÉ PCE 2 CONFIGURATEURS ESCALIER BALANCE A VIDE CENTRAL',
  },
  {
    text: 'CONFIGURATEURS; ESCALIER A DEMI PALIER;',
    href: '/security/pce-2/configurators/8',
    name: 'SÉCURITÉ PCE 2 CONFIGURATEURS ESCALIER A DEMI PALIER',
  },
  {
    text: 'CONFIGURATEURS; ESCALIER DROIT 1 MODULE;',
    href: '/security/pce-2/configurators/6',
    name: 'SÉCURITÉ PCE 2 CONFIGURATEURS ESCALIER DROIT 1 MODULE',
  },
  {
    text: 'CONFIGURATEURS; ESCALIER HELICOIDAL;',
    href: '/security/pce-2/configurators/4',
    name: 'SÉCURITÉ PCE 2 CONFIGURATEURS ESCALIER HELICOIDAL',
  },
  {
    text: "CONFIGURATEURS; ESCALIER BALANCE A FUT;Découvrez la configuration de l'escalier droit avec 1 module A;",
    href: '/security/pce-2/configurators/2',
    name: 'SÉCURITÉ PCE 2 CONFIGURATEURS ESCALIER BALANCE A FUT',
  },

  // PCA 2
  {
    text: 'Sélectionnez une rubrique;',
    href: '/security/pca-2',
    name: 'SÉCURITÉ PCA 2',
  },

  // PCA 2 module
  {
    text: 'Découvrez en 3D les composants de votre PCA 2. Utilisez le bouton "?" si vous avez besoin d\'aide.;',
    href: '/security/pca-2/module/description',
    name: 'SÉCURITÉ PCA 2 MODULE DESCRIPTIF',
  },
  {
    text: 'PCA 2 MODULE MISE EN ŒUVRE;',
    href: '/security/pca-2/module/implementation',
    name: 'SÉCURITÉ PCA 2 MODULE MISE EN ŒUVRE',
  },
  {
    text: 'PCA 2 MODULE MISE EN ŒUVRE; logistics;',
    href: '/security/pca-2/module/implementation/logistics',
    name: 'SÉCURITÉ PCA 2 MODULE MISE EN ŒUVRE LOGISTIQUE',
  },
  {
    text: 'PCA 2 MODULE; Découvrez en vidéo le dépliage et le réglage de la hauteur du PCA 2 avec et sans sangle;',
    href: '/security/pca-2/module/implementation/unfolding',
    name: 'SÉCURITÉ PCA 2 MODULE MISE EN ŒUVRE DÉPLIAGE',
  },
  {
    text: 'PCA 2 MODULE; Découvrez en vidéo le repliage du PCA 2 avec et sans sangle;',
    href: '/security/pca-2/module/implementation/folding',
    name: 'SÉCURITÉ PCA 2 MODULE MISE EN ŒUVRE DÉPLIAGE',
  },

  //PCA 2 components
  {
    text: 'COMPOSANTS; Les composants',
    href: '/security/pca-2/components',
    name: 'COMPOSANTS PCA 2',
  },

  {
    text: "COMPOSANTS; KIT D'APPUI DÉPORTÉ",
    href: '/security/pca-2/components/2',
    name: "COMPOSANTS PCA 2 KIT D'APPUI DÉPORTÉ",
  },
  {
    text: "COMPOSANTS; KIT D'APPUI DÉPORTÉ; Conditions d'utilisation Hauteur de coffrage maximum : 3,85 m Nombre de face et descente de charge à valider selon configuration",
    href: '/security/pca-2/components/2/description',
    name: "COMPOSANTS PCA 2 KIT D'APPUI DÉPORTÉ DESCRIPTIF",
  },
  {
    text: "COMPOSANTS; KIT D'APPUI DÉPORTÉ; Découvrez en images le montage du kit d'appui déporté",
    href: '/security/pca-2/components/2/implementation',
    name: "COMPOSANTS PCA 2 KIT D'APPUI DÉPORTÉ MISE EN ŒUVRE",
  },

  {
    text: 'COMPOSANTS; KIT GRANDE HAUTEUR',
    href: '/security/pca-2/components/3',
    name: 'COMPOSANTS PCA 2 KIT GRANDE HAUTEUR',
  },
  {
    text: "COMPOSANTS; KIT GRANDE HAUTEUR; Conditions d'utilisation : Hauteur de coffrage maximum : 3,85 m Nombre de face et descente de charge à valider selon configuration Hauteur mini du PCA 2 : 3,80 m Hauteur maxi du PCA 2 : 4,80 m",
    href: '/security/pca-2/components/3/description',
    name: 'COMPOSANTS PCA 2 KIT GRANDE HAUTEUR DESCRIPTIF',
  },
  {
    text: 'COMPOSANTS; KIT GRANDE HAUTEUR; Découvrez en images le montage du kit grande hauteur',
    href: '/security/pca-2/components/3/implementation',
    name: 'COMPOSANTS PCA 2 KIT GRANDE HAUTEUR MISE EN ŒUVRE',
  },

  {
    text: 'COMPOSANTS; KIT DE JONCTION',
    href: '/security/pca-2/components/4',
    name: 'COMPOSANTS PCA 2 KIT DE JONCTION',
  },
  {
    text: "COMPOSANTS; KIT DE JONCTION; Conditions d'utilisation : Hauteur de coffrage maximum : 3,85 m Nombre de face et descente de charge à valider selon configuration Jonction des PCA 2 sur les traverses extensibles, les pieds arrières et les balanciers.",
    href: '/security/pca-2/components/4/description',
    name: 'COMPOSANTS PCA 2 KIT DE JONCTION DESCRIPTIF',
  },
  {
    text: 'COMPOSANTS; KIT DE JONCTION; Découvrez en détails le montage du kit de jonction',
    href: '/security/pca-2/components/4/implementation',
    name: 'COMPOSANTS PCA 2 KIT DE JONCTION MISE EN ŒUVRE',
  },

  {
    text: 'COMPOSANTS; KIT PROFONDEUR ET RÉGLAGE DES TRAVERSES',
    href: '/security/pca-2/components/1',
    name: 'COMPOSANTS PCA 2 KIT PROFONDEUR ET RÉGLAGE DES TRAVERSES',
  },
  {
    text: "COMPOSANTS; KIT PROFONDEUR ET RÉGLAGE DES TRAVERSES; Conditions d'utilisation : Hauteur de coffrage maximum : 3,85 m Nombre de face et descente de charge à valider selon configuration Profondeur mini : 2,40 m et à maxi : 3,20 m",
    href: '/security/pca-2/components/1/description',
    name: 'COMPOSANTS PCA 2 KIT PROFONDEUR ET RÉGLAGE DES TRAVERSES DESCRIPTIF',
  },
  {
    text: 'COMPOSANTS; KIT PROFONDEUR ET RÉGLAGE DES TRAVERSES; Découvrez en images le montage du kit profondeur et le réglage des traverses',
    href: '/security/pca-2/components/1/implementation',
    name: 'COMPOSANTS PCA 2 KIT PROFONDEUR ET RÉGLAGE DES TRAVERSES MISE EN ŒUVRE',
  },

  // PCA 2 configurators
  {
    text: 'CONFIGURATEURS;',
    href: '/security/pca-2/configurators',
    name: 'SÉCURITÉ PCA 2 CONFIGURATEURS',
  },
  {
    text: 'CONFIGURATEURS; Réglage & Découpe CP platelage',
    href: '/security/pca-2/adjustment',
    name: 'SÉCURITÉ PCA 2 CONFIGURATEUR Réglage & Découpe CP platelage',
  },
  {
    text: 'CONFIGURATEURS; RÉGLAGE DU LEVAGE ARRIÈRE; Découvrez en vidéo les détails du réglage du levage arrière du PCA 2',
    href: '/security/pca-2/adjustment',
    name: 'SÉCURITÉ PCA 2 CONFIGURATEUR RÉGLAGE DU LEVAGE ARRIÈRE',
  },

  // Innovations
  {
    text: 'INNOVATIONS',
    href: 'innovations',
    name: 'INNOVATIONS',
  },

  {
    text: 'INNOVATIONS; Assemblage hydraulique',
    href: 'innovations/5',
    name: 'INNOVATIONS Assemblage hydraulique',
  },
  {
    text: 'INNOVATIONS; Assemblage hydraulique; description',
    href: 'innovations/5/description',
    name: 'INNOVATIONS Assemblage hydraulique Description',
  },
  {
    text: "INNOVATIONS; Assemblage hydraulique; Découvrez en vidéo l'assemblage hydraulique",
    href: 'innovations/5/implementation',
    name: 'INNOVATIONS Assemblage hydraulique MISE EN ŒUVRE',
  },

  {
    text: 'INNOVATIONS; Capteur de pression (e-capt)',
    href: 'innovations/1',
    name: 'INNOVATIONS Capteur de pression (e-capt)',
  },
  {
    text: 'INNOVATIONS; Capteur de pression (e-capt); description',
    href: 'innovations/1/description',
    name: 'INNOVATIONS Capteur de pression (e-capt) Description',
  },
  {
    text: 'INNOVATIONS; Capteur de pression (e-capt); Découvrez en images le capteur de pression',
    href: 'innovations/1/implementation',
    name: 'INNOVATIONS Capteur de pression (e-capt) MISE EN ŒUVRE',
  },

  {
    text: 'INNOVATIONS; Colonne élévatrice pour PTE-C',
    href: 'innovations/7',
    name: 'INNOVATIONS Colonne élévatrice pour PTE-C',
  },
  {
    text: 'INNOVATIONS; Colonne élévatrice pour PTE-C; description',
    href: 'innovations/7/description',
    name: 'INNOVATIONS Capteur de pression (e-capt) Description',
  },
  {
    text: 'INNOVATIONS; Colonne élévatrice pour PTE-C; 00:07 Dépliage 02:31 Repliage',
    href: 'innovations/7/implementation',
    name: 'INNOVATIONS Colonne élévatrice pour PTE-C MISE EN ŒUVRE',
  },

  {
    text: 'INNOVATIONS; Colonne élévatrice pour P3D',
    href: 'innovations/8',
    name: 'INNOVATIONS Colonne élévatrice pour P3D',
  },
  {
    text: 'INNOVATIONS; Colonne élévatrice pour P3D; description',
    href: 'innovations/8/description',
    name: 'INNOVATIONS Colonne élévatrice pour P3D Description',
  },
  {
    text: 'INNOVATIONS; Colonne élévatrice pour P3D; 00:07 Dépliage 02:47 Repliage',
    href: 'innovations/8/implementation',
    name: 'INNOVATIONS Colonne élévatrice pour P3D MISE EN ŒUVRE',
  },

  {
    text: 'INNOVATIONS; Plate-forme P3D 2',
    href: '/innovations/2',
    name: 'INNOVATIONS Plate-forme P3D 2',
  },
  {
    text: 'INNOVATIONS; Plate-forme P3D 2; description',
    href: '/innovations/2/description',
    name: 'INNOVATIONS Plate-forme P3D 2 Description',
  },
  {
    text: 'INNOVATIONS; Plate-forme P3D 2; Découvrez en image les innovations de la plate-forme P3D 2',
    href: '/innovations/2/implementation',
    name: 'INNOVATIONS Plate-forme P3D 2 MISE EN ŒUVRE',
  },

  {
    text: 'INNOVATIONS; Portique hydraulique',
    href: '/innovations/3',
    name: 'INNOVATIONS Portique hydraulique',
  },
  {
    text: 'INNOVATIONS; Portique hydraulique; description',
    href: '/innovations/3/description',
    name: 'INNOVATIONS Portique hydraulique Description',
  },
  {
    text: "INNOVATIONS; Portique hydraulique; Découvrez en images l'ouverture et la fermeture du portique hydraulique",
    href: '/innovations/3/implementation',
    name: 'INNOVATIONS Portique hydraulique MISE EN ŒUVRE',
  },

  {
    text: 'INNOVATIONS; Sangle PCA 2',
    href: '/innovations/6',
    name: 'INNOVATIONS Sangle PCA 2',
  },
  {
    text: 'INNOVATIONS; Sangle PCA 2; description',
    href: '/innovations/6/description',
    name: 'INNOVATIONS Sangle PCA 2 Description',
  },
  {
    text: 'INNOVATIONS; Sangle PCA 2; Découvrez en images les sangles du PCA 2',
    href: '/innovations/6/implementation',
    name: 'INNOVATIONS Sangle PCA 2 MISE EN ŒUVRE',
  },

  {
    text: 'INNOVATIONS; Satecomap',
    href: '/innovations/4',
    name: 'INNOVATIONS Satecomap',
  },
  {
    text: 'INNOVATIONS; Satecomap; description',
    href: '/innovations/4/description',
    name: 'INNOVATIONS Satecomap Description',
  },
  {
    text: 'INNOVATIONS; Satecomap; Découvrez en images le fonctionnement du satecomap',
    href: '/innovations/4/implementation',
    name: 'INNOVATIONS Satecomap MISE EN ŒUVRE',
  },
];

export function getData(value) {
  const arr = data.filter((item) => item.text.toLowerCase().includes(value.toLowerCase()));
  return Promise.resolve(arr);
}

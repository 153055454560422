import React, { useEffect, useState } from 'react';
import { checkElement } from '@/components/SubSectionComponents/CleanFunctions.jsx';
import S from '../../../../../../styles/porticoReactImgs.module.css';
import img from '@/assets/img/PorticImgs/1panoPortic.jpg';
import img2 from '@/assets/img/PorticImgs/1pano05Portic.jpg';

function ReactImg2({ allHeightsPanel }) {
  const [find, setFind] = useState(false);
  const [find2, setFind2] = useState(false);

  useEffect(() => {
    if (
      allHeightsPanel.length === 2 &&
      (checkElement.firstMeter(allHeightsPanel) || checkElement.firstMeterAndHalf(allHeightsPanel)) &&
      checkElement.allPanels([allHeightsPanel[1]])
    ) {
      setFind(true);
    } else setFind(false);
    if (
      allHeightsPanel.length === 3 &&
      (checkElement.firstMeter(allHeightsPanel) || checkElement.firstMeterAndHalf(allHeightsPanel)) &&
      checkElement.allPanels([allHeightsPanel[1]]) &&
      checkElement.lastHalf(allHeightsPanel)
    ) {
      setFind2(true);
    } else setFind2(false);
  }, [allHeightsPanel]);

  if (!find && !find2) {
    return null;
  }

  return (
    <div className={S.container}>
      {find && (
        <div className={S.img}>
          <img src={img} width="270px" alt={'img'} />
        </div>
      )}
      {find2 && (
        <div className={S.img}>
          <img src={img2} width="270px" alt={'img'} />
        </div>
      )}
    </div>
  );
}

export default ReactImg2;

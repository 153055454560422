import React, { useEffect } from 'react';
import { Container, Typography, Box, Button, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import usePage from '../hooks/usePage.js';
import mainStyles from '../assets/js/mainStyles.js';

const useStyles = makeStyles(mainStyles);

const Home = () => {
  const classes = useStyles();

  const history = useHistory();

  const { setTitle } = usePage();

  useEffect(() => {
    setTitle('SatecoTuto');
  }, []);

  return (
    <Container maxWidth="xs">
      <Box className={classes.root2} style={{ marginTop: '15%' }}>
        <Box style={{ marginBottom: '20px' }}>
          <Typography component="div" className={classes.subTitle} style={{ fontSize: 22 }}>
            Bienvenue sur SATECOTUTO !
          </Typography>
          <Typography className={classes.subTitle} style={{ marginTop: 20 }}>
            Sélectionnez votre catégorie de produit :
          </Typography>
        </Box>
        <Button
          className={classes.outlinedBtn}
          onClick={() => history.push('/formwork/select-category')}
          variant="outlined"
        >
          Coffrage
        </Button>
        <Button
          className={classes.outlinedBtn}
          onClick={() => history.push('/security/select-category')}
          variant="outlined"
        >
          Sécurité
        </Button>
        <Button className={classes.outlinedBtn} onClick={() => history.push('/innovations')} variant="outlined">
          Innovations
        </Button>
        {/*<Button className={classes.outlinedBtn} onClick={() => history.push('/custom-products')} variant="outlined">*/}
        {/*  Produits sur mesure*/}
        {/*</Button>*/}
      </Box>
    </Container>
  );
};

export default Home;

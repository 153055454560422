import React from 'react';
import Px from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstPX/Px.jsx';
import PxPxMax from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstPX/PxPxMax.jsx';
import PxPxMin from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstPX/PxPxMin.jsx';
import PxPxPxMax from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstPX/PxPxPxMax.jsx';
import PxPxPxMin from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstPX/PxPxPxMin.jsx';
import PxPxPxRhMax from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstPX/PxPxPxRhMax.jsx';
import PxPxPxRhMin from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstPX/PxPxPxRhMin.jsx';
import PxPxRhMax from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstPX/PxPxRhMax.jsx';
import PxPxRhMin from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstPX/PxPxRhMin.jsx';
import PxPxRhRhMax from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstPX/PxPxRhRhMax.jsx';
import PxPxRhRhMin from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstPX/PxPxRhRhMin.jsx';
import PxRh from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstPX/PxRh.jsx';
import PxRhRh from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstPX/PxRhRh.jsx';

import Sh from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstMeter/Sh.jsx';
import ShPx from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstMeter/ShPx.jsx';
import ShPxPxMax from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstMeter/ShPxPxMax.jsx';
import ShPxPxMin from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstMeter/ShPxPxMin.jsx';
import ShPxPxRhMax from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstMeter/ShPxPxRhMax.jsx';
import ShPxPxRhMin from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstMeter/ShPxPxRhMin.jsx';
import ShPxPxRhRhMax from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstMeter/ShPxPxRhRhMax.jsx';
import ShPxPxRhRhMin from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstMeter/ShPxPxRhRhMin.jsx';
import ShPxRh from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstMeter/ShPxRh.jsx';
import ShPxRhRh from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstMeter/ShPxRhRh.jsx';
import ShRh from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstMeter/ShRh.jsx';
import ShRhRh from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstMeter/ShRhRh.jsx';

import SH from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstMeterAndHalf/SH.jsx';
import SHPx from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstMeterAndHalf/SHPx.jsx';
import SHPxPxMax from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstMeterAndHalf/SHPxPxMax.jsx';
import SHPxPxMin from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstMeterAndHalf/SHPxPxMin.jsx';
import SHPxPxRhMax from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstMeterAndHalf/SHPxPxRhMax.jsx';
import SHPxPxRhMin from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstMeterAndHalf/SHPxPxRhMin.jsx';
import SHPxPxRhRhMax from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstMeterAndHalf/SHPxPxRhRhMax.jsx';
import SHPxPxRhRhMin from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstMeterAndHalf/SHPxPxRhRhMin.jsx';
import SHPxRh from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstMeterAndHalf/SHPxRh.jsx';
import SHPxRhRh from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstMeterAndHalf/SHPxRhRh.jsx';
import SHRh from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstMeterAndHalf/SHRh.jsx';
import SHRhRh from '@/pages/formwork/boxes/ConfiguratorStabilizier/ImageComponents/AnchhorImgComponents/FirstMeterAndHalf/SHRhRh.jsx';

function ReactImagesAnchor({ allHeightsPanel, B, D, T, selectedSecondH }) {
  return (
    <>
      {allHeightsPanel.length > 0 && (
        <>
          <Px allHeightsPanel={allHeightsPanel} T={T} D={D} />
          <PxPxMax allHeightsPanel={allHeightsPanel} T={T} D={D} B={B} fixTop={selectedSecondH} />
          <PxPxMin allHeightsPanel={allHeightsPanel} T={T} D={D} B={B} fixTop={selectedSecondH} />
          <PxPxPxMax allHeightsPanel={allHeightsPanel} T={T} D={D} B={B} fixTop={selectedSecondH} />
          <PxPxPxMin allHeightsPanel={allHeightsPanel} T={T} D={D} B={B} fixTop={selectedSecondH} />
          <PxPxPxRhMax allHeightsPanel={allHeightsPanel} T={T} D={D} B={B} fixTop={selectedSecondH} />
          <PxPxPxRhMin allHeightsPanel={allHeightsPanel} T={T} D={D} B={B} fixTop={selectedSecondH} />
          <PxPxRhMax allHeightsPanel={allHeightsPanel} T={T} D={D} B={B} fixTop={selectedSecondH} />
          <PxPxRhMin allHeightsPanel={allHeightsPanel} T={T} D={D} B={B} fixTop={selectedSecondH} />
          <PxPxRhRhMax allHeightsPanel={allHeightsPanel} T={T} D={D} B={B} fixTop={selectedSecondH} />
          <PxPxRhRhMin allHeightsPanel={allHeightsPanel} T={T} D={D} B={B} fixTop={selectedSecondH} />
          <PxRh allHeightsPanel={allHeightsPanel} T={T} D={D} />
          <PxRhRh allHeightsPanel={allHeightsPanel} T={T} D={D} />

          <Sh allHeightsPanel={allHeightsPanel} T={T} D={D} />
          <ShPx allHeightsPanel={allHeightsPanel} T={T} D={D} />
          <ShPxPxMax allHeightsPanel={allHeightsPanel} T={T} D={D} B={B} fixTop={selectedSecondH} />
          <ShPxPxMin allHeightsPanel={allHeightsPanel} T={T} D={D} B={B} fixTop={selectedSecondH} />
          <ShPxPxRhMax allHeightsPanel={allHeightsPanel} T={T} D={D} B={B} fixTop={selectedSecondH} />
          <ShPxPxRhMin allHeightsPanel={allHeightsPanel} T={T} D={D} B={B} fixTop={selectedSecondH} />
          <ShPxPxRhRhMax allHeightsPanel={allHeightsPanel} T={T} D={D} B={B} fixTop={selectedSecondH} />
          <ShPxPxRhRhMin allHeightsPanel={allHeightsPanel} T={T} D={D} B={B} fixTop={selectedSecondH} />
          <ShPxRh allHeightsPanel={allHeightsPanel} T={T} D={D} />
          <ShPxRhRh allHeightsPanel={allHeightsPanel} T={T} D={D} />
          <ShRh allHeightsPanel={allHeightsPanel} T={T} D={D} />
          <ShRhRh allHeightsPanel={allHeightsPanel} T={T} D={D} />

          <SH allHeightsPanel={allHeightsPanel} T={T} D={D} />
          <SHPx allHeightsPanel={allHeightsPanel} T={T} D={D} />
          <SHPxPxMax allHeightsPanel={allHeightsPanel} T={T} D={D} B={B} fixTop={selectedSecondH} />
          <SHPxPxMin allHeightsPanel={allHeightsPanel} T={T} D={D} B={B} fixTop={selectedSecondH} />
          <SHPxPxRhMax allHeightsPanel={allHeightsPanel} T={T} D={D} B={B} fixTop={selectedSecondH} />
          <SHPxPxRhMin allHeightsPanel={allHeightsPanel} T={T} D={D} B={B} fixTop={selectedSecondH} />
          <SHPxPxRhRhMax allHeightsPanel={allHeightsPanel} T={T} D={D} B={B} fixTop={selectedSecondH} />
          <SHPxPxRhRhMin allHeightsPanel={allHeightsPanel} T={T} D={D} B={B} fixTop={selectedSecondH} />
          <SHPxRh allHeightsPanel={allHeightsPanel} T={T} D={D} />
          <SHPxRhRh allHeightsPanel={allHeightsPanel} T={T} D={D} />
          <SHRh allHeightsPanel={allHeightsPanel} T={T} D={D} />
          <SHRhRh allHeightsPanel={allHeightsPanel} T={T} D={D} />
        </>
      )}
    </>
  );
}

export default ReactImagesAnchor;

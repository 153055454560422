import React, { useEffect, useState } from 'react';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import Request from '@/helpers/request.js';
import usePage from '@/hooks/usePage.js';
import mainStyles from '@/assets/js/mainStyles.js';
import { BoxVideo_FAQ } from '@/pages/security/pce-2/modules/Description.jsx';

const useStyles = makeStyles(mainStyles);

const Description = () => {
  const classes = useStyles();
  const [module, setModule] = useState(null);
  const { setTitle } = usePage();

  useEffect(async () => {
    const fetchModule = async () => {
      const response = await Request.get(`/pca2-modules`);
      setModule(response.items[0]);
    };
    setTitle('DESCRIPTIF');
    await fetchModule();
  }, []);

  return (
    <Container maxWidth="md">
      <Box>
        <Box className={classes.descRoot}>
          <Typography className={classes.description}>{module?.description?.text ?? ''}</Typography>
          <div
            className={classes.videoInner}
            dangerouslySetInnerHTML={{ __html: `${module?.description?.iframe ?? ''}` }}
          />
          {/*<Card className={classes.imgCard}>*/}
          {/*  {module?.image && (*/}
          {/*    <CardMedia*/}
          {/*      className={classes.imgItem}*/}
          {/*      component="img"*/}
          {/*      image={`${Constants.BASE_URL}/${module.image.path} `}*/}
          {/*    />*/}
          {/*  )}*/}
          {/*</Card>*/}
        </Box>
        <BoxVideo_FAQ pathUrl={module?.usage?.assembly?.faq} style={classes} />
      </Box>
    </Container>
  );
};

export default Description;

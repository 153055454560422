import React, { useEffect, useState } from 'react';
import style from '../../pages/formwork/boxes/styles/optionStyle.module.css';
import S from '@/pages/formwork/boxes/styles/ConfiguratorSumming.module.css';
import { updateTotalPortico } from '@/components/SubSectionComponents/CleanFunctions.jsx';

const TypeStabilisationBy = ({ setTypeStab, totalHeight, totalWidth, doubleTopSection }) => {
  const [buttActive, setButtActive] = useState([true, false, false]);
  const [availablePortico, setAvailablePortico] = useState(true);
  useEffect(() => {
    setAvailablePortico(
      updateTotalPortico(totalHeight, totalWidth) !== 'Stabilisation par portique impossible' && !doubleTopSection,
    );
  }, [totalHeight, totalWidth, setTypeStab, doubleTopSection]);

  return (
    <div className={S.m20}>
      <div className={style.typeContainer__Title} style={{ minWidth: '320px' }}>
        TYPE DE STABILISATION
      </div>
      <div className={S.typeStabilisationBy}>
        <div
          onClick={() => {
            setButtActive([true, false, false]);
            setTypeStab('anchor');
          }}
          className={`${style.optionStab} ${buttActive[0] ? style.active : ''}`}
        >
          Par ancrage au sol
        </div>
        <div
          onClick={() => {
            setButtActive([false, true, false]);
            setTypeStab('ballast');
          }}
          className={`${style.optionStab} ${buttActive[1] ? style.active : ''} ${S.margin015}`}
        >
          Par lest
        </div>
        <div
          onClick={() => {
            if (availablePortico) {
              setButtActive([false, false, true]);
              setTypeStab('portico');
            }
          }}
          className={`${style.optionStab} ${buttActive[2] ? style.active : ''}`}
          style={{
            backgroundColor: !availablePortico ? '#D93829' : '',
            color: !availablePortico ? 'white' : '',
          }}
        >
          Par portique
        </div>
      </div>
    </div>
  );
};

export default TypeStabilisationBy;

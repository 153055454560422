import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import usePage from '@/hooks/usePage.js';
import mainStyles from '@/assets/js/mainStyles.js';
import Request from '@/helpers/request.js';
import CategoriesName from '@/helpers/CategoriesName.js';

const customStyle = {
  videoBox: {
    height: 300,
    width: 'calc(100vw - 62px)',
    '@media (min-width: 400px)': {
      height: 330,
      maxWidth: 600,
    },
    // '@media (min-width: 768px)': {
    //   height: 400,
    //   // width: 550,
    // },
    // '@media (min-width: 992px)': {
    //   height: 500,
    // },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 'calc(100vh - 230px)',
    '@media (min-width: 768px)': {
      minHeight: 'calc(100vh - 238px)',
    },
  },
  heading: {
    fontSize: 10,
    flexBasis: 'auto',
    flexShrink: 0,
    '@media (min-width: 420px)': {
      fontSize: 11,
    },
    '@media (min-width: 450px)': {
      fontSize: 12,
    },
    '@media (min-width: 500px)': {
      fontSize: 13,
    },
    '@media (min-width: 550px)': {
      fontSize: 14,
    },
    '@media (min-width: 600px)': {
      fontSize: 15,
    },
  },
  secondaryHeading: {
    fontSize: 15,
    color: '#0000008a',
  },
};
const useStyles = makeStyles(mainStyles);
const useStylesCustom = makeStyles(customStyle);
const categoryName = 'juxtaposition';

const Juxtaposition = () => {
  const classes = useStyles();
  const classesCustom = useStylesCustom();
  const history = useHistory();
  const { boxId, measureId } = useParams();
  const [box, setBox] = useState({});
  const [category, setCategory] = useState([]);
  const [description, setDescription] = useState('');
  const [expanded, setExpanded] = useState(false);
  const { setTitle } = usePage();
  useEffect(async () => {
    const fetchData = async () => {
      const [responseBox, responseMeasure] = await Promise.all([
        Request.get(`/boxes/${boxId}`),
        Request.get(`/measures/${measureId}`),
      ]);
      setBox(responseBox);
      setCategory(responseMeasure.usage[categoryName].data);
      setDescription(responseMeasure.usage[categoryName].globalDescription);
    };
    setTitle(CategoriesName[categoryName]);
    await fetchData();
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Container maxWidth="md">
      <Box className={classesCustom.root}>
        <Box>
          <Typography className={classes.description}>{description}</Typography>
          {category.map((item, key) => (
            <Accordion key={key} expanded={expanded === key} onChange={handleChange(key)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                <Typography className={`${classes.subTitle} ${classesCustom.heading}`}>{item.title}</Typography>
                {/*<Typography className={classesCustom.heading}>{item.description.slice(0, 150)}</Typography>*/}
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Typography
                    className={classes.subTitle}
                    gutterBottom
                    style={{ fontSize: 18, whiteSpace: 'pre-wrap' }}
                  >
                    {item.description}
                  </Typography>
                  <Box justifyContent={'center'} className={classesCustom.videoBox}>
                    <ReactPlayer url={item.link} width="100%" height="100%" controls />
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
        <Button className={classes.mainBtn} onClick={() => window.open('https://www.sateco.fr/fr/faq.html')}>
          FAQ
        </Button>
      </Box>
    </Container>
  );
};

export default Juxtaposition;

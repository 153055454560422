import React, { useEffect, useState } from 'react';
import usePage from '@/hooks/usePage.js';
import { conditions, defaultParams } from '@/pages/security/pca-2/adjustment/components/AdjustmentParams.jsx';
import S from '../../../../styles/adjustment.module.css';
import InputBox from './components/inputBox.jsx';
import Dimensions from '@/pages/security/pca-2/adjustment/components/Dimensions.jsx';
import Decouple from '@/pages/security/pca-2/adjustment/components/Decouple.jsx';
import LowerIndent from '@/pages/security/pca-2/adjustment/components/LowerIndent.jsx';
import Kit from '@/pages/security/pca-2/adjustment/components/Kit.jsx';
import ReactImg from '@/pages/security/pca-2/adjustment/components/ReactImg.jsx';
import { completedFields, configAlert } from '@/pages/formwork/boxes/ConfiguratorSumming.jsx';
import ExtG_ExtD from '@/pages/security/pca-2/adjustment/components/ExtG_ExtD.jsx';
import { roundTop } from '@/components/SubSectionComponents/CleanFunctions.jsx';
import AdjustmentWarnings from '@/pages/security/pca-2/adjustment/components/AdjustmentWarnings.jsx';

let continue1 = false;

const validate2Lvl = (inputParams) => {
  for (const key in inputParams) {
    if (conditions[key].skip) {
      continue;
    }

    if (
      inputParams[key] < conditions[key].min ||
      inputParams[key] > conditions[key].max ||
      inputParams[key] % conditions[key].cm !== 0
    ) {
      continue1 = false;
      return (
        <div className={S.possible}>
          <div>
            La <b>{conditions[key].title}</b> doit être dans la plage ({conditions[key].min} - {conditions[key].max}
            )cm
          </div>
          <div>REGLABLE AU PAS DE {conditions[key].cm}cm</div>
          <div>CONSULTER HOTLINE</div>
        </div>
      );
    }
    continue1 = true;
  }
};

const validate1Lvl = (inputParams) => {
  for (const key in inputParams) {
    if (inputParams[key] > 0) {
      return validate2Lvl(inputParams);
    }
  }
  return completedFields(S);
};

const Adjustment = () => {
  const { setTitle } = usePage();

  const [inputParams, setInputParams] = useState(defaultParams);
  const [dimensionParams, setDimensionParams] = useState(['', '', '']);
  const [CG_CD, setCG_CD] = useState(['', '']);
  const [AV_BV_Y_Ar_Br, setAV_BV_Y_Ar_Br] = useState(['', '', '', '', '']);
  const [extG_extD, setExtG_extD] = useState([0, 0]);
  const [haveWarnings, setHaveWarnings] = useState(false);

  const updateHaveWarnings = (childData) => {
    setHaveWarnings(childData);
  };

  const updateDimensionParams = (childData) => {
    setDimensionParams(childData);
  };

  const updateCG_CD = (childData) => {
    setCG_CD(childData);
  };

  const updateAV_BV_Y_Ar_Br = (childData) => {
    setAV_BV_Y_Ar_Br(childData);
  };

  const updateExtG_extD = (childData) => {
    setExtG_extD(childData);
  };

  const updateInputParams = (childData) => {
    setInputParams(defaultParams);
    setInputParams(childData);
  };

  useEffect(() => {
    setTitle('REGLAGE ET DECOUPE DU CP PLATELAGE');
  }, []);

  return (
    <div className={S.container}>
      <InputBox updateParams={updateInputParams} />
      <ReactImg
        inputParams={inputParams}
        dimensionParams={dimensionParams}
        CG_CD={CG_CD}
        AV_BV_Y_Ar_Br={AV_BV_Y_Ar_Br}
        alert={
          extG_extD[0] + 130 + extG_extD[1] !== roundTop(inputParams.totalWidth - 10, 5) ||
          CG_CD[0] > 35 ||
          CG_CD[1] > 35 ||
          !continue1 ||
          extG_extD[0] < 10 ||
          extG_extD[1] < 10 ||
          haveWarnings
        }
      />
      <div>{validate1Lvl(inputParams)}</div>
      {continue1 && inputParams.totalDepth > 0 && inputParams.totalHeight > 0 && inputParams.totalWidth > 0 && (
        <>
          <Dimensions inputParams={inputParams} updateParams={updateDimensionParams} />
          <ExtG_ExtD
            updateAV_BV_Y_Ar_Br={updateAV_BV_Y_Ar_Br}
            extG_extD={extG_extD}
            updateExtG_extD={updateExtG_extD}
          />

          <AdjustmentWarnings
            extG_extD={extG_extD}
            inputParams={inputParams}
            CG_CD={CG_CD}
            updateHaveWarnings={updateHaveWarnings}
          />

          <Decouple
            depth={dimensionParams[0]}
            width={dimensionParams[1]}
            updateAV_BV_Y_Ar_Br={updateAV_BV_Y_Ar_Br}
            extG_extD={extG_extD}
            visible={!haveWarnings}
          />
          <div style={{ display: 'flex' }}>
            <LowerIndent
              inputParams={inputParams}
              width={dimensionParams[1]}
              updateCG_CD={updateCG_CD}
              updateExtG_extD={updateExtG_extD}
              visible={!haveWarnings}
            />
            <Kit depth={inputParams.totalDepth} height={dimensionParams[2]} visible={!haveWarnings} />
          </div>
          {(CG_CD[0] > 35 || CG_CD[1] > 35) && <>{configAlert('DECALAGE > A 35cm INTERDIT !')}</>}
        </>
      )}
    </div>
  );
};

export default Adjustment;

import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import customConfiguratorStyle from '../../styles/CustomConfiguratorStyle.js';
import S from '@/pages/formwork/boxes/styles/ConfiguratorSumming.module.css';

function DropList({
  updateSelect,
  typeBox,
  indexBox,
  typeStab,
  indexSection,
  flagBoxH4,
  selectParams = [],
  flagStabilisation,
}) {
  const isWidthBox = indexBox === 1;
  const customStyles = makeStyles(customConfiguratorStyle)();
  const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const widthOptions = [1, 2, 3, 4];
  const top = [0, 1, 2];
  const middle = [0, 1, 2, 3, 4, 5];
  const bottom = [0, 1];
  const [select, setSelect] = useState('');

  const dropItem = (value) => {
    return (
      <MenuItem className={`${customStyles.selectList} ${S.ffSerif}`} value={value}>
        {value}
      </MenuItem>
    );
  };

  const dropItems = (options) => {
    return options.map((option, idx) => (
      <MenuItem className={`${customStyles.selectList} ${S.ffSerif}`} key={idx} value={option}>
        {option}
      </MenuItem>
    ));
  };

  // value = 0 when typeBox was changed
  useEffect(() => {
    updateSelect('');
    setSelect('');
  }, [typeBox, typeStab]);

  //auto counter = 1, for boxRS selecting heights
  useEffect(() => {
    if (typeBox === 'BoxRS' && !isWidthBox && indexSection === 1) {
      setSelect(1);
      updateSelect(1);
    }
  }, [typeBox, indexBox]);

  //update Select
  useEffect(() => {
    updateSelect(select);
  }, [select]);

  // Limit for Exter & Inter
  const limit1 =
    selectParams?.length &&
    selectParams[indexSection]?.length &&
    (selectParams[indexSection][0] === 1140 ||
      selectParams[indexSection][0] === 140 ||
      selectParams[indexSection][0] === 120 ||
      selectParams[indexSection][0] === 100 ||
      selectParams[indexSection][0] === 90);

  return (
    <FormControl variant="outlined" className={`${customStyles.formControl} ${S.ffSerif}`} style={{ padding: '10px' }}>
      {typeBox === 'BoxRS' && indexBox === 0 ? (
        <Select
          className={`${customStyles.selectBody} ${S.ffSerif}`}
          classes={{ outlined: customStyles.selectOutlined }}
          value={select}
          onChange={(e) => {
            setSelect(e.target.value);
          }}
        >
          {indexSection === 0 && flagBoxH4 && dropItem(0)}
          {dropItem(1)}
          {indexSection === 0 && flagBoxH4 && dropItem(2)}
          {/*for config summing*/}
          {selectParams.length === 2 && indexSection === 1 && !flagBoxH4 && dropItem(2)}
        </Select>
      ) : (
        <Select
          className={`${customStyles.selectBody} ${S.ffSerif}`}
          classes={{ outlined: customStyles.selectOutlined }}
          value={select}
          onChange={(e) => {
            setSelect(e.target.value);
          }}
        >
          {!flagBoxH4 && !isWidthBox && flagStabilisation && dropItems(options)}
          {!flagBoxH4 && isWidthBox && flagStabilisation && limit1 && dropItems([1])}
          {!flagBoxH4 && isWidthBox && flagStabilisation && !limit1 && dropItems(widthOptions)}

          {!flagBoxH4 && !flagStabilisation && limit1 && dropItems([1])}
          {!flagBoxH4 && !flagStabilisation && !limit1 && dropItems(options)}
          {/*Stabilite height*/}
          {flagBoxH4 && indexSection === 0 && dropItems(top)}
          {flagBoxH4 && indexSection === 1 && dropItems(middle)}
          {flagBoxH4 && indexSection === 2 && dropItems(bottom)}
        </Select>
      )}

      <InputLabel className={customStyles.selectLabel} />
    </FormControl>
  );
}

export default DropList;
